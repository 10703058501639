import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PopMessage from '../../common/messages/PopMessage';
import FormWrapper from '../../common/form/FormWrapper';
import Form from '../../common/form/Form';
import CheckField from '../../common/form/CheckField';
import stateConfig from '../../../config/state';


class MagdalaConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eligibility: false,
      catholic: false,
      permission: false,
      validationError: false,
    };

    this.handleAcknowledgement = this.handleAcknowledgement.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearPopMessage = this.clearPopMessage.bind(this);
  }

  handleAcknowledgement = field => event => {
    let obj = {};
    obj[field] = event.target.checked;
    this.setState(obj);
  }

  onSubmit(event) {
    event.preventDefault();

    const { eligibility, catholic, permission } = this.state;
    if(!eligibility || !catholic || !permission) {
      this.setState({ validationError: true });
      return;
    }
    this.props.onComplete();
  }

  clearPopMessage() {
    this.setState({ validationError: false });
  }

  render() {
    const { validationError } = this.state;

    return (
      <div className="MagdalaConfirmation registration-step">
        <Fragment>
          <p className="title"><strong>Registration Step:</strong> Confirmation</p>
          <p>
            You must acknowledge the following eligibility requirements prior to completing your registration.
          </p>
          <div id="confirmation-form" className="panel">
            <FormWrapper raised={false}>
              <Form onSubmit={this.onSubmit} autoComplete="off" submitLabel="Register">
                <CheckField 
                  name="eligibility" 
                  label="I verify that I am a religious sister registering for myself or on behalf of my community."
                  onChange={this.handleAcknowledgement('eligibility')} 
                />

                <CheckField 
                  name="catholic" 
                  label="I verify that my community has the support of our local bishop and that we live in accord with the teachings of the Catholic Church."
                  onChange={this.handleAcknowledgement('catholic')} 
                />

                <CheckField 
                  name="permission" 
                  label="I verify that I am applying to the Magdala Apostolate with the permission of my religious superior or formation directress."
                  onChange={this.handleAcknowledgement('permission')} 
                />
              </Form>
            </FormWrapper>
          </div>
        </Fragment>
        { validationError &&
          <PopMessage horizontal="center" open={true} onClose={this.clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>You must complete all acknowledgements to continue.</p>
          </PopMessage>
        }
      </div>
    );
  }
}

MagdalaConfirmation.propTypes = {
  onComplete: PropTypes.func
};

export default MagdalaConfirmation;
