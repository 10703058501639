import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import validator from '../../../../../utils/validator';
const { isEmpty } = validator;


function FreeAnswerQuestion({ question, index, scored, disabled, onChange }) {
  const [field, setField] = useState(!isEmpty(scored)? scored.value : '');

  useEffect(() => {
    let data = scored || { index, wpId: question.wpId, title: question.title, value: null, result: 'incorrect', compare: null };
    if(!isEmpty(field)) {
      data.value = field;
      data.compare = data.value;
      const match = question.answers.find(answer => answer.toLowerCase().trim() === field.toLowerCase().trim());
      data.result = match? 'correct' : 'incorrect';
    }
    onChange(data);
  }, [index, scored, field, question, onChange]);

  return (
    <div className="FreeAnswerQuestion">
      <div className="question-text">
        {`${index+1}. `}
        <span dangerouslySetInnerHTML={{__html: question.text}} />&nbsp;
        <Input 
          name="free-answer" 
          onChange={event => setField(event.target.value)} 
          value={field} 
          disabled={disabled}
        />
      </div>
      { disabled && // means we are in display only results view
        <div className={`scored-result ${scored.result}`}>
          {scored.result === 'correct'? 'Correct!' : 'Incorrect.'}
        </div>
      }
    </div>
  );
}

FreeAnswerQuestion.defaultProps = {
  scored: null,
  disabled: false
};

FreeAnswerQuestion.propTypes = {
  question: PropTypes.object,
  index: PropTypes.number,
  onChange: PropTypes.func
};

export default FreeAnswerQuestion;
