import { FAVORITES_RECEIVED, FAVORITE_ADDED, FAVORITE_REMOVED,
         WATCHED_RECEIVED, WATCHED_CHECKPOINT, WATCHED_LOGGED,
         WATCHED_LOAD_START, WATCHED_LOAD_COMPLETE, WATCHED_LOAD_ERR,
         WATCHING_JUMPPOINT } from '../actions/actionTypes';
import initialState from './initialState';
import config from '../config/config';
import { clearStateById } from '../utils/state';

function behaviorReducer(state = initialState.behavior, action) {  
  const behavior = { ...state };
  const { type, data } = action;
  let index, 
      stored = {},
      favorites = behavior.favorites.slice(),
      watched = behavior.watched.slice();
  const watchedMedia = {
    ids: state.watchedIds.slice(),
    byId: { ...state.watchedById }
  };
  let watchedStateId = `${action.key}.${action.objectId}`;
  let watchPercentage = action.data && action.data.eventType === 'Course'? config.courseCompletedWatchingPercentage : config.completedWatchingPercentage;

  switch(type) {
    case FAVORITES_RECEIVED:
      behavior.favorites = data.results;
      return behavior;
    case FAVORITE_ADDED:
      behavior.favorites = favorites.concat([action.eventId]);
      return behavior;
    case FAVORITE_REMOVED:
      index = favorites.findIndex(item => item === action.eventId);
      index = index === -1? favorites.length : index;
      favorites.splice(index, 1);
      behavior.favorites = favorites;
      return behavior;
    case WATCHED_RECEIVED:
      behavior.watchedLoaded = true;
      behavior.watched = data.results;
      return behavior;
    case WATCHED_CHECKPOINT:
      behavior.currentlyWatching = action.data;
      return behavior;
    case WATCHED_LOGGED:
      index = watched.findIndex(item => item.eventId === action.data.eventId && item.mediaId === action.data.mediaId);
      if(index !== -1) {
        stored = watched[index];
        watched.splice(index, 1);
      }
      Object.assign(stored, action.data, { completed: stored.completed || action.data.progress >= watchPercentage });
      behavior.watched = watched.concat([stored]);
      return behavior;
    case WATCHING_JUMPPOINT:
      behavior.mediaJumpPoint = action.jumpPoint;
      return behavior;
    case WATCHED_LOAD_START:
      clearStateById(watchedMedia.ids, watchedMedia.byId, watchedStateId);
      behavior.watchedIds = watchedMedia.ids;
      behavior.watchedById = watchedMedia.byId;
      return behavior;
    case WATCHED_LOAD_COMPLETE:
      watchedMedia.ids.push(watchedStateId);
      watchedMedia.byId[watchedStateId] = action.data;
      behavior.watchedIds = watchedMedia.ids;
      behavior.watchedById = watchedMedia.byId;
      return behavior;
    case WATCHED_LOAD_ERR:
      watchedMedia.ids.push(watchedStateId);
      watchedMedia.byId[watchedStateId] = { error: action.error };
      behavior.watchedIds = watchedMedia.ids;
      behavior.watchedById = watchedMedia.byId;
      return behavior;
    default:
      return state;
  }
}

export default behaviorReducer;