import React from 'react';
import PropTypes from 'prop-types';
import Form from '../../../common/form/Form';
import countries from '../../../../config/data/countries';
import states from '../../../../config/data/states';
import validator from '../../../../utils/validator';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';


export default function ProfileAddress(props) {
  const { editing, errors, data, exists, onFieldChange, onSubmit, onDelete } = props;
  const address = data || {};
  const { country = 'United States', isForeign, addressLine1, addressLine2, addressLine3,
          city, state = '', postalCode } = address;
  return (
    <div>
      { editing && 
        <div className="MemberProfile-itemEdit">
          <Form id='ProfileEditAddress-form' onSubmit={onSubmit} 
            onClickSecondary={exists? onDelete : null}
            secondaryButtonLabel="Delete" autoComplete="off" submitLabel='Save'>
            <FormControl variant="standard" className="full">
              <InputLabel id="MemberProfile-addr-country" className="FormField-label">Country</InputLabel>
              <Select 
                className="FormField-control FormField-select-labeled full country" 
                variant="standard" 
                name="country" 
                labelId="MemberProfile-addr-country"
                value={country} 
                onChange={onFieldChange}
                error={validator.isDefined(errors['country'])}
                helperText={validator.message(errors['country'], 'country')}
              >
                { 
                  countries.data.map(item => {
                    return (<MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>);
                  }) 
                }
              </Select>
              { validator.isDefined(errors['country']) &&
                <FormHelperText className="FormField-error">
                  {validator.message(errors['country'], 'country')}
                </FormHelperText>
              }
            </FormControl>
            <TextField 
              className="FormField-control full addressLine1"
              variant="standard" 
              label="Address Line 1" 
              name="addressLine1" 
              placeholder="Address Line 1" 
              value={addressLine1} 
              onChange={onFieldChange} 
              error={validator.isDefined(errors['addressLine1'])} 
              helperText={validator.message(errors['addressLine1'], 'address line 1')}
            />
            <TextField 
              className="FormField-control full addressLine2"
              variant="standard" 
              label="Address Line 2" 
              name="addressLine2" 
              placeholder="Address Line 2" 
              value={addressLine2} 
              onChange={onFieldChange} 
              error={validator.isDefined(errors['addressLine2'])} 
              helperText={validator.message(errors['addressLine2'], 'address line 2')}
            />
            { isForeign &&
              <TextField 
                className="FormField-control full addressLine3"
                variant="standard" 
                label="Address Line 3" 
                name="addressLine3" 
                placeholder="Address Line 3" 
                value={addressLine3} 
                onChange={onFieldChange} 
                error={validator.isDefined(errors['addressLine3'])} 
                helperText={validator.message(errors['addressLine3'], 'address line 3')}
              />
            }
            { !isForeign &&
              <div>
                <TextField 
                  className="FormField-control full city"
                  variant="standard" 
                  label="City" 
                  name="city" 
                  placeholder="City" 
                  value={city} 
                  onChange={onFieldChange} 
                  error={validator.isDefined(errors['city'])} 
                  helperText={validator.message(errors['city'], 'city')}
                />
                <FormControl variant="standard" className="full">
                  <InputLabel id="MemberProfile-addr-state" className="FormField-label">State</InputLabel>
                  <Select 
                    className="FormField-control FormField-select-labeled full stste" 
                    variant="standard" 
                    name="state" 
                    labelId="MemberProfile-addr-country"
                    value={state} 
                    onChange={onFieldChange}
                    error={validator.isDefined(errors['state'])}
                    helperText={validator.message(errors['state'], 'state')}
                  >
                    { 
                      states.data.map(item => {
                        return (<MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>);
                      }) 
                    }
                  </Select>
                  { validator.isDefined(errors['state']) &&
                    <FormHelperText className="FormField-error">
                      {validator.message(errors['state'], 'state')}
                    </FormHelperText>
                  }
                </FormControl>
                <TextField 
                  className="FormField-control full postalCode"
                  variant="standard" 
                  name="postalCode" 
                  label="Zip Code" 
                  placeholder="Zip Code" 
                  value={postalCode} 
                  onChange={onFieldChange} 
                  error={validator.isDefined(errors['postalCode'])} 
                  helperText={validator.message(errors['postalCode'], 'zip code')}
                />
              </div>
            }
          </Form>
        </div>
      }
    </div>
  );
}

ProfileAddress.defaultProps = {
  editing: false,
  exists: false,
  errors: {}
};

ProfileAddress.propTypes = {
  data: PropTypes.object,
  onFieldChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func
};

export function addressFromProfile(data) {
  const address = (data.addresses && data.addresses.length > 0)? data.addresses[0] : {};
  return {
    id: address._id,
    isForeign: address.isForeign,
    country: address.country || 'United States',
    addressLine1: address.addressLine1 || '',
    addressLine2: address.addressLine2 || '',
    addressLine3: address.addressLine3 || '',
    city: address.city || '',
    state: address.state || '',
    postalCode: address.postalCode || ''
  };
}

export function formatAddress(data) {
  if(!data.addressLine1) return 'No address on record';

  let displayAddress = data.addressLine1;
  if(data.isForeign) {
    displayAddress += data.addressLine2? `, ${data.addressLine2}` : '';
    displayAddress += data.addressLine3? `, ${data.addressLine3}` : '';
  } else {
    displayAddress += data.addressLine2? `, ${data.addressLine2}` : '';
    displayAddress += `, ${data.city}, ${data.state} ${data.postalCode}`;
  }

  return displayAddress;
}

export function validateAddress(data) {
  let rules = [
    { rule: validator.rules.IS_DEFINED, prop: 'country' },
    { rule: validator.rules.MATCHES_PATTERN, prop: 'addressLine1', against: validator.patterns.ADDRESS_LINE, empty: true },
    { rule: validator.rules.IS_DEFINED, prop: 'addressLine1' },
    { rule: validator.rules.MATCHES_PATTERN, prop: 'addressLine2', against: validator.patterns.ADDRESS_LINE, empty: true },
    { rule: validator.rules.MATCHES_PATTERN, prop: 'city', against: validator.patterns.CITY, empty: true },
    { rule: validator.rules.MATCHES_PATTERN, prop: 'state', against: validator.patterns.STATE, empty: true },
    { rule: validator.rules.MATCHES_PATTERN, prop: 'postalCode', against: validator.patterns.ZIP_CODE, empty: true }
  ];

  if(data.country === 'United States') {
    rules = rules.concat([
      { rule: validator.rules.IS_DEFINED, prop: 'city' },
      { rule: validator.rules.IS_DEFINED, prop: 'state' },
      { rule: validator.rules.IS_DEFINED, prop: 'postalCode' }
    ]);
  } else {
    rules = rules.concat([
      { rule: validator.rules.IS_DEFINED, prop: 'addressLine2' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'addressLine3', against: validator.patterns.ADDRESS_LINE, empty: true }
    ]);
  }

  const validationErrors = validator.validate(data, rules);
  return validationErrors? validationErrors : { valid: true };
}

