import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import * as systemAPI from '../../actions/content/system';
import StandardHeading from './heading/StandardHeading';
import MediaHeading from './heading/MediaHeading';
import DonationHeading from './heading/DonationHeading';
import urls from '../../config/urls';


function FeatureHeading({ systemContent: { featureBanner }, systemAPI }) {
  const [requested, setRequested] = useState(false);
  const [display, setDisplay] = useState(false);
  const [type, setType] = useState(null);
  const [background, setBackground] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [alignment, setAlignment] = useState(null);
  const [data, setData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const shouldDisplay = (location.pathname === urls.eventsPath);
    setDisplay(shouldDisplay);

    if(shouldDisplay && !requested) {
      setRequested(true);
      systemAPI.getFeatureBanner();
    }
  }, [location.pathname, requested, systemAPI]);

  useEffect(() => {
    if(featureBanner && featureBanner.type) {
      setTitle(featureBanner.title);
      setDescription(featureBanner.description);
      setAlignment(featureBanner.textAlignment);
      setBackground(featureBanner.background);

      if(featureBanner.type === 'donation') {
        let dObj = { ...featureBanner.data };
        dObj.amounts = dObj.amounts.split(',').map(amount => amount.trim());
        setData(dObj);
      } else {
        setData(featureBanner.data);
      }
      
      setType(featureBanner.type);
    }
  }, [featureBanner]);

  return (
    <div className={`FeatureHeading ${display && type? type : 'hidden'} text-${alignment}`}>
      { display && type !== null &&
        <Fragment>
          <div className={`background ${background.type}`}>
            { background.image &&
              <img src={background.image} alt="Feature background" />
            }
            <div className={`fill-${background.color || 'default'}`}></div>
          </div>
          <div className={`content ${background.color? `fill-${background.color}` : 'overlay'}`}>
            { type === 'standard' &&
              <StandardHeading title={title} description={description} data={data} />
            }
            { type === 'media' &&
              <MediaHeading title={title} description={description} data={data} />
            }
            { type === 'donation' &&
              <DonationHeading title={title} description={description} data={data} />
            }
          </div>
        </Fragment>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    systemContent: state.systemContent
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    systemAPI: bindActionCreators(systemAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureHeading);