import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import NumberFormatCustom from '../../../common/form/NumberFormatCustom';
import Icon, { IconTypes } from '../../../common/Icon';
import validator from '../../../../utils/validator';


function DonateAmount({ fields, errors, onFieldChange, onAmountBlur }) {
  return (
    <div className="DonateAmount">
      <h3>
        <span className="heading-text">Donation Details</span>
        <div className="secured"><Icon className="icon" type={IconTypes.LockFilled} /> Secure</div>
      </h3>
      <div className="amount">
        <TextField
          className="FormField-control"
          variant="outlined"
          label="Amount (in dollars)"
          name="amount" 
          value={fields.amount}
          onChange={onFieldChange}
          onBlur={onAmountBlur}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          error={validator.isDefined(errors['amount'])}
          helperText={validator.message(errors['amount'], 'amount')}
        />
        <RadioGroup className="frequency-field" name="frequency" value={fields.frequency} 
          onChange={onFieldChange}>
          <FormControlLabel value="one-time" control={<Radio />} label="One Time" />
          <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
        </RadioGroup>
      </div>
    </div>
  );
}

DonateAmount.propTypes = {
  fields: PropTypes.object,
  errors: PropTypes.object,
  onFieldChange: PropTypes.func,
  onAmountBlur: PropTypes.func,
};

export default DonateAmount;