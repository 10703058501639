import axios from 'axios';
import querystring from 'qs';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;
const defaultConfig = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

export async function getAuthStatus(shouldReturnToken) {
  let url = `${urls.hubAuthRoot}/ping`;
  url += shouldReturnToken? '?token=true' : '';
  return await axios.get(url);
}

export async function identifyDeviceOwner() {
  return await axios.get(`${urls.hubAuthRoot}/identify`);
}

export async function identifyWithEmail(email) {
  const params = querystring.stringify({ email });
  return await axios.post(`${urls.hubAuthRoot}/identify`, params, defaultConfig);
}

export async function login(data) {
  const params = querystring.stringify(data);
  return await axios.post(`${urls.hubAuthRoot}/login`, params, defaultConfig);
}

export async function logout() {
  return await axios.get(`${urls.hubAuthRoot}/logout`);
}

export async function sendPasscode(data) {
  const params = querystring.stringify(data);
  return await axios.post(`${urls.hubAuthRoot}/passwordless/issue`, params, defaultConfig);
}

export async function loginWithPasscode(data) {
  const params = querystring.stringify(data);
  return await axios.post(`${urls.hubAuthRoot}/passwordless/login`, params, defaultConfig);
}

export async function loginWithProvider(data) {
  return await axios.post(`${urls.hubAuthRoot}/provider/login`, data);
}

export async function rememberDevice(deviceDescription) {
  const params = querystring.stringify({ deviceDescription });
  return await axios.post(`${urls.hubAuthRoot}/device/store`, params, defaultConfig);
}

export async function loginWithDevice(shouldReturnToken) {
  let url = `${urls.hubAuthRoot}/device/login`;
  url += shouldReturnToken? '?token=true' : '';
  return await axios.get(url);
}

export async function logoutDevice() {
  return await axios.get(`${urls.hubAuthRoot}/device/logout`);
}

export async function sendPasswordResetToken(email) {
  const params = querystring.stringify({ email });
  return await axios.post(`${urls.hubAuthRoot}/password/token/issue`, params, defaultConfig);
}

export async function resetPassword(token, password) {
  const params = querystring.stringify({ token, password });
  return await axios.post(`${urls.hubAuthRoot}/password/reset`, params, defaultConfig);
}