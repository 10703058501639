import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';


function CancelDonationDialog({ donation, onStop, onContinue }) {
  return (
    <Dialog className="DonationCancel-dialog" open={true} onClose={onStop}>
      <DialogTitle className="title">Continue with cancellation?</DialogTitle>
      <DialogContent>
        <DialogContentText className="message">
          Are you sure you want to cancel this <span className="donation">${donation.amount}.00 / {donation.frequency}</span> recurring donation?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" className="continue" onClick={onContinue}>
          Yes
        </Button>
        <Button variant="text" color="secondary" onClick={onStop}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CancelDonationDialog.propTypes = {
  donation: PropTypes.object,
  onStop: PropTypes.func,
  onContinue: PropTypes.func
};

export default CancelDonationDialog;