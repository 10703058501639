import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useAnalytics from '../../../hooks/useAnalytics';
import * as postAPI from '../../../actions/content/posts';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import { resetViewToTopOfPage } from '../../../utils/utils';
import PageTitle from '../../common/PageTitle';
import Paper from '@mui/material/Paper';
import SEOMeta from '../../common/SEOMeta';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Staff({ posts, postAPI }) {
  const [loading, setLoading] = useState(true);
  const [about, setAbout] = useState(null);
  const [error, setError] = useState(null);
  useAnalytics('About: Staff');
  const stateId = `${stateConfig.keys.PAGE}.about`;
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const aboutData = posts.byId[stateId];
    if(loading && isEmpty(about) && isNotEmpty(aboutData) && posts.ids) {
      if(aboutData.error) {
        setError(aboutData.error);
      } else {
        setAbout(aboutData);
      }
      setLoading(false);
    }
  }, [loading, about, posts.ids, posts.byId, stateId]);

  initialize = () => {
    resetViewToTopOfPage();
    const aboutData = posts.byId[stateId];
    if(isNotEmpty(aboutData)) {
      setAbout(aboutData);
      setLoading(false);
    } else {
      postAPI.getPageBySlug('about');
    }
  };

  const clearPopMessage = () => {
    setError(null);
  };

  return (
    <div className="Staff">
      { !about && !error && <LoadingMessage message="Loading..." /> }

      { error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page.</p>
        </PopMessage>
      }

      <div className="content">
        { about && about.staff && !error &&
          <Fragment>
              <SEOMeta 
                title="Staff" 
                description="The Institute of Catholic Culture team" />
              <PageTitle title="Institute Staff" />
              <div className="Staff-list">
              {
                about.staff.map((person, index) => {
                  return (
                    <Paper key={`staff-${index}`} className="staff-member" elevation={4}>
                      <div className="image">
                        <img src={person.image} alt={person.name} />
                      </div>
                      <div className="description">
                        <h3>{person.name}</h3>
                        <h4>{person.title}</h4>
                        <p>{person.description}</p>
                      </div>
                    </Paper>
                  );
                })
              }
              </div>
          </Fragment>
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { posts: state.posts };
}

function mapDispatchToProps(dispatch) {
  return { 
    postAPI: bindActionCreators(postAPI, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
