import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as registrationAPI from '../../../../actions/registration/member';
import LoadingMessage from '../../../common/messages/LoadingMessage';
import ContactErrorPopMessage from '../../../common/messages/ContactErrorPopMessage';
import ProviderAuthRedirect from '../../../authentication/providers/ProviderAuthRedirect';
import ProviderRegistrationForm from './ProviderRegistrationForm';
import urls from '../../../../config/urls';
import config from '../../../../config/config';
import stateConfig from '../../../../config/state';
import errors from '../../../../config/errors';
import { localRetrieve, localStore } from '../../../../utils/store';


function ProviderRegistration({ providerId, membershipRegistration, registrationAPI }) {
  const [authorizing, setAuthorizing] = useState(true);
  const [submission, setSubmission] = useState(false);
  const [authData, setAuthData] = useState(null);
  const [popMessage, setPopMessage] = useState(null);
  const [contextParams, setContextParams] = useState({});
  const [error, setError] = useState(false);
  const { responseStatus: registrationRespStatus } = membershipRegistration;
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let completeRedirect = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(submission) {
      if(registrationRespStatus === stateConfig.responseStatus.ERROR) {
        setPopMessage(errors.MemberRegistrationError.message);
      }
  
      if(registrationRespStatus === stateConfig.responseStatus.COMPLETE) {
        localStore(config.localStorage.registrationMethod, providerId);
        completeRedirect(true);
      }
    }
  }, [registrationRespStatus, submission, providerId, completeRedirect]);

  initialize = () => {
    const context = localRetrieve(config.localStorage.registrationContextParams, true);
    setContextParams(context);
  };

  const onAuthComplete = data => {
    setAuthData(data);
    setAuthorizing(false);
  };

  const onAuthError = error => {
    setError(true);
    setAuthorizing(false);
    setPopMessage(`Error: ${providerId} authorization failed.`);
  };

  const onFormComplete = data => {
    setSubmission(true);
    const { source } = contextParams;
    const registration = { data: { ...data, source }, provider: authData };
    registrationAPI.createMembership(registration);
  };

  const clearPopMessage = () => {
    completeRedirect(false);
  };

  completeRedirect = useCallback((success) => {
    if(!contextParams || !contextParams.redirect) {
      window.location = urls.events;
    }

    const { redirect } = contextParams;
    const redirectPath = `${redirect}${redirect.includes('&')? '&' : '?'}provider=${success? 'success' : 'error'}`;
    window.location = `${urls.baseUrl}${redirectPath}`;
  }, [contextParams]);


  return (
    <div id="ProviderRegistration">
      { popMessage !== null &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>{popMessage}</p>
        </ContactErrorPopMessage>
      }
      { submission && !error &&
        <LoadingMessage message="Loading..." />
      }
      { authorizing && 
        <ProviderAuthRedirect providerId={providerId} onComplete={onAuthComplete} onError={onAuthError} />
      }
      { !authorizing && !submission && !error &&
        <ProviderRegistrationForm providerData={authData} onComplete={onFormComplete} 
          collectSalutation={contextParams.collectSalutation} submission={submission} />
      }
    </div>
  );
}

ProviderRegistration.propTypes = {
  providerId: PropTypes.string
};

function mapStateToProps(state) {
  return {
    membershipRegistration: state.membershipRegistration
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    registrationAPI: bindActionCreators(registrationAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderRegistration);