import React from 'react';
import PropTypes from 'prop-types';

export default function PageTitle(props) {
  return (
    <div className="PageTitle">
      <h1>{props.title}</h1>
      <h2>{props.subtitle}</h2>
    </div>
  );
}

PageTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};
