import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics';
import Form from '../../common/form/Form';
import FormWrapper from '../../common/form/FormWrapper';
import TextField from '@mui/material/TextField';
import Icon, { IconTypes } from '../../common/Icon';
import Heading from '../../common/Heading';
import PopMessage from '../../common/messages/PopMessage';
import ContactErrorPopMessage from '../../common/messages/ContactErrorPopMessage';
import * as profileAPI from '../../../actions/profile';
import validator from '../../../utils/validator';
import errorConfig from '../../../config/errors';
import urls from '../../../config/urls';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';


const fieldDefaults = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: ''
};

function ChangePassword({ user, profile, profileAPI }) {
  const [submitting, setSubmitting] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [passwordUpdateFailed, setPasswordUpdateFailed] = useState(false);
  const [fields, setFields] = useState(fieldDefaults);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  useAnalytics('Profile: Change Password');

  useEffect(() => {
    resetViewToTopOfPage();
    if(!user.authenticated) {
      navigate(`${urls.login}?ra=profile-password`);
    }
  }, [user, navigate]);

  useEffect(() => {
    if(submitting) {
      if(profile.responseStatus === stateConfig.responseStatus.ERROR) {
        if(profile.responseCode === errorConfig.CredentialFailure.code) {
          setIncorrectPassword(true);
        } else {
          // not a authentication or authorization error
          setPasswordUpdateFailed(true);
        }
        setSubmitting(false);
      } else if(profile.responseStatus === stateConfig.responseStatus.COMPLETE) {
        navigate(`${urls.login}?pc=true`);
        setSubmitting(false);
      }
    }
  }, [submitting, profile.responseStatus, profile.responseCode, navigate]);

  const handleFieldChange = (event) => {
    let fieldData = { ...fields };
    fieldData[event.target.name] = event.target.value;
    setFields(fieldData);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if(!validate()) {
      return;
    }

    const { currentPassword, newPassword } = fields;
    profileAPI.updatePassword({ currentPassword, newPassword });
    setSubmitting(true);
  }

  const validate = () => {
    const validationErrors = validator.validate(fields, [
      { rule: validator.rules.IS_DEFINED, prop: 'currentPassword' },
      { rule: validator.rules.PASSWORD_CHECK, prop: 'newPassword', empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'newPassword' },
      { rule: validator.rules.PASSWORD_MATCH, prop: 'passwordConfirmation', against: fields.newPassword }
    ]);
    
    if(validationErrors) {
      setErrors(validationErrors);
      return false
    }

    return true;
  };

  const clearPopMessage = () => {
    setIncorrectPassword(false);
    setPasswordUpdateFailed(false);
  };

  return (
    <div id="MemberProfilePwdChange">
      <FormWrapper>
        <Icon type={IconTypes.Password} avatar={true} />
        <Heading>Change Password</Heading>
        <Form id='ProfileChangePwd-form' onSubmit={onSubmit} autoComplete="off" submitLabel='Update'>
          <TextField
            className="FormField-control full first"
            name="currentPassword"
            label="Enter your current password"
            type="password"
            placeholder='Enter your current password'
            onChange={handleFieldChange}
            helperText={validator.message(errors['currentPassword'], 'current password')}
            error={validator.isDefined(errors['currentPassword'])}
            value={fields.currentPassword}
            variant="standard"
          />
          <TextField
            className="FormField-control full"
            name="newPassword"
            label="Enter your new password"
            type="password"
            placeholder='Enter your new password'
            onChange={handleFieldChange}
            helperText={validator.message(errors['newPassword'], 'new password')}
            error={validator.isDefined(errors['newPassword'])}
            value={fields.newPassword}
            variant="standard"
          />
          <TextField
            className="FormField-control full"
            name="passwordConfirmation"
            label="Confirm your new password"
            type="password"
            placeholder='Confirm your new password'
            onChange={handleFieldChange}
            helperText={validator.message(errors['passwordConfirmation'], 'password')}
            error={validator.isDefined(errors['passwordConfirmation'])}
            value={fields.passwordConfirmation}
            variant="standard"
          />

          { incorrectPassword &&
            <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
              type={stateConfig.messageTypes.ERROR}>
              <p>
                You did not enter the correct current password for your account. Please correct&nbsp;
                the current password and try again.
              </p>
            </PopMessage>
          }

          { passwordUpdateFailed &&
            <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
              <p>Your password change request could not be completed.</p>
            </ContactErrorPopMessage>
          }
        </Form>
      </FormWrapper>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    profile: state.profile
  };
}

function mapDispatchToProps(dispatch) {
  return { profileAPI: bindActionCreators(profileAPI, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
