import axios from 'axios';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

export async function createContact(data) {
  return await axios.post(`${urls.hubUIApiRoot}/member/contact`, data);
}

export async function getMemberRegistrationToken() {
  return await axios.get(`${urls.hubUIApiRoot}/member/registration/token`);
}

export async function createMembership(registration) {
  return await axios.post(`${urls.hubUIApiRoot}/member/registration`, registration);
}

export async function activateMember(code) {
  return await axios.get(`${urls.hubUIApiRoot}/member/registration/activation/${code}`);
}

export async function resendMemberActivation(email) {
  return await axios.post(`${urls.hubUIApiRoot}/member/registration/activation/resend`, { email });
}

export async function getEventRegistrationStatus(data) {
  const { email, eventId, identify } = data;
  return await axios.post(`${urls.hubUIApiRoot}/member/event/${eventId}/registration/status`, { email, identify });
}

export async function createEventRegistration(eventId, webinarId, registration) {
  return await axios.post(`${urls.hubUIApiRoot}/member/event/${eventId}/registration/webinar/${webinarId}`, registration, { timeout: 35000 });
}

export async function getCourseRegistrationStatus(slug) {
  return await axios.get(`${urls.hubUIApiRoot}/member/course/registration/${slug}`);
}

export async function createLiveCourseRegistration(slug, data) {
  let params = Object.assign({}, data, { syncName: data.syncName || false });
  return await axios.post(`${urls.hubUIApiRoot}/member/course/registration/live/${slug}`, params, { timeout: 25000 });
}

export async function markLiveCourseRegistrationComplete(slug) {
  return await axios.post(`${urls.hubUIApiRoot}/member/course/registration/live/${slug}/complete`, {});
}

export async function createSelfPacedCourseRegistration(slug, data) {
  return await axios.post(`${urls.hubUIApiRoot}/member/course/registration/self-paced/${slug}`, data);
}
