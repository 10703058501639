import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'qs';
import * as authAPI from '../../actions/authentication';
import Form from '../common/form/Form';
import FormWrapper from '../common/form/FormWrapper';
import TextField from '@mui/material/TextField';
import Icon, { IconTypes } from '../common/Icon';
import LinkButton from '../common/LinkButton';
import PopMessage from '../common/messages/PopMessage';
import ContactErrorPopMessage from '../common/messages/ContactErrorPopMessage';
import Heading from '../common/Heading';
import validator from '../../utils/validator';
import { resetViewToTopOfPage } from '../../utils/utils';
import urls from '../../config/urls';
import stateConfig from '../../config/state';
import config from '../../config/config';
import Button from '@mui/material/Button';


function PasswordReset({ authentication, authAPI }) {
  const [submitted, setSubmitted] = useState(false);
  const [token, setToken] = useState('');
  const [mode, setMode] = useState('');
  const [fields, setFields] = useState({
    newPassword: '',
    passwordConfirmation: ''
  });
  const [errors, setErrors] = useState({});
  const [displayError, setDisplayError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { responseStatus: authResponseStatus } = authentication;
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  initialize = () => {
    resetViewToTopOfPage();
    const params = querystring.parse(location.search.substring(1));
    setToken(params.token || 'expired');
    setMode(params.mode || 'reset');
  };

  const handleFieldChange = (event) => {
    let obj = { ...fields };
    obj[event.target.name] = event.target.value;
    setFields(obj);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if(!validate()) {
      return;
    }
    authAPI.resetPassword(token, fields.newPassword);
    setSubmitted(true);
  }

  const validate = () => {
    const validationErrors = validator.validate(fields, [
      { rule: validator.rules.PASSWORD_CHECK, prop: 'newPassword', empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'newPassword' },
      { rule: validator.rules.PASSWORD_MATCH, prop: 'passwordConfirmation', against: fields.newPassword }
    ]);
    
    if(validationErrors) {
      setErrors(validationErrors);
      return false
    }

    return true;
  }

  useEffect(() => {
    if(submitted) {
      if(authResponseStatus === stateConfig.authStatus.RESET_COMPLETE) {
        window.location = `${urls.login}?pc=true`;
      }
  
      if(authResponseStatus === stateConfig.authStatus.ERROR) {
        setDisplayError(true);
      }
    }
  }, [submitted, authResponseStatus]);

  const clearPopMessage = () => {
    if(token === 'expired') {
      navigate(urls.pwdResetRequest);
      return;
    }
    setDisplayError(false);
  }

  return (
    <div id="PasswordReset">
      { token !== 'expired' &&
        <Fragment>
          <FormWrapper>
            <Icon type={IconTypes.Password} avatar={true} />
            <Heading>Password {mode === 'setup'? 'Setup' : 'Reset'}</Heading>
            <p className="PasswordReset-instructions">
              Enter the new password you would like to use, and then confirm the new password 
              to ensure that you have typed it correctly.
            </p>
            <Form id='PasswordReset-form' onSubmit={onSubmit} autoComplete="off">
              <TextField
                className="FormField-control full first"
                name="newPassword"
                label="Enter your new password"
                type="password"
                placeholder='New Password'
                onChange={handleFieldChange}
                helperText={validator.message(errors['newPassword'], 'new password')}
                error={validator.isDefined(errors['newPassword'])}
                value={fields.newPassword}
                variant="standard"
              />

              <TextField
                className="FormField-control full"
                name="passwordConfirmation"
                label="Confirm your new password"
                type="password"
                placeholder='Confirm Password'
                onChange={handleFieldChange}
                helperText={validator.message(errors['passwordConfirmation'], 'password')}
                error={validator.isDefined(errors['passwordConfirmation'])}
                value={fields.passwordConfirmation}
                variant="standard"
              />
            </Form>
          </FormWrapper>
          { mode !== 'setup' &&
            <div className="PasswordReset-contact">
              <p>Having trouble resetting your password?</p>
              <LinkButton label="Contact Us" to={urls.contactUs} color="secondary" />
            </div>
          }
        </Fragment>
      }

      { token === 'expired' &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}
          action={
            <Button variant="outlined" onClick={clearPopMessage}>
              Try again
            </Button>
          }>
          <p>
            <strong>Token Expired.</strong><br /><br />
            You might be getting this message if your password reset request expired before&nbsp;
            you clicked on the link.  If you would like to try again we can re-issue a new&nbsp;
            password reset link to your email.
          </p>
        </PopMessage>
      }

      { displayError &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>Your password reset could not be completed.</p>
        </ContactErrorPopMessage>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authAPI: bindActionCreators(authAPI, dispatch) 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);