import { EVT_REGISTRATION_REQUEST, EVT_REGISTRATION_COMPLETE, EVT_REGISTRATION_STATUS,
         EVT_REGISTRATION_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';
import { clearStateById } from '../utils/state';

function eventRegistrationReducer(state = initialState.eventRegistration, action) {
  const { type, data, error } = action;
  const registrations = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  const { eventId } = data || {};
  let responseCode, status = stateConfig.responseStatus;
  
  switch(type) {
    case EVT_REGISTRATION_REQUEST:
      clearStateById(registrations.ids, registrations.byId, eventId);
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, ...registrations });
    case EVT_REGISTRATION_COMPLETE:
    case EVT_REGISTRATION_STATUS:
      registrations.ids.push(eventId);
      registrations.byId[eventId] = data.status;
      return Object.assign({}, state, { responseStatus: status.COMPLETE, ...registrations });
    case EVT_REGISTRATION_ERR:
      ({ code: responseCode } = error);
      registrations.ids.push(eventId);
      registrations.byId[eventId] = { error };
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode, ...registrations });
    default:
      return state;
  }
}

export default eventRegistrationReducer;