import { INSTRUCTOR_LOAD_START, INSTRUCTOR_LOAD_COMPLETE, INSTRUCTOR_LOAD_ERR } from '../actionTypes';
import * as instructors from '../../api/content/instructors';
import stateConfig from '../../config/state';

const itemKey = stateConfig.keys.INSTRUCTOR;
const listKey = stateConfig.keys.INSTRUCTOR_LIST;

export function getInstructors() {
  const slug = 'instructors';
  return async dispatch => {
    dispatch({ type: INSTRUCTOR_LOAD_START, key: listKey, objectId: slug });
    try {
      let result = await instructors.getInstructors();
      dispatch({ type: INSTRUCTOR_LOAD_COMPLETE, data: result.data, key: listKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: INSTRUCTOR_LOAD_ERR, error: err, key: listKey, objectId: slug });
    }
  };
}

export function getInstructorById(id) {
  return async dispatch => {
    dispatch({ type: INSTRUCTOR_LOAD_START, key: itemKey, objectId: id });
    try {
      let result = await instructors.getInstructorById(id);
      dispatch({ type: INSTRUCTOR_LOAD_COMPLETE, data: result.data, key: itemKey, objectId: id });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: INSTRUCTOR_LOAD_ERR, error: err, key: itemKey, objectId: id });
    }
  };
}
