import React, { useEffect } from 'react';
import PageTitle from '../../common/PageTitle';
import useAnalytics from '../../../hooks/useAnalytics';
import { resetViewToTopOfPage } from '../../../utils/utils';
import SEOMeta from '../../common/SEOMeta';


function About() {
  useAnalytics('About');

  useEffect(() => {
    resetViewToTopOfPage();
  }, []);
  
  return (
    <div className="About">
      <SEOMeta 
        title="About the Institute" 
        description="The Institute of Catholic Culture is an adult catechetical organization, faithful to the Magisterium of the Catholic Church, and dedicated to the Church’s call for a new evangelization." />
      <PageTitle title="About the Institute"
        subtitle='Working to fulfill the Church’s call for a "New Evangelization."' />
      <div className="About-mission">
        <h2>Mission Statement</h2>
        <p>
          The Institute of Catholic Culture is an adult catechetical organization, faithful to the Magisterium 
          of the Catholic Church, and dedicated to the Church’s call for a new evangelization. The Institute 
          seeks to fulfill its mission by offering educational programs structured upon the classical liberal 
          arts and by offering opportunities in which authentic Catholic culture is experienced and lived.
        </p>
      </div>
      <div className="About-letter">
        <h2>A Letter from our Founder</h2>
        <h3>Open wide the arms of Christ!</h3>
        <p><strong>Dear Friend in Christ,</strong></p>
        <div className="letter-content">
          <p>
            A number of years ago I was one of millions of “fallen away Catholics.” Unknown to me at that time 
            was God’s plan to put my life to work for Him. Today, I have dedicated my life to making sure that 
            others do not take lightly God’s gift of eternal life. I am convinced that in order to restore the 
            Catholic Church to her former glory, we must begin by educating the faithful in the Church’s great 
            inherited tradition and we must once again reach out an evangelical hand to those outside the Catholic 
            Church, welcoming them to experience the fullness of the Kingdom of God on earth. “Today it is up to 
            you, brothers and sisters . . . to offer the Risen Christ to your fellow citizens.” (P. Benedict XVI) 
            While many Catholics today have a deep desire to grow in their faith and share the Faith with others, 
            few are equipped with the educational tools to do so. Most Catholic parishes have instituted a 
            catechetical program for children, yet few have heeded the Church’s call for continued adult faith 
            formation and the need for evangelical outreach. In the General Directory for Catechesis, the Church 
            has stated clearly, “Catechesis for adults, since it deals with persons who are capable of an adherence 
            that is fully responsible, must be considered the chief form of catechesis” (GDC, 59).
          </p>
          <p>
            Sadly, most Catholics today, if they have received any catechetical formation at all, must rely on an 
            elementary-level training, usually received in preparation for first Holy Communion and Confirmation. 
            With this level of education in the faith, how can Catholics be expected to express the faith to their 
            fellow citizens in a cogent and convincing manner? In an age when the basic teachings of the Catholic 
            Faith have been neglected and the faithful are in great need of intellectual faith formation, the 
            Institute of Catholic Culture stands as a sign of hope. Taking our Lord’s final command to the Apostles 
            to “make disciples of all nations” as our own, the Institute of Catholic Culture was founded in answer 
            to the Church’s call for a “New Evangelization.”
          </p>
          <p>
            To this end, the Institute hosts educational seminars specifically designed to build bridges of 
            understanding, teaching authentic Catholic history, philosophy and theology as a way of healing the wounds 
            in the Body of Christ and reaching out to those who seek knowledge of the Truth. “This saving truth, at the 
            heart of the deposit of faith, must remain the foundation of all the Church’s endeavors, proposed to others 
            always with respect but also without compromise.” (P. Benedict XVI) This is a daunting task, but one that 
            we must undertake!
          </p>
          <p>
            Within this web site, you will learn about the ICC’s exciting mission, its traditional curriculum structured 
            upon the classical liberal arts, how you can help us renew adult faith formation in the Catholic Church, and 
            be able to access a great number of quality, orthodox educational programs. I hope you enjoy all the resources 
            provide, and please feel free to contact me at director@instituteofcatholicculture.org if you have any questions 
            or feedback.
          </p>
          <p><strong>In Christ,</strong></p>
          <div className="signature">
            <img src="https://instituteofcatholicculture.net/wp-content/uploads/2019/09/hcarnazzo.png" alt="Father Hezekias Carnazzo" />
            <div className="name-and-title">
              <p className="name">Fr. Hezekias (Sabatino) Carnazzo</p>
              <p className="title">Founding Executive Director, Institute of Catholic Culture</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;