import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import querystring from 'qs';
import useAnalytics from '../../hooks/useAnalytics';
import * as donationsAPI from '../../actions/donations';
import PopMessage from '../common/messages/PopMessage';
import ContactErrorPopMessage from '../common/messages/ContactErrorPopMessage';
import LoadingMessage from '../common/messages/LoadingMessage';
import DonationDisclaimer from './donations/DonationDisclaimer';
import RecurringDonations from './donations/RecurringDonations';
import ScheduledDonations from './donations/ScheduledDonations';
import DonationHistory from './donations/DonationHistory';
import UpdateDonationAmount from './donations/UpdateDonationAmount';
import UpdateDonationCard from './donations/UpdateDonationCard';
import CancelDonationDialog from './donations/CancelDonationDialog';
import stateConfig from '../../config/state';
import config from '../../config/config';
import errorsConfig from '../../config/errors';
import urls from '../../config/urls';
import { resetViewToTopOfPage } from '../../utils/utils';


function Donations({ donations, donationsAPI }) {
  const [loading, setLoading] = useState(false); 
  const [reloading, setReloading] = useState(false); 
  const [processing, setProcessing] = useState(false); 
  const [error, setError] = useState(null);
  const [history, setHistory] = useState(null);
  const [update, setUpdate] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [updateError, setUpdateError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  useAnalytics('Donations');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    resetViewToTopOfPage();
    donationsAPI.getDonationHistory();
    setLoading(true);
  }, [donationsAPI]);

  useEffect(() => {
    const { responseStatus, responseCode, updateResponseStatus, history: donationHistory } = donations;
    if(loading && responseStatus === stateConfig.responseStatus.COMPLETE) {
      setHistory(donationHistory);
      setLoading(false);
      donationsAPI.resetDonationTransactionState();
    } else if(reloading && donationHistory !== null && updateResponseStatus === stateConfig.responseStatus.COMPLETE) {
      setHistory(donationHistory);
      donationsAPI.resetDonationTransactionState();
    } else if(loading && responseStatus === stateConfig.responseStatus.ERROR) {
      if(responseCode === errorsConfig.ReAuthRequired.code) {
        navigate(`${urls.login}?ra=donations`);
      } else {
        setError('Your donation records could not be loaded at this time.');
        setLoading(false);
        donationsAPI.resetDonationTransactionState();
      }
    }
  }, [loading, reloading, donations, donationsAPI, navigate]);

  useEffect(() => {
    const { updateResponseStatus } = donations;
    if(processing && update && updateResponseStatus === stateConfig.responseStatus.COMPLETE) {
      setHistory(null);
      setUpdate(null);
      setProcessing(false);
      setReloading(true);
      window.location = `${urls.myDonations}?update=${update.type === 'cancel'? 'canceled' : 'changed'}`;
    } else if(processing && update && updateResponseStatus === stateConfig.responseStatus.ERROR) {
      setUpdateError(`Your ${update.type === 'cancel'? 'cancellation' : 'update'} request failed to complete.`);
      setUpdate(null);
      setProcessing(false);
    }
  }, [processing, update, donations, donationsAPI]);

  initialize = () => {
    const params = querystring.parse(location.search.substring(1));
    if(params.update === 'canceled') {
      setUpdateSuccess('Your recurring donation was successfully canceled');
    } else if(params.update === 'changed') {
      setUpdateSuccess('Your recurring donation was successfully updated');
    }
  };

  const onUpdate = (type, donation) => () => {
    setUpdate({ type, donation, confirmed: false });
  };

  const stopUpdate = () => {
    setUpdate(null);
  };

  const performCancel = () => {
    setProcessing(true);
    resetViewToTopOfPage();
    setUpdate(Object.assign({}, update, { confirmed: true }));
    const { recurringDonationId, amount } = update.donation;
    donationsAPI.updateRecurringDonation(recurringDonationId, { amount, action: 'cancel' });
  };

  const performAmountUpdate = amount => {
    setProcessing(true);
    resetViewToTopOfPage();
    donationsAPI.updateRecurringDonation(update.donation.recurringDonationId, { amount, action: 'update-amount' });
  };

  const performCardUpdate = card => {
    setProcessing(true);
    resetViewToTopOfPage();
    donationsAPI.updateRecurringDonationCard(update.donation.customerId, update.donation.recurringDonationId, card);
  };

  const clearPopMessage = () => {
    setUpdateSuccess(null);
    setUpdateError(null);
  };

  return (
    <div className="Donations">
      <div className="Donations-loading">
        { (loading || reloading) && !error && <LoadingMessage message="Loading..." /> }
        { processing && <LoadingMessage message="Processing..." /> }
      </div>
      { !loading && !reloading && !processing && !error && (update == null || (update !== null && update.type === 'cancel')) &&
        <div className="content">
          <h1>My Donations</h1>
          <DonationDisclaimer />
          { history && history.recurring && 
            <RecurringDonations defaultCustomerId={history.defaultCustomerId} donations={history.recurring} onUpdate={onUpdate} />
          }
          { history && history.scheduled && 
            <ScheduledDonations donations={history.scheduled} />
          }
          { history && history.donations &&
            <DonationHistory donations={history.donations} />
          }
        </div>
      }
      { update !== null && update.type === 'update-amount' &&
        <UpdateDonationAmount donation={update.donation} onSubmit={performAmountUpdate} onCancel={stopUpdate} />
      }
      { update !== null && update.type === 'update-card' &&
        <UpdateDonationCard donation={update.donation} onSubmit={performCardUpdate} onCancel={stopUpdate} />
      }
      { update !== null && update.type === 'cancel' && !update.confirmed &&
        <CancelDonationDialog donation={update.donation} onStop={stopUpdate} onContinue={performCancel} />
      }
      { updateSuccess !== null &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>{updateSuccess}</p>
        </PopMessage>
      }
      { (error !== null || updateError !== null) &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>{error || updateError}</p>
        </ContactErrorPopMessage>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    donations: state.donations
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    donationsAPI: bindActionCreators(donationsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Donations);
