import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as courseRegistrationAPI from '../../../../actions/registration/course';
import * as appDisplayAPI from '../../../../actions/appDisplay';
import PopMessage from '../../../common/messages/PopMessage';
import stateConfig from '../../../../config/state';
import validator from '../../../../utils/validator';
const { isEmpty } = validator;


function CourseRegistration({ course, onRegistration, courseRegistration, courseRegistrationAPI, appDisplayAPI }) {
  const [started, setStarted] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [track, setTrack] = useState('certificate');
  const [error, setError] = useState(null);
  const [displayHelp, setDisplayHelp] = useState(false);

  const register = () => {
    let selected = null;
    if(!started && course.tracks === 'cert-audit') {
      setStarted(true);
      return;
    } else if(!started && course.tracks !== 'cert-audit') {
      selected = course.tracks;
    } else {
      selected = track;
    }

    const { id, slug, title, subtitle, transcriptCode, courseCode } = course;
    const courseSlug = slug.replace('course-', '');
    const courseTitle = `${title}${!isEmpty(subtitle)? `: ${subtitle}` : ''}`;
    const data = {
      courseId: id,
      track: selected,
      details: {
        title: courseTitle,
        transcriptCode,
        courseCode
      }
    };
    courseRegistrationAPI.createSelfPacedCourseRegistration(courseSlug, data);
  };

  useEffect(() => {
    const slug = course.slug.replace('course-', '');
    if(!registered && courseRegistration.ids.includes(slug)) {
      const data = courseRegistration.byId[slug];
      if(data) {
        if(data.error) {
          setError({ display: true });
        } else {
          setRegistered(true);
          appDisplayAPI.displayPageMessage({
            message: "You have successfully registered!",
            type: stateConfig.messageTypes.SUCCESS,
            time: 10
          });
          onRegistration();
        }
      }
    }
  }, [registered, course, onRegistration, courseRegistration.ids, courseRegistration.byId, appDisplayAPI]);

  const clearPopMessage = () => {
    setError({ display: false });
  };

  return (
    <div className="CourseRegistration">
      { !isEmpty(error) && error.display &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>We could not complete this registration.</p>
        </PopMessage>
      }
      { !started && !registered &&
        <div className="CourseRegistration-init">
          { course.type === 'live' &&
            <div className="message-content">
              <h4>Closed Course</h4>
              <p className="message">This course can only be accessed by registered students.</p>
            </div>
          }
          { course.type !== 'live' &&
            <Fragment>
              <div className="message-content">
                <h4>Get Registered</h4>
                <p className="message">Enroll to start accessing course content immediately.</p>
              </div>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={register}>
                Register
              </Button>
            </Fragment>
          }
        </div>
      }
      { started && !registered &&
        <div className="CourseRegistration-started">
          <p className="message">Please indicate which track you would like to register for:</p>
          <div className="CourseRegistration-form">
            <RadioGroup 
              className="tracks" 
              name="tracks" 
              value={track} 
              onChange={({ target: { value } }) => setTrack(value)}
            >
              <FormControlLabel value="certificate" control={<Radio />} label="Certificate" />
              <FormControlLabel value="audit" control={<Radio />} label="Audit" />
            </RadioGroup>
            <Button 
              variant="contained" 
              color="secondary" 
              onClick={register}>
              Submit
            </Button>
          </div>
          <p className="CourseRegistration-help">
            <Button variant="text" onClick={() => setDisplayHelp(!displayHelp)}>
              {displayHelp? 'Hide this information' : 'Learn about these registration options'}
            </Button>
          </p>
          <Collapse className="CourseRegistration-info" in={displayHelp}>
            <p>
              <strong>Certificate:</strong> The Certificate Track allows you to take assessments to check your knowledge. 
              Upon completion of the course, you will take a Final Exam and be able to print a certificate with your name 
              and the date that you completed the course. The Certificate Track also gives you access to everything included 
              in the Audit Track.
            </p>
            <p>
              <strong>Audit:</strong> The Audit Track gives you access to the lecture videos, readings, and further study 
              resources. Assessments are not included and you will not receive a certificate at the end.
            </p>
          </Collapse>
        </div>
      }
    </div>
  );
}

CourseRegistration.propTypes = {
  course: PropTypes.object,
  onRegistration: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    courseRegistration: state.courseRegistration
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    courseRegistrationAPI: bindActionCreators(courseRegistrationAPI, dispatch),
    appDisplayAPI: bindActionCreators(appDisplayAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseRegistration);
