import { SHARE_LINK_REQUEST, SHARE_LINK_COMPLETE, SHARE_LINK_RETRIEVED, SHARE_LINK_ERR,
         SHARE_TOKEN_REQUEST, SHARE_TOKEN_VALIDATED, SHARE_TOKEN_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function shareReducer(state = initialState.share, action) {
  const { type, data = {}, error = {} } = action;
  let responseCode, status = stateConfig.responseStatus;

  switch(type) {
    case SHARE_LINK_REQUEST:
    case SHARE_TOKEN_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, identified: false, eventSlug: null, eventLink: null });
    case SHARE_LINK_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.COMPLETE });
    case SHARE_LINK_RETRIEVED:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, eventLink: data.eventLink });
    case SHARE_TOKEN_VALIDATED:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, identified: data.identified, eventSlug: data.eventSlug });
    case SHARE_LINK_ERR:
    case SHARE_TOKEN_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default shareReducer;