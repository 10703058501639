import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../hooks/useAnalytics';
import Banner from './content/home/Banner';
import Offerings from './content/home/Offerings';
import Pitch from './content/home/Pitch';
import Testimonials from './content/home/Testimonials';
import urls from '../config/urls';
import { resetViewToTopOfPage } from '../utils/utils';


function Home({ user }) {
  const [userDataCheckedOnInit] = useState(user.userDataChecked);
  const navigate = useNavigate();
  useAnalytics('Home');

  useEffect(() => {
    resetViewToTopOfPage();
  }, []);

  useEffect(() => {
    /* 
      We only want this redirect to occur when the user data has not yet been retrieved
      (as on site load) and there is an identified/authenticated user
      1. Navigating from another part of the site (i.e., user data already checked) - no redirect
      2. This is the first page loaded (i.e., user data not checked)
        - redirect if user check produces an identified/authenticated user
        - no redirect if user check produces no user
    */
    if(!userDataCheckedOnInit && Boolean(user.email)) {
      navigate(urls.events);
    }
  }, [userDataCheckedOnInit, user.email, navigate]);

  return (
    <Fragment>
      { user.userDataChecked && // make sure we don't display anything before init user load
        <div className="Home">
          <Banner />
          <Offerings />
          <Pitch />
          <Testimonials />
        </div>
      }
    </Fragment>
  );
}

function mapStateToProps(state) {
  return { 
    user: state.user 
  };
}

export default connect(mapStateToProps)(Home);
