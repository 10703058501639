import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NotFoundContent from './NotFoundContent';
import urls from '../config/urls';


function NotFound() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkForMigratedURL();
  });

  const checkForMigratedURL = () => {
    const { pathname } = location;
    let matched = urls.migrationRedirects.find(item => item.oldPath === pathname);
    matched = matched || urls.migrationRedirects.find(item => `${item.oldPath}/` === pathname);
    if(matched && !matched.external) {
      navigate(matched.newPath);
    } else if(matched && matched.external) {
      window.location = matched.newPath;
    }
  };

  return (
    <NotFoundContent />
  );
}

export default NotFound;