import React from 'react';
import PropTypes from 'prop-types';
import Icon, { IconTypes } from '../../common/Icon';
import Button from '@mui/material/Button';


class Reflection extends React.Component {
  constructor(props) {
    super(props);
    this.onReflectionSelection = this.onReflectionSelection.bind(this);
  }
  
  onReflectionSelection = (mediaType, reflection) => event => {
    if(mediaType === 'reading') {
      window.open(reflection.reading.url, '_blank');
      return;
    }

    const reflectionData = {
      id: reflection.id,
      media: {
        video: reflection.video? [ reflection.video ] : [],
        audio: reflection.audio? [ reflection.audio ] : []
      }
    };
    
    this.props.onSelection(mediaType, reflectionData);
  }

  render() {
    const { reflection } = this.props;
    const { title, description, video, audio, reading } = reflection;
    
    return (
      <div className="reflection">
        <h4>{title}</h4>
        <div dangerouslySetInnerHTML={{__html: description}} />
        <div className="actions">
          { video &&
            <Button variant="outlined" color="primary" 
              onClick={this.onReflectionSelection('video', reflection)}>
              <Icon type={IconTypes.OnDemand} /> Watch
            </Button>
          }
          { audio &&
            <Button variant="outlined" color="primary" 
              onClick={this.onReflectionSelection('audio', reflection)}>
              <Icon type={IconTypes.Listen} /> Listen
            </Button>
          }
          { reading &&
            <Button variant="outlined" color="primary" 
              onClick={this.onReflectionSelection('reading', reflection)}>
              <Icon type={IconTypes.Document} /> Read
            </Button>
          }
        </div>
      </div>
    );
  }  
}

Reflection.propTypes = {
  reflection: PropTypes.object,
  onSelection: PropTypes.func
};

export default Reflection;