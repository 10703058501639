import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as annotationsAPI from '../../../actions/annotations';
import Form from '../../common/form/Form';
import validator from '../../../utils/validator';
import TextField from '@mui/material/TextField';
import { truncate } from '../../../utils/format';

function AnnotationsForm({ eventData, selectedAnnotation, onClose, annotationsAPI, currentlyWatching, useMediaId, mediaId }) {
  const [note, setNote] = useState(''); 
  const [noteLength, setNoteLength] = useState(0); 
  const [errors, setErrors] = useState([]); 

  useEffect(() => {
    if(selectedAnnotation) {
      setNote(selectedAnnotation.text); 
    }
  }, [selectedAnnotation]); 

  const validate = () => {
    const data = {note: note};
    const validationErrors = validator.validate(data, [
      { rule: validator.rules.IS_DEFINED, prop: 'note' }
    ]);

    if(validationErrors) {
      setErrors(validationErrors); 
      return false
    }
    return true;
  }

  const onSubmit = event => {
    event.preventDefault(); 
    if(!validate()){
      return;
    }

    let truncatedNote = note;
    if(note && note.length > 5000) {
      truncatedNote = truncate(truncatedNote, 5000);  
    }
  
    let time = 0; 
    if(selectedAnnotation && selectedAnnotation.time) {
      time = selectedAnnotation.time;
    } else if(currentlyWatching && currentlyWatching.time) {
      time = currentlyWatching.time; 
    }

    let data = { eventId: eventData.id, text: truncatedNote };
    
    // Value for mediaId differs based on context (i.e. annotations for course vs lecture)
    let mediaIdForContext = useMediaId? mediaId : currentlyWatching.id; 
      Object.assign(data, {
        mediaId: mediaIdForContext,
        mediaType: currentlyWatching.type,
        time: time
      }); 
    
    if(selectedAnnotation) {
      Object.assign(data, { annotationId: selectedAnnotation.annotationId }); 
    }
    
    annotationsAPI.addAnnotation(data); 
    onClose(); 
  }

  const handleNoteChange = event => {
    setNote(event.target.value); 
    setNoteLength(event.target.value.length); 
  }


  return (
    <div className="AnnotationForm">
      <div className="heading">
        <h4>Add a new annotation</h4>
      </div>
      <Form onSubmit={onSubmit} submitLabel="Save" secondaryButtonLabel="Cancel" onClickSecondary={onClose} autoComplete="off">
        <TextField 
          className="FormField-control full"
          variant="outlined" 
          name="note" 
          label="Note" 
          value={note}
          placeholder='Enter a note...' 
          onChange={handleNoteChange} 
          helperText={validator.message(errors['note'], 'note')}
          error={validator.isDefined(errors['note'])}
          autoFocus={true} 
          multiline 
          rows="4" 
          inputProps={{style: {fontSize: "0.9em"}}}
        />
        <p className={`length ${noteLength > 5000? 'long' : 'normal'}`}>
          Note length: {noteLength}. { noteLength > 5000? 'Limited to 5000 characters.' : '' }
        </p>
      </Form>
    </div>
  )
}

AnnotationsForm.propTypes = {
  eventData: PropTypes.object,
  selectedAnnotation: PropTypes.object,
  onClose: PropTypes.func,
  useMediaId: PropTypes.bool,
  mediaId: PropTypes.string
};

function mapStateToProps(state) {
  const { currentlyWatching } = state.behavior;
  return { annotations: state.annotations, currentlyWatching };
}

function mapDispatchToProps(dispatch) {
  return { 
    annotationsAPI: bindActionCreators(annotationsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationsForm);