import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import stateConfig from '../../../../config/state';
import validator from '../../../../utils/validator';
const { isEmpty } = validator;


function CourseInfo({ course, topic, courseComplete, onStatusChange, courseProgress }) {
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    if(!logged && !isEmpty(course) && !isEmpty(topic)) {
      const stateId = `${stateConfig.keys.PROGRESS}.${course.id}`;
      if(courseProgress.ids.includes(stateId)) {
        const data = courseProgress.byId[stateId];
        const progressData = data && !data.error? data.progress : [];
        const progress = progressData.find(item => item.partId === topic.id);
        if(!progress && topic.required) {
          onStatusChange('complete', 'info');
        }
        setLogged(true);
      }
    }
  }, [logged, course, topic, onStatusChange, courseProgress]);

  return (
    <Fragment>
      { !topic.required &&
        <p className="CourseInfo-instructions">
          These resources are available for you to dive deeper into the topics of this lesson. 
          Viewing or completing these items will not be tracked in this course, and exploring these topics 
          is not necessary to complete this course.
        </p>
      }
      { (logged || courseComplete) && topic.required &&
        <p className="status-message">You have viewed this topic</p>
      }
    </Fragment>
  );
}

CourseInfo.propTypes = {
  course: PropTypes.object,
  topic: PropTypes.object,
  courseComplete: PropTypes.bool,
  onStatusChange: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    courseProgress: state.courseProgress
  };
}

export default connect(mapStateToProps)(CourseInfo);
