import { CRS_REGISTRATION_REQUEST, CRS_REGISTRATION_STATUS, CRS_REGISTRATION_COMPLETE, 
         CRS_REGISTRATION_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';
import { clearStateById } from '../utils/state';

function courseRegistrationReducer(state = initialState.courseRegistration, action) {
  const { type, slug, data, error } = action;
  const registrations = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let responseCode, status = stateConfig.responseStatus;
  
  switch(type) {
    case CRS_REGISTRATION_REQUEST:
      clearStateById(registrations.ids, registrations.byId, slug);
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, ...registrations });
    case CRS_REGISTRATION_STATUS:
      registrations.ids.push(slug);
      registrations.byId[slug] = data;
      return Object.assign({}, state, { responseStatus: status.COMPLETE, ...registrations });
    case CRS_REGISTRATION_COMPLETE:
      registrations.ids.push(slug);
      registrations.byId[slug] = { success: true };
      return Object.assign({}, state, { responseStatus: status.COMPLETE, ...registrations });
    case CRS_REGISTRATION_ERR:
      ({ code: responseCode } = error);
      registrations.ids.push(slug);
      registrations.byId[slug] = { error };
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode, ...registrations });
    default:
      return state;
  }
}

export default courseRegistrationReducer;