
export function cleanInput(input) {
  return typeof input === 'string'? input.trim() : input;
}

export function cleanEmailInput(email) {
  return email? cleanInput(email).toLowerCase() : email;
}

export function cleanSpamCheckInput(input) {
  const numbers = [
    'zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
    'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen',
    'nineteen', 'twenty'
  ];

  const check = input.trim().toLowerCase();
  const index = numbers.findIndex(item => check === item);
  return index !== -1? index : check;
}
