import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as authAPI from '../../../../actions/authentication';
import Icon, { IconTypes } from '../../../common/Icon';
import config from '../../../../config/config';
import urls from '../../../../config/urls';
import { localStore } from '../../../../utils/store';


function RegistrationProviders({ contextParams, authAPI }) {
  const navigate = useNavigate();

  const navigateToProvider = provider => () => {
    localStore(config.localStorage.registrationContextParams, contextParams, true);
    authAPI.startProviderLogin();
    setTimeout(() => {
      if(provider === 'google') {
        navigate(urls.memberGoogleRegistration);
      } else {
        navigate(urls.memberFacebookRegistration);
      }
    }, 250);
  };

  return (
    <Fragment>
      <div className="GoogleRegistration">
        <Button variant="contained" onClick={navigateToProvider('google')}>
          <Icon type={IconTypes.ServiceGoogle} /> Register using Google
        </Button>
      </div>
      <div className="FacebookRegistration">
        <Button variant="contained" onClick={navigateToProvider('facebook')}>
          <Icon type={IconTypes.ServiceFacebook} /> Register using Facebook
        </Button>
      </div>
    </Fragment>
  );
}

RegistrationProviders.propTypes = {
  contextParams: PropTypes.object
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { 
    authAPI: bindActionCreators(authAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationProviders);
