import axios from 'axios';
import urls from '../../config/urls';
import config from '../../config/config';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

const reflectionsPath = '/content/reflections';

export async function getReflections(type) {
  return await axios.get(`${urls.hubUIApiRoot}${reflectionsPath}/${type}`, { timeout: config.wpTimeout });
}

export async function getReflectionBySlug(slug) {
  return await axios.get(`${urls.hubUIApiRoot}${reflectionsPath}/slug/${slug}`, { timeout: config.wpTimeout });
}
