import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Form from '../../../common/form/Form';
import CheckField from '../../../common/form/CheckField';
import PopMessage from '../../../common/messages/PopMessage';
import validator from '../../../../utils/validator';
import urls from '../../../../config/urls';
import stateConfig from '../../../../config/state';


function determineName(providerData) {
  const { name } = providerData;
  if(validator.isNotEmpty(name)) {
    const firstName = name.split(' ')[0].trim();
    const lastName = name.replace(firstName, '').trim();
    return { firstName, lastName };
  }
  return { firstName: '', lastName: '' };
};

function ProviderRegistrationForm({ providerData, collectSalutation, submission, onComplete }) {
  const name = determineName(providerData);
  const [fields, setFields] = useState({
    email: providerData.email,
    salutation: '',
    firstName: name.firstName,
    lastName: name.lastName,
  });
  const [privacySelection, setPrivacySelection] = useState(false);
  const [errors, setErrors] = useState({});
  const [popMessage, setPopMessage] = useState(null);

  const handleFieldChange = event => {
    let obj = { ...fields };
    obj[event.target.name] = event.target.value;
    setFields(obj);
  };

  const handleAcknowledgement = event => {
    setPrivacySelection(event.target.checked);
  };

  const onSubmit = event => {
    event.preventDefault();
    if(!privacySelection) {
      setPopMessage('You must acknowledge your acceptance of the privacy policy.');
      return;
    }

    if(!validate()) {
      return;
    }

    onComplete(fields);
  };

  const clearPopMessage = () => {
    setPopMessage(null);
  };

  const validate = () => {
    let rules = [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'salutation', against: validator.patterns.SALUTATION, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'firstName', against: validator.patterns.NAME, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'firstName' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'lastName', against: validator.patterns.NAME, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'lastName' }
    ];
    
    const validationErrors = validator.validate(fields, rules);
    if(validationErrors) {
      setErrors(validationErrors);
      setPopMessage('Please correct the specified fields and re-submit.');
      return false
    }

    return true;
  };

  return (
    <div id="ProviderRegistrationForm">
      <h2>Complete your member registration</h2>
      <Form id='ProviderRegistrationForm-form' onSubmit={onSubmit} autoComplete="off"
        submitLabel="Continue" submission={submission}>
        
        <div className="email">
          <p className="label">Email</p>
          <p className="value">{fields.email}</p>
        </div>

        { collectSalutation &&
          <TextField
            className="FormField-control full"
            name="salutation"
            label="Title (e.g. Mr., Mrs., Dr., etc.)"
            placeholder='Title'
            onChange={handleFieldChange}
            helperText={validator.message(errors['salutation'], 'title')}
            error={validator.isDefined(errors['salutation'])}
            value={fields.salutation}
            variant="standard"
          />
        }

        <TextField
          className="FormField-control full"
          name="firstName"
          label="First Name *"
          placeholder='First Name'
          onChange={handleFieldChange}
          helperText={validator.message(errors['firstName'], 'first name')}
          error={validator.isDefined(errors['firstName'])}
          value={fields.firstName}
          variant="standard"
        />

        <TextField
          className="FormField-control full"
          name="lastName"
          label="Last Name *"
          placeholder='Last Name'
          onChange={handleFieldChange}
          helperText={validator.message(errors['lastName'], 'last name')}
          error={validator.isDefined(errors['lastName'])}
          value={fields.lastName}
          variant="standard"
        />

        <div className="ProviderRegistrationForm-policy">
          <p>
            Confidentiality is important to us. Please review our 
            &nbsp;<a href={urls.privacyPolicy} target="_blank" rel="noopener noreferrer">privacy policy</a> 
            &nbsp;before submitting your registration.
          </p>
          <CheckField name="acknowledge" label="I have reviewed the privacy policy" onChange={handleAcknowledgement} />
        </div>
      </Form>

      { popMessage &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>{popMessage}</p>
        </PopMessage>
      }
    </div>
  );
}

ProviderRegistrationForm.defaultProps = {
  collectSalutation: false,
  submission: false
};

ProviderRegistrationForm.propTypes = {
  providerData: PropTypes.object,
  onComplete: PropTypes.func
};

export default ProviderRegistrationForm;