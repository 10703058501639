import React, { useEffect, useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import LoadingMessage from '../../../../common/messages/LoadingMessage';


function QuestionSync({ questionTotal, answered, lastChange, lastSaved, onSave, onSubmit }) {
  const [loaded, setLoaded] = useState(false);
  const timer = useRef(null);

  useEffect(() => {
    if(!loaded) {
      if(timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        setLoaded(true);
      }, 1000);
    }
  }, [loaded, timer, lastChange]);

  const howLongSinceSave = () => {
    const diff = Date.now() - lastSaved;
    if(lastSaved === 0) {
      return `(Not saved)`;
    } else if(diff < 1000) {
      return `(Saved)`;
    } else if(diff < 60000) {
      return `(Last saved ${parseInt(diff/1000)}s ago)`;
    } else {
      return `(Last saved ${parseInt(diff/60000)}m ago)`;
    }
  };

  return (
    <div className="QuestionSync">
      { !loaded &&
        <LoadingMessage message="Loading..." />
      }
      { loaded &&
        <Fragment>
          <div className="update">
            <p className="answered">{`${answered} of ${questionTotal} answered`}</p>
            <p className="saved">{howLongSinceSave()}</p>
          </div>
          { answered !== 0 &&
            <Fragment>
              <Button className="save-btn" variant="outlined" color="primary" onClick={onSave}>
                Save
              </Button>
              <Button className={`submit-btn ${(answered !== questionTotal)? 'disabled' : 'enabled'}`} variant="contained" onClick={onSubmit} disabled={answered !== questionTotal}>
                Submit
              </Button>
            </Fragment>
          }
        </Fragment>
      }
    </div>
  );
}

QuestionSync.propTypes = {
  questionTotal: PropTypes.number,
  answered: PropTypes.number,
  lastChange: PropTypes.number,
  lastSaved: PropTypes.number,
  onSave: PropTypes.func,
  onSubmit: PropTypes.func
};

export default QuestionSync;
