import { PROFILE_REQUEST, PROFILE_RETRIEVED, PROFILE_UPDATED, PASSWORD_UPDATED,
         PROFILE_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function profileReducer(state = initialState.profile, action) {
  const { type, data = {}, error = {} } = action;
  let responseCode, status = stateConfig.responseStatus;
  
  switch(type) {
    case PROFILE_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, dataUpdated: false });
    case PROFILE_RETRIEVED:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, data });
    case PROFILE_UPDATED:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, data, dataUpdated: true });
    case PASSWORD_UPDATED:
      return Object.assign({}, state, { responseStatus: status.COMPLETE });
    case PROFILE_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default profileReducer;