import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import config from '../../config/config';

function useWatched(content) {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState(null);
  const watched = useSelector(state => state.behavior.watched);
  const watchedLoaded = useSelector(state => state.behavior.watchedLoaded);
  let determineNextMediaItem = config.emptyFn;

  useEffect(() => {
    if(!loaded && watchedLoaded) {
      setLoaded(true);
      let logs = watched.filter(log => log.eventId === content.id);
      let nextVideo = determineNextMediaItem('video');
      let nextAudio = determineNextMediaItem('audio');
      setData({ logs, nextVideo, nextAudio });
    }
  }, [loaded, watchedLoaded, watched, content.id, determineNextMediaItem]);

  determineNextMediaItem = mediaType => {
    const media = mediaType === 'video'? content.media.video : content.media.audio;
    const mediaLogs = watched.filter(log => log.eventId === content.id && log.mediaType === mediaType);
    
    for(let i=0; i<media.length; ++i) {
      let log = mediaLogs.find(log => log.mediaId === media[i].id);
      if(!log || !log.completed) {
        return { type: mediaType, media: media[i], log };
      }
    }

    // if we get here we didn't find an uncompleted media element of this type, see if any exist
    if(media && media.length > 0) {
      return { type: mediaType, media: media[0] };
    }

    return null;
  };

  return data;
}

export default useWatched;