import React from 'react';
import PropTypes from 'prop-types';
import DonationEmbed from '../common/DonationEmbed';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


function DonationContent({ title, description, data, onUpdate }) {
  return (
    <div className="DonationContent">
      <div className="DonationContent-body">
        <div className="main">
          <h2 dangerouslySetInnerHTML={{__html: title}} />
          { !isEmpty(description) &&
            <p dangerouslySetInnerHTML={{__html: description}} />
          }
        </div>
        <DonationEmbed data={data} onUpdate={onUpdate} />
      </div>
    </div>
  );
}

DonationContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object,
  onUpdate: PropTypes.func
};

export default DonationContent;