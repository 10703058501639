import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as accountDeletionAPI from '../../../actions/account/accountDeletion';
import CheckField from '../../common/form/CheckField';
import PopMessage from '../../common/messages/PopMessage';
import AccountConfirmation from './AccountConfirmation';
import urls from '../../../config/urls';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


function AccountDeletion({ user, accountDeletion, accountDeletionAPI }) {
  const [init, setInit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if(!init) {
      resetViewToTopOfPage();
      if(isEmpty(user.email) || !user.authenticated) {
        navigate(`${urls.login}?ra=deletion`);
      }
      setInit(true);
    }
  }, [init, accountDeletionAPI, user.email, user.authenticated, navigate]);

  const handleAcknowledgement = ({ target: { checked } }) => {
    setAcknowledged(checked);
  };

  const sendDeletionRequest = () => {
    if(!acknowledged) {
      setError({ display: true, message: 'You must acknowledge your understanding of the deletion conditions.' });
      return;
    }
    setSubmitted(true);
    accountDeletionAPI.sendAccountDeletionEmail();
  };

  useEffect(() => {
    if(submitted && !emailSent) {
      const { responseStatus } = accountDeletion;
      if(responseStatus === stateConfig.responseStatus.ERROR) {
        setSubmitted(false);
        setError({ display: true, message: 'We ran into an error while trying to process your request.' });
      } else if(responseStatus === stateConfig.responseStatus.COMPLETE) {
        setEmailSent(true);
      }
    }
  }, [submitted, emailSent, accountDeletion]);

  const clearPopMessage = () => {
    setError({ display: false });
  };

  return (
    <div className="AccountDeletion">
      { !isEmpty(error) && error.display &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>{error.message}</p>
        </PopMessage>
      }
      { init && !confirmed &&
        <AccountConfirmation email={user.email} onComplete={() => setConfirmed(true)} />
      }
      { init && confirmed && !emailSent &&
        <div className="AccountDeletion-content">
          <h1>Account Deletion Request</h1>
          <p className="AccountDeletion-message">
            We require users to maintain a membership account in order to access most Institute of Catholic Culture resources, as described in 
            our <a href={urls.privacyPolicy} target="_blank" rel="noopener noreferrer">privacy policy</a>. If you no longer want a membership 
            account, you have the right to delete your account and have your personal data forgotten.
          </p>
          <p className="AccountDeletion-message">
            Please note that we will maintain data that we are required to retain by law (e.g., donation records), 
            but we will delete all other records.
          </p>
          <p className="AccountDeletion-message">
            <strong>This process is irreversible</strong>, and you will need to create a new membership if 
            you want to access Institute content in the future.
          </p>
          <div className="AccountDeletion-action">
            <CheckField 
              name="acknowledge" 
              label="I understand, and I wish to delete my account"
              onChange={handleAcknowledgement} 
            />
            <div className="AccountDeletion-submit">
              <Button variant="contained" color="primary" onClick={sendDeletionRequest} disabled={submitted}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      }
      { emailSent &&
        <div className="AccountDeletion-completed">
          <h3>You have one more step to take.</h3>
          <p className="AccountDeletion-message">
            For security reasons, you must confirm your deletion request. We have sent you an email to confirm the request. To complete your 
            account deletion, click on the confirmation button in the email. We take this extra step to ensure that you own and have access 
            to this email account.
          </p>
          <p className="AccountDeletion-message">
            <strong>If you do not confirm your deletion your account will not be deleted.</strong>
          </p>
          <p className="AccountDeletion-message">
            If you do not receive the confirmation email to complete your account deletion you may also 
            reach out to our staff through our&nbsp;
            <a href={urls.contactUs} target="_blank" rel="noopener noreferrer">contact form</a>.
          </p>
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    user: state.user,
    accountDeletion: state.accountDeletion
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    accountDeletionAPI: bindActionCreators(accountDeletionAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDeletion);