import axios from 'axios';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

export async function getFavorites() {
  return await axios.get(`${urls.hubUIApiRoot}/member/favorites`);
}

export async function getFavoritesContent() {
  return await axios.get(`${urls.hubUIApiRoot}/member/favorites/content`);
}

export async function addFavorite(eventId) {
  return await axios.post(`${urls.hubUIApiRoot}/member/favorites/${eventId}`);
}

export async function removeFavorite(eventId) {
  return await axios.delete(`${urls.hubUIApiRoot}/member/favorites/${eventId}`);
}

export async function getWatched() {
  return await axios.get(`${urls.hubUIApiRoot}/member/watched`);
}

export async function getWatchedMedia(eventId, mediaId) {
  return await axios.get(`${urls.hubUIApiRoot}/member/watched/events/${eventId}/media/${mediaId}`);
}

export async function getWatchedContent() {
  return await axios.get(`${urls.hubUIApiRoot}/member/watched/content`);
}

export async function logWatched(data) {
  return await axios.post(`${urls.hubUIApiRoot}/member/watched`, data);
}

export async function trackSessionEvent(data) {
  return await axios.post(`${urls.hubUIApiRoot}/tracking/session`, data);
}

export async function trackEventAttendance(eventId, sessionId) {
  try {
    return await callWithRetry(`${urls.hubUIApiRoot}/tracking/attendance/event/${eventId}/${sessionId}`);
  } catch(error) {
    // fail silently
  }
}

export async function trackCourseAttendance(courseId, topicId) {
  try {
    return await callWithRetry(`${urls.hubUIApiRoot}/tracking/attendance/course/${courseId}/${topicId}`);
  } catch(error) {
    // fail silently
  }
}

// retry up to 3 times
async function callWithRetry(url, tried = 0) {
  if(tried === 3) return;
  const tries = tried + 1;

  try {
    const response = await axios.post(url);
    if(response.data && response.data.success) {
      return;
    } 
  } catch(error) {
    // will retry
  }

  return await callWithRetry(url, tries);
}
