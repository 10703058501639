import React from 'react';
import PropTypes from 'prop-types';
import FilterItem from './FilterItem';
import validator from '../../../utils/validator'


function isSelectedFilter(filters, slug) {
  return filters.find(item => item.slug === slug);
}

function FilterGroup(props) {
  const { heading, itemClass, filterType, selectedFilters, filterMap, onAdd, onRemove } = props;

  return (
    <div className={`filter-group ${itemClass}`}>
      <h3>{heading}</h3>
      <div className="content">
        {
          filterMap.map((item, index) => {
            let data = item.slug? item : { name: item, slug: item };
            let isSelected = validator.isDefined(isSelectedFilter(selectedFilters, data.slug));
            return (
              <FilterItem 
                key={`${itemClass}-${index}`} 
                itemClass={itemClass} selected={isSelected} 
                data={{ type: filterType, ...data }} 
                onAdd={onAdd} 
                onRemove={onRemove} />
            );
          })
        }
      </div>
    </div>
  );
}

FilterGroup.propTypes = {
  heading: PropTypes.string,
  itemClass: PropTypes.string,
  filterType: PropTypes.string,
  selectedFilters: PropTypes.array,
  filterMap: PropTypes.array,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func
};

export default FilterGroup;