import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import * as authentication from '../../../../actions/authentication';
import validator from '../../../../utils/validator';


class ApplicationEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailConfirmation: '',
      error: '',
      confirmationError: ''
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
  }

  handleFieldChange(event) {
    let update = {};
    update[event.target.name] = event.target.value;
    this.setState(update);
  }

  onSubmit(event) {
    if(event && event.type === 'keydown' && event.key !== 'Enter') {
      return;
    }

    const { email, emailConfirmation } = this.state;
    let e1 = email.trim(), e2 = emailConfirmation.trim();
    if(!validator.matchesPattern(e1, validator.patterns.EMAIL)) {
      this.setState({ error: 'The email you entered is not valid.' })
      return;
    } else {
      this.setState({ error: '' })
    }
    
    if(!validator.matchesPattern(e2, validator.patterns.EMAIL)) {
      this.setState({ confirmationError: 'The email you entered is not valid.' })
      return;
    } else if(e1 !== e2) {
      this.setState({ confirmationError: 'The email addresses you entered do not match' })
      return;
    } else {
      this.setState({ confirmationError: '' })
    }

    this.checkEmail();
  }

  async checkEmail() {
    const { email } = this.state;

    try {
      await this.props.authentication.identifyWithEmail(email);
    } catch(error) {
      this.props.onContinue(email);
    }
  }

  render() {
    const { email, emailConfirmation, error, confirmationError } = this.state;

    return (
      <div className="ApplicationEmail application-step">
        <p className="title"><strong>Pre-Application Step:</strong> Email Address</p>
        <p>
          If you are already a member, please enter the email address associated with your membership. If you are 
          not a member, please enter your preferred email address. We will use this address to communicate with you 
          throughout the course.
        </p>
        <div className="field-container">
          <div className='email-field-container'>
            <TextField 
              className="email-field"
              name="email"
              placeholder="Enter your email..."
              inputProps={{ 'aria-label': 'applicant email' }} 
              value={email}
              error={validator.isNotEmpty(error)}
              onChange={this.handleFieldChange}
            />
            { validator.isNotEmpty(error) &&
              <FormHelperText error={true}>{error}</FormHelperText>
            }
          </div>
          <div className='email-confirmation-field-container'>
            <TextField 
              className="email-confirmation-field"
              name="emailConfirmation"
              placeholder="Verify your email..."
              inputProps={{ 'aria-label': 'applicant email verification' }} 
              value={emailConfirmation}
              error={validator.isNotEmpty(confirmationError)}
              onChange={this.handleFieldChange}
            />
            { validator.isNotEmpty(confirmationError) &&
              <FormHelperText error={true}>{confirmationError}</FormHelperText>
            }
          </div>
        </div>
        <Button variant="contained" color="primary" onClick={this.onSubmit}>Continue</Button>
      </div>
    );
  }
}

ApplicationEmail.propTypes = {
  onContinue: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { 
    authentication: bindActionCreators(authentication, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationEmail);
