import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';


function PageBanner({ pageBanner }) {
  const [display, setDisplay] = useState(false);
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const location = useLocation();

  useEffect(() => {
    setDisplay(false);
    setImage('');
    setTitle('');
    setSubtitle('');
  }, [location]);

  useEffect(() => {
    if(pageBanner.id) {
      setTimeout(() => {
        setImage(pageBanner.image);
        setTitle(pageBanner.title);
        setSubtitle(pageBanner.subtitle);
        setDisplay(true);
      }, 250); // slight delay to ensure it fires after the history listener
    }
  }, [pageBanner]);

  return (
    <div className={`PageBanner ${display? 'displayed' : 'hidden'}`}>
      { display &&
        <Fragment>
          <div className="feature">
            <img src={image} alt={title} />
          </div>
          <div className="tagline">
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
          </div>
        </Fragment>
      }
    </div>
  );
}

function mapStateToProps(state) {
  const { pageBanner } = state.appDisplay;
  return { pageBanner };
}

export default connect(mapStateToProps)(PageBanner);