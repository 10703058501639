import React, { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';

export default forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        fixedDecimalScale={true}
        thousandSeparator=","
        valueIsNumericString
        prefix="$"
      />
    );
  },
);
