import React, { Fragment } from 'react'; 
import PropTypes from 'prop-types';


function TitleWithSubtext(props) {
	const hideDivider = props.hideDivider; 
	const { title, text } = props.titleWithSubtext; 
	
	let divider = "divider-bar"; 
	let sectionDescription = "section-description"; 
	if (hideDivider) {
			divider = "divider-bar-hidden"; 
			sectionDescription = "section-description-no-divider"
	}

	return (
		<Fragment>
			<div className="TitleWithSubtextContainer">
				{ props.useHeadingForPage? 
						(<h1>{title}</h1>) :
						(<h3>{title}</h3>)
				}
				<div className={divider}></div>
				<div className={sectionDescription}>
				{text}
				</div>
			</div>
		</Fragment>
	)
}

TitleWithSubtext.defaultProps = {
	useHeadingForPage: false
};

TitleWithSubtext.propTypes = {
	titleWithSubtext: PropTypes.object
}

export default TitleWithSubtext; 