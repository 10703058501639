import { EVENT_LOAD_START, EVENT_LOAD_COMPLETE, EVENT_LOAD_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function eventsReducer(state = initialState.events, action) {
  const events = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.objectId}`;
  
  switch(action.type) {
    case EVENT_LOAD_START:
      clearStateById(events.ids, events.byId, stateId);
      return events;
    case EVENT_LOAD_COMPLETE:
      events.ids.push(stateId);
      events.byId[stateId] = action.data;
      return events;
    case EVENT_LOAD_ERR:
      events.ids.push(stateId);
      events.byId[stateId] = { error: action.error };
      return events;
    default:
      return state;
  }
}

export default eventsReducer;