import React, { useState, useEffect, Fragment } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import useAnalytics from '../../../hooks/useAnalytics';
import DonateForm from './DonateForm';
import DonateSuccess from './DonateSuccess';
import DonateHeading from './layout/DonateHeading';
import DonateOther from './layout/DonateOther';
import DonateInformation from './layout/DonateInformation';
import { resetViewToTopOfPage } from '../../../utils/utils';
import SEOMeta from '../../common/SEOMeta';

const stripeInitPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


function Donate(props) {
  const [complete, setComplete] = useState(false);
  useAnalytics('Donate');

  useEffect(() => {
    resetViewToTopOfPage();
  }, []);

  const onComplete = () => {
    setComplete(true);
  };

  return (
    <div className="Donate">
      <SEOMeta 
        title="Donate" 
        description="Support the Institute. Give, and it shall be given to you - Luke 6:38." />

      { !complete &&
        <Fragment>
          <DonateHeading />
          <div className="Donate-form">
            <Elements stripe={stripeInitPromise}>
              <DonateForm onComplete={onComplete} />
            </Elements>
          </div>
          <DonateOther />
          <DonateInformation />
        </Fragment>
      }
      { complete &&
        <DonateSuccess />
      }
    </div>
  );
}

export default Donate;