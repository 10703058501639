import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Collapse from '@mui/material/Collapse';
import CourseLists from './CourseLists';
import urls from '../../../../config/urls';
import config from '../../../../config/config';
const { deviceBreakpoints } = config;


function CourseDescription({ course }) {
  const [displayInstructors, setDisplayInstructors] = useState([]);
  const { description, lists, instructors } = course;
  const isSmallView = useMediaQuery({
    query: `(max-width: ${deviceBreakpoints.small}px)`
  });

  const showInstructorBio = instructorId => () => {
    let arr = displayInstructors.slice();
    arr.push(instructorId);
    setDisplayInstructors(arr);
  }

  return (
    <div className="CourseDescription">
      { description &&
        <Fragment>
          <h3>Course Description</h3>
          <div className="summary" dangerouslySetInnerHTML={{__html: description}} />
        </Fragment>
      }
      { lists && 
        <CourseLists lists={lists} />
      }
      <div className="instructors">
        <h3>Course { instructors.length === 1? 'Instructor' : 'Instructors' }</h3>
        {
          instructors.map(instructor => {
            return (
              <div key={instructor.id} className="instructor">
                <div className="thumbnail">
                  <img src={instructor.imageURL} alt={`${instructor.name}`} />
                </div>
                <div>
                  <h4 className="name">{instructor.name}</h4>
                  { instructors.length === 1 && !isSmallView && // display bio by default
                    <div className="bio" dangerouslySetInnerHTML={{__html: instructor.bio}} />
                  }
                  { (instructors.length > 1 || isSmallView) && // display expandable bio
                    <Collapse in={displayInstructors.includes(instructor.id)}>
                      <div className="bio" dangerouslySetInnerHTML={{__html: instructor.bio}} />
                    </Collapse>
                  }
                  <div className="links">
                    { (instructors.length > 1 || isSmallView) && !displayInstructors.includes(instructor.id) && 
                      <button className="bio-link link-display" onClick={showInstructorBio(instructor.id)}>
                        View bio
                      </button>
                    }
                    <Link className="more-link" to={urls.instructor.replace(':instructorId', instructor.id)}>
                      More from this instructor
                    </Link>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

CourseDescription.propTypes = {
  course: PropTypes.object
};

export default CourseDescription;
