import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';


function FilterItem(props) {
  const { itemClass, selected, data, onAdd, onRemove } = props;

  return (
    <div className="item">
      { !selected &&
        <div onClick={onAdd(data)}>{data.count? `${data.name} (${data.count})` : data.name}</div>
      }
      { selected &&
        <Chip label={data.name} className={itemClass} onDelete={onRemove(data)} />
      }
    </div>
  );
}

FilterItem.propTypes = {
  itemClass: PropTypes.string,
  selected: PropTypes.bool,
  data: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func
};

export default FilterItem;