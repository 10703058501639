import React, { useState, useEffect } from 'react';
import querystring from 'qs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as accountDeletionAPI from '../../../actions/account/accountDeletion';
import * as authAPI from '../../../actions/authentication';
import PopMessage from '../../common/messages/PopMessage';
import LoadingMessage from '../../common/messages/LoadingMessage';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function AccountDeletion({ accountDeletion, accountDeletionAPI, authAPI, location }) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(!init) {
      resetViewToTopOfPage();
      const params = querystring.parse(window.location.search.substring(1));
      const token = params.token
      if(isNotEmpty(token)) {
        accountDeletionAPI.deleteAccount(token);
        setLoading(true);
      } else {
        setLoading(false);
        setError({ display: true, message: 'Your account deletion link was invalid.' });
      }
      setInit(true);
    }
  }, [init, accountDeletionAPI, location]);

  useEffect(() => {
    if(init && loading && !completed) {
      const { responseStatus, pending: deletionPending } = accountDeletion;
      if(responseStatus === stateConfig.responseStatus.ERROR) {
        setLoading(false);
        setError({ display: true, message: 'We ran into an error while trying to process your request.' });
      } else if(responseStatus === stateConfig.responseStatus.COMPLETE) {
        setLoading(false);
        setCompleted(true);
        setPending(deletionPending);
        authAPI.logout();
      }
    }
  }, [init, loading, completed, accountDeletion, authAPI]);

  const clearPopMessage = () => {
    setError({ display: false });
  };

  return (
    <div className="AccountDeletion">
      { loading &&
        <LoadingMessage message="Loading..." />
      }
      { !isEmpty(error) && error.display &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>{error.message}</p>
        </PopMessage>
      }
      { completed && pending &&
        <div className="AccountDeletion-confirmed">
          <p className="AccountDeletion-message">
            Your account deletion has been received and will be processed shortly by our staff. We hope 
            to see you again in the future, and we're always here to help you grow in your Catholic faith.
          </p>
        </div>
      }
      { completed && !pending &&
        <div className="AccountDeletion-confirmed">
          <p className="AccountDeletion-message">
            Your account deletion has been completed. We hope to see you again in the future, and we're 
            always here to help you grow in your Catholic faith.
          </p>
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    accountDeletion: state.accountDeletion
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    accountDeletionAPI: bindActionCreators(accountDeletionAPI, dispatch),
    authAPI: bindActionCreators(authAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountDeletion);