import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import * as authenticationAPI from '../../../actions/authentication';
import PopMessage from '../../common/messages/PopMessage';
import config from '../../../config/config';
import urls from '../../../config/urls';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import ArrowIcon from '@mui/icons-material/ArrowForward';
import validator from '../../../utils/validator';
const { isNotEmpty } = validator;


function RestrictedContentPrompt({ onComplete, user, authenticationAPI }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if(isNotEmpty(user.email)) {
      if(onComplete) {
        onComplete();
      } else {
        window.location.reload();
      }
    }
  }, [user.email, onComplete]);

  const handleFieldChange = (event) => {
    setEmail(event.target.value);
  };

  const handleBrowseLoginRequest = (event) => {
    if(event && event.type === 'keydown' && event.key !== 'Enter') {
      return;
    }

    if(!validator.matchesPattern(email, validator.patterns.EMAIL)) {
      setError('The email you entered is not valid.');
      return;
    }

    authenticationAPI.identifyWithEmail(email)
      .catch(error => {
        setError('We were unable to locate your membership account. Check the email address you entered for mistakes.');
      });
  };

  const clearPopMessage = () => {
    setError(null);
  };

  const memberRegistrationSelected = () => {
    navigate(urls.memberRegistration);
  };

  return (
    <div className="RestrictedContentPrompt">
      { error &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>{error}</p>
        </PopMessage>
      }
      <div className="content">
        <h3>This feature is only available to members.</h3>
        <p>
          If you already have a membership with us then provide your member email
          to get started.
        </p>
        <Paper className="identify">
          <InputBase
            className="browse-id-field"
            placeholder="Enter your email..."
            inputProps={{ 'aria-label': 'identify registered member' }}
            value={email}
            onKeyDown={handleBrowseLoginRequest}
            onChange={handleFieldChange} />
          <IconButton className="login-icon icon" onClick={handleBrowseLoginRequest}>
            <ArrowIcon />
          </IconButton>
        </Paper>
        <Paper className="pitch" elevation={4}>
          <h4>Not a member?</h4>
          <p>
            Signing up is quick, easy, and free. Get instant access to over {config.libraryHoursOfContent} hours 
            of audio &amp; video, study tools, and more.
          </p>
          <div className="actions">
            <Button variant="contained" color="primary" onClick={memberRegistrationSelected}>
              Become a Member
            </Button>
          </div>
        </Paper>
      </div>
    </div>
  );
}

RestrictedContentPrompt.propTypes = {
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authenticationAPI: bindActionCreators(authenticationAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RestrictedContentPrompt);
