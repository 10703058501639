import config from "../config";
import validator from "../../utils/validator";
const { isEmpty } = validator;

const eventDefinitions = {

  /*
  Authentication Events
  */
  loginComplete: (method) => {
    return { 
      name: 'login', 
      data: { method } 
    }
  },

  /*
  Content Selection Events
  */
  listen: (contentId, audioId) => { 
    return { 
      name: 'select_content', 
      data: { content_type: 'audio', content_id: contentId, media_id: audioId } 
    }
  },
  watch: (contentId, videoId) => { 
    return { 
      name: 'select_content', 
      data: { content_type: 'video', content_id: contentId, media_id: videoId } 
    }
  },


  /*
  Donation Events
  */

  donationForm: (fields) => {
    const { campaign } = fields;
    const contentId = isEmpty(campaign)? 'donation_default' : `donation_${campaign}`;
    return { 
      name: 'select_content', 
      data: { content_type: 'donation_form', content_id: contentId } 
    }
  },
  donationRedirect: (service) => {
    return { 
      name: 'select_content', 
      data: { content_type: `donation_redirect`, content_id: service } 
    }
  },
  donationCompleted: (transactionId, fields) => {
    const { campaign, amount, frequency, method: methodCode } = fields;
    const method = config.donationMethods[methodCode];
    const donationId = `${frequency.replace('-', '_')}_${methodCode}_donation`;
    const donationName = `${frequency === 'one-time'? 'One Time' : 'Monthly'} ${method} Donation`;
    return { 
      name: 'purchase', 
      data: { 
        currency: 'USD', 
        transaction_id: transactionId, 
        value: parseInt(amount), 
        coupon: campaign || '',
        items: [{ item_id: donationId, item_name: donationName }]
      }
    }
  }

};

export default eventDefinitions;