import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import querystring from 'qs';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import useAnalytics from '../../../hooks/useAnalytics';
import * as notificationsAPI from '../../../actions/notifications';
import PopMessage from '../../common/messages/PopMessage';
import LoadingMessage from '../../common/messages/LoadingMessage';
import EmailConfirmation from './EmailConfirmation';
import stateConfig from '../../../config/state';
import urls from '../../../config/urls';
import { resetViewToTopOfPage } from '../../../utils/utils';
import { cleanEmailInput } from '../../../utils/input';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty, isInteger } = validator;


function ManageEmailPreferences({ user, emailSettings, notificationsAPI }) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmed, setConfirmed] = useState(false);
  const [saving, setSaving] = useState(false);
  const [unsubscribe, setUnsubscribe] = useState(false);
  const [email, setEmail] = useState(null);
  const [settings, setSettings] = useState(null);
  const [selections, setSelections] = useState(null);
  const [hasSelections, setHasSelections] = useState(false);
  const [changed, setChanged] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  useAnalytics('Profile: Manage Email Preferences');
  const navigate = useNavigate();

  useEffect(() => {
    if(!init) {
      resetViewToTopOfPage();
      const params = querystring.parse(window.location.search.substring(1));
      const prefilledEmail = cleanEmailInput(params.email) || '';
      if(isNotEmpty(prefilledEmail)) {
        setEmail(prefilledEmail);
        notificationsAPI.getEmailSettings(prefilledEmail);
      }
      if(params.unsubscribe) {
        setUnsubscribe(true);
      } else {
        // don't display confirmation unless coming here from unsub link
        setConfirmed(true);
      }
      setInit(true);
    }
  }, [init, notificationsAPI]);

  useEffect(() => {
    if(loading && isEmpty(email) && user.userDataChecked) {
      if(isNotEmpty(user.email)) {
        setEmail(user.email);
        notificationsAPI.getEmailSettings(user.email);
      } else {
        // this shouldn't happen, as user should either be identified or have an email param in URL
        // however, if it does happen then prompt the user to log in
        setLoading(false);
        navigate(`${urls.login}?ra=email-preferences`);
      }
    }
  }, [loading, email, user.email, user.userDataChecked, notificationsAPI, navigate]);

  useEffect(() => {
    if(loading && settings === null) {
      const { responseStatus, settings: settingsData } = emailSettings;
      if(responseStatus === stateConfig.responseStatus.ERROR) {
        setLoading(false);
        setError({ display: true, message: 'Your email preference settings could not be loaded.' });
      } else if(responseStatus === stateConfig.responseStatus.COMPLETE && isNotEmpty(settingsData)) {
        setLoading(false);

        const sortedSettings = prepareCategories(settingsData);
        setSettings(sortedSettings);
        const selectionValues = sortedSettings.reduce((obj, setting) => {
          const { tagId, active } = setting;
          obj[tagId] = active;

          // make sure that we discover if there are no current selections
          if(!obj.hasSelections && active) {
            obj.hasSelections = true;
          }
          return obj;
        }, { hasSelections: false });
        setSelections(selectionValues);
        setHasSelections(selectionValues.hasSelections);
      }
    }
  }, [loading, settings, emailSettings]);

  useEffect(() => {
    if(saving) {
      const { responseStatus } = emailSettings;
      if(responseStatus === stateConfig.responseStatus.ERROR) {
        setSaving(false);
        setError({ display: true, message: 'Your email preference settings could not be updated.' });
      } else if(responseStatus === stateConfig.responseStatus.COMPLETE) {
        setSaving(false);
        setChanged(false);
        setSuccess({ display: true, message: 'Your email preference settings have been saved!' });
        resetViewToTopOfPage();
        
        // now, check to see if we still have any selections
        let hasSelections = settings.reduce((selectionValue, setting) => {
          const { tagId: settingTagId } = setting;
          if(!selectionValue && selections[settingTagId]) {
            selectionValue = true;
          }
          return selectionValue;
        }, false);
        setHasSelections(hasSelections);
      }
    }
  }, [saving, selections, settings, emailSettings]);

  const prepareCategories = categories => {
    return categories.sort((a, b) => a.order > b.order? 1 : -1);
  };

  const onConfirmationComplete = confirmEmail => {
    if(confirmEmail) {
      // entered a different email
      setSettings(null);
      setLoading(true);
      setEmail(confirmEmail);
      notificationsAPI.getEmailSettings(confirmEmail);
    }

    setConfirmed(true);
  };

  const updatePreference = tagId => ({ target: { checked }}) => {
    let selectionValues = { ...selections };
    selectionValues[tagId] = checked;
    setSelections(selectionValues);
    setChanged(true);
  };

  const saveSettings = () => {
    if(!saving) {
      setSaving(true);
      const tagIds = Object.keys(selections).filter(tagId => isInteger(tagId));
      const updatedSettings = tagIds.map(tagId => {
        return { tagId, active: selections[tagId] };
      });
      notificationsAPI.updateEmailSettings(email, updatedSettings);
    }
  };

  const clearPopMessage = () => {
    setError({ display: false });
    setSuccess({ display: false });
  };
  
  return (
    <div className="ManageEmailPreferences">
      <div className="ManageEmailPreferences-container">
        { (loading || !user.userDataChecked) &&
          <LoadingMessage message="Loading..." />
        }
        { !isEmpty(error) && error.display &&
          <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
            <p>{error.message}</p>
          </PopMessage>
        }
        { !isEmpty(success) && success.display &&
          <PopMessage type="info" horizontal="center" open={true} onClose={clearPopMessage}>
            <p>{success.message}</p>
          </PopMessage>
        }
        { unsubscribe && !loading && !confirmed && !isEmpty(settings) && !isEmpty(email) &&
          <EmailConfirmation email={email} onComplete={onConfirmationComplete} />
        }
        { !loading && confirmed && !isEmpty(settings) && !isEmpty(email) &&
          <div className="ManageEmailPreferences-content">
            <h1>Email Preferences</h1>
            <div className="ManageEmailPreferences-user">
              <p><span>Email:</span> {email}</p>
            </div>
            <div className="ManageEmailPreferences-account">
              { !unsubscribe && hasSelections &&
                <p className="ManageEmailPreferences-heading-description">
                  Select what types of communication you would like to receive from the Institute by adjusting the email subscription settings below.
                </p>
              }
              { (unsubscribe || !hasSelections) &&
                <div className="ManageEmailPreferences-account unsub">
                  { unsubscribe && hasSelections &&
                    <p className="ManageEmailPreferences-unsub-description">
                      Adjust your email subscription settings to unsubscribe from Institute communications you no longer wish to receive.
                    </p>
                  }
                  { !hasSelections &&
                    <p className="ManageEmailPreferences-unsub-description">
                      You are not currently subscribed to any Institute communications. If you would like to receive our emails, please 
                      select what types of communication you would prefer by adjusting the email subscription settings below.
                    </p>
                  }
                  <h3 className="account-notifications-heading">A note regarding account notifications</h3>
                  <p className="message">
                    The subscription settings below allow you to manage your Institute communications according to your preferences. However, 
                    please note that while you can manage your email subscriptions you cannot opt out of important account notification emails, 
                    which are necessary to maintain your membership. These emails will only include necessary account communications such as 
                    registration confirmations, notifications of profile updates, authentication and password reset links, and donation receipts.
                  </p>
                  { unsubscribe &&
                    <Fragment>
                      <p className="message">
                        If you no longer wish to receive communication of any kind from the Institute you may <Link to={urls.accountDeletion}>delete your 
                        membership account</Link> at any time.
                      </p>
                      <p className="message emphasize">
                        If you would rather retain your membership, please use the settings below to customize the types of communication you would 
                        like to receive.
                      </p>
                    </Fragment>
                  }
                </div>
              }
              {
                settings.map(setting => {
                  const { tagId, title, description } = setting;
                  return (
                    <div key={`setting-${tagId}`} className={`setting ${tagId}`}>
                      <div className="selection">
                        <Switch
                          checked={selections[tagId]}
                          color="primary"
                          onChange={updatePreference(tagId)}
                        />
                      </div>
                      <div className="info">
                        <div className="title">{title}</div>
                        <div className="description">{description}</div>
                      </div>
                    </div>
                  );
                })
              }
              <div className="ManageEmailPreferences-save">
                <Button variant="contained" color="primary" onClick={saveSettings} disabled={(!changed || saving)}>
                  Save changes
                </Button>
                { saving &&
                  <LoadingMessage message="Saving..." />
                }
              </div>
              { !unsubscribe && hasSelections &&
                <div className="ManageEmailPreferences-account not-unsub">
                  <h3 className="account-notifications-heading">A note regarding account notifications</h3>
                  <p className="message">
                    Please note that while you can manage your email subscriptions we do not let you opt out of important account 
                    notification emails, which are necessary to maintain your membership. These emails will only include necessary 
                    account communications such as registration confirmations, notifications of profile updates, authentication and 
                    password reset links, and donation receipts.
                  </p>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    user: state.user,
    emailSettings: state.emailSettings
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    notificationsAPI: bindActionCreators(notificationsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageEmailPreferences);