import { SEARCH_REQUEST, SEARCH_COMPLETE, SEARCH_ERR, SEARCH_CLEAR,
         TAXONOMY_REQUEST, TAXONOMY_COMPLETE, TAXONOMY_ERR } from './actionTypes';
import * as events from '../api/content/events';
import errors from '../config/errors';


export function clearSearch() {
  return dispatch => {
    dispatch({ type: SEARCH_CLEAR });
  };
}

export function searchWithTerms(terms) {
  return async dispatch => {
    dispatch({ type: SEARCH_REQUEST });
    try {
      let result = await events.searchEvents(terms);
      dispatch({ type: SEARCH_COMPLETE, data: result.data });
    } catch(error) {
      dispatch({ type: SEARCH_ERR, error: errors.RequestFailed });
    }
  };
}

export function searchWithFilters(query) {
  return async dispatch => {
    dispatch({ type: SEARCH_REQUEST });
    try {
      let result = await events.filterEvents(query);
      dispatch({ type: SEARCH_COMPLETE, data: result.data });
    } catch(error) {
      dispatch({ type: SEARCH_ERR, error: errors.RequestFailed });
    }
  };
}

export function getTaxonomy() {
  return async dispatch => {
    dispatch({ type: TAXONOMY_REQUEST });
    try {
      let result = await events.getTaxonomy();
      dispatch({ type: TAXONOMY_COMPLETE, data: result.data });
    } catch(error) {
      dispatch({ type: TAXONOMY_ERR, error: errors.RequestFailed });
    }
  };
}