import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as postsAPI from '../../../actions/content/posts';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';


const DONATION_SUCCESS_SNIPPET_SLUG = 'donation-success';

function DonateSuccess({ messageOnly, posts, postsAPI }) {
  const [snippet, setSnippet] = useState(null);

  useEffect(() => {
    if(!messageOnly) {
      resetViewToTopOfPage();
      postsAPI.getSnippetBySlug(DONATION_SUCCESS_SNIPPET_SLUG);
    }
  }, [messageOnly, postsAPI]);

  useEffect(() => {
    const post = posts.byId[`${stateConfig.keys.SNIPPET}.${DONATION_SUCCESS_SNIPPET_SLUG}`];
    if(post && !post.error) {
      setSnippet(post.content);
    }
  }, [posts.ids, posts.byId]);

  return (
    <div className="DonateSuccess">
      <p className="thanks">Thank you for your support.</p>
      <p className="message">
        Your gift is sacred to us and will be used 100% in support of our mission to form God's people in their 
        knowledge and love of our Catholic faith.
      </p>
      <p className="message">
        We will be emailing you a receipt shortly. Please feel free to contact us if we can assist in any way.
      </p>
      <p className="signature">Your ICC Team</p>
      { !messageOnly && snippet !== null &&
        <div className="DonateSuccess-snippet" dangerouslySetInnerHTML={{__html: snippet}} />
      }
    </div>
  );
}

DonateSuccess.defaultProps = {
  messageOnly: false
};

function mapStateToProps(state) {
  return { 
    posts: state.posts
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    postsAPI: bindActionCreators(postsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DonateSuccess);
