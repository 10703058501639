import React from 'react';
import PropTypes from 'prop-types';
import ProfileName, { formatName, validateName } from './ProfileName';
import ProfileAddress, { formatAddress, validateAddress } from './ProfileAddress';
import ProfilePhone, { formatPhone, validatePhone } from './ProfilePhone';
import Icon, { IconTypes } from '../../../common/Icon';
import config from '../../../../config/config';
import validator from '../../../../utils/validator';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';


const ProfileDataTypes = config.profileDataTypes;

class ProfileEditData extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      exists: false,
      submission: false,
      fields: props.data,
      validationErrors: {}
    };

    this.changeEditMode = this.changeEditMode.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { data, editing } = this.props;
    const { data: prevData, editing: prevEditing } = prevProps;

    if(data !== prevData) {
      this.setState({ fields: data });
    }

    if(editing && editing !== prevEditing) {
      this.setState({ submission: false  });
    }
  }

  hasExistingEntry() {
    const { dataType, data } = this.props;
    switch(dataType) {
      case ProfileDataTypes.NAME: return true;
      case ProfileDataTypes.ADDRESS: return !validator.isEmpty(data.addressLine1);
      case ProfileDataTypes.PHONE: return !validator.isEmpty(data.number);
      default: return false;
    }
  }

  displaySummary() {
    const { dataType, data } = this.props;
    switch(dataType) {
      case ProfileDataTypes.NAME: return formatName(data);
      case ProfileDataTypes.ADDRESS: return formatAddress(data);
      case ProfileDataTypes.PHONE: return formatPhone(data);
      default: return '';
    }
  }

  changeEditMode(event, expanded) {
    this.props.onEditEvent(this.props.dataType, expanded);
    if(expanded) {
      // re-valuate this each time the area is opened for editing
      this.setState({ exists: this.hasExistingEntry(), validationErrors: {} });
    }
  }

  handleFieldChange(event) {
    const { name, value } = event.target;
    const fields = { ...this.state.fields };
    fields[name] = value;
    if(this.props.dataType === ProfileDataTypes.ADDRESS && name === 'country') {
      fields.isForeign = (value !== 'United States');
    }
    this.setState({ fields });
  }

  handleSubmit(event) {
    event.preventDefault();
    const validationResult = this.validate();
    if(validationResult.valid) {
      this.setState({ submission: true, validationErrors: {} });
      this.props.onSave(this.props.dataType, this.state.fields);
    } else {
      this.setState({ validationErrors: validationResult });
    }
  }

  handleDelete(event) {
    this.props.onDelete(this.props.dataType, this.state.fields);
  }

  validate() {
    const data = this.state.fields;
    const dataType = this.props.dataType;
    if(dataType === ProfileDataTypes.NAME) return validateName(data);
    if(dataType === ProfileDataTypes.ADDRESS) return validateAddress(data);
    if(dataType === ProfileDataTypes.PHONE) return validatePhone(data);
    return { valid: true };
  }

  render() {
    const { submission } = this.state;
    return (
      <div id="MemberProfile-edit" className="MemberProfile-section">
        <Accordion className={`expandable ${this.props.editing && 'expanded'}`} 
          expanded={this.props.editing} 
          elevation={1}
          onChange={this.changeEditMode}
          square={true}>
          <AccordionSummary className="MemberProfile-dataSummary" 
            expandIcon={<Icon type={this.props.editing? IconTypes.Cancel : IconTypes.Edit} avatar={true} />}>
            <p className="MemberProfile-dataType">{this.props.label}</p>
            <p className="MemberProfile-dataDisplay">{this.displaySummary()}</p>
          </AccordionSummary>
          <AccordionDetails>
            <div className="MemberProfile-dataEditable">
              { submission &&
                <p className="MemberProfile-submission">Updating...</p>
              }
              { !submission && this.props.dataType === ProfileDataTypes.NAME &&
                <ProfileName data={this.state.fields} editing={this.props.editing}
                  onFieldChange={this.handleFieldChange} onSubmit={this.handleSubmit}
                  onDelete={this.handleDelete} errors={this.state.validationErrors} />
              }
              { !submission && this.props.dataType === ProfileDataTypes.ADDRESS &&
                <ProfileAddress data={this.state.fields} editing={this.props.editing} exists={this.state.exists}
                  onFieldChange={this.handleFieldChange} onSubmit={this.handleSubmit} onDelete={this.handleDelete}
                  errors={this.state.validationErrors} />
              }
              { !submission && this.props.dataType === ProfileDataTypes.PHONE &&
                <ProfilePhone data={this.state.fields} editing={this.props.editing} exists={this.state.exists}
                  onFieldChange={this.handleFieldChange} onSubmit={this.handleSubmit} onDelete={this.handleDelete}
                  errors={this.state.validationErrors} />
              }
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

ProfileEditData.defaultProps = {
  editing: false
};

ProfileEditData.propTypes = {
  label: PropTypes.string,
  dataType: PropTypes.string,
  data: PropTypes.object,
  onEditEvent: PropTypes.func,
  onSave: PropTypes.func,
  onDelete: PropTypes.func
};

export default ProfileEditData;