import React from 'react';
import { useNavigate } from 'react-router-dom';
import urls from '../../../config/urls';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';


const LiveLectureImage = 'https://instituteofcatholicculture.net/wp-content/uploads/2020/11/live-event-cuddeback.jpg';
const CoursesImage = 'https://instituteofcatholicculture.net/wp-content/uploads/2023/08/Courses-thumbnail_old-library_resized.jpg';
const GospelReflectionsImage = 'https://instituteofcatholicculture.net/wp-content/uploads/2023/08/Gospel-Reflections_manuscript_resized.jpg';

function Featured(props) {
  const navigate = useNavigate();

  const nav = url => () => {
    navigate(url);
  }

  return (
    <div className="introduction container">
      <div className="container-content">
        <div className="description">
          <h3 className="section-header">100% Orthodox Catholic faith formation</h3>
          <div className="divider-bar"></div>
          <p className="section-description">
            We offer online adult Catholic education programs free of charge covering a broad spectrum of topics in the Catholic liberal arts, including theology, philosophy, history, scripture, literature, catechetics, and more.
          </p>
        </div>
        <div className="cards">
          <Card className="card courses" onClick={nav(urls.events)}>
            <div className="feature-image"  
              style={{backgroundImage: `url(${LiveLectureImage})`}}></div>
            <div>
              <h4>Live Lectures</h4>
              <p>
                We offer live online lectures covering a broad range of topics that follow the course of the liturgical year. 
              </p>
            </div>
            <div>
              <Button size="small" color="primary" onClick={nav(urls.events)}>
                View Live Lectures
              </Button>
            </div>
          </Card>
          <Card className="card lectures" onClick={nav(urls.courses)}>
            <div className="feature-image" 
              style={{backgroundImage: `url(${CoursesImage})`}}></div>
            <div>
              <h4>In-Depth Courses</h4>
              <p>
                Dive deep into the Catholic liberal arts in our online semester-long courses taught by prominent professors. 
              </p>
            </div>
            <div>
              <Button size="small" color="primary" onClick={nav(urls.courses)}>
                Browse Our Courses
              </Button>
            </div>
          </Card>
          <Card className="card reflections" onClick={nav(urls.reflectionsLatin)}>
            <div className="feature-image"  
              style={{backgroundImage: `url(${GospelReflectionsImage})`}}></div>
            <div>
              <h4>Gospel Reflections</h4>
              <p>
                Prepare each week for the Mass by studying the Gospel readings of the Church with
                our instructors. 
              </p>
            </div>
            <div>
              <Button size="small" color="primary" onClick={nav(urls.reflectionsLatin)}>
                View Reflections
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Featured;