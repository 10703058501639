import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as eventsAPI from '../../../actions/content/events';
import EventList from '../../content/events/display/EventList';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { randomInt } from '../../../utils/utils';


class Recommendations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: null,
      stateId: null,
      events: null,
      error: null
    };

    this.retrieveEvents = this.retrieveEvents.bind(this);
  }

  componentDidMount() {
    const { categories } = this.props.eventData;
    const { byId } = this.props.events;
    if(!categories || categories.length === 0) {
      return;
    }

    // determine which category we will pull from
    const min = 0, max = categories.length - 1;
    const categoryIndex = randomInt(min, max);
    const { slug } = categories[categoryIndex];
    const stateId = `${stateConfig.keys.EVENT_LIST}.${slug}`;

    // now see if we've already retrieved this category
    const events = byId[stateId];
    if(events && !events.error) {
      this.setState({ slug, stateId, events });
    } else {
      this.setState({ slug, stateId }, () => {
        this.retrieveEvents();
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { ids, byId } = this.props.events;
    const prevIds = prevProps.events.ids;
    const events = byId[this.state.stateId];

    if(ids !== prevIds && events && events.results.length) {
      if(events.error) {
        this.setState({ error: events.error });
      } else {
        this.displayEvents(events.results);
      }
    }
  }

  retrieveEvents() {
    this.props.eventsAPI.getEventListBySlug(this.state.slug);
  }

  displayEvents(events) {
    const min = 0, max = events.length - 1;
    let displayIndexes = [], index = 0, i = 0, loopExit = 100;
    while(displayIndexes.length < 5 && i < loopExit) {
      index = randomInt(min, max);
      if(!displayIndexes.includes(index)) {
        displayIndexes.push(index);
      }
      ++i;
    }
    
    displayIndexes = displayIndexes.length === 5? displayIndexes : [];
    this.setState({ events: { results: displayIndexes.map(idx => events[idx]) } });
  }
  
  render() {
    const { events } = this.state;
    return (
      <div className="EventList-container recommendations">
        { events && 
          <EventList events={events.results} displayType={config.eventListTypes.BROWSE}
            heading={`You might also like`} cardsPerRender={5} />
        }
      </div>
    );
  }
}

Recommendations.propTypes = {
  eventData: PropTypes.object
};

function mapStateToProps(state) {
  return { events: state.events };
}

function mapDispatchToProps(dispatch) {
  return { 
    eventsAPI: bindActionCreators(eventsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Recommendations);
