import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useAnalytics from '../../../../hooks/useAnalytics';
import * as postAPI from '../../../../actions/content/posts';
import Heading from './Heading';
import QuarterNavigation from './QuarterNavigation';
import QuarterDisplay from './QuarterDisplay';
import LoadingMessage from '../../../common/messages/LoadingMessage';
import PopMessage from '../../../common/messages/PopMessage';
import stateConfig from '../../../../config/state';
import config from '../../../../config/config';
import { resetViewToTopOfPage } from '../../../../utils/utils';
import SEOMeta from '../../../common/SEOMeta';
import validator from '../../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Curriculum({ posts, postAPI }) {
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(0);
  const [curriculum, setCurriculum] = useState(null);
  const [error, setError] = useState(null);
  const stateId = `${stateConfig.keys.PAGE}.curriculum`;
  useAnalytics('Curriculum');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const data = posts.byId[stateId];
    if(loading && isEmpty(curriculum) && isNotEmpty(data) && posts.ids) {
      if(data.error) {
        setError(data.error);
      } else {
        setCurriculum(data);
        setTab(determineDefaultTab(data.currentQuarter));
      }
      setLoading(false);
    }
  }, [loading, curriculum, stateId, posts, posts.id]);

  initialize = () => {
    resetViewToTopOfPage();
    const data = posts.byId[stateId];
    if(isNotEmpty(data) && !data.error) {
      setCurriculum(data);
      setTab(determineDefaultTab(data.currentQuarter));
      setLoading(false);
    } else {
      postAPI.getPageBySlug('curriculum');
    }
  };  

  const determineDefaultTab = (currentQuarter) => {
    switch(currentQuarter) {
      case 'Quarter 2': return 1;
      case 'Quarter 3': return 2;
      case 'Quarter 4': return 3;
      default: return 0;
    }
  };

  const handleQuarterSelection = (event, tab) => {
    setTab(tab);
  };

  const clearPopMessage = () => {
    setError(null);
  };

  return (
    <div className="Curriculum-container">
      { !curriculum && !error && <LoadingMessage message="Loading..." /> }

      { error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page.</p>
        </PopMessage>
      }

      { curriculum && !error &&
        <Fragment>
          <SEOMeta 
            title={`${curriculum.year} Curriculum`} 
            description={curriculum.description} />
          <Heading curriculum={curriculum} />
          <h2>Curriculum Details <span>(by quarter)</span></h2>
          <div className="Curriculum">
            <QuarterNavigation currentTab={tab} onNavigationChange={handleQuarterSelection} />
            { tab === 0 && <QuarterDisplay quarter="quarter1" curriculum={curriculum} /> }
            { tab === 1 && <QuarterDisplay quarter="quarter2" curriculum={curriculum} /> }
            { tab === 2 && <QuarterDisplay quarter="quarter3" curriculum={curriculum} /> }
            { tab === 3 && <QuarterDisplay quarter="quarter4" curriculum={curriculum} /> }
          </div>
        </Fragment>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { posts: state.posts };
}

function mapDispatchToProps(dispatch) {
  return { 
    postAPI: bindActionCreators(postAPI, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Curriculum);
