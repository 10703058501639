import axios from 'axios';
import urls from '../../config/urls';
import state from '../../config/state';
import config from '../../config/config';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

export async function getPostBySlug(slug, key) {
  let path = 'content';
  switch(key) {
    case state.keys.ARTICLE:
      path += '/articles';
      break;
    case state.keys.SNIPPET:
      path += '/snippets';
      break;
    default:
      path += '/pages';
  }
  return await axios.get(`${urls.hubUIApiRoot}/${path}/${slug}`, { timeout: config.wpTimeout });
}
