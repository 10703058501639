import { ACCT_POLICY_REQUEST, ACCT_POLICY_RETRIEVED, ACCT_POLICY_STATUS_UPDATED, 
         ACCT_POLICY_ERR } from '../actionTypes';
import * as account from '../../api/account';
import errors from '../../config/errors';


export function getPrivacyPolicyStatus() {
  return async dispatch => {
    dispatch({ type: ACCT_POLICY_REQUEST });
    try {
      let result = await account.getPrivacyPolicyStatus();
      dispatch({ type: ACCT_POLICY_RETRIEVED, data: result.data });
    } catch(error) {
      dispatch({ type: ACCT_POLICY_ERR, error: errors.RequestFailed });
    }
  };
}

export function updatePrivacyPolicyStatus(data) {
  return async dispatch => {
    dispatch({ type: ACCT_POLICY_REQUEST });
    try {
      await account.updatePrivacyPolicyStatus(data);
      dispatch({ type: ACCT_POLICY_STATUS_UPDATED });
    } catch(error) {
      dispatch({ type: ACCT_POLICY_ERR, error: errors.RequestFailed });
    }
  };
}
