import React from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import urls from '../../config/urls';
import Button from '@mui/material/Button';


function Toolbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const buildClassList = (path) => {
    let classList = '';
    if(path.includes('/events') || (path.includes('/courses') && !path.includes('/magdala/courses')) || path.includes('/learning-paths')) {
      classList += ' explore-tools';
    }
    return classList;
  }

  const filtersSelected = () => {
    navigate(urls.filter);
  }

  const { pathname: path } = location;

  return (
    <div className={`AppToolbar${buildClassList(path)}`}>
      <div className="AppToolbar-mainNav">
        <div className={`menu-item ${path === '/events' && 'active' }`}>
          <Link to={urls.events}>Live Events</Link>
        </div>
        <div className="vertical-divider">|</div>
        <div className={`menu-item ${path === '/courses' && 'active' }`}>
          <Link to={urls.courses}>Courses</Link>
        </div>
        <div className="vertical-divider">|</div>
        {/* Temporarily disabled
        <div className={`menu-item ${path === '/learning-paths' && 'active' }`}>
          <Link to="/learning-paths">Learning Paths</Link>
        </div>
        <div className="vertical-divider">|</div>
        */}
        <div className={`menu-item ${path === '/events/list/browse' && 'active' }`}>
          <Link to={urls.eventsBrowse}>Browse</Link>
        </div>
      </div>
      
      <div className="AppToolbar-actions">
        <div className="menu-item filter">
          <Button variant="outlined" color="primary" onClick={filtersSelected}>Filters</Button>
        </div>
      </div>
    </div>
  );
}

export default Toolbar;