import React from 'react';
import Icon, { IconTypes } from '../../common/Icon';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import urls from '../../../config/urls';

export default function DuplicateRegistrationMessage() {
  return (
    <div id="NewMemberConfirmation" size="double">
      <div className="NewMemberConfirmation-followup">
        <h2>You are already registered in the Magdala Apostolate program.</h2>
        <div className="NewMemberConfirmation-steps addMarginBottomToPushFooter300">
          <Card className="NewMemberConfirmation-step one" square={false}>
            <CardContent className="NewMemberConfirmation-instructions">
              <h3><Icon type={IconTypes.CheckboxChecked} avatar={true} spacing="padded" inline={true} />Registration Complete</h3>
            </CardContent>
            <CardContent>
              <p>
                Good news! You are already registered in the Magdala Apostolate program. You can register for <Link to={urls.magdalaCoursesPage}>available Magdala Apostolate courses</Link>, or <Link to={urls.magdalaContactUs}>contact us</Link> if you need assistance. 
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
)}