import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useAnalytics from '../../../hooks/useAnalytics';
import * as eventsAPI from '../../../actions/content/events';
import EventList from '../../content/events/display/EventList';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import SEOMeta from '../../common/SEOMeta';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function LinkedList(props) {
  const [loading, setLoading] = useState(true);
  const [stateId, setStateId] = useState(null);
  const [eventList, setEventList] = useState(null);
  const [error, setError] = useState(null);
  const { events, eventsAPI } = props;
  useAnalytics('Linked List');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const data = events.byId[stateId];
    if(loading && isEmpty(eventList) && isNotEmpty(data) && events.ids) {
      if(data.error) {
        setError(data.error);
      } else {
        setEventList(data);
      }
      setLoading(false);
    }
  }, [loading, eventList, stateId, events, events.ids]);

  initialize = () => {
    resetViewToTopOfPage();
    const { slug, stateId } = pathSettings();
    setStateId(stateId);

    const data = events.byId[stateId];
    if(isNotEmpty(data) && !data.error) {
      setEventList(data);
      setLoading(false);
    } else {
      eventsAPI.getEventListBySlug(slug, true);
    }
  };

  const pathSettings = () => {
    let pathname = window.location.pathname;
    const slug = props.slug || pathname.replace('/events/list/', '');
    const stateId = props.stateId || `${stateConfig.keys.EVENT_LIST}.${slug}`;
    return { slug, stateId };
  };

  const clearPopMessage = () => {
    setError(null);
  };

  const renderDescription = () => {
    if(eventList && eventList.description) {
      const description = eventList.description.replace(/\r?\n|\r/g, '<br />');
      return (
        <Fragment>
          <p dangerouslySetInnerHTML={{__html: description}} />
        </Fragment>
      );
    }

    return null;
  };

  const description = renderDescription();

  return (
    <div>
      <div className="MainContent-container linked-list">
        { isEmpty(eventList) && isEmpty(error) && <div className="loadMessage"><LoadingMessage message="Loading..." /></div> }

        { error &&
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>We could not load the requested page.</p>
          </PopMessage>
        }

        { isNotEmpty(eventList) &&
          <div className="LinkedList EventList-container">
            <SEOMeta 
              title={eventList.title}
              description={eventList.description}  
            />
            <EventList 
              events={eventList.results} 
              displayType={config.eventListTypes.CURRENT_AND_UPCOMING}
              heading={eventList.title} 
              description={description}
              cardsPerRender={config.defaultCardsPerRender}
            />
          </div>
        }
      </div>
    </div>
  );
}

LinkedList.propTypes = {
  slug: PropTypes.string,
  stateId: PropTypes.string,
  header: PropTypes.string,
  cardsPerRender: PropTypes.string
}

function mapStateToProps(state) {
  return { events: state.events };
}

function mapDispatchToProps(dispatch) {
  return { 
    eventsAPI: bindActionCreators(eventsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedList);
