import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon, { IconTypes } from '../../common/Icon';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FullPageSystemPopup(props) {
  const { className, open, message, actions, onClose } = props;

  return (
    <Dialog 
      fullScreen
      className="FullPageSystemPopup"
      open={open}
      onClose={() => {}}
      TransitionComponent={Transition}
    >
      <DialogContent>
        <div className={`content ${className}`}>
          <div className="content-heading">
            <div className="close-icon" onClick={onClose}>
              <Icon type={IconTypes.ClosePlain} />
            </div>
          </div>
          <div className="content-body">
            <div className="content-main">
              { !isEmpty(message) &&
                  <div dangerouslySetInnerHTML={{__html: message}} />
              }
              { isEmpty(message) &&
                <Fragment>{props.children}</Fragment>
              }
            </div>
            { actions &&
              <div className="content-actions">
                {actions}
              </div>
            }
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

FullPageSystemPopup.defaultProps = {
  className: ''
};

FullPageSystemPopup.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  actions: PropTypes.object,
  onClose: PropTypes.func
};

export default FullPageSystemPopup;
