import React, { useState, useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import useAnalytics from '../../../hooks/useAnalytics';
import * as authenticationAPI from '../../../actions/authentication'; 
import * as magdalaAPI from '../../../actions/magdala';
import * as appDisplay from '../../../actions/appDisplay';
import CommunitySelection from './CommunitySelection'; 
import MagdalaConfirmation from './MagdalaConfirmation'; 
import SEOMeta from '../../common/SEOMeta';
import Icon, { IconTypes } from '../../common/Icon';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import ContactErrorPopMessage from '../../common/messages/ContactErrorPopMessage';
import MagdalaRegistrationForm from './MagdalaRegistrationForm'; 
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import { resetViewToTopOfPage } from '../../../utils/utils';
import validator from '../../../utils/validator';
import NewICCMemberMagdalaConfirmation from './NewICCMemberMagdalaConfirmation';
import ExistingICCMemberMagdalaConfirmation from './ExistingICCMemberMagdalaConfirmation';
import DuplicateRegistrationMessage from './DuplicateRegistrationMessage';


function MagdalaRegistration({ user, magdalaRegistration, authenticationAPI, magdalaAPI, appDisplay }) {
  const [step, setStep] = useState('identification'); 
  const [email, setEmail] = useState(''); 
  const [communityId, setCommunityId] = useState(''); 
  const [completed, setCompleted] = useState(false); 
  const [submitted, setSubmitted] = useState(false); 
  const [duplicateError, setDuplicateError] = useState(false); 
  const [registrationError, setRegistrationError] = useState(false); 
  const [membershipError, setMembershipError] = useState(false); 
  const [memberData, setMemberData] = useState({}); 
  const [verified, setVerified] = useState(false);
  let refreshMemberIdentity = config.emptyFn;
  let submitMagdalaProgramApplication = config.emptyFn; 
  useAnalytics('Magdala Registration'); 
  
  useEffect(() => {
    if(submitted && magdalaRegistration.responseStatus === stateConfig.responseStatus.COMPLETE){
      setStep('completed');
      setCompleted(true); 
      appDisplay.displayPageMessage({
        message: 'Your application has been successfully submitted!',
        type: stateConfig.messageTypes.SUCCESS
      }); 
      refreshMemberIdentity(); 
    } else if(magdalaRegistration.responseStatus === stateConfig.responseStatus.ERROR){
      setCompleted(true); 
      setRegistrationError(true); 
    }
  },[magdalaRegistration.responseStatus, appDisplay, refreshMemberIdentity, submitted]); 
  
  const completedCommunitySelection = (email, communityId) => {
    resetViewToTopOfPage();    
    setEmail(email); 
    setCommunityId(communityId); 
    setStep('confirmation');  
  }    

  const confirmationComplete = () => {
    // if already magdala member, display duplicate error
    if(user.type === config.memberTypes.magdala) {
      setStep('duplicateError'); 
      setDuplicateError(true); 
      return; 
    }
    
    // if not an ICC member, register for icc & magdala
    if(validator.isEmpty(user.email)) {
      setVerified(false); 
      setStep('member-creation'); 
    } else {
      // already an icc member, make magdala member
      setVerified(true); 
      setMemberData({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        password: user.password,
        passwordConfirmation: user.passwordConfirmation
      });
      setStep('member-updateMagdala'); 
    }
  }
    
  const backToSelection = () => {
    setEmail(''); 
    setCommunityId(''); 
    setMemberData({}); 
    setStep('identification'); 
  }
  
  const memberRegistrationComplete = useCallback(async (error, fields) => {
    resetViewToTopOfPage(); 
      
    if(error) {
      setMembershipError(true);
      return; 
    }

    try {
      await authenticationAPI.identifyDeviceOwner(); 
    }catch(error) {
      // fail silently if this does not work for some reason
    }
    
    if(!submitted && communityId) {
      submitMagdalaProgramApplication(); 
    }
  },[authenticationAPI, submitMagdalaProgramApplication, submitted, communityId]); 

  submitMagdalaProgramApplication = () => {
    magdalaAPI.submitMagdalaProgramApplication({
      communityId: communityId
    }); 
    setSubmitted(true); 
  }

  refreshMemberIdentity = async () => {
    await authenticationAPI.identifyDeviceOwner(); 

    if(user.authenticated) {
      authenticationAPI.getAuthStatus(false); 
    }
  }
  
  const clearPopMessage = () => {
    setMembershipError(false); 
    setDuplicateError(false); 
    setRegistrationError(false); 
  }
    
  return (
    <div className="MainContent-container">
      <SEOMeta 
        title="Magdala Apostolate Program Registration" 
        description="Registration for access to Magdala Apostolate courses and program resources"
      />

      <div className="MagdalaRegistration">
        { step === 'processing' && !completed && <div className='loadMessage'><LoadingMessage message='Processing...' /></div> }
        { step === 'identification' &&
          <CommunitySelection onComplete={completedCommunitySelection} />
        }
        { step === 'confirmation' && 
          <MagdalaConfirmation onComplete={confirmationComplete} />
        }
        { step === 'member-creation' &&
          <div className="membership-creation registration-step">
            <div className="back">
              <Button variant="text" onClick={backToSelection}>
                <Icon type={IconTypes.ArrowBack} /> Go Back
              </Button>
            </div>
            <p className="title"><strong>Registration Step:</strong> Magdala Apostolate Registration</p>
            <p className="message">
              It looks like you are new to the Institute. To register for the Magdala Apostolate Program, please complete the form below. In addition to gaining access to the Magdala Apostolate courses reserved for women religious, you will also have full access to all Institute of Catholic Culture courses & resources available to the lay faithful. Welcome to the ICC family!
            </p>      
            <MagdalaRegistrationForm 
              collectSalutation={true} 
              collectPassword={true} 
              submitLabel="Continue"
              email={email} 
              onComplete={memberRegistrationComplete} 
              source="magdala-registration" 
            />
          </div>
        }
        { step === 'member-updateMagdala' &&
          <div className="membership-creation registration-step">
            <div className="back">
              <Button variant="text" onClick={backToSelection}>
                <Icon type={IconTypes.ArrowBack} /> Go Back
              </Button>
            </div>
            <p className="title"><strong>Registration Step:</strong> Magdala Apostolate Registration</p>
            <p className="message">
              To register for the Magdala Apostolate Program, please update any inaccurate or incomplete fields in the form below. Once your application is approved, you will be able to apply for any Magdala Apostolate courses, all of which are reserved for women religious. In addition, you will still have access to all ICC courses & resources available to the lay faithful. Welcome to the Magdala Apostolate! 
            </p>
            <MagdalaRegistrationForm 
              collectSalutation={true} 
              collectPassword={true} 
              submitLabel="Continue"
              email={email} 
              memberData={memberData}
              onComplete={memberRegistrationComplete} 
              source="magdala-registration" 
            />
          </div> 
        }
        { completed && step === 'completed' &&
          <div className="completed-message">
            { verified ? <ExistingICCMemberMagdalaConfirmation /> : <NewICCMemberMagdalaConfirmation />}
          </div>
        }
        { duplicateError &&
          <DuplicateRegistrationMessage />
        }
        { registrationError && 
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>
              Your registration could not be completed at this time. Please wait a bit and try again. If this issue continues, please reach out to us using our contact form.
            </p>
          </PopMessage> 
        }
        { membershipError &&
          <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
            <p>
              Your form has timed out or encountered an error. Please refresh the page and try 
              again. If this issue continues please reach out to us using our contact form.
            </p>
          </ContactErrorPopMessage>
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    magdalaRegistration: state.magdalaRegistration
  };
}
  
function mapDispatchToProps(dispatch) {
  return { 
    authenticationAPI: bindActionCreators(authenticationAPI, dispatch),
    magdalaAPI: bindActionCreators(magdalaAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MagdalaRegistration);