import axios from 'axios';
import config from '../config/config';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

export async function getProfile() {
  return await axios.get(`${urls.hubUIApiRoot}/member/profile`);
}

export async function updateProfile(dataType, profileData) {
  let { id, data } = profileData;
  switch(dataType) {
    case config.profileDataTypes.NAME:
      return await axios.post(`${urls.hubUIApiRoot}/member/profile`, data);
    case config.profileDataTypes.LEGAL_NAME:
      return await axios.post(`${urls.hubUIApiRoot}/member/profile`, data);
    case config.profileDataTypes.ADDRESS:
      data.isPrimary = true;
      data = setupAddress(data);
      let addressUpdate = { action: id? 'update' : 'add', addressId: id, address: data };
      return await axios.post(`${urls.hubUIApiRoot}/member/profile/address`, addressUpdate);
    case config.profileDataTypes.PHONE:
      data.isPrimary = true;
      let phoneUpdate = { action: id? 'update' : 'add', phoneId: id, phone: data };
      return await axios.post(`${urls.hubUIApiRoot}/member/profile/phone`, phoneUpdate);
    default: throw new Error("Data type not defined");
  }
}

function setupAddress(data) {
  const address = { ...data };
  if(!address.addressLine2) delete address.addressLine2;
  if(address.isForeign) {
    if(!address.addressLine3) delete address.addressLine3;
    delete address.city;
    delete address.state;
    delete address.postalCode;
  } else {
    delete address.addressLine3;
    if(!address.city) delete address.city;
    if(!address.state) delete address.state;
    if(!address.postalCode) delete address.postalCode;
  }
  return address;
}

export async function deleteProfileItem(dataType, itemId) {
  switch(dataType) {
    case config.profileDataTypes.ADDRESS:
      let addressUpdate = { action: 'delete', addressId: itemId };
      return await axios.post(`${urls.hubUIApiRoot}/member/profile/address`, addressUpdate);
    case config.profileDataTypes.PHONE:
      let phoneUpdate = { action: 'delete', phoneId: itemId };
      return await axios.post(`${urls.hubUIApiRoot}/member/profile/phone`, phoneUpdate);
    default: throw new Error("Data type not defined");
  }
}

export async function updatePassword(data) {
  return await axios.post(`${urls.hubUIApiRoot}/member/profile/password`, data);
}

