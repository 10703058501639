import { ACCT_POLICY_REQUEST, ACCT_POLICY_RETRIEVED, ACCT_POLICY_STATUS_UPDATED, 
         ACCT_POLICY_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function privacyPolicyReducer(state = initialState.privacyPolicy, action) {
  const { type, data = {}, error = {} } = action;
  let responseCode, acknowledged, status = stateConfig.responseStatus;
  
  switch(type) {
    case ACCT_POLICY_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null });
    case ACCT_POLICY_RETRIEVED:
      ({ acknowledged } = data);
      return Object.assign({}, state, { responseStatus: status.COMPLETE, acknowledged });
    case ACCT_POLICY_STATUS_UPDATED:
      return Object.assign({}, state, { responseStatus: status.COMPLETE });
    case ACCT_POLICY_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default privacyPolicyReducer;