import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as authAPI from '../../actions/authentication';
import SendPasscodeForm from './passwordless/SendPasscodeForm';
import stateConfig from '../../config/state';


function LoginPasswordlessForm({ defaultEmail, reportEmail, authentication, authAPI }) {
  const [submission, setSubmission] = useState(false);
  const [authError, setAuthError] = useState(false);
  const [completed, setCompleted] = useState(false);
  const { responseStatus: authResponseStatus, contextParams } = authentication;

  useEffect(() => {
    if(authResponseStatus === stateConfig.authStatus.AUTH_CODE_SENT) {
      setCompleted(true);
    } else if(submission && authResponseStatus === stateConfig.authStatus.ERROR) {
      setAuthError(true);
    }
  }, [authResponseStatus, submission]);

  const onSubmit = (email) => {
    setSubmission(true);
    reportEmail(email);
    authAPI.sendPasscode({ email, contextParams });
  }

  return (
    <div id="LoginPasswordlessForm">
      { !completed &&
        <SendPasscodeForm defaultEmail={defaultEmail} submission={submission} 
          displayError={authError} onSubmit={onSubmit} />
      }
      { completed &&
        <p className="LoginPasswordlessForm-instructions">
          We have emailed you an authentication link. Access your email and click on your 
          personalized authentication link to complete your login.
        </p>
      }
    </div>
  );
}

LoginPasswordlessForm.defaultProps = {
  defaultEmail: ''
};

LoginPasswordlessForm.propTypes = {
  reportEmail: PropTypes.func
};

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authAPI: bindActionCreators(authAPI, dispatch) 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPasswordlessForm);
