import React from 'react';
import { connect } from 'react-redux';

function DonateInformation({ systemContent }) {

  return (
    <div className="Donate-information">
      The Institute is a 501 (c) (3) non-profit institution registered as the Institute of Catholic Culture. 
      The Institute’s federal tax ID number is 90-0486-670. All gifts to the Institute of Catholic Culture 
      are deductible for income tax purposes. For a copy of the Institute’s most recent 990, please&nbsp;
      <a href={systemContent.webConfig['document.990']} target="_blank" rel="noopener noreferrer">
        click here
      </a>.
    </div>
  );
}

function mapStateToProps(state) {
  return {
    systemContent: state.systemContent
  };
}

export default connect(mapStateToProps)(DonateInformation);