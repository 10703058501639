import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as annotationsAPI from '../../../actions/annotations'; 
import AnnotationView from './AnnotationView';
import AnnotationsForm from './AnnotationsForm'; 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import Icon, { IconTypes } from '../Icon';
import List from '@mui/material/List'; 
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { convertSecondsIntoTimeDisplay } from '../../../utils/format'; 
import stateConfig from '../../../config/state'; 
import StandardSystemPopup from '../../layout/popup/StandardSystemPopup';
import config from '../../../config/config';
import validator from '../../../utils/validator'; 
const { isEmpty, isNotEmpty } = validator; 

function Annotations({content, mediaType, mediaId, annotations, annotationsAPI, useMediaId, displayAnnotations}) {
  const [view, setView] = useState('annotation-list'); 
  const [annotationsList, setAnnotationsList] = useState([]); 
  const [selectedAnnotation, setSelectedAnnotation] = useState(null); 
  const [printAnnotationsInit, setPrintAnnotationsInit] = useState(false); 
  const [dataForPrintIsAList, setDataForPrintIsAList] = useState(null); 
  const [showMore, setShowMore] = useState(false); 
  const [remove, setRemove] = useState(false); 
  const [previousPrintId, setPreviousPrintId] = useState(null); 
  const [currentPrintId, setCurrentPrintId] = useState(null); 
  const listKey = stateConfig.keys.ANNOTATIONS;
  let retrieveAnnotations = config.emptyFn; 
  let enrichDataForPrint = config.emptyFn; 
  let print = config.emptyFn;
  
  // Retrieve Annotations
  useEffect(() => {
    retrieveAnnotations(); 
  }, [retrieveAnnotations]);
  
  const makeSlug = useCallback(() => {
    return `${listKey}.event${content.id}-${mediaType}${mediaId}`; 
  }, [content.id, listKey, mediaId, mediaType]);
  
  useEffect(() => {
    const stateId = makeSlug(); 
    if(annotations.byId[stateId]) {
      setAnnotationsList(annotations.byId[stateId].results); 
    }
  }, [annotations, makeSlug]);
  
  retrieveAnnotations = () => {
    if(content.id && mediaId && mediaType) {
      annotationsAPI.getMediaAnnotations(content.id, mediaType, mediaId); 
    }
  }
  
  // Print annotation(s)
  const initiatePrintProcess = (list) => {
    // avoid duplicate requests
    if(isNotEmpty(selectedAnnotation) && previousPrintId === selectedAnnotation.annotationId) {
      print(annotations.byId[`annotations.${previousPrintId}`], true); 
    } else if(isEmpty(selectedAnnotation) && annotationsList.length && previousPrintId === annotationsList[0].mediaId) {
      print(annotations.byId[`annotations.${previousPrintId}`], true); 
    } else {
      setDataForPrintIsAList(list); 
      setCurrentPrintId((isNotEmpty(selectedAnnotation)? selectedAnnotation.annotationId : annotationsList[0].mediaId));
    }
  }
 
  useEffect(() => {
    if(isNotEmpty(currentPrintId)) {
      enrichDataForPrint(dataForPrintIsAList, currentPrintId); 
    }
  }, [currentPrintId, dataForPrintIsAList, enrichDataForPrint]); 

  useEffect(() => {
    if(printAnnotationsInit && isNotEmpty(currentPrintId) && annotations.byId[`annotations.${currentPrintId}`]) {
      const htmlString = annotations.byId[`annotations.${currentPrintId}`]; 
      print(htmlString, false); 
    }
  }, [printAnnotationsInit, print, annotations.byId, currentPrintId]); 

  useEffect(() => {
    if(annotations.ids) {
      setPrintAnnotationsInit(true); 
    }
  }, [annotations.ids]); 
  
  enrichDataForPrint = (list, printId) => {
    let enrichedData = null; 
    if(list) {
      const annotationsListWithTitles = annotationsList; 
      if(annotationsListWithTitles && annotationsListWithTitles.length) {
        for(let i = 0; i < annotationsListWithTitles.length; i++) {
          annotationsListWithTitles[i].title = content.title; 
          annotationsListWithTitles[i].subtitle = content.subtitle; 
        }
        enrichedData = annotationsListWithTitles; 
      }
    } else if(!list) {
      const annotationWithTitles = selectedAnnotation; 
      annotationWithTitles.title = content.title; 
      annotationWithTitles.subtitle = content.subtitle; 
      enrichedData = annotationWithTitles; 
    }
    annotationsAPI.prepAnnotationsForPrint(enrichedData, printId); 
  }
  
  print = (htmlString, repeat) => {
    let ref = window.open('/', '_blank'); 
    if(ref) {
      ref.document.write(htmlString);
      ref.print();
    }
    if(!repeat) {
      setPreviousPrintId(currentPrintId); 
      setCurrentPrintId(null); 
    }
    setPrintAnnotationsInit(null); 
  }
  
  // Create/Read/Update an Annotation
  const annotationSelected = id => event => {
    const selected = annotationsList.find(item => item.annotationId === id);
    setSelectedAnnotation(selected); 
    setView('annotation-view'); 
  }
  
  const openAnnotationForm = event => {
    setView('annotation-form'); 
  }
  
  const closeAnnotation = event => {
    setView('annotation-list'); 
    setSelectedAnnotation(null); 
  }
  
  // Delete an Annotation
  const deleteAnnotation = event => {
    annotationsAPI.deleteAnnotation(selectedAnnotation); 
    closeAnnotation(); 
    setSelectedAnnotation(null);    
  }

  const closePopup = () => {
    setRemove(false);
  };
  
  const deleteAndClose = () => {
    deleteAnnotation(); 
    closePopup(); 
  }
  
  return (
    <div className="annotations-container">
      { displayAnnotations && selectedAnnotation && view === 'annotation-view' &&
        <AnnotationView
          annotation={selectedAnnotation}
          mediaType={mediaType}
          onEdit={openAnnotationForm} 
          onDelete={deleteAnnotation}
          onClose={closeAnnotation}
          onPrint={initiatePrintProcess}
          setRemove={setRemove}
        />
      }
      { displayAnnotations && view === 'annotation-form' &&
        <div>
          <AnnotationsForm
            eventData={content}
            selectedAnnotation={selectedAnnotation}
            onClose={closeAnnotation}
            useMediaId={useMediaId}
            mediaId={mediaId}
          />
        </div>
      }
      { displayAnnotations && view === 'annotation-list' &&
      <Fragment>
        { annotationsList && annotationsList.length === 0 &&
          <div className="add-first">
            <p>
              You don't have any annotations for this recording yet.
            </p>
          </div>
        }
        { annotationsList.length > 0 &&
          <div>
            <List
              component="div"
              disablePadding
              className="Classroom-submenu annotations">
              {
                annotationsList.map((annotation, index) => {
                  let displayUpToTwo = ''; 
                  let displayAll = ''; 
                  let displayUpToFive = ''; 
                  let display = ''; 

                  if(index >= 0 && index < 2 && !showMore) {
                    displayUpToTwo='display-up-to-two'; 
                  } else if(index >= 2 && index <= 4 && !showMore) {
                    displayUpToFive='display-up-to-five'; 
                  } else if(showMore) {
                    displayAll='display-all'; 
                  }
                    
                  const { annotationId, text, time } = annotation; 
                  const fTime = time === 0? '00:00' : convertSecondsIntoTimeDisplay(time); 
                  return (
                    <ListItem
                      key={`annotation-${index}`}
                      disablePadding
                      className={`annotation-item ${displayAll} ${displayUpToFive} ${displayUpToTwo} ${display}`}
                      button 
                      onClick={annotationSelected(annotationId)}>
                      <ListItemText>
                        <div className="annotation-list-item-text-container">
                          <p className="time">{fTime}</p>
                          <p className="title">{text}</p> 
                        </div>
                      </ListItemText>
                    </ListItem>
                  );
                })
              }
            </List>
            { !showMore && annotationsList.length > 5 &&
              <div className="show-more-btn desktop">
                <Button variant="text" size="small" endIcon={<Icon type={IconTypes.ArrowDown} />} onClick={() => setShowMore(true)}>Show More</Button>
              </div>
            }
            { !showMore && annotationsList.length > 2 &&
              <div className="show-more-btn mobile">
                <Button variant="text" size="small" endIcon={<Icon type={IconTypes.ArrowDown} />} onClick={() => setShowMore(true)}>Show More</Button>
              </div>
            }
          </div>
        }
        <div className="annotations-list-btns">
          <Button variant="contained" color="secondary" onClick={() => openAnnotationForm()}>Add New</Button>
          { (annotationsList.length > 0) &&
            <Button variant="text" onClick={() => initiatePrintProcess(true)} startIcon={<Icon type={IconTypes.Print} />}>Print</Button>
          }
        </div>
      </Fragment>
      }
      { remove &&
        <StandardSystemPopup
          className="AnnotationDelete-popup"
          open={remove} 
          title="Are you sure?" 
          onClose={closePopup}
          actions={(
            <Fragment>
              <Button className="keep" variant="text" onClick={closePopup}>
                Cancel
              </Button>
              <Button className="remove" variant="text" color="secondary" onClick={deleteAndClose}>
                Delete
              </Button>
            </Fragment>
          )}
        >
          <p className="what">You are about to delete your annotation. </p>
          <p className="consequences">Once deleted, this annotation cannot be recovered.</p>
        </StandardSystemPopup>
      }
    </div>
  )
}

Annotations.propTypes = {
  content: PropTypes.object,
  mediaType: PropTypes.string,
  mediaId: PropTypes.string,
  annotations: PropTypes.object,
  displayAnnotations: PropTypes.bool
}

Annotations.defaultProps = {
  useMediaId: false
};

function mapStateToProps(state) {
  return { annotations: state.annotations };
}

function mapDispatchToProps(dispatch) {
  return { 
    annotationsAPI: bindActionCreators(annotationsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Annotations);