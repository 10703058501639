import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import urls from '../../../../config/urls';


function CourseLogin({ slug }) {
  const navigate = useNavigate();

  return (
    <div className="CourseLogin">
      <div className="message-content">
        <h4>Course Access</h4>
        <p className="message">You must be logged in to access course content.</p>
      </div>
      <Button 
        variant="contained" 
        color="secondary" 
        onClick={() => navigate(`${urls.login}?ra=course&rs=${slug}`)}>
        Login
      </Button>
    </div>
  );
}

CourseLogin.propTypes = {
  slug: PropTypes.string
};

export default CourseLogin;
