import React from 'react';
import PropTypes from 'prop-types';
import validator from '../../../utils/validator';
const { isNotEmpty } = validator;


function StandardContent({ type, title, description, snippet }) {
  return (
    <div className="StandardContent">
      <div className="StandardContent-body">
        <h2 className={`${isNotEmpty(description)? 'with-desc' : 'without-desc'}`} dangerouslySetInnerHTML={{__html: title}} />
        { isNotEmpty(description) &&
          <p dangerouslySetInnerHTML={{__html: description}} />
        }
        { isNotEmpty(snippet) &&
          <div dangerouslySetInnerHTML={{__html: snippet}} />
        }
      </div>
    </div>
  );
}

StandardContent.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  snippet: PropTypes.string
};

export default StandardContent;