import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';


function SelectedFilters(props) {
  const { itemClass, selectedFilters, onRemove } = props;

  return (
    <Fragment>
      {
        selectedFilters.map((item, index) => {
          return (
            <Chip 
              key={`s${itemClass}-${index}`} 
              className={itemClass} 
              label={item.name} 
              onDelete={onRemove(item)} />
          );
        })
      }
    </Fragment>
  );
}

SelectedFilters.propTypes = {
  itemClass: PropTypes.string,
  selectedFilters: PropTypes.array,
  onRemove: PropTypes.func
};

export default SelectedFilters;