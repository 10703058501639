import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as eventsAPI from '../../../../api/content/events';
import StandardSystemPopup from '../../../layout/popup/StandardSystemPopup';
import RestrictedContentPrompt from '../../common/RestrictedContentPrompt';
import QuestionEmbed from './QuestionEmbed';
import Icon, { IconTypes } from '../../../common/Icon';
import { localRetrieve } from '../../../../utils/store';
import config from '../../../../config/config';
import urls from '../../../../config/urls';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import validator from '../../../../utils/validator';
const { isEmpty } = validator;


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DetailMedia({ eventData, eventRegistration, onOpenClassroom, isCompleted, user }) {
  const [loaded, setLoaded] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [displayResources, setDisplayResources] = useState(false);
  const [displayQuestionForm, setDisplayQuestionForm] = useState(false);
  const [storedMediaType, setStoredMediaType] = useState(null);
  const [isLive, setIsLive] = useState(false);
  const navigate = useNavigate();
  let initialize = config.emptyFn;


  useEffect(() => {
    initialize();
  }, [initialize]);

  initialize = () => {
    if(!isCompleted) {
      checkLiveStatus();
    } else {
      setLoaded(true);
    }
  };

  const hasTrialAccess = () => {
    const trialAccess = localRetrieve(config.localStorage.eventTrialAccess)  || '';
    return trialAccess.includes(eventData.slug);
  }

  const isTrialMemberWithoutAccess = () => {
    return user.type === 'trial' && !hasTrialAccess();
  }

  const openWebinar = () => {
    window.open(eventRegistration.webinarLink, '_blank');
  }

  const classroomAccessRequested = mediaType => event => {
    if(user.email || hasTrialAccess()) {
      if(mediaType === 'resources') {
        setDisplayResources(true);
      } else {
        onOpenClassroom(mediaType);
      }
    } else {
      setDisplayDialog(true);
      setStoredMediaType(mediaType);
    }
  }

  const openQuestionDisplay = () => {
    setDisplayQuestionForm(true);
  }

  const identificationCompleted = () => {
    setDisplayDialog(false);
    onOpenClassroom(storedMediaType);
  }

  const onCloseDialog = () => {
    setDisplayDialog(false);
  }

  const onClosePopup = () => {
    setDisplayResources(false);
    setDisplayQuestionForm(false);
  }

  const joinButtonSelected = () => {
    navigate(urls.memberRegistration);
  }

  const checkLiveStatus = async () => {
    try {
      const resp = await eventsAPI.getCourseLiveStatus(eventData.slug);
      if(resp.data && resp.data.live) {
        setIsLive(true);
      }
      setLoaded(true);
    } catch(error) {
      // fail silently
    }
  };

  const renderButtons = () => {
    const { video, audio, resource } = eventData.media;
    const hasVideo = (video.length > 0);
    const hasAudio = (audio.length > 0);
    const hasResources = (resource.length > 0);

    return (
      <div className="media-actions">
        { !isCompleted && 
          <div className="status-message"><Icon type={IconTypes.Complete} /> You are registered for this event</div>
        }
        { isCompleted && eventData.status === 'Completed - Pending' &&
          <p className="status-message">Media is pending for this event and will be posted soon.</p>
        }
        { loaded &&
          <Fragment>
            { isLive &&
              <Button variant="contained" color="secondary" className="join" onClick={openWebinar}>
                <Icon type={IconTypes.ExternalLink} /> Join Webinar
              </Button>
            }
            { hasVideo && !isLive &&
              <Button variant="contained" color="secondary" className="video"
                onClick={classroomAccessRequested('video')}>
                <Icon type={IconTypes.Play} /> Watch Now
              </Button>
            }
            { hasAudio && !isLive &&
              <Button variant="outlined" className="audio"
                onClick={classroomAccessRequested('audio')}>
                <Icon type={IconTypes.Listen} /> Listen
              </Button>
            }
            { hasResources &&
              <Button variant="outlined" className="resources"
                onClick={classroomAccessRequested('resources')}>
                View Resources
              </Button>
            }
            { !isCompleted && !isLive &&
              <Button variant="outlined" className="question" onClick={openQuestionDisplay}>
                Ask a question
              </Button>
            }
          </Fragment>
        }
      </div>
    );
  }

  const renderAccessDialog = () => {
    return (
      <Dialog 
        fullScreen
        className="EventAccessDialog"
        open={displayDialog}
        onClose={onCloseDialog}
        TransitionComponent={Transition}
      >
        <DialogActions>
          <Button onClick={onCloseDialog} variant="contained" color="secondary">
            Close
          </Button>
        </DialogActions>
        <DialogContent>
          <RestrictedContentPrompt onComplete={identificationCompleted} />
        </DialogContent>
      </Dialog>
    );
  }

  const renderTrialMemberDenial = () => {
    return (
      <div className="TrialAccessDenied">
        <p>Join as a member to gain immediate access to our full library.</p>
        <Button variant="contained" color="secondary" onClick={joinButtonSelected}>Get Started</Button>
      </div>
    );
  }

  const renderResourcesPopup = () => {
    const { media } = eventData;
    return (
      <Fragment>
        { displayResources &&
          <StandardSystemPopup 
            className="EventResourcesPopup"
            open={displayResources} 
            title="Resources" 
            onClose={onClosePopup}>
            <ul className="resources">
              {
                media.resource.map((resource, index) => {
                  return (
                    <li key={`resource-${index}`}>
                      { isEmpty(resource.url) &&
                        <span dangerouslySetInnerHTML={{__html: resource.title}} />
                      }
                      { !isEmpty(resource.url) &&
                        <a className="resource-link" href={resource.url} target="_blank" 
                          rel="noopener noreferrer" 
                          dangerouslySetInnerHTML={{__html: resource.title}} 
                        />
                      }
                    </li>
                  );
                })
              }
            </ul>
          </StandardSystemPopup>
        }
      </Fragment>
    );
  }

  const renderQuestionPopup = () => {
    return (
      <Fragment>
        { displayQuestionForm &&
          <Dialog 
            fullScreen
            className="EventQuestionPopup"
            open={displayQuestionForm}
            onClose={onClosePopup}
            TransitionComponent={Transition}
          >
            <DialogActions>
              <Button onClick={onClosePopup} variant="contained" color="secondary">
                Close
              </Button>
            </DialogActions>
            <DialogContent>
              <QuestionEmbed eventData={eventData} />
            </DialogContent>
          </Dialog>
        }
      </Fragment>
    );
  }

  return (
    <div className="EventMedia">
      { isTrialMemberWithoutAccess()?
        <Fragment>{renderTrialMemberDenial()}</Fragment> :
        <Fragment>
          <Fragment>{renderButtons()}</Fragment>
          <Fragment>{renderAccessDialog()}</Fragment>
          <Fragment>{renderResourcesPopup()}</Fragment>
          <Fragment>{renderQuestionPopup()}</Fragment>
        </Fragment>
      }
    </div>
  );
}

DetailMedia.defaultProps = {
  isCompleted: false
}

DetailMedia.propTypes = {
  eventData: PropTypes.object,
  eventRegistration: PropTypes.object,
  onOpenClassroom: PropTypes.func
};

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps)(DetailMedia);
