import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import validator from '../../utils/validator';
const { isNotEmpty } = validator;


/*
Note: supported params:
- message: string   (required) the message that will be displayed
- type: string      (required) controls the message display (e.g. info, error, promo, etc.)
- time: number      (optional) length of time the message should stay presented, 0 = stays visible until nav to the next page
- speed: number     (optional) speed of animation (in seconds), if not supplied MaterialUI calculates the speed automatically
- button: object    (optional) configuration if you want to display a button
   - label: string
   - url: string
*/

function PageMessage({ pageMessage }) {
  const [display, setDisplay] = useState(false);
  const [time, setTime] = useState(-1);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');
  const [button, setButton] = useState(null);
  const [speed, setSpeed] = useState('auto')
  const location = useLocation();

  useEffect(() => {
    setDisplay(false);
  }, [location]);

  useEffect(() => {
    if(pageMessage.message) {
      setTime(pageMessage.time);
      setMessage(pageMessage.message);
      setType(pageMessage.type);
      setButton(pageMessage.button);
      setSpeed(pageMessage.speed? (pageMessage.speed * 1000) : 'auto'); // set/convert speed to MS
      setDisplay(true);
    }
  }, [pageMessage]);

  useEffect(() => {
    if(display && time !== 0) {
      const length = 1000 * time;
      setTimeout(function() {
        setDisplay(false);
      }, length);
    }
  }, [display, time]);

  const navigate = () => {
    window.location.href = button.url;
  };
  
  return (
    <Collapse in={display} timeout={speed}>
      <div id="PageMessage" className={`${type} button-${isNotEmpty(button)? 'show' : 'hide'}`}>
        <div className="message">{ message }</div>
        { isNotEmpty(button) &&
          <Button className="action" variant="outlined" onClick={navigate}>
            {button.label}
          </Button>
        }
      </div>
    </Collapse>
  );
}

function mapStateToProps(state) {
  const { pageMessage } = state.appDisplay;
  return { pageMessage };
}

export default connect(mapStateToProps)(PageMessage);