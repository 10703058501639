import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ExploreNav from './header/ExploreNav';
import SecondaryNavMenu from './header/SecondaryNavMenu';
import FeaturePopup from '../marketing/FeaturePopup';
import * as authentication from '../../actions/authentication';
import * as behavior from '../../actions/behavior';
import * as system from '../../actions/content/system';
import * as appDisplay from '../../actions/appDisplay';
import logo from '../../images/icc-logoV2.png'; 
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import urls from '../../config/urls';
import stateConfig from '../../config/state';


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      featurePopup: null,
      systemAlert: null
    };

    this.onClosePopup = this.onClosePopup.bind(this);
  }

  componentDidMount() {
    if(!window.location.href.includes('/login')) {
      this.props.authentication.getAuthStatus(false);
    }
    this.props.system.getPopup();
    this.props.system.getAlert();
    this.props.system.getWebConfig();
  }

  componentDidUpdate(prevProps) {
    if(this.props.user.email !== prevProps.user.email) {
      this.getUserData();
    }

    if(this.props.systemContent.popup && this.props.systemContent.popup !== prevProps.systemContent.popup) {
      this.setState({ featurePopup: this.props.systemContent.popup });
    }

    if(this.props.systemContent.alert && this.props.systemContent.alert !== prevProps.systemContent.alert) {
      const self = this;
      this.setState({ systemAlert: this.props.systemContent.alert }, () => {
        self.renderAlert();
      });
    }
  }

  getUserData() {
    if(this.props.user.email) {
      this.props.behavior.getBehaviorData();
    }
  }

  onClosePopup() {
    this.setState({ featurePopup: null });
  }

  renderAlert() {
    const { message, type } = this.state.systemAlert;
    this.props.appDisplay.displayPageMessage({
      message,
      type: type === 'Informational'? stateConfig.messageTypes.INFO : stateConfig.messageTypes.WARNING,
      time: 0
    });
  }

  render() {
    const { featurePopup } = this.state;
    const { memberAuthenticated, user } = this.props;

    return (
      <AppBar position="fixed" className="AppHeader">
        { featurePopup && <FeaturePopup onClose={this.onClosePopup} /> }
        <Toolbar className="AppHeader-toolbar">
          <Link to={urls.root} className="AppHeader-logo">
            <img src={logo} alt="Institute of Catholic Culture Logo" />
          </Link>
          <ExploreNav />
          <SecondaryNavMenu memberAuthenticated={memberAuthenticated} user={user} />
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  user: PropTypes.object
};

function mapStateToProps(state) {
  return { 
    user: state.user,
    systemContent: state.systemContent
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authentication: bindActionCreators(authentication, dispatch),
    behavior: bindActionCreators(behavior, dispatch),
    system: bindActionCreators(system, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);