import React from 'react';
import PropTypes from 'prop-types';
import MemberList from './MemberList';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';


function MemberMenu(props) {
  const { isOpen, onClose } = props;
  return (
    <div className="MemberMenu">
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        onOpen={() => {}}
      >
        <MemberList onClose={onClose} />
      </SwipeableDrawer>
    </div>
  );
}

MemberMenu.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default MemberMenu;