import React from 'react';
import Icon, { IconTypes } from '../../common/Icon';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import urls from '../../../config/urls';

export default function ExistingICCMemberMagdalaConfirmation() {
  return (
    <div id="NewMemberConfirmation" size="double">
      <div className="NewMemberConfirmation-followup">
        <h2>Application Complete!</h2>
        <div className="NewMemberConfirmation-steps addMarginBottomToPushFooter400">
          <Card className="NewMemberConfirmation-step one" square={false}>
            <CardContent className="NewMemberConfirmation-instructions">
              <Icon type={IconTypes.Email} avatar={true} spacing="padded" inline={true} />
            </CardContent>
            <CardContent>
              <p>
                Your application to the Magdala Apostolate program was successfully submitted! Please keep an eye on your email inbox. As the Magdala Apostolate is only open to religious sisters, our Magdala Apostolate Program Coordinator will send you an email within the next few days with some additional questions you must complete before your membership can be granted. If you do not receive an email within a few days, please <Link to={urls.magdalaContactUs}>contact us</Link>!.
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
)}