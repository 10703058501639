import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useReflections from '../../hooks/content/useReflections';
import validator from '../../utils/validator';
const { isNotEmpty } = validator;

function Reflections({ type, slug, onReflectionsLoaded }) {
  const [loaded, setLoaded] = useState(false);
  const reflectionsData = useReflections(type, slug);

  useEffect(() => {
    setLoaded(false);
  }, [slug]);

  useEffect(() => {
    if(!loaded && isNotEmpty(reflectionsData)) {
      setLoaded(true);
      onReflectionsLoaded(reflectionsData);
    }
  }, [loaded, reflectionsData, onReflectionsLoaded]);

  return (<Fragment></Fragment>);
}

Reflections.propTypes = {
  type: PropTypes.string,
  onReflectionsLoaded: PropTypes.func
};

export default Reflections;