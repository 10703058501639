import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import * as issuesAPI from '../../../../actions/issues';
import Icon, { IconTypes } from '../../../common/Icon';
import PopMessage from '../../../common/messages/PopMessage';
import stateConfig from '../../../../config/state';
import { generateCertificatePage1, getImageData } from '../../../../utils/pdf';
import { formatDateTimeET } from '../../../../utils/format';
import validator from '../../../../utils/validator';
const { isEmpty, isDefined } = validator;


function CourseStatus({ course, transcript, courseComplete, user, courseProgress, issuesAPI }) {
  const [started, setStarted] = useState(false);
  const [progress, setProgress] = useState(null);
  const [percent, setPercent] = useState(0);
  const [status, setStatus] = useState('');
  const [error, setError] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const downloadCertificate = async () => {
    setDownloading(true);
    let dtArr = null;
    if(isDefined(transcript.completed) || isDefined(transcript.lastUpdated)) {
      const dt = isDefined(transcript.completed)? formatDateTimeET(transcript.completed) : formatDateTimeET(transcript.lastUpdated);
      dtArr = dt.split(' ');
    } else {
      setError({ display: true, message: 'We were unable to generate your certificate. If this condition continues please notify our staff.' });
      await issuesAPI.logIssue('course.downloadCertificate', { email: user.email, transcript });
      setDownloading(false);
      return;
    }

    const { page1, page2 } = course.certificate;
    const data1 = await getImageData(page1);
    const data2 = await getImageData(page2);
    generateCertificatePage1({
      image1: data1,
      image2: data2,
      completionDate: dtArr[0],
      name: `${user.firstName} ${user.lastName}`,
      fileName: `${course.slug.replace('course-', '')}-certificate`
    });
    setDownloading(false);
  };

  useEffect(() => {
    if(isEmpty(progress) && !courseComplete) {
      const stateId = `${stateConfig.keys.PROGRESS}.${course.id}`;
      if(courseProgress.ids.includes(stateId)) {
        const data = courseProgress.byId[stateId];
        if(data && !data.error) {
          // filter progress to only completed items
          // build lesson parts list, including exams, excluding non-required parts
          let lessonParts = course.lessons.map(lesson => lesson.parts);
          let parts = lessonParts.reduce((arr, parts) => arr.concat(parts), []);
          parts = parts.filter(part => (part.type === 'assessment' || part.required));
          parts = parts.concat(course.exams);
          // determine parts that pertain to the registered track
          let steps = parts.filter(part => (part.track === 'cert-audit' || part.track === transcript.track));
          // now filter progress to completed items those that pertain to the registered track
          let progress = data.progress.filter(item => {
            if(item.status !== 'complete') return false;
            
            const part = parts.find(p => p.id === item.partId);
            return isDefined(part) && (part.track === 'cert-audit' || transcript.track === part.track);
          });
          
          const percent = parseInt((progress.length/steps.length) * 100);
          let status = '';
          if(data.progress.length === 0) {
            status = 'Not Started';
          } else if(percent === 100) {
            status = 'Completed';
          } else if(percent < 100) {
            status = `In Progress: ${percent}%`;
          }
          
          setStarted((data.progress.length > 0));
          setProgress(progress);
          setPercent(percent);
          setStatus(status);
        }
      }
    }
  }, [progress, courseProgress.ids, courseProgress.byId, course, transcript, courseComplete]);

  const clearPopMessage = () => {
    setError({ display: false });
  };

  return (
    <div className="CourseStatus">
      { courseComplete &&
        <div className="CourseStatus-complete">
          <div className="message-content">
            <h4>Congratulations!</h4>
            <p className="message">You have completed this course.</p>
          </div>
          { transcript.track === 'certificate' &&
            <Button variant="contained" color="secondary" onClick={downloadCertificate}>
              <Icon type={IconTypes.Certificate} />
              { downloading? 'Downloading...' : 'Download Certificate' }
            </Button>
          }
        </div>
      }
      { !courseComplete && !isEmpty(progress) && !started &&
        <Tooltip title="If you would like to switch tracks, please contact our office.">
          <div className="CourseStatus-message not-started">
            <div className="status-text">
              You are enrolled in this {transcript.track === 'certificate'? 'certificate' : 'audit' } course.&nbsp;
              <Icon className="info" type={IconTypes.InfoCircleFilled} />
            </div>
          </div>
        </Tooltip>
      }
      { !courseComplete && !isEmpty(progress) && started &&
        <Fragment>
          <div className="CourseStatus-message started">
            <div className="status-text">{status}</div>
          </div>
          <div className="CourseStatus-progress">
            <div className="background"></div>
            <div className="progress" style={{width: `${percent}%`}}></div>
          </div>
        </Fragment>
      }
      { !isEmpty(error) && error.display && error.message &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>{error.message}</p>
        </PopMessage>
      }
    </div>
  );
}

CourseStatus.propTypes = {
  course: PropTypes.object,
  transcript: PropTypes.object,
  courseComplete: PropTypes.bool
};

function mapStateToProps(state) {
  return { 
    user: state.user,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    issuesAPI: bindActionCreators(issuesAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseStatus);
