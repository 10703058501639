import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Icon, { IconTypes } from '../../../common/Icon';
import urls from '../../../../config/urls';
import validator from '../../../../utils/validator';
import Button from '@mui/material/Button';


function DetailCourseAccess({ eventData }) {
  const { type, slug, status, magdala, course: { releaseDate, auditApplication, certificateApplication, waitlisted } } = eventData;
  const hasApplications = !validator.isEmpty(`${auditApplication}${certificateApplication}`.trim());
  const navigate = useNavigate();

  return (
    <Fragment>
      { type === 'Self-Paced Course' && status === 'Completed' && !releaseDate &&
        <div className="EventDetail-course-access self-paced open">
          <Button variant="contained" color="secondary" onClick={() => navigate(`${urls.course.replace(':slug', slug)}`)}>
            <Icon type={IconTypes.ExternalLink} /> Open Course
          </Button>
        </div>
      }
      { type === 'Self-Paced Course' && status === 'Completed' && releaseDate &&
        <div className="EventDetail-course-access self-paced closed">
          <p className="course-status">Registration is not currently open</p>
          <p className="release-date"><strong>Projected Release:</strong> {releaseDate}</p>
        </div>
      }
      { type === 'Live Course' && status === 'Upcoming' && !hasApplications && !releaseDate &&
        <div className="EventDetail-course-access live application">
          <p className="course-status">Registration for this course is not open yet.</p>
        </div>
      }
      { type === 'Live Course' && status === 'Upcoming' && !hasApplications && releaseDate &&
        <div className="EventDetail-course-access live application">
          <p className="course-status">Registration for this course is not open yet.</p>
          <p className="release-date"><strong>Registration Opens:</strong> {releaseDate}</p>
        </div>
      }
      { type === 'Live Course' && status === 'Upcoming' && hasApplications && !waitlisted &&
        <div className="EventDetail-course-access live application">
          <p className="course-status">{magdala? 'Magdala Apostolate registration' : 'Registration'} is now open!</p>
        </div>
      }
      { type === 'Live Course' && status === 'Upcoming' && hasApplications && waitlisted &&
        <div className="EventDetail-course-access live application">
          <p className="course-status">This class is full. You can apply to be placed on the waitlist.</p>
        </div>
      }
      { type === 'Live Course' && (status === 'Running Now' || status === 'Completed') && !releaseDate &&
        <div className="EventDetail-course-access live running">
          <p className="course-status">{magdala? 'Magdala Apostolate registration' : 'Registration'} is closed. Only enrolled students may access the course.</p>
          <Button variant="contained" color="secondary" onClick={() => navigate(urls.myTranscript)}>
            <Icon type={IconTypes.ExternalLink} /> Open Student Profile
          </Button>
        </div>
      }
      { type === 'Live Course' && (status === 'Running Now' || status === 'Completed') && releaseDate &&
        <div className="EventDetail-course-access live closed">
          <p className="course-status">{magdala? 'Magdala Apostolate registration' : 'Registration'} is closed</p>
          <p className="release-date"><strong>Open to enrolled students:</strong> {releaseDate}</p>
        </div>
      }
    </Fragment>
  );
}

DetailCourseAccess.propTypes = {
  eventData: PropTypes.object
};

export default DetailCourseAccess;