import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import * as appDisplayAPI from '../../../actions/appDisplay';
import * as memberRegistrationAPI from '../../../actions/registration/member';
import stateConfig from '../../../config/state';
import errors from '../../../config/errors';


function ActivationPrompt({ authentication, membershipActivation, appDisplayAPI, memberRegistrationAPI }) {
  const [enabled, setEnabled] = useState(true);
  const { contextParams } = authentication;
  const { responseStatus: activationRespStatus, responseCode: activationRespCode, resent: activationResent } = membershipActivation;
  
  const resendActivationEmail = () => {
    setEnabled(false);
    memberRegistrationAPI.resendMemberActivation(contextParams.email);
  };

  useEffect(() => {
    if(activationResent) {
      appDisplayAPI.displayPageMessage({
        message: 'We have sent you a new activation email.',
        type: stateConfig.messageTypes.SUCCESS
      });
    }

    if(activationRespStatus === stateConfig.responseStatus.ERROR && activationRespCode === errors.ActivationResendFailure.code) {
      appDisplayAPI.displayPageMessage({
        message: 'We were not able to successfully re-send your activation email.',
        type: stateConfig.messageTypes.ERROR
      });
    }
  }, [activationRespStatus, activationResent, activationRespCode, appDisplayAPI]);

  return (
    <div id="ActivationPrompt">
      <div className="content">
        <p className="title">Your account has not been activated</p>
        <p className="message">
          You must activate your account before you can log in. You should have received an activation email shortly 
          after your member registration.
        </p>
        <div className="actions">
          <Button variant="contained" color="primary" onClick={resendActivationEmail} disabled={!enabled}>
            Re-send Activation Email
          </Button>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    membershipActivation: state.membershipActivation
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    appDisplayAPI: bindActionCreators(appDisplayAPI, dispatch),
    memberRegistrationAPI: bindActionCreators(memberRegistrationAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivationPrompt);