import { parseISO, isValid as isValidDate } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

/**
 * STRING FORMATTING
 */

export function truncate(str = '', maxlength = 100) {
  if(str.length <= maxlength) return str;
  let trimmed = str.substring(0, maxlength);
  return trimmed.substring(0, Math.min(trimmed.length, trimmed.lastIndexOf(' '))) + '...';
}

export function stripHTML(str = '') {
  return str.replace(/<[^>]*>?/gm, '');
}

export function formatCurrency(number, options = { sign: false, cents: false}) {
  const { sign, cents } = options;
  let formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: cents? 2 : 0,
    maximumFractionDigits: cents? 2 : 0
  });
  return `${sign? '$' : ''}${formatter.format(number)}`;
}


/**
 * DATE/TIME FORMATTING
 */

export function formatDateTimeET(datetimeStr, provideDateObj = false) {
  let dt = parseISO(datetimeStr);
  if(isValidDate(dt)) {
    const dtStr = formatInTimeZone(dt, 'America/New_York', 'MM/dd/yyyy hh:mm a');
    return provideDateObj? { date: dt, str: `${dtStr} ET` } : `${dtStr} ET`;
  } else {
    // trying to deal with a hard to recreate issue where moment sometimes throws 'invalid date' message
    // putting in this backup code in case moment is not working in some browsers
    dt = new Date(datetimeStr);
    if(dt instanceof Date && !isNaN(dt)) {
      const localeStr = dt.toLocaleString('en-US', {timeZone: 'America/New_York'});
      const formattedDate = localeStr.substring(0, 10).replace(',', '');
      const dateArr = localeStr.split(' ');
      const timeArr = dateArr[1].split(':');
      return `${formattedDate} ${timeArr[0]}:${timeArr[1]} ${dateArr[2]} ET`;
    }
  }
  return ''; // all date parsing attempts failed, something is wrong with this date
}

export function formatDateForDisplay(date) {
  let month = `0${date.getMonth()+1}`;
  month = month.substring(month.length-2);
  let day = `0${date.getDate()}`;
  day = day.substring(day.length-2);
  return `${month}/${day}/${date.getFullYear()}`;
}

export function formatTimeForDisplay(timeStr, timeZone = 'ET') {
  try {
    const time = timeStr.split(":");
    const dt = new Date();
    dt.setHours(time[0], time[1]); // reset time with timestr provided

    // format according US locale
    const localeTS = dt.toLocaleTimeString('en-US');
    const timeAndClockFormat = localeTS.split(' ');
    const timeIncrements = timeAndClockFormat[0].split(':');
    const twelveHourPeriod = localeTS.includes('AM')? 'AM' : 'PM';
    const formattedTime = `${timeIncrements[0]}:${timeIncrements[1]} ${twelveHourPeriod} ${timeZone}`;
    return formattedTime;
  } catch(e) {
    return timeStr;
  }
}

export function convertSecondsIntoTimeDisplay(seconds) { // e.g., 00:00:00
  let min = Math.floor(seconds / 60);
  let sec = Math.floor(seconds - (min * 60));
  let hrs = Math.floor(min / 60);
  min = min - (hrs * 60);

  let hrsDisplay = hrs > 0? hrs+':' : '';
  let minDisplay = `0${min}`;
  minDisplay = minDisplay.substring(minDisplay.length-2) + ':';
  let secDisplay = `0${sec}`;
  secDisplay = secDisplay.substring(secDisplay.length-2);

  return hrsDisplay+minDisplay+secDisplay;
}