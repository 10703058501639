import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../hooks/useAnalytics';
import * as coursesAPI from '../../actions/content/courses';
import * as progressAPI from '../../actions/progress';
import PopMessage from '../common/messages/PopMessage';
import LoadingMessage from '../common/messages/LoadingMessage';
import TranscriptHeading from './transcript/TranscriptHeading';
import TranscriptRecords from './transcript/TranscriptRecords';
import config from '../../config/config';
import stateConfig from '../../config/state';
import urls from '../../config/urls';
import { resetViewToTopOfPage } from '../../utils/utils';
import validator from '../../utils/validator';
const { isEmpty } = validator;


function Transcript({ user, courses, courseProgress, coursesAPI, progressAPI }) {
  const [loading, setLoading] = useState(false);
  const [transcripts, setTranscripts] = useState(null);
  const [error, setError] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const navigate = useNavigate();
  useAnalytics('Student Transcript');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(loading && isEmpty(transcripts)) {
      const transcriptId = `${stateConfig.keys.PROGRESS_TRANSCRIPTS}.all`;
      const progressId = `${stateConfig.keys.PROGRESS_TRANSCRIPTS}.progress`;
      const coursesId = `${stateConfig.keys.COURSE_LIST}.summary`;
      const tData = courseProgress.ids.includes(transcriptId)? courseProgress.byId[transcriptId] : null;
      const pData = courseProgress.ids.includes(progressId)? courseProgress.byId[progressId] : null;
      const cData = courses.ids.includes(coursesId)? courses.byId[coursesId] : null;
      if(tData && pData && cData) {
        if(tData.error || pData.error || cData.error) {
          setError({ display: true });
        } else {
          let records = tData.transcripts.map(transcript => {
            let record = { ...transcript, certificate: null, progress: { complete: 0, steps: 0, percent: 0 } };
            let progress = pData.progress? pData.progress[record.courseId] : null;
            if(progress) {
              record.progress.complete = progress.complete;
            }
            let course = cData.results.find(result => result.id === record.courseId);
            if(course) {
              record.courseStatus = course.status;
              record.certificate = course.certificate;
              record.progress.steps = record.track === 'certificate'? course.certificateSteps : course.auditSteps;
            }
            record.progress.percent = record.progress.steps > 0? parseInt((record.progress.complete / record.progress.steps) * 100) : 0;
            return record;
          });
          records = records.filter(record => !record.waitlisted);
          setTranscripts(records);
          setLoading(false);
        }
      }
    }
  }, [loading, transcripts, courseProgress.ids, courseProgress.byId, courses.ids, courses.byId]);

  initialize = () => {
    resetViewToTopOfPage();
    progressAPI.getCourseTranscripts();
    progressAPI.getProgressSummary();
    coursesAPI.getCourses();
    setTimeout(() => setLoading(true), 250);
  };

  const clearPopMessage = () => {
    setError({ display: false });
    setDeleteError({ display: false });
  };

  const removeCourseRecord = async record => {
    const { courseId, status } = record;
    await progressAPI.deleteCourseProgress(courseId, status);
    window.location.reload();
  };

  const authenticated = user && !isEmpty(user.email) && user.authenticated;
  if(user.userDataChecked && !authenticated) { 
    // redirect
    navigate(`${urls.login}?ra=transcript`);
  }

  return (
    <div className="Transcript">
      <div className="Transcript-container">
        { isEmpty(transcripts) && isEmpty(error) &&
          <div className="loadMessage"><LoadingMessage message="Loading..." /></div>
        }
        { !isEmpty(error) && error.display && user.userDataChecked &&
          <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
            <p>We could not load this resource.</p>
          </PopMessage>
        }
        { !isEmpty(deleteError) && deleteError.display &&
          <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
            <p>An error occurred while attempting to delete the selected course record.</p>
          </PopMessage>
        }
        { !isEmpty(transcripts) && user.userDataChecked && authenticated &&
          <div className="Transcript-content">
            <h1>Student Transcript</h1>
            <TranscriptHeading transcripts={transcripts} />
            { transcripts.length === 0 &&
              <p className="no-transcript">You have not registered for any courses.</p>
            }
            <div className={`Transcript-recordList ${transcripts.length === 0? 'no-records' : 'has-records'}`}>
              <TranscriptRecords transcripts={transcripts} statuses={config.courseNotComplete} onRemove={removeCourseRecord} />
              <TranscriptRecords transcripts={transcripts} statuses={config.courseComplete} />
            </div>
          </div>
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    user: state.user,
    courses: state.courses,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    coursesAPI: bindActionCreators(coursesAPI, dispatch),
    progressAPI: bindActionCreators(progressAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Transcript);