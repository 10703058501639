import axios from 'axios';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

const donationsPath = '/member/donations';

export async function startDonationTransaction(data) {
  return await axios.post(`${urls.hubUIApiRoot}${donationsPath}/single/start`, data);
}

export async function storeDonationTransaction(data) {
  return await axios.post(`${urls.hubUIApiRoot}${donationsPath}/single/complete`, data);
}

export async function performRecurringDonation(data) {
  return await axios.post(`${urls.hubUIApiRoot}${donationsPath}/recurring/complete`, data);
}

export async function getDonationHistory() {
  return await axios.get(`${urls.hubUIApiRoot}${donationsPath}/manage/history`);
}

export async function getCards(customerId) {
  return await axios.get(`${urls.hubUIApiRoot}${donationsPath}/manage/${customerId}/cards`);
}

export async function updateRecurringDonation(recurringDonationId, data) {
  return await axios.post(`${urls.hubUIApiRoot}${donationsPath}/manage/recurring/${recurringDonationId}`, data);
}

export async function updateRecurringDonationCard(customerId, recurringDonationId, card) {
  return await axios.post(`${urls.hubUIApiRoot}${donationsPath}/manage/recurring/${recurringDonationId}/card`, { customerId, card });
}