import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'qs';
import * as appDisplay from '../../../../actions/appDisplay';
import * as courseRegistrationAPI from '../../../../actions/registration/course';
import * as authenticationAPI from '../../../../actions/authentication';
import Icon, { IconTypes } from '../../../common/Icon';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import urls from '../../../../config/urls';
import config from '../../../../config/config';
import stateConfig from '../../../../config/state';
import validator from '../../../../utils/validator';
import { localRetrieve } from '../../../../utils/store';


function DetailCourseApplication({ eventData, courseRegistration, user, courseRegistrationAPI, authenticationAPI, appDisplay }) {
  const [loading, setLoading] = useState(true);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { salutation, firstName } = user;
  const { magdala, course: { auditApplication, certificateApplication, waitlisted } } = eventData;
  const hasApplications = !validator.isEmpty(`${auditApplication}${certificateApplication}`.trim());
  const auditOnly = !validator.isEmpty(`${auditApplication}`.trim()) && validator.isEmpty(`${certificateApplication}`.trim());
  const certificateOnly = validator.isEmpty(`${auditApplication}`.trim()) && !validator.isEmpty(`${certificateApplication}`.trim());
  const certAndAudit = hasApplications && !auditOnly && !certificateOnly;
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let checkForConfirmation = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(user.email) {
      checkForConfirmation(true);
    }
  }, [user, checkForConfirmation]);

  useEffect(() => {
    if(loading && !Boolean(registration) && courseRegistration.ids) {
      const { slug } = eventData;
      const rObj = courseRegistration.byId[slug];

      if(rObj) {
        if(!rObj || rObj.error) {
          setLoading(false);
        } else {
          setLoading(false);
          setAlreadyApplied(true);
          setRegistration(rObj);
        }
      }
    }
  }, [loading, registration, eventData, courseRegistration.ids, courseRegistration.byId]);

  initialize = () => {
    const { slug } = eventData;
    const { byId } = courseRegistration;
    const rObj = byId[slug];

    if(rObj && !validator.isDefined(rObj.error)) {
      setLoading(false);
      setAlreadyApplied(true);
      setRegistration(rObj);
    } else {
      courseRegistrationAPI.getCourseRegistrationStatus(slug);
    }

    checkForConfirmation(false);
  };

  checkForConfirmation = (suppressMessage = false) => {
    const { slug } = eventData;
    const params = querystring.parse(location.search.substring(1));
    if(params.confirmation) {
      if(!suppressMessage) {
        const providerId = localRetrieve(config.localStorage.registrationMethod);
        const activationMessage = providerId !== 'google' && providerId !== 'facebook'? ' Please check your inbox for your account activation email.' : '';
        appDisplay.displayPageMessage({
          message: `You have successfully applied!${params.newMember === 'true'? activationMessage : ''}`,
          type: stateConfig.messageTypes.SUCCESS,
          time: 10
        });
      }
      if(validator.isNotEmpty(user.email)) {
        courseRegistrationAPI.markLiveCourseRegistrationComplete(slug);
      }
    }
  }

  const onApplicationSelection = track => () => {
    const { slug } = eventData;
    navigate(`${urls.courseApplication}?slug=${slug}&track=${track}`);
  }

  const logoutAndRefresh = () => {
    const { protocol, host, pathname } = window.location;
    authenticationAPI.logout()
      .then(() => {
        window.location = `${protocol}//${host}${pathname}`;
      });
  }

  return (
    <Fragment>
      { !loading && alreadyApplied &&
        <div className="EventDetail-course-application-status">
          <div>
            <Icon type={IconTypes.Complete} /> { validator.isDefined(registration)? (
                <span>Thanks {magdala && `${salutation} `}{firstName}! You have applied for the {registration.track} track of this course.</span>
              ) :
              ( <span>You have applied for this course</span> )
            }
          </div>
          { validator.isDefined(registration) &&
            <div>
              <p>If you have any questions concerning your application please <a href={urls.contactUs}>contact us.</a></p>
              <p>
                Not {firstName}? Please <span onClick={logoutAndRefresh}>log out</span>, then log in using your email address.
              </p>
            </div>
          }
        </div>
      }
      { !loading && hasApplications && !magdala && !alreadyApplied &&
        <div className="EventDetail-course-application">
          { certAndAudit &&
            <p className="instructions">
              You may apply to either the Audit Track or the Certificate Track. Select the tabs below to understand 
              the differences between the two.
            </p>
          }
          { certAndAudit &&
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
              value={tab}
              onChange={(event, tabIndex) => setTab(tabIndex)}
              className="course-application-tabs">
              <Tab label="Audit Track" />
              <Tab label="Certificate Track" />
            </Tabs>
          }
          { (tab === 0 && (certAndAudit || auditOnly)) &&
            <Paper className="content audit" elevation={4}>
              <p>The audit track includes access to all reading assignments and lectures. There are no assessments, and a certificate of completion will not be issued.</p>
              <div className="actions">
                <Button variant="contained" color="secondary" onClick={onApplicationSelection('audit')}>
                  {waitlisted? 'Apply for Waitlist' : 'Apply to Audit'}
                </Button>
              </div>
            </Paper>
          }
          { ((tab === 1 && certAndAudit) || certificateOnly) &&
            <Paper className="content certificate" elevation={4}>
              <p>The certificate track includes weekly reading assignments, lectures, quizzes, and a final exam. The weekly quiz is typically a 10-question multiple choice and/or True/False quiz to test the student’s knowledge of the reading and lecture material. Upon completion of all course readings, lectures, quizzes, and the final exam, a certificate of completion will be available to download from the course site.</p>
              <br />
              <p><span className="italic">Note:</span> Certificates can only be issued to individuals. To apply as a group (married couples, families, parish groups, etc.), please select the audit track.</p>
              <div className="actions">
                <Button variant="contained" color="secondary" onClick={onApplicationSelection('certificate')}>
                  {waitlisted? 'Apply for Waitlist' : 'Apply to Certificate'}
                </Button>
              </div>
            </Paper>
          }
        </div>
      }
      { !loading && hasApplications && magdala && !alreadyApplied &&
        <div className="EventDetail-course-application">
          { certAndAudit &&
            <p className="instructions">
              You may apply to either the Audit Track or the Certificate Track. Select the tabs below to understand 
              the differences between the two.
            </p>
          }
          { certAndAudit &&
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              indicatorColor="primary"
              textColor="primary"
              value={tab}
              onChange={(event, tabIndex) => setTab(tabIndex)}
              className="course-application-tabs">
              <Tab label="Audit Track" />
              <Tab label="Certificate Track" />
            </Tabs>
          }
          { (tab === 0 && (certAndAudit || auditOnly)) &&
            <Paper className="content certificate" elevation={4}>
              <p>
                The Audit Track provides access to lectures, readings, and further study resources. It does not provide assessments or a certificate of completion. 
              </p>
              <div className="actions">
                <Button variant="contained" color="secondary" onClick={onApplicationSelection('audit')}>
                  {waitlisted? 'Apply for Waitlist' : 'Apply to Audit'}
                </Button>
              </div>
            </Paper>
          }
          { ((tab === 1 && certAndAudit) || certificateOnly) &&
            <Paper className="content certificate" elevation={4}>
              <p>
                The Certificate Track provides access to lectures, readings, further study resources, assessments, and a final exam. A certificate of completion is provided upon completion of the course.
              </p>
              <br />
              <p>
                Please note: To register for the certificate track, sisters must have their own unique email address. Please contact the Magdala Apostolate if this requirement will inhibit enrollment.
              </p>
              <div className="actions">
                <Button variant="contained" color="secondary" onClick={onApplicationSelection('certificate')}>
                  {waitlisted? 'Apply for Waitlist' : 'Apply to Certificate'}
                </Button>
              </div>
            </Paper>
          }
        </div>
      }
    </Fragment>
  );
}

DetailCourseApplication.propTypes = {
  eventData: PropTypes.object
};

function mapStateToProps(state) {
  return {
    user: state.user,
    courseRegistration: state.courseRegistration
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    appDisplay: bindActionCreators(appDisplay, dispatch),
    courseRegistrationAPI: bindActionCreators(courseRegistrationAPI, dispatch),
    authenticationAPI: bindActionCreators(authenticationAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailCourseApplication);
