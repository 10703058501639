import { CRS_REGISTRATION_REQUEST, CRS_REGISTRATION_STATUS, CRS_REGISTRATION_COMPLETE, 
         CRS_REGISTRATION_ERR, CRS_REGISTRATION_MARK_COMPLETE } from '../../actions/actionTypes';
import * as registration from '../../api/registration';
import errors from '../../config/errors';


export function getCourseRegistrationStatus(slug) {
  return async dispatch => {
    dispatch({ type: CRS_REGISTRATION_REQUEST, slug });
    try {
      let result = await registration.getCourseRegistrationStatus(slug);
      dispatch({ type: CRS_REGISTRATION_STATUS, slug, data: result.data });
    } catch(error) {
      dispatch({ type: CRS_REGISTRATION_ERR, slug, error: errors.RequestFailed });
    }
  };
}

export function createLiveCourseRegistration(slug, registrationData) {
  return async dispatch => {
    dispatch({ type: CRS_REGISTRATION_REQUEST, slug });
    try {
      await registration.createLiveCourseRegistration(slug, registrationData);
      dispatch({ type: CRS_REGISTRATION_COMPLETE, slug });
    } catch(error) {
      const err = (error.response && error.response.data)? error.response.data : errors.RequestFailed;
      dispatch({ type: CRS_REGISTRATION_ERR, slug, error: err });
    }
  };
}

export function markLiveCourseRegistrationComplete(slug) {
  return async dispatch => {
    try {
      await registration.markLiveCourseRegistrationComplete(slug);
      dispatch({ type: CRS_REGISTRATION_MARK_COMPLETE, slug });
    } catch(error) {
      // fail silently
    }
  };
}

export function createSelfPacedCourseRegistration(slug, registrationData) {
  return async dispatch => {
    dispatch({ type: CRS_REGISTRATION_REQUEST, slug });
    try {
      await registration.createSelfPacedCourseRegistration(slug, registrationData);
      dispatch({ type: CRS_REGISTRATION_COMPLETE, slug });
    } catch(error) {
      const err = (error.response && error.response.data)? error.response.data : errors.RequestFailed;
      dispatch({ type: CRS_REGISTRATION_ERR, slug, error: err });
    }
  };
}