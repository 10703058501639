import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';


function PopupActions({ type, data, onActionClick, onDonationClick }) {
  return (
    <Fragment>
      { type !== 'donation' &&
        <Button className="default" onClick={onActionClick}>
          {data.actionTitle}
        </Button>
      }
      { type === 'donation' &&
        <Button className="default" onClick={onDonationClick}>
          Donate Now
        </Button>
      }
    </Fragment>
  );
}

PopupActions.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  onActionClick: PropTypes.func,
  onDonationClick: PropTypes.func
};

export default PopupActions;