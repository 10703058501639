import axios from 'axios';
import urls from '../../config/urls';
import config from '../../config/config';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

const instructorsPath = '/content/instructors';

export async function getInstructors() {
  return await axios.get(`${urls.hubUIApiRoot}${instructorsPath}`, { timeout: config.wpTimeout });
}

export async function getInstructorById(id) {
  return await axios.get(`${urls.hubUIApiRoot}${instructorsPath}/${id}`, { timeout: config.wpTimeout });
}
