import React, { Fragment, useState, useEffect } from 'react'; 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useAnalytics from '../../hooks/useAnalytics';
import Banner from './common/Banner'; 
import MagdalaToolbar from '../layout/MagdalaToolbar';
import * as eventsAPI from '../../actions/content/events'; 
import EventList from '../content/events/display/EventList';
import { resetViewToTopOfPage } from '../../utils/utils';
import SEOMeta from '../common/SEOMeta';
import MobileNav from './common/MobileNav';
import * as systemContentAPI from '../../actions/content/system'; 
import LoadingMessage from '../common/messages/LoadingMessage';
import PopMessage from '../common/messages/PopMessage'; 
import stateConfig from '../../config/state'; 
import config from '../../config/config'; 
import urls from '../../config/urls'; 
import UnenclosedCallToAction from '../../components/common/UnenclosedCallToAction'; 
import validator from '../../utils/validator'; 
const { isEmpty, isNotEmpty } = validator; 

const currentEventListSlug = "magdala-apostolate-current-semester"; 
const upcomingEventListSlug = "magdala-apostolate-upcoming-semester"; 
const selfPacedEventListSlug = "magdala-apostolate-self-paced"; 


function MagdalaCourses(props) {
  useAnalytics('Magdala Courses');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(false);
    
  // Magdala Content Settings
  const { systemContent, systemContentAPI } = props; 
  const [magdalaContentLoading, setMagdalaContentLoading] = useState(true);
  const [content, setContent] = useState(null);

  // Magdala Events (Current, Upcoming, SelfPaced Courses)
  const { events, eventsAPI } = props; 
  const [currentEventList, setCurrentEventList] = useState(null);
  const [currentEventListLoading, setCurrentEventListLoading] = useState(true);
  const currentStateId = `${stateConfig.keys.EVENT_LIST}.${currentEventListSlug}`;
  
  const [upcomingEventList, setUpcomingEventList] = useState(null);
  const [upcomingEventListLoading, setUpcomingEventListLoading] = useState(true); 
  const upcomingStateId = `${stateConfig.keys.EVENT_LIST}.${upcomingEventListSlug}`;

  const [selfPacedEventList, setSelfPacedEventList] = useState(null); 
  const [selfPacedEventListLoading, setSelfPacedEventListLoading] = useState(true); 
  const selfPacedStateId = `${stateConfig.keys.EVENT_LIST}.${selfPacedEventListSlug}`;
    
  useEffect(() => {
    initialize();
  },[initialize]); 
    
  useEffect(() => {
    const magdalaContent = systemContent.magdala; 
    if(magdalaContentLoading && !Boolean(content) && Boolean(magdalaContent)) {
      if(!Boolean(magdalaContent.error) && Boolean(magdalaContent.settings)) {
        setContent(parseContent(magdalaContent.settings));
      }
      setMagdalaContentLoading(false);
    }
  }, [magdalaContentLoading, content, systemContent.magdala]); 

  useEffect(() => {
    const currentData = events.byId[currentStateId];
    if(currentEventListLoading && isEmpty(currentEventList) && isNotEmpty(currentData) && events.ids) {
      if(hasEventData(currentData)) {
        setCurrentEventList(getEventResults(currentData));
      }
      setCurrentEventListLoading(false);
    }
  }, [currentEventListLoading, currentEventList, currentStateId, events, events.ids]);

  useEffect(() => {
    const upcomingData = events.byId[upcomingStateId]; 
    if(upcomingEventListLoading && isEmpty(upcomingEventList) && isNotEmpty(upcomingData) && events.ids) {
      if(hasEventData(upcomingData)) {
        setUpcomingEventList(getEventResults(upcomingData));
      }
      setUpcomingEventListLoading(false);
    }
  }, [upcomingEventListLoading, upcomingEventList, upcomingStateId, events, events.ids]); 

  useEffect(() => {
    const selfPacedData = events.byId[selfPacedStateId]; 
    if(selfPacedEventListLoading && isEmpty(selfPacedEventList) && isNotEmpty(selfPacedData) && events.ids) {
      if(hasEventData(selfPacedData)) {
        setSelfPacedEventList(getEventResults(selfPacedData));
      }
      setSelfPacedEventListLoading(false);
    }
  }, [selfPacedEventListLoading, selfPacedEventList, selfPacedStateId, events, events.ids]);
  
  useEffect(() => {
    // When everything has loaded check to see if we have met the minimum threshold for content
    // Must have content settings and at least one of: current, upcoming, self-paced listing
    if(loading && !magdalaContentLoading && !currentEventListLoading && !upcomingEventListLoading && !selfPacedEventListLoading) {
      if(!Boolean(content) || (!Boolean(currentEventList) && !Boolean(upcomingEventList) && !Boolean(selfPacedEventList))) {
        setError(true);
      }
      setLoading(false);
    }
  }, [loading, content, magdalaContentLoading, currentEventList, currentEventListLoading, upcomingEventList, upcomingEventListLoading, selfPacedEventList, selfPacedEventListLoading]);

  initialize = () => {
    resetViewToTopOfPage();

    const magdalaContent = systemContent.magdala; 
    if(Boolean(magdalaContent) && Boolean(magdalaContent.settings)) {
      setContent(parseContent(magdalaContent.settings)); 
      setMagdalaContentLoading(false);
    } else {
      systemContentAPI.getMagdalaContent(); 
    }

    const currentData = events.byId[currentStateId];
    if(hasEventData(currentData)) {
      setCurrentEventList(getEventResults(currentData));
      setCurrentEventListLoading(false);
    } else {
      eventsAPI.getEventListBySlug(currentEventListSlug, true);
    }

    const upcomingData = events.byId[upcomingStateId];
    if(hasEventData(upcomingData)) {
      setUpcomingEventList(getEventResults(upcomingData));
      setUpcomingEventListLoading(false);
    } else {
      eventsAPI.getEventListBySlug(upcomingEventListSlug, true);
    }

    const selfPacedData = events.byId[selfPacedStateId]; 
    if(hasEventData(selfPacedData)) {
      setSelfPacedEventList(getEventResults(selfPacedData)); 
      setSelfPacedEventListLoading(false); 
    } else {
      eventsAPI.getEventListBySlug(selfPacedEventListSlug, true); 
    }
  };

  const parseContent = (settings) => {
    return settings.reduce((obj, setting) => {
      const { key, type, value } = setting;
      obj[key] = { type, value };
      return obj;
    }, {});
  };

  const hasEventData = (data) => {
    return Boolean(data) && !Boolean(data.error) && Boolean(data.results);
  };

  const getEventResults = (data) => {
    return Boolean(data.results.length)? data : null;
  }

  const clearPopMessage = () => {
    setError(false); 
  };


  let cardsPerRender = null; 
  if(Boolean(content) && content['coursesNumCardsRendered.default']){
    cardsPerRender = parseInt(content['coursesNumCardsRendered.default'].value); 
  }

  return (
    <div>
      { loading && !error && <div className='loadMessage'><LoadingMessage message="Loading..." /></div>}
      { !loading && error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage} type={stateConfig.messageTypes.ERROR}>
          <p>Failed to load page content. Please try refreshing the page. If this issue persists, please contact ICC staff so we can resolve the issue as soon as possible. Thank you!</p>
        </PopMessage>
      }
      { !loading && !error &&
      <Fragment>
        <SEOMeta
          title="Magdala Apostolate Courses"
          description="In-depth live courses on the Catechism, Sacred Scripture, and more."
        />
        <Banner />
        <MagdalaToolbar />
        <MobileNav />
        <div className="Magdala-Courses">
          { Boolean(currentEventList) && 
            <div>
              <h3>{ Boolean(content['coursesCurrent.header']) && content['coursesCurrent.header'].value }</h3>
              <div className="LinkedList EventList-container">
                <EventList 
                  events={currentEventList.results} 
                  displayType={config.eventListTypes.CURRENT_AND_UPCOMING}
                  heading={currentEventList.title} 
                  cardsPerRender={cardsPerRender}
                  moreAtLimit={true}
                  moreText="Full Curriculum Listing"
                  moreLink={urls.magdalaCurriculumPage}
                  showIntroduction={false}
                /> 
              </div>
            </div> 
          }
          { Boolean(upcomingEventList) && 
            <div>
              <h3>{ Boolean(content['coursesUpcoming.header']) && content['coursesUpcoming.header'].value }</h3>
              <div className="LinkedList EventList-container">
                <EventList 
                  events={upcomingEventList.results} 
                  displayType={config.eventListTypes.CURRENT_AND_UPCOMING}
                  heading={upcomingEventList.title} 
                  cardsPerRender={cardsPerRender}
                  moreAtLimit={true}
                  showIntroduction={false}
                />
              </div>
            </div>
          }
          { Boolean(selfPacedEventList) && 
            <div>
              <h3>{ Boolean(content['coursesSelfPaced.header']) && content['coursesSelfPaced.header'].value }</h3>
              <div className="LinkedList EventList-container">
                <EventList 
                  events={selfPacedEventList.results} 
                  displayType={config.eventListTypes.CURRENT_AND_UPCOMING}
                  heading={selfPacedEventList.title} 
                  cardsPerRender={cardsPerRender}
                  moreAtLimit={true}
                  showIntroduction={false}
                />
              </div>
            </div>
          }
          { Boolean(content['coursesCallToAction.title']) && 
          <UnenclosedCallToAction
            title={content['coursesCallToAction.title'].value}
            subtext={content['coursesCallToAction.subtext'].value}
            buttonTitle={content['coursesCallToAction.button'].value.title}
            buttonURL={content['coursesCallToAction.button'].value.url}
          /> }
        </div>
      </Fragment>
      }
    </div>
  )
}

function mapStateToProps(state) {
  return { 
    systemContent: state.systemContent,
    events: state.events
  };
}
  
function mapDispatchToProps(dispatch) {
  return { 
    systemContentAPI: bindActionCreators(systemContentAPI, dispatch),
    eventsAPI: bindActionCreators(eventsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MagdalaCourses); 