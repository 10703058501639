import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import querystring from 'qs';
import useAnalytics from '../../../hooks/useAnalytics';
import * as registrationAPI from '../../../actions/registration/member';
import * as postAPI from '../../../actions/content/posts';
import * as appDisplay from '../../../actions/appDisplay';
import Icon, { IconTypes } from '../../common/Icon';
import Heading from '../../common/Heading';
import LinkButton from '../../common/LinkButton';
import ContactErrorPopMessage from '../../common/messages/ContactErrorPopMessage';
import errors from '../../../config/errors';
import urls from '../../../config/urls';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import { resetViewToTopOfPage } from '../../../utils/utils';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import validator from '../../../utils/validator';
const { isNotEmpty, isEmpty } = validator;


const MEMBER_WELCOME_SNIPPET_SLUG = 'member-registration-welcome';

function MemberActivation({ membershipActivation, posts, postAPI, registrationAPI, appDisplay }) {
  const [activating, setActivating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeExpired, setCodeExpired] = useState(false);
  const [activationSucceeded, setActivationSucceeded] = useState(false);
  const [passwordDisplay, setPasswordDisplay] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [welcomeSnippet, setWelcomeSnippet] = useState(null);
  const location = useLocation();
  useAnalytics('Member Activation');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const { responseCode, passwordToken } = membershipActivation;
    if(activating && membershipActivation.responseStatus === stateConfig.responseStatus.COMPLETE) {
      setActivationSucceeded(true);
      setPasswordDisplay(passwordToken? true : false);
      appDisplay.displayPageMessage({
        message: 'Congratulations! Your membership is all set up!',
        type: stateConfig.messageTypes.SUCCESS,
        time: 0
      });
      postAPI.getSnippetBySlug(MEMBER_WELCOME_SNIPPET_SLUG);
      setLoading(true);
    }

    if(activating && membershipActivation.responseStatus === stateConfig.responseStatus.ERROR) {
      if(responseCode === errors.MemberActivationExpired.code) {
        setCodeExpired(true);
      } else {
        setErrorCode(responseCode);
      }
    }
  }, [activating, membershipActivation.responseStatus, membershipActivation, appDisplay, postAPI]);

  useEffect(() => {
    const snippet = posts.byId[`${stateConfig.keys.SNIPPET}.${MEMBER_WELCOME_SNIPPET_SLUG}`];
    if(loading && isEmpty(welcomeSnippet) && isNotEmpty(snippet) && posts.ids) {
      if(!snippet.error) {
        setWelcomeSnippet(snippet.content);
      }
      setLoading(false);
    }
  }, [loading, welcomeSnippet, posts, posts.ids]);

  initialize = () => {
    resetViewToTopOfPage();
    const params = querystring.parse(location.search.substring(1));
    const activationCode = params.code || '';
    if(isEmpty(activationCode)) {
      setCodeExpired(true);
    } else {
      registrationAPI.activateMember(activationCode);
      setActivating(true);
    }
  };

  const hidePasswordDisplay = () => {
    setPasswordDisplay(false);
  };

  const clearPopMessage = () => {
    setErrorCode(null);
  };

  return (
    <div id="MemberActivation">
      { activationSucceeded &&
        <div id="MemberActivation-success">
          { passwordDisplay &&
            <Paper className="MemberActivation-passwordSetup" elevation={4}>
              <Heading variant="h2">It looks like you haven't set up a password yet.</Heading>
              <p className="MemberActivation-passwordMessage">
                You can set up a password now, set one up later in your member profile, or skip&nbsp; 
                passwords altogether and use only your email to log in.
              </p>
              <div className="MemberActivation-passwordOptions">
                <Button className="MemberActivation-passwordBtn" variant="contained" size="small" 
                  href={`${urls.pwdReset}?mode=setup&token=${membershipActivation.passwordToken}`}
                  color="primary" target="_blank">
                  Setup password
                </Button>
                <Button size="small" color="secondary" onClick={hidePasswordDisplay}>
                  Not right now
                </Button>
              </div>
            </Paper>
          }

          { welcomeSnippet && 
            <div className="MemberActivation-welcome" dangerouslySetInnerHTML={{__html: welcomeSnippet}} /> 
          }
          
          <div className="MemberActivation-messages">
            <div className="MemberActivation-help">
              <div className="MemberActivation-helpMessage">
                <p>
                  If you have any questions, please use our contact form or reach us by phone (540) 635-7155. We’re here to help.
                </p>
              </div>
              <div className="MemberActivation-helpAction">
                <LinkButton label="Contact Us" to={urls.contactUs} color="secondary" 
                  external={true} />
              </div>
            </div>
            <Paper className="MemberActivation-conclusion" square={true}>
              <p className="MemberActivation-finalStatement">We’re excited to meet you!</p>
              <div className="MemberActivation-team">
                <Icon type={IconTypes.Group} avatar={true} spacing="padded" inline={true} />
                <p>Your ICC Team</p>
              </div>
            </Paper>
          </div>
        </div>
      }

      { codeExpired &&
        <div id="MemberActivation-error">
          <div className="error-heading">
            <Icon type={IconTypes.Surprise} size="4x" />
            <p><strong>Whoops!</strong> This activation link is either invalid or expired.</p>
          </div>
          <p className="error-content">
            You may be getting this error if you have already activated your account, or if your&nbsp;
            activation link has expired.  If you have not yet activated your account we can re-send&nbsp;
            your activation email with a new link.
          </p>
          <LinkButton label="Re-Send Activation Link" icon={IconTypes.Email} to="/" color="secondary" />
        </div>
      }
      
      { errorCode &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>We encountered an error while attempting to activate your account.</p>
        </ContactErrorPopMessage>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    membershipActivation: state.membershipActivation, 
    posts: state.posts 
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    postAPI: bindActionCreators(postAPI, dispatch),
    registrationAPI: bindActionCreators(registrationAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberActivation);