import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Icon, { IconTypes } from '../../../common/Icon';
import validator from '../../../../utils/validator';


function ApplicationWarning(props) {
  const { event } = props;
  const { course: { auditApplication, certificateApplication } } = event;
  const certAndAudit = !validator.isEmpty(`${auditApplication}`.trim()) && !validator.isEmpty(`${certificateApplication}`.trim());

  return (
    <div className="ApplicationWarning">
      <Icon type={IconTypes.Warning} className="warning-icon" />
      { event.magdala? (
          <Fragment>
            <p className="warning-text">
              <strong>
                Past this point you will start the application process. You are only allowed to enter one application 
                per class with your membership. 
              </strong>
            </p>
            <p className="warning-text">
              <strong>
                Please note that your application must be completed in one sitting and should take approximately 
                5-10 minutes to complete. 
              </strong>
            </p>
          </Fragment>
        ) : (
          <p className="warning-text">
            <strong>
              Past this point, you will start the application process. Once you click "Continue" below, you will not 
              be able to go back and your application must be completed in one sitting. The application should take 
              approximately 5-10 minutes to complete.
            </strong>
          </p>
        )
      }
      <p className="warning-text">
        <strong>
          Once you apply, you will not be able to edit or re-submit your application. If changes are needed, you may 
          contact our office.
        </strong>
      </p>
      { props.track === 'audit' && certAndAudit &&
        <div className="instruction-text">
         <h3>{event.title} Audit Application</h3>
         <p>
           You are applying to the {event.title} Audit Track. As an audit student, you will not have access to the 
           weekly quizzes. If you would like a more in-depth learning experience, please consider applying to the 
           Certificate Track.
          </p>
        </div>
      }
      { props.track === 'certificate' && 
        <div className="instruction-text">
          <h3>{event.title} Certificate Application</h3>
          <p>
            You are applying to the {event.title} Certificate Track. You will receive an in-depth learning experience 
            with required reading and weekly quizzes. You must apply as an individual, as certificates are personalized. 
            If you are part of a group and individual members would like to track their progress and take the weekly 
            quizzes, they should also apply individually to the Certificate Track.
          </p>
        </div>
      }
      <div className="actions">
        <div>
          <Button variant="contained" color="primary" onClick={props.onContinue}>
            {`Continue with ${props.track === 'audit'? 'Audit' : 'Certificate'}`}
          </Button>
        </div>
        { certAndAudit &&
          <div>
            <Button variant="text" color="secondary" onClick={props.changeTrack}>
              {`Switch to ${props.track === 'audit'? 'Certificate' : 'Audit'}`}
            </Button>
          </div>
        }
      </div>
    </div>
  );
}

ApplicationWarning.propTypes = {
  event: PropTypes.object,
  track: PropTypes.string,
  changeTrack: PropTypes.func,
  onContinue: PropTypes.func
};

export default ApplicationWarning;
