import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Icon, { IconTypes } from '../../common/Icon';
import * as searchAPI from '../../../actions/search';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import urls from '../../../config/urls';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function ExploreList({ taxonomy, onClose, searchAPI, user }) {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState(null);
  const navigate = useNavigate();
  // defining fns used in useEffect
  let initialize = config.emptyFn;
  let prepareCategories = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const { responseStatus, results } = taxonomy;
    if(loading && isEmpty(categories) && isNotEmpty(results) && responseStatus === stateConfig.responseStatus.COMPLETE) {
      setCategories(prepareCategories(results.categories));
      setLoading(false);
    }
  }, [loading, categories, taxonomy, prepareCategories]);

  initialize = () => {
    const { results } = taxonomy;
    if(results) {
      setCategories(prepareCategories(results.categories));
      setLoading(false);
    } else {
      searchAPI.getTaxonomy();
    }
  };

  prepareCategories = (categories) => {
    return categories.map(category => {
      return { title: category.name, slug: category.slug };
    });
  };

  const onLiveEventsSelection = () => {
    navigate(urls.events);
  };

  const onCoursesSelection = () => {
    navigate(urls.courses);
  };

  return (
    <div 
      className="ExploreList"
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}>
      
      <div className="ExploreList-primary">
        <Button variant="outlined" color="primary" onClick={onLiveEventsSelection}>
          Live Events
        </Button>
        <Button variant="outlined" color="primary" onClick={onCoursesSelection}>
          Courses
        </Button>
      </div>

      <Divider />

      <div className="ExploreList-secondary">
        <ul>
          <li className="menu-item my-list">
            <Icon type={IconTypes.FavoriteMenu} inline={true} />
            <Link to={urls.myFavorites}>Favorites</Link>
          </li>
          <li className="menu-item recently-watched">
            <Icon type={IconTypes.WatchedContent} inline={true} />
            <Link to={urls.myWatched}>Recently Watched</Link>
          </li>
          <li className="menu-item browse">
            <Icon type={IconTypes.ContentList} inline={true} />
            <Link to={urls.eventsBrowse}>Browse</Link>
          </li>
          <li className="menu-item instructors">
            <Icon type={IconTypes.User} inline={true} />
            <Link to={urls.instructors}>Instructors</Link>
          </li>
          <li className="menu-item reflections">
            <Icon type={IconTypes.BookOpen} inline={true} />
            <Link to={urls.reflectionsLatin}>Gospel Reflections</Link>
          </li>
          <li className="menu-item search">
            <Icon type={IconTypes.Search} inline={true} />
            <Link to={urls.search}>Search</Link>
          </li>
          <li className="menu-item cross">
            <Icon type={IconTypes.Cross} inline={true} />
            <Link to={user.type === 'magdala-member'? urls.magdalaCoursesPage : urls.magdalaLandingPage}>Magdala Apostolate</Link>
          </li>
        </ul>
      </div>

      <Divider />
      
      { isNotEmpty(categories) &&
        <div className="ExploreList-categories">
          <h3>Categories</h3>
          <ul>
            {
              categories.map((item, index) => {
                return (
                  <li className="menu-item" key={`categories-${index}`}>
                    <Link to={`/events/categories/${item.slug}`}>{item.title}</Link>
                  </li>
                )
              })
            }
            <li className="menu-item more">
              <Link to={urls.filter}>+ More Filters</Link>
            </li>
          </ul>
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    taxonomy: state.taxonomy,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    searchAPI: bindActionCreators(searchAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploreList);