import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as reflectionsAPI from '../../actions/content/reflections';
import config from '../../config/config';
import stateConfig from '../../config/state';
import validator from '../../utils/validator';
const { isEmpty, isNotEmpty} = validator;


function useReflections(reflectionType, slug) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stateId, setStateId] = useState(null);
  const [reflectionsList, setReflectionsList] = useState(null);
  const [error, setError] = useState(null);
  const reflections = useSelector(state => state.reflections);
  const dispatch = useDispatch();
  let initialize = config.emptyFn;

  useEffect(() => {
    if(!init) {
      setInit(true);
      initialize();
    }
  }, [init, initialize]);

  useEffect(() => {
    setStateId(null);
    setReflectionsList(null);
    setLoading(true);
    initialize();
  }, [slug, initialize]);

  useEffect(() => {
    const data = stateId? reflections.byId[stateId] : null;
    if(loading && isEmpty(reflectionsList) && reflections.ids && isNotEmpty(data)) {
      if(data.error) {
        setError(data.error);
      } else {
        setReflectionsList(data.results? data.results : [data]);
      }
      setLoading(false);
    }
  }, [loading, stateId, reflectionsList, reflections.ids, reflections.byId]);
  
  initialize = () => {
    let stateId = null, isList = true;
    if(slug === 'sunday-gospel') {
      stateId = `${stateConfig.keys.REFLECTION_LIST}.reflections-latin`;
    } else if(slug === 'byzantine-gospel') {
      stateId = `${stateConfig.keys.REFLECTION_LIST}.reflections-byzantine`;
    } else {
      isList = false;
      stateId = `${stateConfig.keys.REFLECTION}.${slug}`;
    }
    setStateId(stateId);

    const data = reflections.byId[stateId];
    if(!data || data.error) {
      if(isList) {
        dispatch(reflectionsAPI.getReflections(reflectionType));
      } else {
        dispatch(reflectionsAPI.getReflectionBySlug(slug));
      }
    } else {
      setReflectionsList(data.results? data.results : [data]);
    }
  };

  return (reflectionsList === null && error === null)? null : { reflections: reflectionsList, error };
}

export default useReflections;