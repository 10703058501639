import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as appDisplay from '../../../actions/appDisplay';
import * as profileAPI from '../../../actions/profile';
import ProfileEditData from './personal/ProfileEditData';
import { nameFromProfile } from './personal/ProfileName';
import { addressFromProfile } from './personal/ProfileAddress';
import { phoneFromProfile } from './personal/ProfilePhone';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import errors from '../../../config/errors';
import urls from '../../../config/urls';
import { resetViewToTopOfPage } from '../../../utils/utils';
import validator from '../../../utils/validator';
const { isNotEmpty } = validator;

const ProfileDataTypes = config.profileDataTypes;

function MemberInfo({ profile, profileAPI, appDisplay }) {
  const [currentlyEditedData, setCurrentlyEditedData] = useState(null);
  const navigate = useNavigate();
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const { responseCode, dataUpdated } = profile;

    if(profile.responseStatus === stateConfig.responseStatus.ERROR) {
      if(responseCode === errors.ReAuthRequired.code) {
        navigate(`${urls.login}?ra=profile`);
      } else {
        appDisplay.displayPageMessage({
          message: 'Failed to load the member profile.',
          type: stateConfig.messageTypes.ERROR,
          time: 0
        });
      }
    }

    if(profile.responseStatus === stateConfig.responseStatus.COMPLETE && dataUpdated) {
      resetViewToTopOfPage();
      resetViewToTopOfPage();
      setCurrentlyEditedData(null);
      appDisplay.displayPageMessage({
        message: 'Your member profile was successfully updated.',
        type: stateConfig.messageTypes.SUCCESS
      });
    }
  }, [profile, profile.responseStatus, appDisplay, navigate]);

  initialize = () => {
    resetViewToTopOfPage();
    profileAPI.getProfile();
  };

  const onEditEvent = (dataType, editing) => {
    setCurrentlyEditedData(editing? dataType : null);
  };

  const onSave = (dataType, data) => {
    let profileData = { id: data.id };
    delete data.id;
    profileData.data = data;

    profileAPI.updateProfile(dataType, profileData);
  };

  const onDelete = (dataType, data) => {
    profileAPI.deleteProfileItem(dataType, data.id);
  };

  const dataByType = (dataType) => {
    const { data } = profile;
    switch(dataType) {
      case ProfileDataTypes.NAME:
        return nameFromProfile(data);
      case ProfileDataTypes.ADDRESS:
        return addressFromProfile(data);
      case ProfileDataTypes.PHONE:
        return phoneFromProfile(data);
      default:
        return null;
    }
  };

  return (
    <div id="MemberProfile">
      { isNotEmpty(profile.data) &&
        <div>
          <ProfileEditData label='Name' dataType={ProfileDataTypes.NAME} 
            editing={(currentlyEditedData === ProfileDataTypes.NAME)}
            data={dataByType(ProfileDataTypes.NAME)} 
            onEditEvent={onEditEvent} onSave={onSave} />
            
          <ProfileEditData label='Address' dataType={ProfileDataTypes.ADDRESS} 
            editing={(currentlyEditedData === ProfileDataTypes.ADDRESS)}
            data={dataByType(ProfileDataTypes.ADDRESS)} onEditEvent={onEditEvent}
            onSave={onSave} onDelete={onDelete}  />

          <ProfileEditData label='Phone' dataType={ProfileDataTypes.PHONE} 
            editing={(currentlyEditedData === ProfileDataTypes.PHONE)}
            data={dataByType(ProfileDataTypes.PHONE)} onEditEvent={onEditEvent}
            onSave={onSave} onDelete={onDelete} />
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    appDisplay: bindActionCreators(appDisplay, dispatch),
    profileAPI: bindActionCreators(profileAPI, dispatch) 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberInfo);
