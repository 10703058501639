import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import validator from '../../../../utils/validator';


function DonateIdentification({ fields, errors, onFieldChange }) {
  return (
    <div className="DonateIdentification">
      <div className="email">
        <TextField 
          className="FormField-control first full"
          variant="outlined" 
          name="email" label="Email" 
          value={fields.email}
          placeholder="Email" 
          onChange={onFieldChange}
          helperText={validator.message(errors['email'], 'email')}
          error={validator.isDefined(errors['email'])} 
        />
      </div>
      <div className="name">
        <TextField 
          className="FormField-control"
          variant="outlined" 
          name="firstName" 
          label="First Name" 
          value={fields.firstName}
          placeholder="First Name" 
          onChange={onFieldChange}
          error={validator.isDefined(errors['firstName'])} 
          helperText={validator.message(errors['firstName'], 'first name')}
        />
        <TextField 
          className="FormField-control"
          variant="outlined" 
          name="lastName" 
          label="Last Name" 
          value={fields.lastName}
          placeholder="Last Name" 
          onChange={onFieldChange}
          error={validator.isDefined(errors['lastName'])}
          helperText={validator.message(errors['lastName'], 'last name')} 
        />
      </div>
    </div>
  );
}

DonateIdentification.propTypes = {
  fields: PropTypes.object,
  errors: PropTypes.object,
  onFieldChange: PropTypes.func
};

export default DonateIdentification;