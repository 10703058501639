import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


function StandardHeading({ title, description, data }) {

  const navigate = () => {
    window.location = data.actionLink;
  };

  return (
    <div className="StandardHeading">
      <div className="StandardHeading-content">
        <h2 className={`${!isEmpty(description)? 'with-desc' : 'without-desc'}`} dangerouslySetInnerHTML={{__html: title}} />
        { !isEmpty(description) &&
          <p dangerouslySetInnerHTML={{__html: description}} />
        }
        <div className="action">
          <Button className="StandardHeading-button" variant="outlined" onClick={navigate}>
            {data.actionTitle}
          </Button>
        </div>
      </div>
    </div>
  );
}

StandardHeading.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object
};

export default StandardHeading;