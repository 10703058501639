import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import validator from '../../../../../utils/validator';
const { isEmpty } = validator;


function SingleChoiceQuestion({ question, index, scored, disabled, onChange }) {
  const [choice, setChoice] = useState('');

  useEffect(() => {
    let data = scored || { index, wpId: question.wpId, title: question.title, value: null, result: 'incorrect', compare: null };
    if(!isEmpty(choice)) {
      data.value = parseInt(choice.replace('answer-', ''));
      data.compare = data.value;
      data.result = question.answers[data.value].correct? 'correct' : 'incorrect';
    }
    onChange(data);
  }, [index, scored, choice, question, onChange]);

  const defaultValue = () => {
    if(choice === '' && !isEmpty(scored)) {
      return `${scored.value}`;
    }
    return choice;
  };

  return (
    <div className="SingleChoiceQuestion">
      <p className="question-text" dangerouslySetInnerHTML={{__html: `${index+1}. ${question.text}`}} />
      <RadioGroup 
        className="answers" 
        name={`question-${question.wpId}`} 
        value={defaultValue()} 
        onChange={({ target: { value } }) => setChoice(`${value}`)}
      >
        {
          question.answers.map((answer, index) => {
            return (
              <FormControlLabel 
                key={`answer-${index}`} 
                value={`${index}`} 
                disabled={disabled}
                control={<Radio />} 
                label={(<span dangerouslySetInnerHTML={{__html: answer.text}} />)} />
            );
          })
        }
      </RadioGroup>
      { disabled && // means we are in display only results view
        <div className={`scored-result ${scored.result}`}>
          {scored.result === 'correct'? 'Correct!' : 'Incorrect.'}
        </div>
      }
    </div>
  );
}

SingleChoiceQuestion.defaultProps = {
  scored: null,
  disabled: false
};

SingleChoiceQuestion.propTypes = {
  question: PropTypes.object,
  index: PropTypes.number,
  onChange: PropTypes.func
};

export default SingleChoiceQuestion;
