import React from 'react'; 
import TitleWithSubtext from '../../common/TitleWithSubtext';
import Map from './Map'; 
import Pitch from './Pitch'; 
import Quote from './Quote'; 
import PropTypes from 'prop-types';


function CommunitySection({content}) {
	const { value: titleWithSubtext } = content['landingCommunity.intro']; 

	return (
		<div className="Magdala-Community">
			<TitleWithSubtext titleWithSubtext={titleWithSubtext} />
			<Map />
			<Pitch content={content} />
			<Quote />
		</div>
	)
}

CommunitySection.propTypes = {
	content: PropTypes.object
}

export default CommunitySection; 