import React from 'react';
import PropTypes from 'prop-types';
import { formatDateForDisplay } from '../../../utils/format';
import validator from '../../../utils/validator';


function RecurringDonations({ defaultCustomerId, donations, onUpdate }) {
  return (
    <div className="RecurringDonations">
      <h2>Recurring Donations</h2>
      { donations.length > 0 &&
        <div className="recurring-list">
          {
            donations.map((donation, index) => {
              const card = donation.card;
              return (
                <div key={`recurring-donation-${index}`} className="recurring-donation">
                  <div className="amount">
                    ${donation.amount}.00/{donation.frequency}
                  </div>
                  { card &&
                    <div className="payment-method">
                      <span>Payment Method:</span> {`${card.last4} (exp: ${card.expires.month}/${card.expires.year})`}
                    </div>
                  }
                  <div className="dates">
                    <div className="created">
                      <span>Created On:</span>
                      {formatDateForDisplay(new Date(donation.createdOn))}
                    </div>
                    { validator.isNotEmpty(donation.lastOccurrence) &&
                      <div className="last-charge">
                        <span>Last Charged:</span>
                        {formatDateForDisplay(new Date(donation.lastOccurrence))}
                      </div>
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      }
      { donations.length === 0 &&
        <div className="recurring-list">
          You do not currently have any recurring donations.
        </div>
      }
    </div>
  );
}

RecurringDonations.propTypes = {
  defaultCustomerId: PropTypes.string,
  donations: PropTypes.array,
  onUpdate: PropTypes.func
};

export default RecurringDonations;