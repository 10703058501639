import React from 'react';
import { Link } from 'react-router-dom'; 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'; 
import urls from '../../../config/urls';

export default function AnnualReportExpired() {
  return (
    <div className="AnnualReportExpired-content">
      <Card square={false}>
        <CardContent>
          <h3>Page not available</h3>
          <p>
            We're sorry, but the ICC's 2022 Annual Report is no longer available. If you have any questions or would like to know more about the ICC's mission, feel free to <Link to={urls.contactUs}>contact us</Link>.
          </p>
        </CardContent>
      </Card>
    </div>
  )
}