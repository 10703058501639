
const defaultCacheTimeout = 1000 * 60 * 30;    // 30 min

const state = {
  authStatus: {
    DEFAULT: 'DEFAULT',
    ERROR: 'ERROR',
    WAITING: 'WAITING',
    NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
    AUTH_COMPLETE: 'AUTH_COMPLETE',
    DEVICE_REMEMBERED: 'DEVICE_REMEMBERED',
    AUTH_CODE_SENT: 'AUTH_CODE_SENT',
    RESET_LINK_SENT: 'RESET_LINK_SENT',
    RESET_COMPLETE: 'RESET_COMPLETE',
    PROVIDER_START: 'PROVIDER_START'
  },

  defaultCacheTimeout,
  
  keys: {
    ANNOTATIONS: 'annotations',
    ARTICLE: 'article',
    COURSE: 'course',
    COURSE_ASSESSMENT: 'courseAssessment',
    COURSE_LIST: 'courses',
    COURSE_TOPIC: 'courseTopic',
    EVENT: 'event',
    EVENT_LIST: 'eventList',
    INSTRUCTOR: 'instructor',
    INSTRUCTOR_LIST: 'instructorList',
    LIVE_COURSE_LIST: 'live-courses',
    PAGE: 'page',
    PROGRESS: 'progress',
    PROGRESS_ASSESSMENT: 'assessment',
    PROGRESS_HISTORY: 'history',
    PROGRESS_TASKS: 'tasks',
    PROGRESS_TRANSCRIPTS: 'transcripts',
    REFLECTION: 'reflection',
    REFLECTION_LIST: 'reflectionList',
    SNIPPET: 'snippet',
    WATCHED: 'watched'
  },

  messageTypes: {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    PROMO: 'promo',
    ERROR: 'error'
  },

  responseStatus: {
    DEFAULT: 'DEFAULT',
    COMPLETE: 'COMPLETE',
    ERROR: 'ERROR'
  }
};

export default state;