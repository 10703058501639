import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Form from '../../common/form/Form';
import NumberFormatCustom from '../../common/form/NumberFormatCustom';
import validator from '../../../utils/validator';


function UpdateDonationAmount({ donation, onSubmit, onCancel }) {
  const [amount, setAmount] = useState(`${donation.amount}`);
  const [unformattedAmount, setUnformattedAmount] = useState(parseInt(`${donation.amount}`));
  const [errors, setErrors] = useState({});

  const onFieldChange = (event) => {
    let { value } = event.target;
    setAmount(value);
    setUnformattedAmount(parseInt(value));
  };

  const onAmountBlur = (event) => {
    if(amount.includes('.')) {
      let formattedAmount = `${amount.split('.')[0]}.00`;
      setAmount(formattedAmount);
      setUnformattedAmount(parseInt(formattedAmount));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!validate()) {
      return;
    }
    setErrors({});
    onSubmit(unformattedAmount);
  };

  const validate = () => {
    let rules = [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'amount', against: validator.patterns.CURRENCY_NO_CENTS, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'amount' }
    ];

    let validationErrors = validator.validate({ amount: unformattedAmount }, rules);
    if(validationErrors) {
      setErrors(validationErrors);
    } else if(parseInt(unformattedAmount) === 0) {
      validationErrors = { amount: 'The amount cannot be zero.' };
      setErrors(validationErrors);
    }

    return validationErrors? false : true;
  };

  const card = donation.card;
  return (
    <div className="UpdateDonationAmount">
      <div className="content">
        <h2>Update Donation Amount</h2>
        <div className="current">
          <span>Current:</span>
          ${donation.amount}.00/{donation.frequency}
          {card? ` - ${card.last4} (exp: ${card.expires.month}/${card.expires.year})` : ''}
        </div>
        <Form onSubmit={handleSubmit} onClickSecondary={onCancel} autoComplete="off" 
          submitLabel="Update Amount" secondaryButtonLabel="Cancel">
          <TextField
            className="amount-field"
            variant="outlined"
            label="Amount (in dollars)"
            helperText={validator.message(errors['amount'], 'amount')}
            name="amount" 
            value={amount}
            onChange={onFieldChange}
            onBlur={onAmountBlur}
            autoFocus
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            InputLabelProps={{
              shrink: true
            }}
            error={validator.isDefined(errors['amount'])}
          />
        </Form>
      </div>
    </div>
  );
}

UpdateDonationAmount.propTypes = {
  donation: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default UpdateDonationAmount;