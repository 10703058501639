import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { getAnalytics, logEvent } from "firebase/analytics";
import { AppContext } from '../App';
import * as behaviorAPI from '../actions/behavior';
import config from '../config/config';
import validator from '../utils/validator';
const { isDefined } = validator;


/*
Note on use:
- The hook must be used for each instance in which option values are different
- This hook is not built to handle events being sent to the same tracker back to back, this is likely to lead to a race condition
- It is the responsibility of the calling component to avoid duplicate event logging

Params
- loggers: (req) an array of event loggers

- rootOptions: (opt) options object (at tracker level)
    - source:          (opt) only applies to Hub tracking, can pass in an identifier for a referrer (e.g., an Ad)

*/
function useEventTracking(loggers, rootOptions = {}) {
  const user = useSelector(state => state.user);
  const { firebaseApp, testModes } = useContext(AppContext);
  const googleAnalytics = getAnalytics(firebaseApp);

  // can switch Google Analytics on/off at the app level (as well as GAE specifically w/test modes)
  const gaEnabled = process.env.REACT_APP_GA_ENABLED === 'true'; 

  const log = (logger, event) => {
    switch(logger) {
      case config.trackingLoggers.googleEvents:
        logGoogleEvent(event);
        break;
      case config.trackingLoggers.hubEvents:
        logHubEvent(event);
        break;
      default:
        // this shouldn't happen, but if it does, fail silently
    }
  };

  const logGoogleEvent = (event) => {
    // if GA is disabled at an app level, or by test mode usage, only log to the console
    if(!gaEnabled) {
      console.log('GA not enabled, console logging only...', event.name, event);
    } else if(testModes.includes(config.testModes.googleAnalyticsEvents)) {
      console.log('GAE test mode is on, console logging only...', event.name, event);
    } else { // logging is enabled, send to GA
      logEvent(googleAnalytics, event.name, event.data);
    }
  };

  const logHubEvent = (event) => {
    if(testModes.includes(config.testModes.hubBehaviorTracking)) {
      console.log('HBT test mode is on, console logging only...', event.type, event);
    } else { // logging is enabled, send to Hub
      behaviorAPI.trackSessionEvent(event, user);
    }
  };
  
  // Supported options: can override settings at the level of an individual event call
  // - source         (opt)
  const trackEvent = (event, options = {}) => {
    for(let i=0; i<loggers.length; i++) {
      const logger = loggers[i];
      let eventConfig = event[logger] || {};
      eventConfig = { 
        ...eventConfig,
        source: isDefined(options.source)? options.source : (rootOptions.source || undefined)  
      };
      log(logger, eventConfig);
    }
  };

  return trackEvent;
}

export default useEventTracking;