import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import validator from '../../../utils/validator';
const { isDefined } = validator;

function AccountConfirmation({ email, onComplete }) {
  const [confirmEmail, setConfirmEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [confirmError, setConfirmError] = useState(false);

  const onFieldChange = ({ target: { value } }) => {
    setEmailError(null);
    setConfirmEmail(value);
  };

  const checkEmailConfirmation = () => {
    if(!validate()) {
      setEmailError('The email you entered is not valid');
      return;
    }

    if(confirmEmail !== email) {
      setConfirmError(true);
      return;
    }

    onComplete();
  };

  const validate = () => {
    const validationErrors = validator.validate({ confirmEmail }, [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'confirmEmail', against: validator.patterns.EMAIL, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'confirmEmail' }
    ]);
    return validationErrors? false : true;
  }

  return (
    <div className="AccountConfirmation">
      <div className="AccountConfirmation-content Form">
        <h1>Account Deletion Request</h1>
        <p className="AccountConfirmation-message">
          Please confirm the account that you wish to delete by entering the email address associated with the account below.
        </p>

        <TextField
          className="FormField-control AccountConfirmation-email full"
          name="email"
          label="Email"
          placeholder='Email'
          onChange={onFieldChange}
          helperText={emailError}
          error={isDefined(emailError)}
          value={confirmEmail}
          variant="standard"
        />

        <div className="AccountConfirmation-submit">
          <Button variant="contained" color="primary" onClick={checkEmailConfirmation}>
            Continue
          </Button>
        </div>

        { confirmError &&
          <div className="AccountConfirmation-error">
            The email you entered does not match the logged in account. If you want to continue you will need to first log out 
            and log back in with the account you wish to delete.
          </div>
        }
      </div>
    </div>
  );
}

AccountConfirmation.propType = {
  email: PropTypes.string,
  onComplete: PropTypes.func
};

export default AccountConfirmation;