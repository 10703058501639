import React, { Fragment } from 'react'; 

function Map() {
	return (
		<Fragment>
			<iframe className="Magdala-Map" src="https://snazzymaps.com/embed/475467" title="Magdala Communities Map"></iframe>

			{/* Default Google Map - Backup */}
				{/* <iframe className="Magdala-Map" src="https://www.google.com/maps/d/embed?mid=1wgW4c9aFBpZlGl9gvIiGxgOYB7OQKBk&ehbc=2E312F" title="Magdala Communities Map"></iframe> */}
		</Fragment>
	)
}

export default Map; 