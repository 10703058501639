import React from 'react';
import { useNavigate } from 'react-router-dom';
import urls from '../../config/urls'; 
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

function UnenclosedCallToAction({ title, subtext, buttonTitle, buttonURL }) {

  const navigate = useNavigate();

  return (
    <div className="Magdala-Courses-CallToAction">
      <div className="container-content">
        <h3>{title}</h3>
        <div className="divider-bar"></div>
        <div className="section-description">{subtext}</div>
        <Button variant="outlined" onClick={() => navigate(urls.baseUrl+'/'+buttonURL)}>{buttonTitle}</Button>
      </div>
    </div>
  )
}

UnenclosedCallToAction.propTypes = {
  content: PropTypes.object
}

export default UnenclosedCallToAction; 