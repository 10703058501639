import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as behavior from '../../../actions/behavior'; 
import Icon, { IconTypes } from '../../common/Icon';
import { convertSecondsIntoTimeDisplay } from '../../../utils/format'; 
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';


function AnnotationView({ annotation, mediaType, onEdit, onClose, behavior, onPrint, setRemove }) {
  const { text, time } = annotation;
  const displayText = text.replace(/\r?\n|\r/g, '<br />');
  const fTime = time === 0? '00:00' : convertSecondsIntoTimeDisplay(time);

  const jumpToPointInCurrentMedia = event => {
    behavior.publishJumpPointForCurrentMedia(time);
  };

  const initiatePrint = event => {
    onPrint(false); 
  }

  return (
    <div className="AnnotationView">
      <div className="pin">
        <div>
          <span className="label">Pinned to:&nbsp;</span>
          <span className="time">{fTime}</span>
        </div>
        { time > 0 &&
          <Button className="link" size="small" onClick={jumpToPointInCurrentMedia}>Jump to this place in the {mediaType}</Button>
        }
      </div>
      <div className="annotation-view-text-container">
        <p className="annotation-text" dangerouslySetInnerHTML={{__html: displayText}} />
      </div>
      <div className="action">
        <Button variant="contained" color="primary" onClick={onClose}>Close</Button>
        <IconButton onClick={onEdit}>
          <Icon type={IconTypes.Pen} size="sm" />
        </IconButton>
        <IconButton onClick={() => setRemove(true)}>
          <Icon type={IconTypes.TrashCan} size="sm" />
        </IconButton>
        <IconButton onClick={initiatePrint}>
          <Icon type={IconTypes.Print} size="sm" />
        </IconButton>
      </div>
    </div>
  );
}

AnnotationView.propTypes = {
  annotation: PropTypes.object,
  mediaType: PropTypes.string,
  onEdit: PropTypes.func,
  onClose: PropTypes.func,
  onPrint: PropTypes.func,
  setRemove: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { 
    behavior: bindActionCreators(behavior, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationView);
