import { DONATION_REQUEST, DONATION_STARTED, DONATION_COMPLETE, DONATION_ERR,
         DONATION_UPDATE_REQUEST, DONATION_UPDATE_COMPLETE, DONATION_UPDATE_ERR,
         DONOR_HISTORY_LOAD_START, DONOR_HISTORY_LOAD_COMPLETE, DONOR_HISTORY_LOAD_ERR,
         DONOR_CARDS_LOAD_START, DONOR_CARDS_LOAD_COMPLETE, DONOR_CARDS_LOAD_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function donationsReducer(state = initialState.donations, action) {
  const { type, data = {}, error = {} } = action;
  let responseCode, status = stateConfig.responseStatus;

  switch(type) {
    case DONATION_REQUEST:
    case DONATION_UPDATE_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, transaction: null });
    case DONATION_STARTED:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, transaction: data.transaction });
    case DONATION_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.COMPLETE });
    case DONATION_UPDATE_COMPLETE:
      return Object.assign({}, state, { updateResponseStatus: status.COMPLETE });
    case DONOR_HISTORY_LOAD_START:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, history: null });
    case DONOR_HISTORY_LOAD_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, history: data });
    case DONOR_CARDS_LOAD_START:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, cards: null });
    case DONOR_CARDS_LOAD_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, cards: data });
    case DONATION_ERR:
    case DONOR_HISTORY_LOAD_ERR:
    case DONOR_CARDS_LOAD_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    case DONATION_UPDATE_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { updateResponseStatus: status.ERROR, updateResponseCode: responseCode });
    default:
      return state;
  }
}

export default donationsReducer;