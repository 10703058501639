import { DONATION_REQUEST, DONATION_STARTED, DONATION_COMPLETE, DONATION_ERR,
         DONATION_UPDATE_REQUEST, DONATION_UPDATE_COMPLETE, DONATION_UPDATE_ERR,
         DONOR_HISTORY_LOAD_START, DONOR_HISTORY_LOAD_COMPLETE, DONOR_HISTORY_LOAD_ERR,
         DONOR_CARDS_LOAD_START, DONOR_CARDS_LOAD_COMPLETE, DONOR_CARDS_LOAD_ERR } from './actionTypes';
import * as donations from '../api/donations';
import errors from '../config/errors';


export function resetDonationTransactionState() {
  return async dispatch => {
    dispatch({ type: DONATION_REQUEST });
  };
}

export function startDonationTransaction(data) {
  return async dispatch => {
    dispatch({ type: DONATION_REQUEST });
    try {
      let result = await donations.startDonationTransaction(data);
      if(result.data.success) {
        dispatch({ type: DONATION_STARTED, data: result.data });
      } else {
        dispatch({ type: DONATION_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: DONATION_ERR, error: errors.RequestFailed });
    }
  };
}

export function storeDonationTransaction(data) {
  return async dispatch => {
    dispatch({ type: DONATION_REQUEST });
    try {
      let result = await donations.storeDonationTransaction(data);
      if(result.data.success) {
        dispatch({ type: DONATION_COMPLETE });
      } else {
        dispatch({ type: DONATION_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: DONATION_ERR, error: errors.RequestFailed });
    }
  };
}

export function performRecurringDonation(data) {
  return async dispatch => {
    dispatch({ type: DONATION_REQUEST });
    try {
      let result = await donations.performRecurringDonation(data);
      if(result.data.success) {
        dispatch({ type: DONATION_COMPLETE });
      } else {
        dispatch({ type: DONATION_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: DONATION_ERR, error: errors.RequestFailed });
    }
  };
}

export function getDonationHistory() {
  return async dispatch => {
    dispatch({ type: DONOR_HISTORY_LOAD_START });
    try {
      let result = await donations.getDonationHistory();
      dispatch({ type: DONOR_HISTORY_LOAD_COMPLETE, data: result.data });
    } catch(error) {
      if(error.response && [401, 403].includes(error.response.status)) {
        dispatch({ type: DONOR_HISTORY_LOAD_ERR, error: errors.ReAuthRequired });
      } else {
        dispatch({ type: DONOR_HISTORY_LOAD_ERR, error: errors.RequestFailed });
      }
    }
  };
}

export function getCards(customerId) {
  return async dispatch => {
    dispatch({ type: DONOR_CARDS_LOAD_START });
    try {
      let result = await donations.getCards(customerId);
      if(result && result.data && result.data.cards) {
        dispatch({ type: DONOR_CARDS_LOAD_COMPLETE, data: result.data.cards });
      } else {
        dispatch({ type: DONOR_CARDS_LOAD_ERR, error: errors.ResponseMalformed });
      }
    } catch(error) {
      dispatch({ type: DONOR_CARDS_LOAD_ERR, error: errors.RequestFailed });
    }
  };
}

export function updateRecurringDonation(recurringDonationId, data) {
  return async dispatch => {
    dispatch({ type: DONATION_UPDATE_REQUEST });
    try {
      let result = await donations.updateRecurringDonation(recurringDonationId, data);
      if(result.data.success) {
        dispatch({ type: DONATION_UPDATE_COMPLETE });
      } else {
        dispatch({ type: DONATION_UPDATE_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: DONATION_UPDATE_ERR, error: errors.RequestFailed });
    }
  };
}

export function updateRecurringDonationCard(customerId, recurringDonationId, card) {
  return async dispatch => {
    dispatch({ type: DONATION_UPDATE_REQUEST });
    try {
      let result = await donations.updateRecurringDonationCard(customerId, recurringDonationId, card);
      if(result.data.success) {
        dispatch({ type: DONATION_UPDATE_COMPLETE });
      } else {
        dispatch({ type: DONATION_UPDATE_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: DONATION_UPDATE_ERR, error: errors.RequestFailed });
    }
  };
}