import urls from '../config/urls';
import { formatDateTimeET } from './format';
import { slugify } from './utils';
import validator from './validator';
const { isEmpty, isDefined } = validator;

export function listCourseParts(course, registeredTrack) {
  let parts = course.lessons.reduce((arr, lesson) => arr.concat(lesson.parts), []);
  parts = parts.concat(course.exams);
  return parts.filter(part => (part.track === 'cert-audit' || part.track === registeredTrack));
}

export function listAccessibleCourseParts(parts, progress, isLinear) {
  let aParts = [];
  if(!isLinear) {
    aParts = parts; // free-form progression, all parts of the course are accessible
  } else if(progress.length === 0) {
    aParts.push(parts[0]); // linear, no progress, only the first part is accessible
  } else {
    for(let i=0; i<parts.length; i++) {
      const part = parts[i];
      const match = progress.find(item => item.partId === part.id);
      const optional = (!part.required && part.type !== 'assessment');
      const required = (part.required || part.type === 'assessment');
      if(optional) {
        aParts.push(part); // all optional parts and assessments are accessible
      } else if(required && (isEmpty(match) || match.status === 'started')) {
        aParts.push(part); // next part up, should be accessible but nothing beyond this
        break;
      } else {
        aParts.push(part); // completed part, all completed parts are accessible
      }
    }
  }
  return aParts;
}

export function isPartAvailable(selectedPartId, course, transcript, progress) {
  const isLinear = course.progression === 'linear';
  const parts = listCourseParts(course, transcript.track);
  const accessible = listAccessibleCourseParts(parts, progress, isLinear);
  const match = accessible.find(a => a.id === selectedPartId);
  return isDefined(match);
}

export function isPartOpen(data) {
  let open = { isOpen: true };
  if(data) {
    const { enabled, opening } = data;
    if(enabled) {
      const openDt = formatDateTimeET(opening, true);
      open = { isOpen: openDt.date.getTime() <= Date.now(), str: openDt.str };
    }
  }
  return open;
}

export function partNavigationUrl(part, courseSlug) {
  let url = part.type === 'topic'? 
    urls.courseTopic.replace(':topicSlug', `topic-${part.id}-${slugify(part.title)}`) : 
    urls.courseAssessment.replace(':assessmentSlug', `assessment-${part.id}-${slugify(part.title)}`);
  return url.replace(':slug', courseSlug.replace('course-', ''));
}