import config from "../config";
const { trackingCategories } = config;

const eventDefinitions = {

  /*
  Application Events
  */
  appLoad: (page) => {
    return { 
      category: trackingCategories.view, 
      type: 'app.load',
      data: { page } 
    }
  },

  /*
  Authentication Events
  */
  loginComplete: (method) => {
    return { 
      category: trackingCategories.identification, 
      type: 'login.completed',
      data: { method } 
    }
  },

  /*
  Content Selection Events
  */
  listen: (contentType, contentId, audioId) => { 
    return { 
      category: trackingCategories.listen, 
      type: 'audio.listen',
      data: { contentType, contentId: contentId, mediaId: audioId } 
    }
  },
  watch: (contentType, contentId, videoId) => { 
    return { 
      category: trackingCategories.watch, 
      type: 'video.watch',
      data: { contentType, contentId: contentId, mediaId: videoId }  
    }
  },

  /*
  Conversions
  */
  googleAdClick: () => {
    return { 
      category: trackingCategories.conversion, 
      type: config.googleAdTrackingType,
      data: null
    }
  },

  /*
  Donation Events
  */

  donationForm: (fields) => {
    const { campaign, email } = fields;
    return { 
      category: trackingCategories.view, 
      type: 'donation.form',
      data: { campaign, email } 
    }
  },
  donationRedirect: (service) => {
    return { 
      category: trackingCategories.view, 
      type: 'donation.redirect',
      data: { service } 
    }
  },
  donationCompleted: (transactionId, fields) => {
    const { email, campaign, amount, frequency, method } = fields;
    return { 
      category: trackingCategories.conversion, 
      type: 'donation.completed',
      data: { transactionId, campaign, email, amount: parseInt(amount), frequency, method }
    }
  }

};

export default eventDefinitions;