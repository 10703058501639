import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingMessage from '../../../common/messages/LoadingMessage';
import urls from '../../../../config/urls';


function QuestionEmbed({ eventData, user }) {
  const [loading, setLoading] = useState(true);
  const { email, firstName, lastName } = user;
  const parameters = `?email=${email}&firstName=${firstName}&lastName=${lastName}&eventTitle=${eventData.title}`;
  const questionFormUrl = `${urls.eventQuestion}${encodeURI(parameters)}`;


  return (
    <div className="EventQuestionEmbed">
      { loading &&
        <div className="loading">
          <LoadingMessage message="Loading question form..." />
        </div>
      }
      <div className="responsive-iframe-container">
        <iframe title="Live Event Question" src={questionFormUrl} onLoad={() => setLoading(false)} />
      </div>
    </div>
  );
}

QuestionEmbed.propTypes = {
  eventData: PropTypes.object
};

function mapStateToProps(state) {
  return { 
    user: state.user 
  };
}

export default connect(mapStateToProps)(QuestionEmbed);