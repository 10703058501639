import { EMAIL_SETTINGS_REQUEST, EMAIL_SETTINGS_RECEIVED, EMAIL_SETTINGS_COMPLETE, 
         EMAIL_SETTINGS_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function emailSettingsReducer(state = initialState.emailSettings, action) {
  const { type, data, error } = action;
  let responseCode, status = stateConfig.responseStatus;
  
  switch(type) {
    case EMAIL_SETTINGS_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, settings: null });
    case EMAIL_SETTINGS_RECEIVED:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, responseCode: null, settings: data.settings });
    case EMAIL_SETTINGS_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, responseCode: null });
    case EMAIL_SETTINGS_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default emailSettingsReducer;