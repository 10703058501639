import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AnnotationsToggle from '../../../common/annotations/AnnotationsToggle'; 
import AudioEmbed from '../../../common/media/audioEmbed/AudioEmbed';
import validator from '../../../../utils/validator';
const { isNotEmpty } = validator;

function ClassroomAudio({ media, startTime, onProgress, toggle, displayAnnotations, user }) {

  let actions = []; 
  if(user.userDataChecked && isNotEmpty(user.email)) {
    actions = [
      <AnnotationsToggle
        toggle={toggle}
        displayAnnotations={displayAnnotations}
      />
    ]; 
  }
  return (
    <div className="ClassroomAudio">
      <AudioEmbed
        audio={media}
        startTime={startTime}
        onProgress={onProgress}
        actions={actions}   
      />
    </div>
  );
}

ClassroomAudio.defaultProps = {
  startTime: 0
};

ClassroomAudio.propTypes = {
  media: PropTypes.object,
  startTime: PropTypes.number,
  onProgress: PropTypes.func,
  toggle: PropTypes.func,
  displayAnnotations: PropTypes.bool
};

function mapStateToProps(state) {
  return { user: state.user };
}

export default connect(mapStateToProps)(ClassroomAudio);