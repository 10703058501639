import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import CourseQuestion from './questions/CourseQuestion';
import { resetViewToTopOfPage } from '../../../../utils/utils';
import validator from '../../../../utils/validator';
const { isEmpty, isDefined, isNotDefined } = validator;


function CourseAssessmentResults({ assessment, results, onRestart, embedded }) {

  useEffect(() => {
    resetViewToTopOfPage();
  }, []);

  const findMatch = (question, index) => {
    let match = results.answers.find(answer => question.wpId === answer.wpId || question.title === answer.title);
    if(isNotDefined(match)) {
      match = results.answers.find(answer => index === answer.index);
    }
    return match || null;
  };

  return (
    <div className="CourseAssessmentResults">
      { !isEmpty(results) &&
        <Fragment>
          { !embedded &&
            <div className="CourseAssessmentResults-score">
              <h4>Result</h4>
              { results.score >= assessment.passingPercentage &&
                <span className="message passed">
                  Congratulations! You passed the assessment with a score of&nbsp;
                  <span className="score">{results.score}% ({results.correct} out of {results.total})</span>.
                </span>
              }
              { results.score < assessment.passingPercentage &&
                <span className="message failed">
                  You scored <span className="score">{results.score}% ({results.correct} out of {results.total})</span>. 
                  You must answer {assessment.passingPercentage}% of the questions correctly to pass this 
                  assessment and move forward with the course. You may re-take the assessment as needed.
                </span>
              }
            </div>
          }
          { !embedded && results.score < 100 &&
            <div className="CourseAssessmentResults-retake">
              <Button variant="contained" color="primary" onClick={onRestart}>
                Re-take Assessment
              </Button>
            </div>
          }
          <div className="CourseAssessmentResults-answers">
            { !embedded && <h4>Review your answers</h4> }
            { embedded && results.migrated && isDefined(results.answers) && results.answers.length > 0 &&
              <p className="migrated-answers">
                This assessment was migrated over from our previous course site. Because of this, there may be 
                slight inconsistencies in how these questions and answers are displayed.
              </p>
            }
            { embedded && results.migrated && (isNotDefined(results.answers) || results.answers.length === 0) &&
              <p className="migrated-no-answers">
                This assessment was migrated over from our previous course site. Records of your specific answers 
                from this assessment are not available.
              </p>
            }
            { isDefined(results.answers) && results.answers.length > 0 &&
              assessment.questions.map((question, index) => {
                return (
                  <CourseQuestion 
                    key={`question-${index}`} 
                    question={question} 
                    index={index} 
                    scored={findMatch(question, index)}
                    displayInfo={results.score >= assessment.passingPercentage}
                    disabled={true}
                    onChange={() => {}}
                  />
                );
              })
            }
          </div>
        </Fragment>
      }
    </div>
  );
}

CourseAssessmentResults.defaultProps = {
  embedded: false
};

CourseAssessmentResults.propTypes = {
  assessment: PropTypes.object,
  results: PropTypes.object,
  onRestart: PropTypes.func
};

export default CourseAssessmentResults;
