import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SelectedFilters from './SelectedFilters';
import Button from '@mui/material/Button';


function FilterToolbar(props) {
  const { className, selectedFilters, onRemove, onSearch, onClear, displayingResults } = props;
  const { selectedTypes, selectedDates, selectedCategories, selectedTags } = selectedFilters;
  const numberOfFilters = selectedTypes.length + selectedDates.length + selectedCategories.length + selectedTags.length;

  return (
    <div className={className}>
      <div className="title">
        <h1>Advanced Filter Search</h1>
      </div>
      <div className={`selected-filters ${ displayingResults? 'results' : 'filtering' }`}>
        <h2>Selected Filters:</h2>
        { numberOfFilters === 0 &&
          <div>None</div>
        }
        <div className="filters">
          <SelectedFilters itemClass="type" selectedFilters={selectedTypes} onRemove={onRemove} />
          <SelectedFilters itemClass="date" selectedFilters={selectedDates} onRemove={onRemove} />
          <SelectedFilters itemClass="category" selectedFilters={selectedCategories} onRemove={onRemove} />
          <SelectedFilters itemClass="tag" selectedFilters={selectedTags} onRemove={onRemove} />
        </div>
        { numberOfFilters > 0 &&
          <Fragment>
            <Button className="search" variant="contained" color="primary" onClick={onSearch}>
              Search
            </Button>
            <Button className="clear" variant={displayingResults? 'contained' : 'text'} color="secondary" 
              onClick={onClear}>
              Clear
            </Button>
          </Fragment>
        }
      </div>
    </div>
  );
}

FilterToolbar.propTypes = {
  selectedFilters: PropTypes.object,
  onRemove: PropTypes.func, 
  onSearch: PropTypes.func, 
  onClear: PropTypes.func,
  displayingResults: PropTypes.bool
};

export default FilterToolbar;