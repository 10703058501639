import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Icon, { IconTypes } from '../../common/Icon';
import config from '../../../config/config';
import urls from '../../../config/urls';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


function TranscriptHeading({ transcripts, user }) {
  const [certificates, setCertificates] = useState(null);
  const [displayCerts, setDisplayCerts] = useState(false);

  useEffect(() => {
    if(isEmpty(certificates)) {
      const certs = transcripts.filter(transcript => (transcript.track === 'certificate' && config.courseComplete.includes(transcript.status)));
      setCertificates(certs);
    }
  }, [transcripts, certificates]);

  return (
    <div className="TranscriptHeading">
      { isEmpty(user.firstName) && isEmpty(user.firstName) &&
        <p className="no-name">
          We do not have a name on record for you. <Link to={urls.profile}>Update your profile</Link>
        </p>
      }
      { (!isEmpty(user.firstName) || !isEmpty(user.firstName)) &&
        <p className="name">{user.firstName} {user.lastName}</p>
      }
      { !isEmpty(certificates) && certificates.length > 0 &&
        <div className="certificates">
          <Button variant="text" onClick={() => setDisplayCerts(!displayCerts)}>
            <Icon type={IconTypes.Certificate} />
            {`${certificates.length} ${certificates.length > 1? 'Certificates' : 'Certificate'} Earned`}
          </Button>
        </div>
      }
      { !isEmpty(certificates) && certificates.length > 0 &&
        <Collapse className="certificates-display" in={displayCerts}>
          <h5>List of certificates:</h5>
          {
            certificates.map((c, index) => (<div key={`cert-${index}`} className="certificate">{c.details.title}</div>))
          }
        </Collapse>
      }
    </div>
  );
}

TranscriptHeading.propTypes = {
  transcripts: PropTypes.array
};

function mapStateToProps(state) {
  return { 
    user: state.user
  };
}

export default connect(mapStateToProps)(TranscriptHeading);
