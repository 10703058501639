import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import * as issuesAPI from '../../../actions/issues';
import Icon, { IconTypes } from '../../common/Icon';
import PopMessage from '../../common/messages/PopMessage';
import StandardSystemPopup from '../../layout/popup/StandardSystemPopup';
import TranscriptAssessments from './TranscriptAssessments';
import config from '../../../config/config';
import urls from '../../../config/urls';
import { generateCertificatePage1, getImageData } from '../../../utils/pdf';
import { formatDateTimeET } from '../../../utils/format';
import validator from '../../../utils/validator';
const { isEmpty, isDefined } = validator;


function TranscriptRecords({ transcripts, statuses, onRemove, user, issuesAPI }) {
  const [records, setRecords] = useState(null);
  const [displayAssessments, setDisplayAssessments] = useState(null);
  const [remove, setRemove] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if(isEmpty(records)) {
      const arr = transcripts.filter(transcript => statuses.includes(transcript.status));
      setRecords(arr);
    }
  }, [transcripts, statuses, records]);

  const downloadCertificate = record => async () => {
    let dtArr = null;
    if(isDefined(record.completed) || isDefined(record.lastUpdated)) {
      const dt = isDefined(record.completed)? formatDateTimeET(record.completed) : formatDateTimeET(record.lastUpdated);
      dtArr = dt.split(' ');
    } else {
      setError({ display: true, message: 'We were unable to generate your certificate. If this condition continues please notify our staff.' });
      await issuesAPI.logIssue('transcript.downloadCertificate', { email: user.email, transcript: record });
      return;
    }

    const { page1, page2 } = record.certificate;
    const data1 = await getImageData(page1);
    const data2 = await getImageData(page2);
    generateCertificatePage1({
      image1: data1,
      image2: data2,
      completionDate: dtArr[0],
      name: `${user.firstName} ${user.lastName}`,
      fileName: `${record.slug}-certificate`
    });
  };

  const removeCoursePrompt = record => () => {
    setRemove(record);
  };

  const removeCourseRecord = () => {
    const data = remove;
    onRemove(data);
    setRemove(null);
  };

  const closePopup = () => {
    setRemove(null);
  };

  const clearPopMessage = () => {
    setError({ display: false });
  };

  const toggleAssessmentDisplay = record => () => {
    if(displayAssessments === record.courseId) {
      setDisplayAssessments(null);
    } else {
      setDisplayAssessments(record.courseId);
    }
  };

  const renderProgress = record => {
    if(statuses.includes('complete')) return (<Fragment></Fragment>);
    return (
      <p className="progress">
        <span className="label">Progress:</span>
        <span className="text">
          { 
            record.progress.complete > 0 && record.progress.steps > 0? 
              `${record.progress.percent}% (${record.progress.complete} of ${record.progress.steps} steps)` :
              'Not started'
          }
        </span>
      </p>
    );
  };

  const renderActions = record => {
    const courseOpen = record.courseStatus === 'open';
    if(record.type === 'live' && !courseOpen) {
      return (<Fragment></Fragment>);
    }

    return (
      <Fragment>
        <div className="actions">
          <span className="label">Actions:</span>
          { record.status !== config.courseRecordOnly && courseOpen &&
            <Button className="course" variant="text" onClick={() => navigate(urls.course.replace(':slug', record.slug))}>
              View Course
            </Button>
          }
          { record.track === 'certificate' && !config.courseNotStarted.includes(record.status) &&
            <Button className="assessment" variant="text" onClick={toggleAssessmentDisplay(record)}>
              {displayAssessments === record.courseId? 'Hide' : 'View'} Assessments
            </Button>
          }
          { statuses.includes('complete') && record.track === 'certificate' &&
            <Button className="certificate" variant="text" onClick={downloadCertificate(record)}>
              Download Certificate
            </Button>
          }
          { onRemove && record.type === 'self-paced' &&
            <Button className="remove" variant="text" onClick={removeCoursePrompt(record)}>
              Remove
            </Button>
          }
          
        </div>
        { !isEmpty(error) && error.display && error.message &&
          <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
            <p>{error.message}</p>
          </PopMessage>
        }
      </Fragment>
    );
  };

  const renderDate = record => {
    const dt = statuses.includes('complete')? record.completed : record.lastUpdated;
    return (
      <div className="date">
        <span className="label">{statuses.includes('complete')? 'Completed:' : 'Last Updated:'}</span>
        <span className="text">{formatDateTimeET(dt)}</span>
      </div>
    );
  };

  const hasRecords = !isEmpty(records) && records.length > 0;

  return (
    <div className={`TranscriptRecords ${hasRecords? 'records' : 'no-records'}`}>
      { hasRecords &&
        <Fragment>
          <h3>{statuses.includes('started')? 'Courses In Progress' : 'Completed Courses'}</h3>
          {
            records.map((record, index) => {
              const courseOpen = record.courseStatus === 'open';
              return (
                <div key={`record-${index}`} className="record">
                  <div className="title">
                    { statuses.includes('complete') && record.track === 'certificate' && 
                      <Icon type={IconTypes.Certificate} /> 
                    }
                    { record.status === config.courseRecordOnly &&
                      <span>{record.details.title} (Archived)</span>
                    }
                    { record.status !== config.courseRecordOnly && courseOpen &&
                      <Link to={urls.course.replace(':slug', record.slug)}>
                        {record.details.title}
                      </Link>
                    }
                    { record.status !== config.courseRecordOnly && !courseOpen &&
                      <span>{record.details.title}</span>
                    }
                  </div>
                  <div className="description">
                    <span className="label">Type:</span>
                    <span className="text">
                      {record.type === 'live'? 'Live' : 'Self-Paced'} {record.track === 'audit'? 'Audit' : 'Certificate'} Course 
                    </span>
                  </div>
                  <div className="date-details">
                    {renderDate(record)}
                  </div>
                  <div className="details">
                    {renderProgress(record)}
                    {renderActions(record)}
                  </div>
                  <Collapse className="TranscriptAssessments" in={displayAssessments === record.courseId}>
                    <TranscriptAssessments transcript={record} />
                  </Collapse>
                </div>
              );
            })
          }
          { !isEmpty(remove) &&
            <StandardSystemPopup 
              className="TranscriptRecords-popup"
              open={!isEmpty(remove)} 
              title="Warning" 
              onClose={closePopup}
              actions={(
                <Fragment>
                  <Button className="keep" variant="text" onClick={closePopup}>
                    Keep this course
                  </Button>
                  <Button className="remove" variant="text" color="secondary" onClick={removeCourseRecord}>
                    Remove this course
                  </Button>
                </Fragment>
              )}
            >
              <p className="what">You are about to delete all course records for:</p>
              <p className="course-title">{remove.details.title}</p>
              <p className="consequences">This action will remove all of your progress data for this course, and this action cannot be reversed.</p>
              <p className="sure">Are you sure you wish to remove this course record?</p>
            </StandardSystemPopup>
          }
        </Fragment>
      }
    </div>
  );
}

TranscriptRecords.propTypes = {
  transcripts: PropTypes.array,
  statuses: PropTypes.array,
  onRemove: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    issuesAPI: bindActionCreators(issuesAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TranscriptRecords);
