import { PROGRESS_LOAD_START, PROGRESS_LOAD_COMPLETE, PROGRESS_LOAD_ERR } from './actionTypes';
import * as progress from '../api/progress';
import stateConfig from '../config/state';
import errors from '../config/errors';

const progressKey = stateConfig.keys.PROGRESS;
const tasksKey = stateConfig.keys.PROGRESS_TASKS;
const assessmentKey = stateConfig.keys.PROGRESS_ASSESSMENT;
const historyKey = stateConfig.keys.PROGRESS_HISTORY;
const transcriptsKey = stateConfig.keys.PROGRESS_TRANSCRIPTS;

export function getCourseProgress(courseId) {
  return async dispatch => {
    dispatch({ type: PROGRESS_LOAD_START, key: progressKey, objectId: courseId });
    try {
      let result = await progress.getCourseProgress(courseId);
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: progressKey, objectId: courseId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      if(err.code === errors.ResourceNotFoundError.code) {
        dispatch({ type: PROGRESS_LOAD_COMPLETE, data: { progress: [] }, key: progressKey, objectId: courseId });
      } else {
        dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: progressKey, objectId: courseId });
      }
    }
  };
}

export function deleteCourseProgress(courseId, status) {
  return async dispatch => {
    try {
      await progress.deleteCourseProgress(courseId, status);
      return true;
    } catch(error) {
      return false;
    }
  };
}

export function updateTopicProgress(courseId, topicId, data) {
  return async dispatch => {
    try {
      let result = await progress.updateTopicProgress(courseId, topicId, data);
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: progressKey, objectId: courseId });
    } catch(error) {
      // fail silently
    }
  };
}

export function getTasks(courseId, listId) {
  return async dispatch => {
    const id = `${courseId}.${listId}`;
    dispatch({ type: PROGRESS_LOAD_START, key: tasksKey, objectId: id });
    try {
      let result = await progress.getTasks(courseId, listId);
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: tasksKey, objectId: id });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: tasksKey, objectId: id });
    }
  };
}

export function updateTasks(courseId, listId, data) {
  return async dispatch => {
    try {
      await progress.updateTasks(courseId, listId, data);
      return { success: true };
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      return err;
    }
  };
}

export function getAssessmentHistory(courseId, assessmentId) {
  return async dispatch => {
    dispatch({ type: PROGRESS_LOAD_START, key: historyKey, objectId: assessmentId });
    try {
      let result = await progress.getAssessmentHistory(courseId, assessmentId);
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: historyKey, objectId: assessmentId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: historyKey, objectId: assessmentId });
    }
  };
}

export function getAllAssessmentHistory(courseId) {
  return async dispatch => {
    dispatch({ type: PROGRESS_LOAD_START, key: historyKey, objectId: courseId });
    try {
      let result = await progress.getAllAssessmentHistory(courseId);
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: historyKey, objectId: courseId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: historyKey, objectId: courseId });
    }
  };
}

export function getAssessmentRecord(courseId, assessmentId, recordId) {
  return async dispatch => {
    let objectId = `${assessmentId}.${recordId}`;
    dispatch({ type: PROGRESS_LOAD_START, key: assessmentKey, objectId });
    try {
      let result = await progress.getAssessmentRecord(courseId, assessmentId, recordId);
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: assessmentKey, objectId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: assessmentKey, objectId });
    }
  };
}

export function updateAssessment(courseId, assessmentId, data) {
  return async dispatch => {
    dispatch({ type: PROGRESS_LOAD_START, key: assessmentKey, objectId: assessmentId });
    dispatch({ type: PROGRESS_LOAD_START, key: historyKey, objectId: courseId }); // clear assessment history
    try {
      let result = await progress.updateAssessment(courseId, assessmentId, data);
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: assessmentKey, objectId: assessmentId });
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: progressKey, objectId: courseId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: assessmentKey, objectId: assessmentId });
    }
  };
}

export function getCourseTranscripts() {
  return async dispatch => {
    const objectId = 'all';
    dispatch({ type: PROGRESS_LOAD_START, key: transcriptsKey, objectId });
    try {
      let result = await progress.getCourseTranscripts();
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: transcriptsKey, objectId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      if(err.code === errors.ResourceNotFoundError.code) {
        dispatch({ type: PROGRESS_LOAD_COMPLETE, data: { transcripts: [] }, key: transcriptsKey, objectId });
      } else {
        dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: transcriptsKey, objectId });
      }
    }
  };
}

export function getProgressSummary() {
  return async dispatch => {
    const objectId = 'progress';
    dispatch({ type: PROGRESS_LOAD_START, key: transcriptsKey, objectId });
    try {
      let result = await progress.getProgressSummary();
      dispatch({ type: PROGRESS_LOAD_COMPLETE, data: result.data, key: transcriptsKey, objectId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: PROGRESS_LOAD_ERR, error: err, key: transcriptsKey, objectId });
    }
  };
}
