

export function localStore(key, value, isJSON = false) {
  const sValue = isJSON? JSON.stringify(value) : value;
  localStorage.setItem(key, sValue);
}

export function localRetrieve(key, isJSON = false) {
  const value = localStorage.getItem(key);
  return isJSON? JSON.parse(value) : value;
}

export function sessionStore(key, value, isJSON = false) {
  const sValue = isJSON? JSON.stringify(value) : value;
  sessionStorage.setItem(key, sValue);
}

export function sessionRetrieve(key, isJSON = false) {
  const value = sessionStorage.getItem(key);
  return isJSON? JSON.parse(value) : value;
}
