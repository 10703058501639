import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import config from '../../../../config/config';
const { deviceBreakpoints } = config;


function QuarterNavigation({ currentTab, onNavigationChange }) {
  const isSmallView = useMediaQuery({
    query: `(max-width: ${deviceBreakpoints.tablet}px)`
  });

  return (
    <Tabs
      orientation="horizontal"
      variant="scrollable"
      indicatorColor="secondary"
      textColor="secondary"
      value={currentTab}
      onChange={onNavigationChange}
      className="Curriculum-tabs">
      <Tab label={isSmallView? 'QI' : 'Quarter I'} />
      <Tab label={isSmallView? 'QII' : 'Quarter II'} />
      <Tab label={isSmallView? 'QIII' : 'Quarter III'} />
      <Tab label={isSmallView? 'QIV' : 'Quarter IV'} />
    </Tabs>
  );
}

QuarterNavigation.propTypes = {
  currentTab: PropTypes.number,
  onNavigationChange: PropTypes.func
};

export default QuarterNavigation;