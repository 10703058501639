import { REFLECTION_LOAD_START, REFLECTION_LOAD_COMPLETE, REFLECTION_LOAD_ERR } from '../actionTypes';
import * as reflections from '../../api/content/reflections';
import stateConfig from '../../config/state';

const itemKey = stateConfig.keys.REFLECTION;
const listKey = stateConfig.keys.REFLECTION_LIST;


export function getReflections(type) {
  const slug = `reflections-${type}`;
  return async dispatch => {
    dispatch({ type: REFLECTION_LOAD_START, key: listKey, objectId: slug });
    try {
      let result = await reflections.getReflections(type);
      dispatch({ type: REFLECTION_LOAD_COMPLETE, data: result.data, key: listKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: REFLECTION_LOAD_ERR, error: err, key: listKey, objectId: slug });
    }
  };
}

export function getReflectionBySlug(slug) {
  return async dispatch => {
    dispatch({ type: REFLECTION_LOAD_START, key: itemKey, objectId: slug });
    try {
      let result = await reflections.getReflectionBySlug(slug);
      dispatch({ type: REFLECTION_LOAD_COMPLETE, data: result.data, key: itemKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: REFLECTION_LOAD_ERR, error: err, key: itemKey, objectId: slug });
    }
  };
}
