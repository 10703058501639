import React, { useEffect, useState } from 'react';
import useAnalytics from '../../../hooks/useAnalytics';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import LeadershipContent from './LeadershipContent';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import SEOMeta from '../../common/SEOMeta';


function Leadership() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useAnalytics('About: Leadership');

  useEffect(() => {
    resetViewToTopOfPage();
  });

  const onComplete = (success, err) => {
    setLoading(false);
    if(!success) {
      setError(true);
    }
  };

  const clearPopMessage = () => {
    setError(null);
  };

  return (
    <div className="Leadership">
      { loading && <LoadingMessage message="Loading..." /> }

      { error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page.</p>
        </PopMessage>
      }

      <div className="Leadership-container">
        <SEOMeta 
          title="Board Leadership" 
          description="Board of Directors and Board of Advisors for the Institute of Catholic Culture" 
        />
        <LeadershipContent onComplete={onComplete} />
      </div>
    </div>
  );
}

export default Leadership;
