import { POST_LOAD_START, POST_LOAD_COMPLETE, POST_LOAD_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';
import stateConfig from '../config/state';

function postsReducer(state = initialState.posts, action) {
  const posts = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let { type, key, objectId, data } = action;
  let stateId = `${key}.${objectId}`;
  let altStateId = null;
  if(key === stateConfig.keys.PAGE && data && data.type === 'article') {
    // this is an article that was found matching the page request
    altStateId = `${stateConfig.keys.ARTICLE}.${objectId}`;
  }
  
  switch(type) {
    case POST_LOAD_START:
      clearStateById(posts.ids, posts.byId, stateId);
      return posts;
    case POST_LOAD_COMPLETE:
      posts.ids.push(stateId);
      posts.byId[stateId] = data;
      return posts;
    case POST_LOAD_ERR:
      posts.ids.push(stateId);
      posts.byId[stateId] = { error: action.error };
      return posts;
    case 'article-sub':
      posts.ids.push(stateId);
      posts.byId[stateId] = { type: 'article' }; // indicator to page component
      posts.ids.push(altStateId);
      posts.byId[altStateId] = data;
      return posts;
    default:
      return state;
  }
}

export default postsReducer;