import { REFLECTION_LOAD_START, REFLECTION_LOAD_COMPLETE, REFLECTION_LOAD_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function reflectionsReducer(state = initialState.reflections, action) {
  const reflections = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.objectId}`;
  
  switch(action.type) {
    case REFLECTION_LOAD_START:
      clearStateById(reflections.ids, reflections.byId, stateId);
      return reflections;
    case REFLECTION_LOAD_COMPLETE:
      reflections.ids.push(stateId);
      reflections.byId[stateId] = action.data;
      return reflections;
    case REFLECTION_LOAD_ERR:
      reflections.ids.push(stateId);
      reflections.byId[stateId] = { error: action.error };
      return reflections;
    default:
      return state;
  }
}

export default reflectionsReducer;