import { ACCT_DELETE_REQUEST, ACCT_DELETE_EMAIL_SENT, ACCT_DELETE_COMPLETE, 
         ACCT_DELETE_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function accountDeletionReducer(state = initialState.accountDeletion, action) {
  const { type, data = {}, error = {} } = action;
  let responseCode, pending, status = stateConfig.responseStatus;

  switch(type) {
    case ACCT_DELETE_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null });
    case ACCT_DELETE_EMAIL_SENT:
      return Object.assign({}, state, { responseStatus: status.COMPLETE });
    case ACCT_DELETE_COMPLETE:
      pending = data.pending? true : false;
      return Object.assign({}, state, { responseStatus: status.COMPLETE, pending });
    case ACCT_DELETE_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default accountDeletionReducer;