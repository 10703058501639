import { EMAIL_SETTINGS_REQUEST, EMAIL_SETTINGS_RECEIVED, EMAIL_SETTINGS_COMPLETE, 
         EMAIL_SETTINGS_ERR } from '../actions/actionTypes';
import * as notifications from '../api/notifications';
import errors from '../config/errors';


export function getEmailSettings(email) {
  return async dispatch => {
    dispatch({ type: EMAIL_SETTINGS_REQUEST });
    try {
      let result = await notifications.getEmailSettings(email);
      if(result && result.data && result.data.settings) {
        dispatch({ type: EMAIL_SETTINGS_RECEIVED, data: result.data });
      } else {
        dispatch({ type: EMAIL_SETTINGS_ERR, error: errors.ResponseMalformed });
      }
    } catch(error) {
      dispatch({ type: EMAIL_SETTINGS_ERR, error: errors.RequestFailed });
    }
  };
}

export function updateEmailSettings(email, settings) {
  return async dispatch => {
    dispatch({ type: EMAIL_SETTINGS_REQUEST });
    try {
      let result = await notifications.updateEmailSettings(email, settings);
      if(result && result.data && result.data.success) {
        dispatch({ type: EMAIL_SETTINGS_COMPLETE });
      } else {
        dispatch({ type: EMAIL_SETTINGS_ERR, error: errors.ResponseMalformed });
      }
    } catch(error) {
      dispatch({ type: EMAIL_SETTINGS_ERR, error: errors.RequestFailed });
    }
  };
}