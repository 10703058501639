import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as postAPI from '../../../actions/content/posts';
import PageTitle from './PageTitle';
import NotFoundContent from '../../NotFoundContent';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import SEOMeta from '../../common/SEOMeta';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import errors from '../../../config/errors';
import { resetViewToTopOfPage } from '../../../utils/utils';
import { stripHTML } from '../../../utils/format';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Article({ posts, postAPI }) {
  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [error, setError] = useState(null);
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const data = posts.byId[stateId];
    if(loading && isEmpty(article) && isNotEmpty(data) && posts.ids) {
      if(data.error) {
        setError(data.error);
      } else {
        setArticle(data);
      }
      setLoading(false);
    }
  }, [loading, article, stateId, posts, posts.ids]);

  initialize = () => {
    resetViewToTopOfPage();
    const slug = window.location.pathname.replace('/articles/', '');
    const stateId = `${stateConfig.keys.ARTICLE}.${slug}`;
    setStateId(stateId);

    const data = posts.byId[stateId];
    if(isNotEmpty(data) && !data.error) {
      setArticle(data);
      setLoading(false);
    } else {
      postAPI.getArticleBySlug(slug);
    }
  };

  const clearPopMessage = () => {
    setError(null);
  };

  return (
    <div className="MainContent-container">
      { isEmpty(article) && isEmpty(error) && <LoadingMessage message="Loading..." /> }

      { isNotEmpty(error) && error.code === errors.ContentResourceNotFoundError.code &&
        <NotFoundContent />
      }

      { isNotEmpty(error) && error.code !== errors.ContentResourceNotFoundError.code &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested article.</p>
        </PopMessage>
      }

      { isNotEmpty(article) && isEmpty(error) &&
        <div className="Article">
          <SEOMeta 
            title={article.title} 
            description={stripHTML(article.content)} />
          { !article.content.includes('<h1>') && // don't add H1 if already has one
            <PageTitle type="Article" data={article} />
          }
          <div className="ArticleContent" dangerouslySetInnerHTML={{__html: article.content}} />
        </div>
      }
    </div>
  );
}


function mapStateToProps(state) {
  return { posts: state.posts };
}

function mapDispatchToProps(dispatch) {
  return { 
    postAPI: bindActionCreators(postAPI, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
