import React from 'react';
import Typograpy from '@mui/material/Typography';

export default function Heading(props) {
  return (
    <Typograpy className={`Heading size-${props.size} weight-${props.weight} ${props.className}`} variant={props.variant}>
      {props.children}
    </Typograpy>
  );
}

Heading.defaultProps = {
  variant: 'h1',
  className: '',
  size: 'default',
  weight: 'default'
}