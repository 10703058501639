import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../common/Icon';
import Button from '@mui/material/Button';

export default function LinkButton(props) {
  const btn = props.external?
    (
      <Button className={`LinkButton ${props.className}`} color={props.color} href={props.to}>
        { props.icon && <Icon type={props.icon} /> }
        { props.label }
      </Button>
    ) :
    (
      <Button className={`LinkButton ${props.className}`} color={props.color} component={Link} to={props.to}>
        { props.icon && <Icon type={props.icon} /> }
        { props.label }
      </Button>
    );
  return (
    <div>{btn}</div>
  );
}

LinkButton.defaultProps = {
  color: "primary",
  external: false
};

LinkButton.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string
};

