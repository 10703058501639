import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';


export const themeColors = {
  gold: '#D0932C',
  lightGold: '#E1C585',
  green: '#477366',
  mediumBlue: '#466388',
  lightBlue: '#739cd2',
  veryLightBlue: '#dee5ee',
  red: '#98272b',
  darkBlue: '#102B4E',
  tan: '#D4CBB6',
  white: '#FFFFFF',
  black: '#000000',
  gray: '#898179',
  lightGray: '#ddd9d4'
};

export default createTheme({
  palette: {
    primary: { main: '#3E4F7D' },
    secondary: { main: '#98272b' },
    default: { main: '#F9F4E8' },
    error: red
  },
  typography: {
    useNextVariants: true,
    fontSize: 16
  }
});
