import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import stateConfig from '../../../../config/state';
import { listCourseParts, listAccessibleCourseParts, partNavigationUrl } from '../../../../utils/course';
import validator from '../../../../utils/validator';
const { isEmpty, isDefined } = validator;


function CourseNavigation({ course, transcript, selectedPartId, courseComplete, lastUpdated, courseProgress }) {
  const [partId, setPartId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const [updated, setUpdated] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if(isEmpty(partId) || partId !== selectedPartId) {
      setPartId(selectedPartId);
      setLoading(true);
    }
  }, [partId, selectedPartId, courseComplete]);

  useEffect(() => {
    if(!loading && lastUpdated !== 0 && lastUpdated !== updated) {
      setUpdated(lastUpdated);
      setLoading(true);
    }
  }, [loading, updated, lastUpdated]);

  useEffect(() => {
    const stateId = `${stateConfig.keys.PROGRESS}.${course.id}`;
    if(loading && !isEmpty(partId)) {
      const data = courseProgress.byId[stateId];
      if(data && !data.error) {
        const progressData = data && !data.error? data.progress : [];
        const isLinear = course.progression === 'linear';
        const parts = listCourseParts(course, transcript.track);
        const accessible = listAccessibleCourseParts(parts, progressData, isLinear);
        const index = parts.findIndex(p => p.id === partId);
        const p = index > 0? parts[index-1] : null;
        let n = index < parts.length? parts[index+1] : null;
        if(isDefined(p)) {
          const match = accessible.find(a => a.id === p.id);
          p.isAccessible = courseComplete || isDefined(match);
        }
        if(isDefined(n)) {
          const match = accessible.find(a => a.id === n.id);
          n.isAccessible = courseComplete || isDefined(match);
        }

        setPrevious(p);
        setNext(n);
        setLoading(false);
      }
    }
  }, [loading, partId, courseComplete, courseProgress.ids, courseProgress.byId, course, transcript]);

  const navToPart = part => () => {
    let url = partNavigationUrl(part, course.slug);
    navigate(url);
  };

  return (
    <Fragment>
      { (isDefined(previous) || isDefined(next)) &&
        <div className="CourseNavigation">
          <hr />
          <div className="nav-actions">
            <div className="nav-action prev">
              { isDefined(previous) &&
                <Fragment>
                  <Button variant="outlined" color="primary" onClick={navToPart(previous)} disabled={!previous.isAccessible}>
                    Previous
                  </Button>
                  <div className="nav-title">{previous.title}</div>
                </Fragment>
              }
            </div>
            <div className="nav-action next">
              { isDefined(next) &&
                <Fragment>
                  <Button variant="outlined" color="primary" onClick={navToPart(next)} disabled={!next.isAccessible}>
                    Next
                  </Button>
                  <div className="nav-title">{next.title}</div>
                </Fragment>
              }
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
}

CourseNavigation.propTypes = {
  course: PropTypes.object,
  transcript: PropTypes.object,
  selectedPartId: PropTypes.string,
  courseComplete: PropTypes.bool,
  lastUpdated: PropTypes.number
};

function mapStateToProps(state) {
  return { 
    courseProgress: state.courseProgress
  };
}

export default connect(mapStateToProps)(CourseNavigation);
