import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import * as authentication from '../../../actions/authentication';
import * as magdalaAPI from '../../../actions/magdala';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import FormWrapper from '../../common/form/FormWrapper';
import Form from '../../common/form/Form';
import stateConfig from '../../../config/state';
import { sortObjArray } from '../../../utils/object';
import validator from '../../../utils/validator';


function CommunitySelection(props) {

  const [loading, setLoading] = useState(true);
  const [submission, setSubmission] = useState(false); 
  const [communities, setCommunities] = useState(null);
  const [identified, setIdentified] = useState(false); 
  const [fields, setFields] = useState({
    communityId: '',
    email: ''
  }); 
  const [validationErrors, setValidationErrors] = useState({}); 
  const [dataError, setDataError] = useState(null); 

  useEffect(() => {
    props.magdalaAPI.getMagdalaCommunities();
  }, [props.magdalaAPI]); 
  
  useEffect(() => {
    try {
      if(loading && props.magdalaRegistration.responseStatus === stateConfig.responseStatus.COMPLETE) {
        setFields({...fields, email: props.user.email});
        setCommunities(sortObjArray(props.magdalaRegistration.communities, 'communityName')); 
        setLoading(false);   
      } else if(loading && props.magdalaRegistration.responseStatus === stateConfig.responseStatus.ERROR) {
        setDataError(props.magdalaRegistration.responseCode); 
        setLoading(false);
      }

      if(!identified && props.user.email) {
        setIdentified(true);  
      }
    } catch(error){
      console.error(error); 
    }
  }, [loading, identified, props, fields]); 

  const onFieldChange = (event) => {
    if (event.target.name === Object.keys(fields)[0]) {
      setFields({
        communityId: event.target.value,
        email: fields.email
      }); 
    } else {
      setFields({
        communityId: fields.communityId,
        email: event.target.value
      }); 
    }
  }

  const checkEmail = async () => {
    const { communityId, email } = fields; 

    if(validator.isNotEmpty(props.user.email)) {
      props.onComplete(email, communityId); 
      return; // already identified
    }

    try {
      await props.authentication.identifyWithEmail(email); 
    } catch(error) {
      // fail silently
    }

    props.onComplete(email, communityId); 
  }

  const clearPopMessage = () => {
    setDataError(null); 
  }

  const validate = () => {
    let rules = [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'email' },
      { rule: validator.rules.IS_DEFINED, prop: 'communityId' }
    ]; 

    const err = validator.validate(fields, rules); 
    if(err) {
      setValidationErrors(err); 
      return false; 
    }

    return true; 
  }

  const onSubmit = (event) => {
    event.preventDefault();
    
    if(!validate()) {
      return;
    }
    setSubmission(true); 
    setValidationErrors({}); 
    checkEmail(); 
  }

  const logoutAndRefresh = () => {
    const { protocol, host, pathname } = window.location; 

    props.authentication.logout() 
    .then(() => {
      window.location = `${protocol}//${host}${pathname}`; 
    });
  }

  return (
      <div className="CommunitySelection registration-step">
        { loading && <LoadingMessage message="Loading..." /> }
        { !loading && communities !== null &&
        <Fragment>
          <p className="title"><strong>Registration Step:</strong> Student Identification</p>
          <p>
            Select your community and provide the email address you wish to use for your student record. If 
            you are an existing member please provide the email associated with your membership. Once you have 
            registered, you will be allowed to apply for individual courses.
          </p>
          <p>
            A working email address is necessary for applying to the Magdala Apostolate program. Please ensure 
            you are able to check the inbox of the email address you enter.
          </p>
          <p>
            If you do not see your community listed, please select “Other/Not Listed” and we will follow up with you.
          </p>

          <div id="selection-form" className="panel">
            <FormWrapper raised={false}>
              <Form onSubmit={onSubmit} autoComplete="off" submitLabel="Continue" submission={submission}>
                <FormControl variant="standard" className="full">
                  <InputLabel id="community-select-label">Community</InputLabel>
                  <Select 
                    className="FormField-control full community" 
                    labelId="community-select-label" 
                    name="communityId" 
                    label="Community"
                    value={fields.communityId} 
                    onChange={onFieldChange}
                    error={validator.isDefined(validationErrors['communityId'])}
                    helperText={validator.message(validationErrors['communityId'], 'community')}
                  >
                    <MenuItem key="other" value="other">Other/Not Listed</MenuItem>
                    {
                      communities.map(community => (
                        <MenuItem key={community.communityId} value={community.communityId}>{community.communityName}</MenuItem>
                      ))
                    }
                  </Select>
                  { validator.isDefined(validationErrors['communityId']) &&
                    <FormHelperText className="FormField-error">
                      {validator.message(validationErrors['communityId'], 'community')}
                    </FormHelperText>
                  }
                </FormControl>
                
                <TextField
                  className="FormField-control full"
                  name="email"
                  label="Email"
                  placeholder='Email'
                  onChange={onFieldChange}
                  helperText={validator.message(validationErrors['email'], 'email')}
                  error={validator.isDefined(validationErrors['email'])}
                  value={fields.email}
                  variant="standard"
                />

                { identified && 
                  <p className="signout-prompt">*Not "{fields.email}"? Please <span onClick={logoutAndRefresh}>log out</span>, then log in using your email address.</p>
                }
              </Form>
            </FormWrapper>
          </div>
        </Fragment>}
        { validator.isDefined(dataError) &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage} type={stateConfig.messageTypes.ERROR}>
          <p>The registration form could not be displayed</p>
        </PopMessage>
        }
    </div>
  )
}

CommunitySelection.propTypes = {
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return {
    user: state.user,
    magdalaRegistration: state.magdalaRegistration
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authentication: bindActionCreators(authentication, dispatch),
    magdalaAPI: bindActionCreators(magdalaAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunitySelection);