import React from 'react';
import PropTypes from 'prop-types';
import validator from '../../../../utils/validator'; 
const { isNotEmpty } = validator;

function ClassroomHeading({ title, subtitle, media }) {

  let headingType = "ClassroomHeading";  
    
  if (isNotEmpty(media.title)) {
    if (media.title === "Reflection Video" || media.title === "Reflection Audio") {
      headingType = "sgrHeading"; 
    } else if (media.title !== "Video" && media.title !== "Audio") {
      subtitle = `${subtitle} - ${media.title}`;              
    }
  }

  return (
    <div className={headingType}>
      <h1>{title}</h1>
      {subtitle && media.title !== "Reflection Audio" && 
        <h2>{subtitle}</h2>
      }
    </div>
  );
}

ClassroomHeading.propTypes = {
  media: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default ClassroomHeading;