import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics';
import * as eventsAPI from '../../../actions/content/events';
import EventList from './display/EventList';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import SEOMeta from '../../common/SEOMeta';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function ByCategory({ events, eventsAPI }) {
  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [eventsList, setEventsList] = useState(null);
  const [error, setError] = useState(null);
  const location = useLocation();
  useAnalytics('Category List');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let retrieveEvents = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const { pathname } = location;
    if(pathname.includes('/events/categories/')) {
      const slug = getSlug(pathname);
      setSlug(slug);
      setStateId(`${stateConfig.keys.EVENT_LIST}.${slug}`);
      setEventsList(null);
      setError(null);
      resetViewToTopOfPage();
    }
  }, [location, retrieveEvents]);

  useEffect(() => {
    if(!loading && isNotEmpty(slug) && isNotEmpty(stateId)) {
      setLoading(true);
      retrieveEvents();
    }
  }, [loading, slug, stateId, retrieveEvents]);

  useEffect(() => {
    const data = events.byId[stateId];
    if(loading && isEmpty(eventsList) && isNotEmpty(data) && events.ids) {
      if(data.error) {
        setError(data.error);
      } else {
        setEventsList(data);
      }
      setLoading(false);
    }
  }, [loading, events, events.ids, eventsList, stateId]);

  initialize = () => {
    resetViewToTopOfPage();
    const slug = getSlug(window.location.pathname);
    setSlug(slug);
    setStateId(`${stateConfig.keys.EVENT_LIST}.${slug}`);
  };

  const getSlug = (pathname) => {
    return pathname.replace('/events/categories/', '');
  };

  const getCategoryName = () => {
    if(!slug.includes('-')) {
      return `${slug.substring(0,1).toUpperCase()}${slug.substring(1, slug.length)}`;
    }
    let words = slug.split('-');
    words = words.map(word => `${word.substring(0,1).toUpperCase()}${word.substring(1, word.length)}`)
    return words.join(' ');
  };

  retrieveEvents = () => {
    const data = events.byId[stateId];
    if(isNotEmpty(data) && !data.error) {
      setEventsList(data);
      setLoading(false);
    } else {
      eventsAPI.getEventListBySlug(slug);
      setLoading(true);
    }
  };

  const clearPopMessage = () => {
    setError(null);
  };
  
  return (
    <div className="MainContent-container">
      { !eventsList && !error && <LoadingMessage message="Loading..." /> }
      
      <div className="EventList-container by-category">
        { error &&
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>We could not load the requested page.</p>
          </PopMessage>
        }

        { eventsList &&
          <SEOMeta 
            title="Live Events" 
            description={`Study ${getCategoryName()} with our live and self-paced lectures and courses`} />
        }

        { eventsList && 
          <EventList events={eventsList.results} displayType={config.eventListTypes.BROWSE}
            heading={`Related to ${getCategoryName()}`} cardsPerRender={config.defaultCardsPerRender} 
            useHeadingForPage={true} />
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { events: state.events };
}

function mapDispatchToProps(dispatch) {
  return { 
    eventsAPI: bindActionCreators(eventsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ByCategory);