import { ACCT_DELETE_REQUEST, ACCT_DELETE_EMAIL_SENT, ACCT_DELETE_COMPLETE, 
         ACCT_DELETE_ERR } from '../actionTypes';
import * as account from '../../api/account';
import errors from '../../config/errors';


export function sendAccountDeletionEmail() {
  return async dispatch => {
    dispatch({ type: ACCT_DELETE_REQUEST });
    try {
      await account.sendAccountDeletionEmail();
      dispatch({ type: ACCT_DELETE_EMAIL_SENT });
    } catch(error) {
      dispatch({ type: ACCT_DELETE_ERR, error: errors.RequestFailed });
    }
  };
}

export function deleteAccount(token) {
  return async dispatch => {
    dispatch({ type: ACCT_DELETE_REQUEST });
    try {
      let result = await account.deleteAccount(token);
      dispatch({ type: ACCT_DELETE_COMPLETE, data: result.data });
    } catch(error) {
      dispatch({ type: ACCT_DELETE_ERR, error: errors.RequestFailed });
    }
  };
}