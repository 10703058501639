import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import EventCard from './EventCard';
import config from '../../../../config/config';


function renderCard(displayType, eventData) {
  switch(displayType) {
    case config.eventListTypes.CURRENT_AND_UPCOMING:
      return (<EventCard eventData={eventData} displayFavorite={true} displayStatus={true} />);
    default:
      return (<EventCard eventData={eventData} displayFavorite={true} displayProgress={true} displayRating={true} />);
  }
}

class EventListRow extends React.Component {  
  render() {
    const { events, className, displayType } = this.props;
    return (
      <div className={`EventList-row ${className}`}>
        {
          events.map((event, index) => {
            return (
              <Fragment key={event.slug || `placeholder-${index}`}>
                { event.placeholder? 
                  <div className="EventCard placeholder"></div> :
                  renderCard(displayType, event) 
                }
                { index !== events.length-1 && <div className="spacer"></div> }
              </Fragment>
            );
          })
        }
      </div>
    );
  }
}

EventListRow.propTypes = {
  displayType: PropTypes.string,
  events: PropTypes.array,
  className: PropTypes.string
};

export default EventListRow;