import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useAnalytics from '../../../hooks/useAnalytics';
import * as eventsAPI from '../../../actions/content/events';
import EventList from './display/EventList';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import urls from '../../../config/urls';
import SEOMeta from '../../common/SEOMeta';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Browse({ events, eventsAPI }) {
  const [loading, setLoading] = useState(true);
  const [eventLists, setEventLists] = useState(null);
  const [error, setError] = useState(null);
  const stateId = `${stateConfig.keys.EVENT_LIST}.browse`;
  useAnalytics('Browse');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const data = events.byId[stateId];
    if(loading && isEmpty(eventLists) && isNotEmpty(data) && events.ids) {
      if(data.error) {
        setError(data.error);
      } else {
        setEventLists(data.results);
      }
      setLoading(false);
    }
  }, [loading, eventLists, stateId, events, events.ids]);

  initialize = () => {
    resetViewToTopOfPage();
    const data = events.byId[stateId];
    if(isNotEmpty(data) && !data.error) {
      setEventLists(data.results);
      setLoading(false);
    } else {
      eventsAPI.browseEvents();
    }
  };

  const clearPopMessage = () => {
    setError(null);
  };
  
  return (
    <div className="MainContent-container">
      { !eventLists && !error && <LoadingMessage message="Loading..." /> }
      
      <div className="EventList-container browse">
        { error &&
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>We could not load the requested page.</p>
          </PopMessage>
        }

        { eventLists && 
          <Fragment>
            <SEOMeta 
              title="Browse" 
              description={`Browse over ${config.libraryHoursOfContent} hours of in-depth education in the Institute of Catholic Culture library`} />
            {
              eventLists.map((list, index) => {
                return (
                  <EventList key={`list-${index}`} 
                    events={list.events} 
                    displayType={config.eventListTypes.BROWSE}
                    heading={`Related to ${list.name}`} 
                    cardsPerRender={config.defaultCardsPerRender}
                    moreText={!list.featured? `More from ${list.name}` : ''} 
                    moreLink={urls.eventsByCategory.replace(':slug', list.slug)} 
                    useHeadingForPage={index === 0? true : false}
                  />
                )
              })
            }
          </Fragment>
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { events: state.events };
}

function mapDispatchToProps(dispatch) {
  return { 
    eventsAPI: bindActionCreators(eventsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Browse);