import React from 'react';
import ProviderRegistration from './ProviderRegistration';
import useAnalytics from '../../../../hooks/useAnalytics';

function FacebookRegistration() {
  useAnalytics('Facebook Registration');
  return (
    <ProviderRegistration providerId='facebook' />
  );
}

export default FacebookRegistration;