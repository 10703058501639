import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics';
import * as shareAPI from '../../../actions/share';
import * as authenticationAPI from '../../../actions/authentication';
import LoadingMessage from '../../common/messages/LoadingMessage';
import Icon, { IconTypes } from '../../common/Icon';
import errors from '../../../config/errors';
import urls from '../../../config/urls';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { localStore, localRetrieve } from '../../../utils/store';
import { resetViewToTopOfPage } from '../../../utils/utils';


function ShareLink({ share, shareAPI, authenticationAPI }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  useAnalytics('Share Link');
  const token = window.location.pathname.replace('/share/', '');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(loading && share.responseStatus === stateConfig.responseStatus.ERROR) {
      setError(`Share link token could not be validated: ${share.responseCode}`)
      setLoading(false);
    }
    
    if(loading && share.responseStatus === stateConfig.responseStatus.COMPLETE) {
      const { identified, eventSlug } = share;
      storeTrialAccess(eventSlug);
      if(identified) {
        authenticationAPI.getAuthStatus(true)
          .then(() => {
            navigate(urls.event.replace(':slug', eventSlug));
          });
      } else {
        navigate(urls.event.replace(':slug', eventSlug));
      }
    }
  }, [loading, share, share.responseStatus, share.responseCode, authenticationAPI, navigate]);

  initialize = () => {
    resetViewToTopOfPage();
    shareAPI.validateShareToken(token);
  };

  const storeTrialAccess = (eventSlug) => {
    const key = config.localStorage.eventTrialAccess;
    let events = localRetrieve(key) || '';
    if(!events.includes(eventSlug)) {
      events = events.split(',');
      events.push(eventSlug);
      localStore(key, events.join(','));
    }
  };
    
  return (
    <div className="Share">
      <div className="MainContent-container">
        { loading && !error && <LoadingMessage message="Loading..." /> }

        { !loading && error === errors.TokenValidationFailed.code && 
          <div className="Share-error">
            <div className="error-heading">
              <Icon type={IconTypes.Surprise} size="6x" />
            </div>
            <p className="error-message">
              <strong>Uh Oh!</strong> This link appears to be invalid or expired.
            </p>
          </div>
        }

        { !loading && error !== errors.TokenValidationFailed.code && 
          <div className="Share-error">
            <div className="error-heading">
              <Icon type={IconTypes.Surprise} size="6x" />
            </div>
            <p className="error-message">
              <strong>Uh Oh!</strong> We could not process this link.
            </p>
          </div>
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    share: state.share 
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    shareAPI: bindActionCreators(shareAPI, dispatch),
    authenticationAPI: bindActionCreators(authenticationAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareLink);