import React from 'react';
import Button from '@mui/material/Button';
import TitleWithSubtext from '../../common/TitleWithSubtext';
import PropTypes from 'prop-types';
import urls from '../../../config/urls';

function Pitch(props) {
  const { content } = props; 
  const { value: titleWithSubtext } = content['landingCommunity.donationIntro']; 
  const button = content['landingCommunity.donationButton'].value; 

  return (
    <div className="Magdala-Landing-Pitch container">
      <div className="container-content">
        <TitleWithSubtext titleWithSubtext={titleWithSubtext} />
        <Button variant="outlined" onClick={() => window.location = urls.donateFundraiseupMagdala}>{button.title}</Button>
      </div>
    </div>
  );
}

Pitch.propTypes = {
  content: PropTypes.object
}

export default Pitch;