import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


function StandardSystemPopup(props) {
  const { className, open, title, actions, onClose } = props;

  return (
    <Dialog
      className={`StandardSystemPopup ${className} ${title? 'with-title' : 'no-title'}`}
      open={open}
      onClose={() => {}}
    >
      <DialogTitle className="title">
        <span>{title}</span>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="content-main">
          {props.children}
        </div>
      </DialogContent>
      { actions &&
        <DialogActions>
          <div className="content-actions">
            {actions}
          </div>
        </DialogActions>
      }
    </Dialog>
  );
}

StandardSystemPopup.defaultProps = {
  className: 'standard'
}

StandardSystemPopup.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  actions: PropTypes.object,
  onClose: PropTypes.func
};

export default StandardSystemPopup;
