import React from 'react';
import PropTypes from 'prop-types';
import { formatDateForDisplay } from '../../../utils/format';
import validator from '../../../utils/validator';


function DonationHistory({ donations }) {
  return (
    <div className="DonationHistory">
      <h2>Donation History</h2>
      { donations.length > 0 &&
        <div className="history-list">
          {
            donations.map((donation, index) => {
              const succeeded = donation.status === 'complete';
              const card = donation.card;
              return (
                <div key={`donation-${index}`} className="donation">
                  <span className="date">{formatDateForDisplay(new Date(donation.createdOn))}</span>
                  <span className="description">
                    ${donation.amount}.00 {donation.recurringDonationId? 'Monthly Donation' : 'One-Time Donation' }
                    {succeeded && card? ` - ${card.last4} (exp: ${card.expires.month}/${card.expires.year})` : ''}
                    {!succeeded? ' - Transaction Failed' : '' }
                  </span>
                  <div className="notation">
                    <span className={validator.isEmpty(donation.notation)? 'empty' : 'not-empty'}>
                      {validator.isNotEmpty(donation.notation)? `Note: ${donation.notation}` : ''}
                    </span>
                  </div>
                </div>
              )
            })
          }
        </div>
      }
      { donations.length === 0 &&
        <div className="history-list">
          No donation transactions were found.
        </div>
      }
    </div>
  );
}

DonationHistory.propTypes = {
  donations: PropTypes.array
};

export default DonationHistory;