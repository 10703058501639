import { EVT_REGISTRATION_REQUEST, EVT_REGISTRATION_COMPLETE, EVT_REGISTRATION_STATUS,
         EVT_REGISTRATION_ERR, AUTH_USER_IDENTIFIED } from '../../actions/actionTypes';
import * as registration from '../../api/registration';
import errors from '../../config/errors';


export function getEventRegistrationStatus(reqData) {
  const { eventId, identify } = reqData;
  return async dispatch => {
    dispatch({ type: EVT_REGISTRATION_REQUEST, data: { eventId } });
    try {
      let result = await registration.getEventRegistrationStatus(reqData);
      const data = { eventId, status: result.data };
      dispatch({ type: EVT_REGISTRATION_STATUS, data });
      
      // if this was a successful ID request then publish identity as well
      const { registered, member } = result.data;
      if(identify && registered && member) {
        dispatch({ type: AUTH_USER_IDENTIFIED, data: { member } });
      }
    } catch(error) {
      dispatch({ type: EVT_REGISTRATION_ERR, data: { eventId }, error: errors.RequestFailed });
    }
  };
}

export function createEventRegistration(eventId, webinarId, registrationData) {
  return async dispatch => {
    dispatch({ type: EVT_REGISTRATION_REQUEST, data: { eventId } });
    try {
      let result = await registration.createEventRegistration(eventId, webinarId, registrationData);
      const data = { eventId, status: result.data };
      dispatch({ type: EVT_REGISTRATION_COMPLETE, data });
    } catch(error) {
      dispatch({ type: EVT_REGISTRATION_ERR, data: { eventId }, error: errors.RequestFailed });
    }
  };
}