import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import urls from '../../../config/urls'; 
import Button from '@mui/material/Button';


function Heading(props) {
  const { heading, description, videoId, brochureURL, brochureButton } = props.curriculum;
  const [videoDisplay, setVideoDisplay] = useState(false);

  const showVideo = event => {
    setVideoDisplay(true);
  };

  return (
    <Fragment>
      <div className="heading">
        <h1>{heading}</h1>
        <div className="brochures">
          <Button href={brochureURL} target="_blank" rel="noopener noreferrer" 
            variant="outlined" color="secondary">
              {brochureButton}
          </Button>
        </div>
      </div>
      <div className="curriculum-description">
        <div className="text">
          <h2>Curriculum Description</h2>
          <p>{description}</p>
        </div>
        <div className={`video ${videoDisplay? 'displayed' : ''}`}>
          <div className="responsive-iframe-container">
            <iframe src={`${urls.vimeoPlayer}/${videoId}`} title={`${heading} Welcome`} 
              frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
          </div>
        </div>
        <div className={`welcome-video-btn ${videoDisplay? 'not-displayed' : ''}`}>
          <Button variant="contained" color="primary"
            onClick={showVideo}>
            Curriculum Welcome Video
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

Heading.propTypes = {
  curriculum: PropTypes.object
};

export default Heading;