import { AUTH_USER_IDENTIFIED, AUTH_USER_ID_ERR, AUTH_STATUS_RECEIVED, 
         AUTH_COMPLETE, MBR_REGISTRATION_COMPLETE, PASSWORD_UPDATED,
         AUTH_RESET_COMPLETE, MBR_ACTIVATION_COMPLETE, AUTH_CHECKED } from '../actions/actionTypes';
import initialState from './initialState';

function userReducer(state = initialState.user, action) {
  const { type: actionType, data } = action;
  let email, type, salutation, firstName, lastName, trackingId;
  
  switch(actionType) {
    case AUTH_USER_IDENTIFIED:
      ({ email, type, salutation, firstName, lastName, trackingId } = data.member || data);
      return Object.assign({}, state, { email, type, salutation, firstName, lastName, trackingId });
    case AUTH_STATUS_RECEIVED:
    case MBR_ACTIVATION_COMPLETE:
      ({ email, type, salutation, firstName, lastName, trackingId } = data.member || data);
      return Object.assign({}, state, { authenticated: true, email, type, salutation, firstName, lastName, trackingId });
    case AUTH_COMPLETE:
      ({ email, type, salutation, firstName, lastName, trackingId } = data.member || data);
      return Object.assign({}, state, { authenticated: true, email, type, salutation, firstName, lastName, trackingId });
    case AUTH_CHECKED:
      return Object.assign({}, state, { userDataChecked: true });
    case MBR_REGISTRATION_COMPLETE:
      ({ trackingId } = data);
      return Object.assign({}, state, { trackingId });
    case AUTH_RESET_COMPLETE:
    case PASSWORD_UPDATED:
      return Object.assign({}, state, { authenticated: false });
    case AUTH_USER_ID_ERR:
      return Object.assign({}, initialState.user);
    default:
      return state;
  }
}

export default userReducer;