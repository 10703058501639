import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useAnalytics from '../../../hooks/useAnalytics';
import * as systemAPI from '../../../actions/content/system';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import { resetViewToTopOfPage } from '../../../utils/utils';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function PrivacyPolicy({ privacyPolicy, systemAPI }) {
  const [loading, setLoading] = useState(true);
  const [policy, setPolicy] = useState(null);
  const [error, setError] = useState(null);
  useAnalytics('Privacy Policy');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(loading && isEmpty(policy) && isNotEmpty(privacyPolicy)) {
      if(privacyPolicy.error) {
        setError(privacyPolicy.error);
      } else {
        setPolicy(privacyPolicy);
      }
      setLoading(false);
    }
  }, [loading, policy, privacyPolicy]);

  initialize = () => {
    resetViewToTopOfPage();
    if(isNotEmpty(privacyPolicy)) {
      setPolicy(privacyPolicy);
    } else {
      systemAPI.getPrivacyPolicy();
    }
  };

  const clearPopMessage = () => {
    setError(null);
  }

  return (
    <div className="PrivacyPolicy">
      { !policy && !error && <LoadingMessage message="Loading..." /> }

      { error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page.</p>
        </PopMessage>
      }

      <div className="content">
        { policy && !error &&
          <div dangerouslySetInnerHTML={{__html: policy}} />
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { privacyPolicy: state.systemContent.privacyPolicy };
}

function mapDispatchToProps(dispatch) {
  return { 
    systemAPI: bindActionCreators(systemAPI, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
