import { EXPLORE_MENU_TOGGLED, PAGE_MESSAGE_PUBLISHED,
         PAGE_BANNER_PUBLISHED } from '../actions/actionTypes';
import initialState from './initialState';
import { random } from '../utils/utils';

function appDisplayReducer(state = initialState.appDisplay, action) {
  const appDisplay = Object.assign({}, state);

  switch(action.type) {
    case EXPLORE_MENU_TOGGLED:
      appDisplay.exploreMenuOpen = !appDisplay.exploreMenuOpen;
      return appDisplay;
    case PAGE_MESSAGE_PUBLISHED:
      appDisplay.pageMessage = action.data;
      return appDisplay;
    case PAGE_BANNER_PUBLISHED: 
      appDisplay.pageBanner = Object.assign({}, { id: random() }, action.data);
      return appDisplay;
    default:
      return state;
  }
}

export default appDisplayReducer;