import React, { Fragment } from 'react';
import PopMessage from './PopMessage';
import Button from '@mui/material/Button';
import urls from '../../../config/urls';

class ContactErrorPopMessage extends React.Component {
  constructor(props) {
    super(props);
    this.contactNavigation = this.contactNavigation.bind(this);
  }
  
  contactNavigation() {
    window.location = urls.contactUs;
  }

  render() {
    const { vertical, horizontal, open, onClose, children } = this.props;
    return (
      <PopMessage type="error" vertical={vertical} horizontal={horizontal}
        open={open} onClose={onClose}
        action={
          <Fragment>
            Need help?&nbsp;&nbsp;
            <Button onClick={this.contactNavigation} variant="outlined">Contact Us</Button>
          </Fragment>
        }>
        {children}
      </PopMessage>
    );
  }
}

ContactErrorPopMessage.defaultProps = {
  vertical: 'top',
  horizontal: 'left',
  open: false,
  onClose: () => {}
};

export default ContactErrorPopMessage;