import { INSTRUCTOR_LOAD_START, INSTRUCTOR_LOAD_COMPLETE, INSTRUCTOR_LOAD_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import { clearStateById } from '../utils/state';

function instructorsReducer(state = initialState.instructors, action) {
  const instructors = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.objectId}`;
  
  switch(action.type) {
    case INSTRUCTOR_LOAD_START:
      clearStateById(instructors.ids, instructors.byId, stateId);
      return instructors;
    case INSTRUCTOR_LOAD_COMPLETE:
        instructors.ids.push(stateId);
        instructors.byId[stateId] = action.data;
      return instructors;
    case INSTRUCTOR_LOAD_ERR:
        instructors.ids.push(stateId);
        instructors.byId[stateId] = { error: action.error };
      return instructors;
    default:
      return state;
  }
}

export default instructorsReducer;