import React from 'react';
import { ReactComponent as QuoteImage } from '../../../images/QuotationMarks.svg';

class Testimonials extends React.Component {
  render() {
    return (
      <div className="Home-testimonials">
        <div>
          <QuoteImage />
          <div className="quote">
            <p>
            The Institute of Catholic Culture is a burning fire of hope, and a safe haven where the saving truths 
            of the Catholic Faith are being taught with clarity! 
            </p>
            <p className="name">– Fr. Benedict Groeschel, C.F.R.</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Testimonials;