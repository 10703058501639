import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import Tooltip from '@mui/material/Tooltip';
import useAnalytics from '../../../../hooks/useAnalytics';
import ShareMeta from '../../../common/ShareMeta';
import Icon, { IconTypes } from '../../../common/Icon';
import validator from '../../../../utils/validator';
import config from '../../../../config/config';
const { isNotEmpty } = validator;
const { deviceBreakpoints } = config;

const certLessonInfo = 'Lessons in certificate courses often include reading, lecture, and further study topics, as well as lesson quizzes.';
const auditLessonInfo = 'Lessons in audit courses often include reading, lecture, and further study topics.';

function CourseHeading({ course, transcript }) {
  const { title, subtitle, type, tracks, thumbnail, excerpt, lessons, exams, length, statusMessage } = course;
  const isSmallView = useMediaQuery({
    query: `(max-width: ${deviceBreakpoints.small}px)`
  });
  useAnalytics(`${title}: ${subtitle}`);

  let showCertificateDetails = false, courseDescription = '';
  if(isNotEmpty(transcript.track)) {
    showCertificateDetails = transcript.track === 'certificate';
    courseDescription = transcript.track === 'certificate'? 'Certificate Course' : 'Audit Course';
  } else {
    showCertificateDetails = tracks === 'certificate' || tracks === 'cert-audit';
    courseDescription = type === 'live'? 'Live Course' : 'Self-Paced Course';
  }

  const statusIcon = () => {
    switch(statusMessage.icon) {
      case 'calendar':
        return (<Icon type={IconTypes.Events} />);
      case 'video':
        return (<Icon type={IconTypes.Video} />);
      default:
        return (<Fragment></Fragment>);
    }
  };

  return (
    <div className="CourseHeading">
      <ShareMeta title={`${title}: ${subtitle}`} excerpt={isNotEmpty(excerpt)? excerpt : ''} image={thumbnail} />
      <div className="CourseHeading-container">
        <div className="CourseHeading-feature-container">
          <div className="CourseHeading-feature" style={{backgroundImage: `url(${thumbnail})`}}>
            <div className="actions">
            </div>
            <div className="info-bar">
              <div className="type">
                { showCertificateDetails && <Icon type={IconTypes.Certificate} /> }
                &nbsp;{courseDescription}
              </div>
            </div>
          </div>
          { isSmallView &&
            <div className="CourseHeading-title">
              <h1>{title}</h1>
              <h2>{subtitle}</h2>
            </div>
          }
        </div>
        <div className="Info-container">
          { !isSmallView &&
            <Fragment>
              <h1>{title}</h1>
              <h2>{subtitle}</h2>
            </Fragment>
          }
          <div className="highlights">
            { isNotEmpty(lessons) && lessons.length > 0 &&
              <Tooltip title={showCertificateDetails? certLessonInfo : auditLessonInfo}>
                <div className="item lesson-info">
                  <Icon type={IconTypes.ContentList} /> {lessons.length} Lessons <Icon className="info" type={IconTypes.InfoCircleFilled} />
                </div>
              </Tooltip>
            }
            { isNotEmpty(exams) && exams.length > 0 && showCertificateDetails &&
              <div className="item"><Icon type={IconTypes.Quiz} /> {exams.length} {exams.length === 1? 'Exam' : 'Exams'}</div>
            }
            { isNotEmpty(length) && length.totalTime > 0 &&
              <div className="item"><Icon type={IconTypes.Time} /> {length.totalTime} hours</div>
            }
          </div>
          { statusMessage.enabled &&
            <div className={`status-message icon-${statusMessage.icon}`}>
              {statusIcon()}
              <p>{statusMessage.message}</p>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

CourseHeading.propTypes = {
  course: PropTypes.object,
  transcript: PropTypes.object
};

export default CourseHeading;
