import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Form from '../../common/form/Form';
import PopMessage from '../../common/messages/PopMessage';
import validator from '../../../utils/validator';
import stateConfig from '../../../config/state';


function PasswordlessForm({ defaultEmail, submission, displayError, onSubmit }) {
  const [email, setEmail] = useState(defaultEmail || '');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setEmail(defaultEmail);
  }, [defaultEmail]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const submit = (event) => {
    event.preventDefault();
    if(!validate()) {
      return;
    }
    onSubmit(email);
  }

  const validate = () => {
    let rules = [ 
      { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'email' }
    ];
    
    const validationErrors = validator.validate({ email }, rules);
    if(validationErrors) {
      setErrors(validationErrors);
      return false
    }

    return true;
  }
    
  return (
    <Form id="LoginPasswordlessForm-request" submitLabel="Send Link" onSubmit={submit} submission={submission}>
      <p className="LoginPasswordlessForm-instructions">
        Enter your email address. We will send you an authentication link that you 
        can use to log in without a password.
      </p>
      <TextField
        className="FormField-control full first"
        name="email"
        label="Email *"
        placeholder='Email'
        onChange={handleEmailChange}
        helperText={validator.message(errors['email'], 'email')}
        error={validator.isDefined(errors['email'])}
        value={email}
        variant="standard"
      />

      { displayError &&
        <PopMessage horizontal="center" type={stateConfig.messageTypes.ERROR} open={true}>
          <p>We were unable to send your authentication link. Please double check your email address.</p>
        </PopMessage>
      }
    </Form>
  );
}

PasswordlessForm.defaultProps = {
  defaultEmail: '',
  displayError: false
};

PasswordlessForm.propTypes = {
  onSubmit: PropTypes.func,
  submission: PropTypes.bool
};

export default PasswordlessForm;