import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'qs';
import useAnalytics from '../../../hooks/useAnalytics';
import * as eventsAPI from '../../../actions/content/events';
import * as courseRegistrationAPI from '../../../actions/registration/course';
import ContactErrorPopMessage from '../../common/messages/ContactErrorPopMessage';
import ApplicationWarning from './applications/ApplicationWarning';
import ApplicationIdentification from './applications/ApplicationIdentification';
import LoadingMessage from '../../common/messages/LoadingMessage';
import { resetViewToTopOfPage } from '../../../utils/utils';
import stateConfig from '../../../config/state';
import errors from '../../../config/errors';
import urls from '../../../config/urls';
import config from '../../../config/config';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function CourseApplication({ events, user, courseRegistration, eventsAPI, courseRegistrationAPI }) {
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [slug, setSlug] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [track, setTrack] = useState(null);
  const [newMember, setNewMember] = useState(false);
  const [identification, setIdentification] = useState(null);
  const [event, setEvent] = useState(null);
  const [error, setError] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);
  const [step, setStep] = useState('identification');
  const navigate = useNavigate();
  const location = useLocation();
  useAnalytics('Course Application');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let redirectToApplicationForm = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const eventData = events.byId[stateId];
    if(loading && isEmpty(event) && events.ids && isNotEmpty(eventData)) {
      const params = querystring.parse(location.search.substring(1));
      const providerRedirect = (params.provider && params.provider === 'success')? true : false;

      if(eventData.error) {
        setLoading(false);
        setError(true);
      } else if(!hasActiveApplication(eventData)) { 
        // the application has been closed, or this is not a live course
        setLoading(false);
        setEvent(eventData);
        setError(true);
      } else {
        // store event data, if this is a provider redirect then make sure the step is set to warning
        setEvent(eventData);
        setStep(providerRedirect? 'warning' : step);
        courseRegistrationAPI.getCourseRegistrationStatus(slug);
      }
    }
  }, [loading, event, slug, stateId, step, events, events.ids, location, courseRegistrationAPI]);

  useEffect(() => {
    const registration = courseRegistration.byId[slug];
    if(loading && courseRegistration.ids && isNotEmpty(registration)) {
      if(registration.error) {
        // no completed or partially completed registration record found, let them apply
        setLoading(false);
      } else {
        setError(true);
        setAlreadyApplied(true);
        setLoading(false);
      }
    }
  }, [loading, slug, courseRegistration, courseRegistration.ids]);

  useEffect(() => {
    const registration = courseRegistration.byId[slug];
    if(processing && courseRegistration.ids && isNotEmpty(registration)) {
      if(registration.error && courseRegistration.responseCode === errors.DuplicateRecordError.code) {
        setProcessing(false);
        setError(true);
        setAlreadyApplied(true);
      } else if(registration.error) {
        setProcessing(false);
        setError(true);
        setRegistrationError(true);
      } else {
        // completed the registration, redirect them to complete the application info form
        redirectToApplicationForm();
      }
    }
  }, [processing, redirectToApplicationForm, slug, courseRegistration, courseRegistration.ids]);

  useEffect(() => {
    if(isNotEmpty(user.email)) {
      const params = querystring.parse(location.search.substring(1));
      const providerRedirect = (params.provider && params.provider === 'success')? true : false;
      // if the user is identified and this is a redirect from a provider registration
      // then we want to store the result of the registration
      if(providerRedirect) {
        setStep(isNotEmpty(event)? 'warning' : step); // don't try to advance before event is loaded
        setNewMember(true);
        setIdentification({
          email: user.email, 
          salutation: user.salutation, 
          firstName: user.firstName, 
          lastName: user.lastName, 
          syncName: false
        });
      }
    }
  }, [user, user.email, event, location, step]);

  initialize = () => {
    resetViewToTopOfPage();
    const params = querystring.parse(location.search.substring(1));
    const sId = `${stateConfig.keys.EVENT}.${params.slug}`;
    setSlug(params.slug);
    setStateId(sId);
    setTrack(params.track || 'audit');

    const eventData = events.byId[sId];
    if(isEmpty(eventData) || eventData.error) {
      eventsAPI.getEventBySlug(params.slug);
    } else if(!hasActiveApplication(eventData)) { 
      // the application has been closed, or this is not a live course
      setLoading(false);
      setEvent(eventData);
      setError(true);
    } else {
      setEvent(eventData);
      courseRegistrationAPI.getCourseRegistrationStatus(params.slug);
    }
  };

  const hasActiveApplication = (event) => {
    const { type, status, course: { auditApplication, certificateApplication } } = event;
    const hasApplications = !validator.isEmpty(`${auditApplication}${certificateApplication}`.trim());
    return type === 'Live Course' && status === 'Upcoming' && hasApplications? true : false;
  };

  const storeApplicantIdentification = (fields, newMember) => {
    resetViewToTopOfPage();
    const { email, salutation, firstName, lastName, syncName } = fields;
    setStep('warning');
    setNewMember(newMember);
    setIdentification({ email, salutation, firstName, lastName, syncName });
  };

  const changeTrack = () => {
    setTrack(track === 'audit'? 'certificate' : 'audit');
  };

  const initiateApplication = () => {
    const { salutation, firstName, lastName, syncName } =  identification;
    const data = { track, salutation, firstName, lastName, syncName, waitlisted: event.course.waitlisted };
    setProcessing(true);
    courseRegistrationAPI.createLiveCourseRegistration(slug, data);
  };

  redirectToApplicationForm = () => {
    const { email, firstName, lastName, salutation } = identification;
    const { course: { auditApplication, certificateApplication } } = event;
    const params = `email=${email}&firstName=${firstName}&lastName=${lastName}&salutation=${salutation}&newMember=${newMember}`;
    const applicationUrl = track === 'audit'? auditApplication : certificateApplication;
    window.location = `${applicationUrl}?${params}`;
  };

  const clearPopMessage = () => {
    setStep('error');
    setError(false);
    navigate(urls.event.replace(':slug', slug));
  };

  return (
    <div className="MainContent-container CourseApplication">
      { loading && <LoadingMessage message="Loading..." /> }
      { processing && <LoadingMessage message="Opening application..." /> }

      { !loading && !error && isNotEmpty(event) && step === 'identification' &&
        <ApplicationIdentification event={event} onContinue={storeApplicantIdentification} />
      }

      { !processing && step === 'warning' &&
        <ApplicationWarning event={event} track={track} changeTrack={changeTrack} 
          onContinue={initiateApplication} />
      }

      { error && alreadyApplied &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>
            You have already entered an application. If you need to update or withdraw your application 
            please contact us.
          </p>
        </ContactErrorPopMessage>
      }

      { error && registrationError &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>
            Your application could not be successfully processed.
          </p>
        </ContactErrorPopMessage>
      }

      { error && !alreadyApplied && !registrationError &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>This application link is not valid or the application window has closed.</p>
        </ContactErrorPopMessage>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    events: state.events,
    user: state.user,
    courseRegistration: state.courseRegistration
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    eventsAPI: bindActionCreators(eventsAPI, dispatch),
    courseRegistrationAPI: bindActionCreators(courseRegistrationAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseApplication);
