import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import stateConfig from '../../../../config/state';
import urls from '../../../../config/urls';
import { listCourseParts, isPartOpen } from '../../../../utils/course';
import { slugify } from '../../../../utils/utils';
import validator from '../../../../utils/validator';
const { isEmpty } = validator;


function CourseNextAction({ course, transcript, courseComplete, courseProgress }) {
  const [progress, setProgress] = useState(null);
  const [next, setNext] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const stateId = `${stateConfig.keys.PROGRESS}.${course.id}`;
    if(isEmpty(progress) && !courseComplete) {
      const data = courseProgress.byId[stateId];
      if(data && !data.error) {
        const progress = data && !data.error? data.progress : [];
        let parts = listCourseParts(course, transcript.track);
        parts = parts.filter(part => (part.type === 'assessment' || part.required));

        setProgress(progress);
        if(progress.length === 0) {
          setNext({ ...parts[0], action: 'start-course' });
          return;
        }

        for(let i=0; i<parts.length; i++) {
          const part = parts[i];
          const match = progress.find(item => item.partId === part.id);
          if(isEmpty(match) || match.status === 'started') {
            const openObj = isPartOpen(part.opening);
            const nObj = openObj.isOpen? { ...part, action: 'start-part' } : { ...part, action: 'waiting', when: openObj.str };
            setNext(nObj);
            return;
          }
        }
      }
    }
  }, [progress, courseProgress.ids, courseProgress.byId, course, transcript, courseComplete]);

  const headingText = () => {
    switch(next.action) {
      case 'start-course':
        return 'Get Started With Your Course';
      default:
        return 'Next Up With Your Course';
    }
  };

  const navToPart = () => {
    let url = next.type === 'topic'? 
      urls.courseTopic.replace(':topicSlug', `topic-${next.id}-${slugify(next.title)}`) : 
      urls.courseAssessment.replace(':assessmentSlug', `assessment-${next.id}-${slugify(next.title)}?jump=true`);
    url = url.replace(':slug', course.slug.replace('course-', ''));
    navigate(url);
  };

  // This is a temporary function to be removed after the 2024 Magdala Fall Semester
  const navToVSICanvas = () => {
    window.location.href = urls.canvasVSI; 
  }

  return (
    <Fragment>
      { !isEmpty(progress) && !isEmpty(next) &&
        <div className="CourseNextAction">
          <div className="next-content">
            <h4>{headingText()}</h4>
            <p>{next.title}</p>
          </div>
          { next.action !== 'waiting' &&
            <div className="next-action">
              <Button variant="contained" color="secondary" onClick={course.slug === 'course-magdala-apostolate-latin-101'? navToVSICanvas : navToPart}>
                { next.action === 'start-course'? 'Start Now' : 'Continue Now' }
              </Button>
            </div>
          }
          { next.action === 'waiting' &&
            <p className="waiting">Available {next.when}</p>
          }
        </div>
      }
    </Fragment>
  );
}

CourseNextAction.propTypes = {
  course: PropTypes.object,
  transcript: PropTypes.object,
  courseComplete: PropTypes.bool
};

function mapStateToProps(state) {
  return { 
    courseProgress: state.courseProgress
  };
}

export default connect(mapStateToProps)(CourseNextAction);
