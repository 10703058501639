import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as behaviorAPI from '../../../../actions/behavior';
import VimeoVideoEmbed from '../../media/VimeoVideoEmbed';
import VideoPromo from '../../../common/media/VideoPromo';
import config from '../../../../config/config';
import stateConfig from '../../../../config/state';
import validator from '../../../../utils/validator';
const { isEmpty } = validator;


function CourseVideo({ course, topic, courseComplete, onStatusChange, watchedIds, watchedById, courseProgress, behaviorAPI }) {
  const [load, setLoad] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [topicId, setTopicId] = useState(null);
  const [watchedStateId, setWatchedStateId] = useState(null);
  const [watched, setWatched] = useState(null);
  const [progressData, setProgressData] = useState(null);
  const [lastLogged, setLastLogged] = useState(0);
  const [progressStatus, setProgressStatus] = useState(null);
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    if(topicId !== topic.id) {
      setLoad(true);
      setTopicId(topic.id);
      setVideoLoaded(false);
    }
  }, [topic.id, topicId]);

  useEffect(() => {
    if(load && course && topic) {
      const video = topic.content;
      behaviorAPI.getWatchedMedia(course.id, video.id);
      setLoad(false);
      setWatchedStateId(`${stateConfig.keys.WATCHED}.${course.id}.${video.id}`);
      setWatched(null);
    }
  }, [load, course, topic, behaviorAPI]);

  useEffect(() => {
    if(!watched && watchedIds.includes(watchedStateId)) {
      const data = watchedById[watchedStateId];
      const obj = data && data.lastWatchPoint? data : { lastWatchPoint : 0 };
      setWatched(obj);
    }
  }, [watchedIds, watchedById, watched, watchedStateId]);

  useEffect(() => {
    if(isEmpty(progressStatus)) {
      const stateId = `${stateConfig.keys.PROGRESS}.${course.id}`;
      if(courseProgress.ids.includes(stateId)) {
        const data = courseProgress.byId[stateId];
        const progressData = data && !data.error? data.progress : [];
        const progress = progressData.find(item => item.partId === topic.id);
        if(progress) {
          setProgressStatus(progress.status);
          if(progress.status === 'complete') {
            setComplete(true);
          }
        }
      }
    }
  }, [progressStatus, courseProgress.ids, courseProgress.byId, course, topic]);

  useEffect(() => {
    if(progressData) {
      const { videoId, type, percent, time } = progressData;
      const progress = parseInt(percent * 100);
      const lastWatchPoint = parseInt(time);
      if(percent && (time - lastLogged >= config.courseWatchLogInterval || time < lastLogged)) {
        behaviorAPI.logWatched({ 
          eventId: course.id, 
          eventType: 'Course', 
          mediaId: videoId, 
          mediaType: type, 
          progress, 
          lastWatchPoint,
          data: {
            slug: course.slug,
            topicId: topic.id,
            courseTitle: course.title,
            topicTitle: topic.title
          }
        });
        setLastLogged(time);
      }
      behaviorAPI.publishWatchedCheckpoint(progressData);

      if(isEmpty(progressStatus)) {
        onStatusChange('started', 'video');
        setProgressStatus('started');
      } else if(progressStatus === 'started' && progress >= config.courseCompletedWatchingPercentage) {
        onStatusChange('complete', 'video');
        setProgressStatus('complete');
        setComplete(true);
      }
    }
  }, [progressData, lastLogged, progressStatus, course, topic, onStatusChange, behaviorAPI]);

  const onProgress = data => {
    setProgressData(data);
    if(!videoLoaded) {
      setVideoLoaded(true);
    }
  };

  const onEnded = data => {
    if(!complete) {
      onStatusChange('complete', 'video');
      setProgressStatus('complete');
      setComplete(true);
    }
  };

  return (
    <div className="CourseVideo">
      { !isEmpty(watched) && !isEmpty(topic.content) && !isEmpty(topic.content.id) &&
        <Fragment>
          <VimeoVideoEmbed 
            video={topic.content} 
            meta={{ title: topic.title }} 
            onProgress={onProgress} 
            onEnded={onEnded}
            startTime={watched.lastWatchPoint}
          />
          <VideoPromo 
            isComplete={complete} 
            contentType="course" 
          />
        </Fragment>
      }
      { videoLoaded && (complete || courseComplete) &&
        <p className="status-message">You have completed this video</p>
      }
    </div>
  );
}

CourseVideo.propTypes = {
  course: PropTypes.object,
  topic: PropTypes.object,
  courseComplete: PropTypes.bool,
  onStatusChange: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    watchedIds: state.behavior.watchedIds,
    watchedById: state.behavior.watchedById,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    behaviorAPI: bindActionCreators(behaviorAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseVideo);
