import { TAXONOMY_REQUEST, TAXONOMY_COMPLETE, TAXONOMY_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function taxonomyReducer(state = initialState.taxonomy, action) {  
  const { type, data, error } = action;
  let responseCode, status = stateConfig.responseStatus;

  switch(type) {
    case TAXONOMY_REQUEST:
      return Object.assign({}, initialState.taxonomy);
    case TAXONOMY_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, results: data });
    case TAXONOMY_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default taxonomyReducer;