import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


function StickySystemPopup({ children, className, open, actions, onClose }) {
  return (
    <div className={`StickySystemPopup ${className} popup-${open? 'open' : 'hidden'}`}>
      <div className="close">
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="content-main">
        {children}
      </div>
      <div className="content-actions">
        {actions}
      </div>
    </div>
  );
}

StickySystemPopup.defaultProps = {
  className: ''
};

StickySystemPopup.propTypes = {
  open: PropTypes.bool,
  actions: PropTypes.object,
  onClose: PropTypes.func
};

export default StickySystemPopup;