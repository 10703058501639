import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as authAPI from '../../actions/authentication';
import LoadingMessage from '../common/messages/LoadingMessage';
import { resetViewToTopOfPage } from '../../utils/utils';
import stateConfig from '../../config/state';
import urls from '../../config/urls';
import validator from '../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Logout({ user, authentication, authAPI }) {
  const [init, setInit] = useState(false);
  const [submission, setSubmission] = useState(false);
  const { responseStatus: authResponseStatus } = authentication;
  
  useEffect(() => {
    if(!init) {
      resetViewToTopOfPage();
      if(isNotEmpty(user.email)) {
        setSubmission(true);
        authAPI.logout();
      }
      setInit(true);
    }
  }, [init, user.email, authAPI]);

  useEffect(() => {
    if(init && !submission && isNotEmpty(user.email)) {
      authAPI.logout();
    }
  }, [init, submission, user.email, authAPI]);

  useEffect(() => {
    if(submission) {
      if(isEmpty(user.email) || authResponseStatus === stateConfig.authStatus.NOT_AUTHENTICATED) {
        window.location.href = urls.logoutCompleted;
      }
    }
  }, [submission, authResponseStatus, user.email, authAPI]);

  return (
    <div id="Logout">
      { submission && <LoadingMessage message="Logging out..." /> }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    user: state.user, 
    authentication: state.authentication 
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authAPI: bindActionCreators(authAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);