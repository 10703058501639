import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import urls from '../../../../config/urls';


function QuarterDisplay(props) {
  const { description, events } = props.curriculum[props.quarter];
  return (
    <div className={`${props.quarter} tab-content`}>
      <p className="quarter-description">{description}</p>
      <div className="events">
        {
          events.map((event, index) => {
            const title = `${event.title}${event.subtitle? ': '+event.subtitle : ''}`;
            const { status, datetime } = event.details || {};
            return (
              <div key={`event-${index}`} className="event">
                <h3>{event.category}</h3>
                <p className="title">
                  { event.slug? <Link to={urls.event.replace(':slug', event.slug)}>{title}</Link> : title }
                </p>
                <div className="status">
                  { status && status !== 'Completed' &&
                    <Fragment>
                      { status === 'Running Now'? 
                        <p>Running Now</p> :
                        <p>Starting on {datetime.date}</p>
                      }
                    </Fragment>
                  }
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

QuarterDisplay.propTypes = {
  quarter: PropTypes.string,
  curriculum: PropTypes.object
};

export default QuarterDisplay;