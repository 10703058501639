import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useAnalytics from '../../hooks/useAnalytics';
import * as authAPI from '../../actions/authentication';
import Form from '../common/form/Form';
import FormWrapper from '../common/form/FormWrapper';
import TextField from '@mui/material/TextField';
import Icon, { IconTypes } from '../common/Icon';
import LinkButton from '../common/LinkButton';
import PopMessage from '../common/messages/PopMessage';
import ContactErrorPopMessage from '../common/messages/ContactErrorPopMessage';
import Heading from '../common/Heading';
import validator from '../../utils/validator';
import urls from '../../config/urls';
import { resetViewToTopOfPage } from '../../utils/utils';
import stateConfig from '../../config/state';


function PasswordResetRequest({ authentication, authAPI }) {
  const [submitted, setSubmitted] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [complete, setComplete] = useState(false);
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const { responseStatus: authResponseStatus } = authentication;
  useAnalytics('Password Reset');
  
  useEffect(() => {
    resetViewToTopOfPage();
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!validate()) {
      return;
    }
    authAPI.sendPasswordResetToken(email);
    setSubmitted(true);
  }

  const validate = () => {
    const validationErrors = validator.validate({ email }, [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'email' }
    ]);
    
    if(validationErrors) {
      setErrors(validationErrors);
      return false
    }

    return true;
  }

  useEffect(() => {
    if(submitted) {
      if(authResponseStatus === stateConfig.authStatus.ERROR) {
        setDisplayError(true);
      }
  
      if(authResponseStatus === stateConfig.authStatus.RESET_LINK_SENT) {
        setComplete(true);
      }
    }
  }, [submitted, authResponseStatus]);

  const clearPopMessage = () => {
    setDisplayError(false);
    setComplete(false);
  }

  return (
    <div id="PasswordResetRequest">
      <FormWrapper>
        <Icon type={IconTypes.Password} avatar={true} />
        <Heading>Password Reset Request</Heading>
        <p className="PasswordResetRequest-instructions">
          Enter your registered email address and we will send you a link to reset your password.
        </p>
        <Form id='PasswordResetRequest-form' onSubmit={handleSubmit} autoComplete="off">
          <TextField
            className="FormField-control full first"
            name="email"
            label="Email *"
            placeholder='Email'
            onChange={handleEmailChange}
            helperText={validator.message(errors['email'], 'email')}
            error={validator.isDefined(errors['email'])}
            value={email}
            variant="standard"
          />
        </Form>
      </FormWrapper>

      <div className="PasswordResetRequest-contact">
        <p>Having trouble resetting your password?</p>
        <LinkButton label="Contact Us" to={urls.contactUs} color="secondary" external={true} />
      </div>

      { displayError &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>Your password reset request could not be completed.</p>
        </ContactErrorPopMessage>
      }

      { complete &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>
            <strong>Success!</strong><br /><br />
            To confirm your identity, we’ve sent the link to reset your password to the email address you provided.&nbsp;
            Please open your email and click the link inside to initiate your password reset.
          </p>
        </PopMessage>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authAPI: bindActionCreators(authAPI, dispatch) 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetRequest);
