import axios from 'axios';
import querystring from 'qs';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;
const defaultConfig = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

export async function validateShareToken(token) {
  return await axios.get(`${urls.hubUIApiRoot}/member/share/link/${token}`);
}

export async function shareEventLink(data) {
  const params = querystring.stringify(data);
  return await axios.post(`${urls.hubUIApiRoot}/member/share/link`, params, defaultConfig);
}

export async function getEventShareLink(slug) {
  return await axios.get(`${urls.hubUIApiRoot}/member/share/link/event/${slug}`);
}
