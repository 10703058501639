import React from 'react';
import Icon, { IconTypes } from '../../common/Icon';
import config from '../../../config/config';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';


export default function MemberBenefits(props) {
  return (
    <div className="MemberBenefits-marketing">
      <Card className="MemberBenefits-benefit">
        <CardContent className="MemberBenefits-benefitIcon">
          <Icon type={IconTypes.OnDemand} size="4x" />
        </CardContent>
        <CardContent className="MemberBenefits-benefitDescription">
          <h3><Icon type={IconTypes.OnDemand} />{config.libraryHoursOfContent}+ hours of audio &amp; video</h3>
          <p>Get instant <strong>on demand</strong> access to classes taught by Dr. Alice von Hildebrand, Tim Staples, Fr. Scalia, and many more!</p>
        </CardContent>
      </Card>

      <Card className="MemberBenefits-benefit">
        <CardContent className="MemberBenefits-benefitIcon">
          <Icon type={IconTypes.Written} size="4x" />
        </CardContent>
        <CardContent className="MemberBenefits-benefitDescription">
          <h3><Icon type={IconTypes.Written} />Tons of written resources</h3>
          <p>Supplement your learning with written resources and study questions provided for lectures.</p>
        </CardContent>
      </Card>

      <Card className="MemberBenefits-benefit">
        <CardContent className="MemberBenefits-benefitIcon">
          <Icon type={IconTypes.Webinar} size="4x" />
        </CardContent>
        <CardContent className="MemberBenefits-benefitDescription">
          <h3><Icon type={IconTypes.Webinar} />Live online classroom access</h3>
          <p>Interact and engage in real time with master teachers, 100% faithful to the magesterium!</p>
        </CardContent>
      </Card>
    </div>
  );
}