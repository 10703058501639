import React from 'react';
import { useNavigate } from 'react-router-dom';
import urls from '../../../config/urls';
import Button from '@mui/material/Button';

function Banner(props) {
  const navigate = useNavigate();

  const nav = url => () => {
    navigate(url);
  }

  return (
    <div className="Home-banner">
      <div className="feature">
        <img src="https://instituteofcatholicculture.net/wp-content/uploads/2023/08/Main-header_Rome_resized.jpg" alt="Institute educational event" />
      </div>
      <div className="tagline">
        <h1>Know the Faith. &nbsp;&nbsp;Love the Faith. &nbsp;&nbsp;Live the Faith.</h1>
        <h2>Be transformed through an authentic Catholic education</h2>
        <Button variant="outlined" onClick={nav(urls.memberRegistration)}>Get Started</Button>
      </div>
    </div>
  );
}

export default Banner;