import React from 'react';
import PropTypes from 'prop-types';
import VideoEmbed from '../../../common/media/VideoEmbed';


function ClassroomVideo({ media, startTime, onProgress }) {
  return (
    <div className="ClassroomVideo">
      <VideoEmbed video={media} onProgress={onProgress} startTime={startTime} />
    </div>
  );
}

ClassroomVideo.defaultProps = {
  startTime: 0
};

ClassroomVideo.propTypes = {
  media: PropTypes.object,
  startTime: PropTypes.number,
  onProgress: PropTypes.func
};

export default ClassroomVideo;