import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import Event from '../../data/Event';
import Classroom from './content/Classroom';
import config from '../../../config/config';
import urls from '../../../config/urls';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;

function EventClassroom() {
  const [slug, setSlug] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [mediaId, setMediaId] = useState(null);
  const [baseURL, setBaseURL] = useState(null);
  const [mediaChanged, setMediaChanged] = useState(false);
  const [mediaChangeDone, setMediaChangeDone] = useState(true);
  const [event, setEvent] = useState(null);
  const [error, setError] = useState(false);
  const location = useLocation();
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(mediaChanged && mediaId === null && mediaType === null) {
      const { pathname } = location;
      const pathParts = pathname.split('/');
      setMediaType(pathParts[3]);
      setMediaId(pathParts[4]);
      setMediaChanged(false);
      setMediaChangeDone(true);
    }
  }, [location, mediaChanged, mediaId, mediaType]);

  useEffect(() => {
    if(mediaChangeDone && mediaId !== null && mediaType !== null) {
      setMediaChangeDone(true);
    }
  }, [mediaChangeDone, mediaId, mediaType]);

  const onEventLoaded = (data) => {
    if(isNotEmpty(data.error)) {
      setError(true);
    } else {
      setEvent(data.event);
    }
  };

  initialize = () => {
    const { pathname } = location;
    const pathParts = pathname.split('/');
    const slug = pathParts[2];
    setSlug(slug);
    setMediaType(pathParts[3]);
    setMediaId(pathParts[4]);
    setBaseURL(urls.eventsClassroomBase.replace(':slug', slug));
  };

  const onPathChange = () => {
    setMediaChanged(true);
    setMediaType(null);
    setMediaId(null);
  };

  const clearPopMessage = () => {
    setError(false);
  };

  const reloadingMedia = mediaChanged || !mediaChangeDone;

  return (
    <div className="EventClassroom">
      { isNotEmpty(slug) && <Event slug={slug} onEventLoaded={onEventLoaded} /> }

      { (reloadingMedia || isEmpty(event)) && !error && <LoadingMessage message="Loading..." /> }

      { error &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>We could not load the requested media.</p>
        </PopMessage>
      }
      
      { !reloadingMedia && isNotEmpty(event) && 
        <Classroom 
          content={event} 
          error={isNotEmpty(error)} 
          mediaType={mediaType}
          mediaId={mediaId} 
          baseURL={baseURL}
          onPathChange={onPathChange}
        /> 
      }
    </div>
  );
}

export default EventClassroom;