import React, { useState, useEffect, Fragment } from 'react'; 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../hooks/useAnalytics';
import Banner from './common/Banner'; 
import AboutSection from './Landing/AboutSection';
import ActionsSection from './Landing/ActionsSection';
import CommunitySection from './Landing/CommunitySection';
import { resetViewToTopOfPage } from '../../utils/utils';
import SEOMeta from '../common/SEOMeta';
import * as systemContentAPI from '../../actions/content/system'; 
import LoadingMessage from '../common/messages/LoadingMessage';
import PopMessage from '../common/messages/PopMessage'; 
import stateConfig from '../../config/state'; 
import urls from '../../config/urls';

function MagdalaLanding({ systemContent, systemContentAPI, user }) {
  const [init, setInit] = useState(false); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [content, setContent] = useState(null);
  const [userDataCheckedOnInit] = useState(user.userDataChecked); 
  const navigate = useNavigate();
  useAnalytics('Magdala Landing');

  useEffect(() => {
    /* 
      We only want this redirect to occur when the user data has not yet been retrieved
      (as on site load) and there is an identified/authenticated magdala user
      1. Navigating from another part of the site (i.e., user data already checked) - no redirect
      2. This is the first page loaded (i.e., user data not checked)
        - redirect if user check produces an identified/authenticated magdala user
        - no redirect if user check produces no user or a non-magdala user
    */
    if(!userDataCheckedOnInit && Boolean(user.type === 'magdala-member')) {
      navigate(urls.magdalaCoursesPage); 
    }
  }, [userDataCheckedOnInit, user.type, navigate]); 
  
  useEffect(() => {
    if(!init) {
      resetViewToTopOfPage();
      const magdalaContent = systemContent.magdala; 
      if(Boolean(magdalaContent) && Boolean(magdalaContent.settings)) {
        setContent(parseContent(magdalaContent.settings)); 
        setLoading(false);
      } else {
        systemContentAPI.getMagdalaContent(); 
      }
      
      setInit(true); 
    }
  },[init, systemContent, systemContentAPI]); 
  
  useEffect(() => {
    const magdalaContent = systemContent.magdala; 
    if(loading && !Boolean(content) && Boolean(magdalaContent)) {
      setLoading(false); 
      if(Boolean(magdalaContent.error) || !Boolean(magdalaContent.settings)) {
        setError(true);
      } else {
        setContent(parseContent(magdalaContent.settings));
      }
    }
  }, [loading, content, systemContent.magdala]);

  const parseContent = (settings) => {
    return settings.reduce((obj, setting) => {
      const { key, type, value } = setting;
      obj[key] = { type, value };
      return obj;
    }, {});
  };

  const clearPopMessage = () => {
    setError(false); 
  }

  return (
    <div>
      { loading && !error && <div className='loadMessage'><LoadingMessage message="Loading..." /></div>}
      { !loading && error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage} type={stateConfig.messageTypes.ERROR}>
          <p>Failed to retrieve page content. Please try refreshing the page. If this issue persists, please contact ICC staff. Thank you!</p>
        </PopMessage>
      }
      { !loading && Boolean(content) &&
      <Fragment>
        <SEOMeta
          title="About the Magdala Apostolate"
          description="The Magdala Apostolate is an outreach project of the Institute of Catholic Culture and is dedicated to providing sound doctrinal formation—both initial and ongoing—for religious sisters and novices, in accord with the Church’s call for a new evangelization. Each term, we offer semester-long courses in the faith to any religious sister or community who applies."
        />
        <Banner />
        <div className="Magdala-Landing">
          <AboutSection content={content} />
          <ActionsSection content={content} />
          <CommunitySection content={content} />
        </div>
      </Fragment>
    }
    </div>
  )
}

function mapStateToProps(state) {
  return { 
    systemContent: state.systemContent,
    user: state.user
  };
}
 
function mapDispatchToProps(dispatch) {
  return { 
    systemContentAPI: bindActionCreators(systemContentAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MagdalaLanding); 