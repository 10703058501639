import React, { useEffect, useState, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import querystring from 'qs';
import Button from '@mui/material/Button';
import * as coursesAPI from '../../../../actions/content/courses';
import * as progressAPI from '../../../../actions/progress';
import CourseQuestions from './CourseQuestions';
import CourseAssessmentResults from './CourseAssessmentResults';
import CourseAssessmentHistory from './CourseAssessmentHistory';
import ShareMeta from '../../../common/ShareMeta';
import PopMessage from '../../../common/messages/PopMessage';
import LoadingMessage from '../../../common/messages/LoadingMessage';
import stateConfig from '../../../../config/state';
import config from '../../../../config/config';
import urls from '../../../../config/urls';
import { isPartAvailable, isPartOpen } from '../../../../utils/course';
import validator from '../../../../utils/validator';
import CourseTitle from '../detail/CourseTitle';
const { isEmpty, isNotEmpty, isDefined, isNotDefined } = validator;


function CourseAssessment({ course, transcript, settings, courseComplete, courseLocked, onAssessmentChange, onProgressUpdated, navigation, courses, courseProgress, coursesAPI, progressAPI }) {
  const [assessmentLoaded, setAssessmentLoaded] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [prerequisitesChecked, setPrerequisitesChecked] = useState(false);
  const [prerequisitesSatisfied, setPrerequisitesSatisfied] = useState(false);
  const [started, setStarted] = useState(0);
  const [assessmentResult, setAssessmentResult] = useState(null);
  const [dateOpen, setDateOpen] = useState(null);
  const [assessmentStateId, setAssessmentStateId] = useState(null);
  const [assessmentId, setAssessmentId] = useState(null);
  const [assessment, setAssessment] = useState(null);
  const [availability, setAvailability] = useState(null);
  const [isExam, setIsExam] = useState(false);
  const [historyStateId, setHistoryStateId] = useState(null);
  const [history, setHistory] = useState(null);
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let retrieveAssessment = config.emptyFn;
  let checkIsExam = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(!isEmpty(assessment) && settings.assessmentId !== assessmentId) {
      // new assessment selected from the outline, load new assessment
      setAssessment(null);
      setAvailability(null);
      setHistory(null);
      setError(null);
      setAssessmentLoaded(false);
      setHistoryLoading(false);
      setPrerequisitesChecked(false);
      setPrerequisitesSatisfied(false);
      setHistoryStateId(null);
      setAssessmentResult(null);
      setStarted(0);
      setInProgress(null);
      setAssessmentId(settings.assessmentId);
      setAssessmentStateId(settings.assessmentStateId);
      retrieveAssessment(settings.assessmentId, settings.assessmentStateId);
    }
  }, [settings.assessmentId, settings.assessmentStateId, assessment, assessmentId, location.pathname, retrieveAssessment]);

  useEffect(() => {
    if(!assessmentLoaded && isEmpty(assessment) && courses.ids.includes(assessmentStateId)) {
      const data = courses.byId[assessmentStateId];
      if(data) {
        if(data.error) {
          setError({ display: true });
        } else {
          const lesson = course.lessons.find(lesson => {
            return lesson.parts.find(part => part.id === assessmentId);
          });
          const lessonId = isEmpty(lesson)? 'exam' : lesson.id;
          const openObj = isPartOpen(data.opening);
          onAssessmentChange(lessonId, assessmentId);
          setAssessment(data);
          setDateOpen(openObj);
          setAssessmentLoaded(true);
          setHistoryStateId(`${stateConfig.keys.PROGRESS_HISTORY}.${data.id}`);
        }
      }
    }
  }, [assessmentLoaded, assessment, assessmentId, assessmentStateId, courses.ids, courses.byId, course, onAssessmentChange, progressAPI]);

  useEffect(() => {
    if(assessmentLoaded && isNotEmpty(assessment)) {
      progressAPI.getAssessmentHistory(course.id, assessment.id);
      setHistoryLoading(true);
    }
  }, [assessmentLoaded, assessment, course.id, progressAPI]);

  useEffect(() => {
    if(historyLoading && isEmpty(history) && courseProgress.ids.includes(historyStateId)) {
      const data = courseProgress.byId[historyStateId];
      if(data) {
        if(data.error) {
          setHistory({ records: [] });
          setHistoryLoading(false);
          setInProgress(null);
        } else {
          setHistory(data);
          setHistoryLoading(false);
          setInProgress(data.records.find(record => record.status === 'started'));
        }
      }
    }
  }, [historyLoading, history, historyStateId, courseProgress]);

  useEffect(() => {
    if(isNotDefined(availability) && isNotEmpty(assessmentId)) {
      if(courseComplete) {
        setIsExam(checkIsExam());
        setAvailability({ isAccessible: true });
      } else {
        const data = courseProgress.byId[`${stateConfig.keys.PROGRESS}.${course.id}`];
        if(data && !data.error) {
          const progressData = data && !data.error? data.progress : [];
          const isAccessible = isPartAvailable(assessmentId, course, transcript, progressData);
          setIsExam(checkIsExam());
          setAvailability({ isAccessible });
        }
      }
    }
  }, [availability, assessmentId, courseComplete, courseProgress.ids, courseProgress.byId, course, transcript, checkIsExam]);

  useEffect(() => {
    if(!prerequisitesChecked && !isEmpty(assessment)) {
      if(assessment.prerequisites.length === 0) {
        setPrerequisitesSatisfied(true);
        setPrerequisitesChecked(true);
        return;
      }

      const courseStateId = `${stateConfig.keys.PROGRESS}.${course.id}`;
      if(courseProgress.ids.includes(courseStateId)) {
        const data = courseProgress.byId[courseStateId];
        if(!data || data.error) {
          setPrerequisitesSatisfied(true);
          setPrerequisitesChecked(true);
        } else {
          // build a list of course assessments
          let parts = course.lessons.reduce((arr, lesson) => arr.concat(lesson.parts), []);
          parts = parts.concat(course.exams);
          let aParts = parts.filter(part => part.type === 'assessment');
          // filter parts to those which have been completed
          let completed = aParts.filter(part => {
            const match = data.progress.find(item => item.partId === part.id && item.status === 'complete');
            return !isEmpty(match);
          });
          // determine if any prerequisites missing from completed list
          const incomplete = assessment.prerequisites.filter(prId => {
            const match = completed.find(part => parseInt(part.ldId) === parseInt(prId));
            return isEmpty(match);
          });
          setPrerequisitesSatisfied((incomplete.length === 0 || transcript.status === 'complete'));
          setPrerequisitesChecked(true);
        }
      }
    }
  }, [prerequisitesChecked, course, transcript, assessment, courseProgress.ids, courseProgress.byId]);

  initialize = () => {
    const { assessmentId, assessmentStateId } = settings;
    setAssessmentId(assessmentId);
    setAssessmentStateId(assessmentStateId);
    retrieveAssessment(assessmentId, assessmentStateId);
    const params = querystring.parse(location.search.substring(1));
    if(params.jump === 'true') {
      setStarted(Date.now());
    }
  };

  retrieveAssessment = useCallback((id, stateId) => {
    const data = courses.byId[stateId];
    if(!data || data.error) {
      coursesAPI.getCourseAssessmentById(id);
    }
  }, [courses.byId, coursesAPI]);

  const onRestartAssessment = () => {
    setAssessmentResult(null);
    setInProgress(null);
    setError(null);
    setStarted(Date.now());
  };

  const onAssessmentComplete = result => {
    setAssessmentResult(result);
    onProgressUpdated();
  };

  checkIsExam = useCallback(() => {
    // Determine whether to treat this assessment as an exam, do not do so if audit course as exams are not applicable to audit courses
    if(course.exams && course.exams.length > 0 && isNotEmpty(assessmentId) && transcript.track === 'certificate') {
      const match = course.exams.find(exam => exam.id === assessmentId);
      if(isDefined(match)) {
        return true;
      }
    }
    return false;
  }, [course, transcript, assessmentId]);

  const clearPopMessage = () => {
    setError({ display: false });
  };

  if(isDefined(assessment) && isDefined(availability) && !availability.isAccessible && !isExam) {
    // redirect
    navigate(urls.course.replace(':slug', settings.slug.replace('course-', '')));
  }

  return (
    <div className={`CourseAssessment-main ${(started !== 0 && isEmpty(assessmentResult))? 'in-progress' : 'not-in-progress'}`}>
      { (isNotDefined(assessment) || isNotDefined(availability)) && !prerequisitesChecked && isNotDefined(error) &&
        <div className="loading">
          <LoadingMessage message="Loading..." />
        </div>
      }
      { isDefined(error) && error.display &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>We could not load this resource.</p>
        </PopMessage>
      }
      { isDefined(assessment) && isDefined(history) && prerequisitesChecked && isDefined(availability) && (availability.isAccessible || isExam) &&
        <Fragment>
          <div className="CourseAssessment-heading">
            <ShareMeta title={`${course.title}: ${assessment.title}`} excerpt={isNotEmpty(course.excerpt)? course.excerpt : ''} image={course.thumbnail} />
            <CourseTitle title={course.title} subtitle={assessment.title} />
          </div>
          { !dateOpen.isOpen &&
            <div className="CourseAssessment-open">
              <p className="message">This assessment is not open yet.</p>
              <p className="date">
                {`Available on ${dateOpen.str}`}
              </p>
            </div>
          }
          { prerequisitesSatisfied && started === 0 && dateOpen.isOpen && history && history.records.length > 0 &&
            <CourseAssessmentHistory assessment={assessment} records={history.records} />
          }
          { !courseLocked && prerequisitesSatisfied && started === 0 && dateOpen.isOpen && 
            <div className="CourseAssessment-initial">
              <Button variant="contained" color="primary" onClick={() => setStarted(Date.now())}>
                { isEmpty(inProgress)? 'Start Assessment' : 'Continue Assessment' }
              </Button>
            </div>
          }
          { !courseLocked && !prerequisitesSatisfied && started === 0 && dateOpen.isOpen && 
            <div className="status-message">
              All prerequisite assessments must be completed prior to taking this assessment.
            </div>
          }
          { courseLocked &&
            <div className="status-message">
              This assessment is closed.
            </div>
          }
          { started !== 0 && isEmpty(assessmentResult) &&
            <CourseQuestions 
              courseId={course.id}
              assessment={assessment} 
              started={started}
              inProgress={inProgress}
              onComplete={onAssessmentComplete}
            />
          }
          { started !== 0 && !isEmpty(assessmentResult) &&
            <CourseAssessmentResults 
              assessment={assessment}
              results={assessmentResult} 
              onRestart={onRestartAssessment}
            />
          }
        </Fragment>
      }
      {navigation}
      <div className="Course-divider">
        <hr />
      </div>
    </div>
  );
}

CourseAssessment.propTypes = {
  course: PropTypes.object,
  transcript: PropTypes.object,
  settings: PropTypes.object,
  courseComplete: PropTypes.bool,
  courseLocked: PropTypes.bool,
  onAssessmentChange: PropTypes.func,
  onProgressUpdated: PropTypes.func,
  navigation: PropTypes.any
};

function mapStateToProps(state) {
  return { 
    courses: state.courses,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    coursesAPI: bindActionCreators(coursesAPI, dispatch),
    progressAPI: bindActionCreators(progressAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseAssessment);