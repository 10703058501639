import axios from 'axios';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

export async function getEmailSettings(email) {
  return await axios.post(`${urls.hubUIApiRoot}/member/notifications/channels/email`, { email });
}

export async function updateEmailSettings(email, settings) {
  return await axios.post(`${urls.hubUIApiRoot}/member/notifications/channels/email/update`, { email, settings });
}