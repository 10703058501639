import { ANNOTATION_LOAD_START, ANNOTATION_LOAD_COMPLETE, ANNOTATION_LOAD_ERR,
         ANNOTATION_ADD_REQUEST, ANNOTATION_ADD_COMPLETE, ANNOTATION_ADD_ERR, ANNOTATION_DELETE_REQUEST, ANNOTATION_DELETE_COMPLETE, ANNOTATION_DELETE_ERR, ANNOTATIONS_PRINT_REQUEST, ANNOTATIONS_PRINT_COMPLETE, ANNOTATIONS_PRINT_ERR } from './actionTypes';
import * as annotations from '../api/annotations';
import stateConfig from '../config/state';
import errors from '../config/errors';

const listKey = stateConfig.keys.ANNOTATIONS;

function makeSlug(eventId, mediaType, mediaId) {
  return `event${eventId}-${mediaType || 'event'}${mediaId || ''}`;
}

export function getEventAnnotations(eventId) {
  const slug = makeSlug(eventId);
  return async dispatch => {
    dispatch({ type: ANNOTATION_LOAD_START, key: listKey, objectId: slug });
    try {
      let result = await annotations.getEventAnnotations(eventId);
      dispatch({ type: ANNOTATION_LOAD_COMPLETE, data: result.data, key: listKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: ANNOTATION_LOAD_ERR, error: err, key: listKey, objectId: slug });
    }
  };
}

export function getMediaAnnotations(eventId, mediaType, mediaId) {
  const slug = makeSlug(eventId, mediaType, mediaId);
  return async dispatch => {
    dispatch({ type: ANNOTATION_LOAD_START, key: listKey, objectId: slug });
    try {
      let result = mediaType === 'video'?
        await annotations.getVideoAnnotations(eventId, mediaId) :
        await annotations.getAudioAnnotations(eventId, mediaId) ;
      dispatch({ type: ANNOTATION_LOAD_COMPLETE, data: result.data, key: listKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: ANNOTATION_LOAD_ERR, error: err, key: listKey, objectId: slug });
    }
  };
}

export function addAnnotation(data) {
  const { eventId, mediaId, mediaType } = data;
  const slug = makeSlug(eventId, mediaType, mediaId);
  return async dispatch => {
    dispatch({ type: ANNOTATION_ADD_REQUEST });
    try {
      let result = await annotations.addAnnotation(data);
      dispatch({ type: ANNOTATION_ADD_COMPLETE, data: result.data, key: listKey, objectId: slug });
    } catch(error) {
      dispatch({ type: ANNOTATION_ADD_ERR, error: errors.RequestFailed });
    }
  };
}

export function deleteAnnotation(data) {
  const { eventId, mediaId, mediaType } = data; 
  const slug = makeSlug(eventId, mediaType, mediaId); 
  return async dispatch => {
    dispatch({ type: ANNOTATION_DELETE_REQUEST }); 
    try {
      let result = await annotations.deleteAnnotation(data); 
      dispatch({ type: ANNOTATION_DELETE_COMPLETE, data: result.data, key: listKey, objectId: slug }); 
    } catch(error) {
      dispatch({ type: ANNOTATION_DELETE_ERR, error: errors.RequestFailed }); 
    }
  }; 
}

export function prepAnnotationsForPrint(data, printId) {
  return async dispatch => {
    dispatch({ type: ANNOTATIONS_PRINT_REQUEST }); 
    try {
      let result = await annotations.prepAnnotationsForPrint(data); 
      dispatch({ type: ANNOTATIONS_PRINT_COMPLETE, data: result.data, key: listKey, objectId: printId});
    } catch(error) {
      dispatch({ type: ANNOTATIONS_PRINT_ERR, error: errors.RequestFailed}); 
    }
  }
}