import React, { useState, useEffect, Fragment } from 'react'; 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useAnalytics from '../../hooks/useAnalytics';
import * as postAPI from '../../actions/content/posts'; 
import { resetViewToTopOfPage } from '../../utils/utils';
import SEOMeta from '../common/SEOMeta';
import PopMessage from '../common/messages/PopMessage';
import LoadingMessage from '../common/messages/LoadingMessage';
import stateConfig from '../../config/state'; 
import config from '../../config/config'; 
import Banner from './common/Banner';
import MagdalaToolbar from '../layout/MagdalaToolbar'; 
import Heading from './Curriculum/Heading'; 
import CategoryNavigation from './Curriculum/CategoryNavigation';
import CategoryDisplay from './Curriculum/CategoryDisplay';
import validator from '../../utils/validator'; 
import MobileNav from './common/MobileNav';
const { isEmpty, isNotEmpty } = validator; 

function MagdalaCurriculum({ posts, postAPI }) {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [tab, setTab] = useState(0); 
  const [curriculum, setCurriculum] = useState(null);
  const stateId = `${stateConfig.keys.PAGE}.magdala-curriculum`; 
  useAnalytics('Magdala Curriculum'); 
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn; 

  useEffect(() => {
    initialize(); 
  }, [initialize]); 

  useEffect(() => {
      resetViewToTopOfPage(); 
      const data = posts.byId[stateId]; 
      if(loading && isEmpty(curriculum) && isNotEmpty(data) && posts.ids) {
        if(data.error) {
          setError(data.error);
        } else {
        setCurriculum(data); 
      }
      setLoading(false); 
      }
  },[loading, curriculum, stateId, posts, posts.id]); 

  initialize = () => {
    resetViewToTopOfPage(); 
    const data = posts.byId[stateId]; 
    if(isNotEmpty(data) && !data.error) {
      setCurriculum(data); 
      setLoading(false); 
    } else {
      postAPI.getPageBySlug('magdala-curriculum'); 
    }
  }; 

  const handleCategorySelection = (event, tab) => {
    setTab(tab)
  }

  const clearPopMessage = () => {
    setError(false); 
  }

  return (
    <div className="MagdalaCurriculum-container">
      { !curriculum && !error && <div className="loadMessage"><LoadingMessage message="Loading..." /></div> }

      { error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
        type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page</p>
        </PopMessage>
      }

      { curriculum && !error &&
      <Fragment>
        <SEOMeta
          title="Magdala Apostolate Curriculum"
          description="The Magdala Apostolate seeks to provide theologically sound Catholic education for consecrated religious women. We offer three tiers of formative courses that build on each other. These include classes for essential human formation, followed by topics specifically tailored to religious life, and finally branching out into further advanced studies."
        />
        <Banner />
        <MagdalaToolbar />
        <MobileNav />
        <div className="container">
          <div className="container-content">
            <Heading curriculum={curriculum} /> 
            <h2>Curriculum Details</h2> 
            <div className="Curriculum">
              <CategoryNavigation currentTab={tab} curriculum={curriculum} onNavigationChange={handleCategorySelection} />
              { tab === 0 && <CategoryDisplay category="foundations" curriculum={curriculum} /> }
              { tab === 1 && <CategoryDisplay category="core" curriculum={curriculum} /> }
              { tab === 2 && <CategoryDisplay category="electives" curriculum={curriculum} /> }
            </div>
          </div>
        </div>
      </Fragment>}

    </div>
  )
}

function mapStateToProps(state) {
  return { 
    posts: state.posts
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    postAPI: bindActionCreators(postAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MagdalaCurriculum);