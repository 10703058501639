import axios from 'axios';
import querystring from 'qs';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;
const defaultConfig = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

const annotationsPath = '/member/annotations';

export async function getEventAnnotations(eventId) {
  return await axios.get(`${urls.hubUIApiRoot}${annotationsPath}/event/${eventId}`);
}

export async function getVideoAnnotations(eventId, videoId) {
  return await axios.get(`${urls.hubUIApiRoot}${annotationsPath}/event/${eventId}/video/${videoId}`);
}

export async function getAudioAnnotations(eventId, audioId) {
  return await axios.get(`${urls.hubUIApiRoot}${annotationsPath}/event/${eventId}/audio/${audioId}`);
}

export async function addAnnotation(data) {
  const params = querystring.stringify(data);
  return await axios.post(`${urls.hubUIApiRoot}${annotationsPath}`, params, defaultConfig);
}

export async function deleteAnnotation(data) {
  return await axios.delete(`${urls.hubUIApiRoot}${annotationsPath}/event/${data.eventId}/${data.mediaType}/${data.mediaId}/${data.annotationId}`);
}

export async function prepAnnotationsForPrint(data) {
  const params = querystring.stringify(data); 
  return await axios.post(`${urls.hubUIApiRoot}${annotationsPath}/print`, params, defaultConfig);
}