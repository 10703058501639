import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import validator from '../../../../utils/validator';


class ApplicationName extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        salutation: this.props.user.salutation || '',
        firstName: this.props.user.firstName || '',
        lastName: this.props.user.lastName || ''
      },
      errors: {
        salutation: '',
        firstName: '',
        lastName: ''
      }
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleFieldChange(event) {
    const fields = { ...this.state.fields };
    fields[event.target.name] = event.target.value;
    this.setState({ fields });
  }

  onSubmit() {
    const { fields: { salutation, firstName, lastName } } = this.state;

    let errors = { failed: false, salutation: '', firstName: '', lastName: '' };
    if(!validator.isEmpty(salutation) && !validator.matchesPattern(salutation, validator.patterns.SALUTATION)) {
      errors = Object.assign(errors, { failed: true, salutation: 'Please enter a valid title' });
    }
    if(validator.isEmpty(firstName) || !validator.matchesPattern(firstName, validator.patterns.NAME)) {
      errors = Object.assign(errors, { failed: true, firstName: 'Please enter a valid first name' });
    }
    if(validator.isEmpty(lastName) || !validator.matchesPattern(lastName, validator.patterns.NAME)) {
      errors = Object.assign(errors, { failed: true, lastName: 'Please enter a valid last name' });
    }

    if(errors.failed) {
      this.setState({ errors });
      return;
    }

    this.props.onContinue({ salutation, firstName, lastName, syncName: true });
  }

  render() {
    const { event } = this.props;
    const { fields, errors } = this.state;

    return (
      <div className="ApplicationName application-step">
        <p className="title"><strong>Pre-Application Step:</strong> Name Confirmation</p>
        { event.magdala? (
            <p>
              Take a moment to review the name associated with your member account and make any necessary 
              corrections. This is the name that will be used for your student record. If you are applying 
              as a group, please enter the name of the person who will be the main contact between the Magdala 
              Apostolate and your community.
            </p>
          ) : (
            <p>
              Take a moment to review the name associated with your member account, and make any necessary 
              corrections. This is the name that will be used for your student record.
            </p>
          )
        }
        <div className="field-container">
          <TextField 
            className="salutation-field"
            placeholder="Enter a title..."
            inputProps={{ 'aria-label': 'applicant salutation' }} 
            label="Title (e.g. Mr., Mrs., Dr., etc.)"
            name="salutation"
            value={fields.salutation}
            error={validator.isNotEmpty(errors.salutation)}
            onChange={this.handleFieldChange}
          />
          { validator.isNotEmpty(errors.salutation) &&
            <FormHelperText error={true}>{errors.salutation}</FormHelperText>
          }
        </div>
        <div className="field-container">
          <TextField 
            className="firstName-field"
            placeholder="Enter your first name..."
            inputProps={{ 'aria-label': 'applicant first name' }} 
            label="First Name"
            name="firstName"
            value={fields.firstName}
            error={validator.isNotEmpty(errors.firstName)}
            onChange={this.handleFieldChange}
          />
          { validator.isNotEmpty(errors.firstName) &&
            <FormHelperText error={true}>{errors.firstName}</FormHelperText>
          }
        </div>
        <div className="field-container">
          <TextField 
            className="lastName-field"
            placeholder="Enter your last name..."
            inputProps={{ 'aria-label': 'applicant last name' }} 
            label="Last Name"
            name="lastName"
            value={fields.lastName}
            error={validator.isNotEmpty(errors.lastName)}
            onChange={this.handleFieldChange}
          />
          { validator.isNotEmpty(errors.lastName) &&
            <FormHelperText error={true}>{errors.lastName}</FormHelperText>
          }
        </div>
        <Button variant="contained" color="primary" onClick={this.onSubmit}>Continue</Button>
      </div>
    );
  }
}

ApplicationName.propTypes = {
  event: PropTypes.object,
  onContinue: PropTypes.func
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(ApplicationName);
