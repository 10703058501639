import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as authAPI from '../../../actions/authentication';
import LoadingMessage from '../../common/messages/LoadingMessage';
import config from '../../../config/config';
import urls from '../../../config/urls';
import { localStore } from '../../../utils/store';
import { resetViewToTopOfPage } from '../../../utils/utils';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


function LoginRedirect({ authentication, authAPI }) {
  const { contextParams } = authentication;
  const navigate = useNavigate();

  useEffect(() => {
    resetViewToTopOfPage();
    authAPI.publishAuthChecked();
    // reset local storage context params
    localStore(config.localStorage.authContextParams, {}, true);
    const { key, slug } = contextParams.reAuthentication || {};
    const raConfig = config.reAuthentication[key] || {};

    // perform appropriate redirect
    if(!isEmpty(contextParams.reAuthentication) && isEmpty(slug)) {
      navigate(raConfig.url);
    } else if(!isEmpty(contextParams.reAuthentication) && !isEmpty(slug)) {
      navigate(raConfig.url(slug));
    } else if(!isEmpty(contextParams.referrer) && !isEmpty(contextParams.permalink) && !isEmpty(config.validReferrers[contextParams.referrer])) {
      let referrerConfig = config.validReferrers[contextParams.referrer];
      if(!isEmpty(referrerConfig[contextParams.permalink])) {
        navigate(referrerConfig[contextParams.permalink]);
      } else {
        let urlFn = referrerConfig['default'];
        navigate(urlFn(contextParams.permalink));
      }
    } else {
      navigate(urls.events);
    }
  }, [contextParams, authAPI, navigate]);

  return (
    <div id="LoginRedirect">
      <LoadingMessage message="Loading..." />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authAPI: bindActionCreators(authAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginRedirect);