import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { truncate, stripHTML } from '../../utils/format';


function ShareMeta(props) {
  let { title, excerpt, image } = props;
  let pageTitle = truncate(title, 147);
  let pageExcerpt = truncate(stripHTML(excerpt), 147);
  let page = window.location.href;

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageExcerpt} />

      {/*Twitter card meta*/}
      <meta name="twitter:site" content="@InstituteCC" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageExcerpt} />
      <meta name="twitter:creator" content="@InstituteCC" />

      {/*Open Graph meta*/}
      <meta property="og:title" content={pageTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={page} />
      <meta property="og:image" content={image} />
      <meta property="og:description" content={pageExcerpt} />
      <meta property="og:site_name" content="Institute of Catholic Culture" />
    </Helmet>
  );
}

ShareMeta.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  image: PropTypes.string
};

export default ShareMeta;