import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as authAPI from '../../../actions/authentication';
import Icon, { IconTypes } from '../../common/Icon';
import urls from '../../../config/urls';
import config from '../../../config/config';
import { localStore } from '../../../utils/store';


function LoginProviders({ authentication, authAPI }) {
  const { contextParams } = authentication;
  const navigate = useNavigate();

  const navToProvider = provider => () => {
    // save context params locally, then kick off the login process
    localStore(config.localStorage.authContextParams, contextParams, true);
    authAPI.startProviderLogin();
    setTimeout(() => {
      if(provider === 'google') {
        navigate(urls.loginGoogle);
      } else {
        navigate(urls.loginFacebook);
      }
    }, 250);
  };

  return (
    <Fragment>
      <div className="LoginGoogle">
        <Button variant="contained" onClick={navToProvider('google')}>
          <Icon type={IconTypes.ServiceGoogle} /> Log in with Google
        </Button>
      </div>
      <div className="LoginFacebook">
        <Button variant="contained" onClick={navToProvider('facebook')}>
          <Icon type={IconTypes.ServiceFacebook} /> Log in with Facebook
        </Button>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authAPI: bindActionCreators(authAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginProviders);