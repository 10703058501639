import React from 'react';
import Icon, { IconTypes } from '../../common/Icon';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import urls from '../../../config/urls';

export default function NewICCMemberMagdalaConfirmation(props) {
  return (
    <div id="NewMemberConfirmation" size="double">
      <div className="NewMemberConfirmation-followup">
        <h2>Part I: Activate Your New ICC Membership</h2>
        <div className="NewMemberConfirmation-steps">
          <Card className="NewMemberConfirmation-step one" square={false}>
            <CardContent className="NewMemberConfirmation-instructions">
              <Icon type={IconTypes.Email} avatar={true} spacing="padded" inline={true} />
            </CardContent>
            <CardContent>
              <p>
                You will receive an account activation email in your email inbox. To complete setting up your membership, open the activation email and click on the 'Activate Your Membership' button. 
              </p>
            </CardContent>
          </Card>
        </div>
        <h2>Part II: Magdala Program Coordinator Approval</h2>
        <Card className="NewMemberConfirmation-step one" square={false}>
          <CardContent className="NewMemberConfirmation-instructions">
            <Icon type={IconTypes.Email} avatar={true} spacing="padded" inline={true} />
          </CardContent>
          <CardContent>
            <p>
              As the Magdala Apostolate is only open to religious sisters, our Magdala Apostolate Program Coordinator will send you an email within the next few days with some additional questions you must complete before your membership can be granted. If you do not receive an email within a few days, please <Link to={urls.magdalaContactUs}>contact us</Link>!.
            </p>
          </CardContent>
        </Card>
        <Card className="NewMemberConfirmation-step two" square={false}>
          <CardContent className="NewMemberConfirmation-instructions">
            <Icon type={IconTypes.Click} avatar={true} spacing="padded" inline={true} />
          </CardContent>
          <CardContent>
            <p>
              While you are waiting, here are some details on the <Link to={urls.magdalaCurriculumPage}>Magdala Apostolate Curriculum</Link>. We also encourage you to <Link to={urls.eventsBrowse}>browse our catalog</Link> of courses, lectures, and other events available to all ICC members!
            </p>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}