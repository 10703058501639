import axios from 'axios';
import querystring from 'qs';
import urls from '../../config/urls';
import config from '../../config/config';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;
const defaultConfig = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

const eventsPath = '/content/events';

export async function getEvents() {
  return await axios.get(`${urls.hubUIApiRoot}${eventsPath}`, { timeout: config.wpTimeout });
}

export async function getEventListBySlug(slug, linkedList = false) {
  return await axios.get(`${urls.hubUIApiRoot}${eventsPath}/list/${slug}?hidden=${linkedList}`, { timeout: config.wpTimeout });
}

export async function getEventBySlug(slug) {
  return await axios.get(`${urls.hubUIApiRoot}${eventsPath}/slug/${slug}`, { timeout: config.wpTimeout });
}

export async function browseEvents() {
  return await axios.get(`${urls.hubUIApiRoot}${eventsPath}/browse`, { timeout: config.wpTimeout });
}

export async function getCourses() {
  return await axios.get(`${urls.hubUIApiRoot}${eventsPath}/courses`, { timeout: config.wpTimeout });
}

export async function getCourseLiveStatus(slug) {
  return await axios.get(`${urls.hubUIApiRoot}${eventsPath}/slug/${slug}/live`, { timeout: config.wpTimeout });
}

export async function searchEvents(terms) {
  return await axios.get(`${urls.hubUIApiRoot}${eventsPath}/search?terms=${terms}`, { timeout: config.wpTimeout });
}

export async function filterEvents(query) {
  const params = querystring.stringify(query);
  return await axios.post(`${urls.hubUIApiRoot}${eventsPath}/filter`, params, defaultConfig);
}

export async function getTaxonomy() {
  return await axios.get(`${urls.hubUIApiRoot}${eventsPath}/taxonomy`, { timeout: config.wpTimeout });
}

