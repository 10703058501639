import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App, { AppContext } from './App';
import { initializeApp } from "firebase/app";
import "firebase/auth";
//import AppMaintenance from './AppMaintenance';
import configureStore from './store/configureStore';
// Note: for the time being, not making use of the service worker
import { unregister } from './serviceWorker';
import { getTestModes } from './utils/testing';
unregister();

// configure app context
let context = {};
// firebase context
const firebaseApp = initializeApp({
  apiKey: "AIzaSyAUWfouxcOFw7vgtVl5uRhho_9seFczE3k",
  authDomain: "iccwebapp-c37dc.firebaseapp.com",
  projectId: "iccwebapp-c37dc",
  storageBucket: "iccwebapp-c37dc.appspot.com",
  messagingSenderId: "116246841720",
  appId: "1:116246841720:web:dd4b4e20a32a6cbb6fa08d",
  measurementId: "G-RW2WPQJ9ZE"
});
context = Object.assign(context, { firebaseApp });
// test mode context
context = Object.assign(context, { testModes: getTestModes() });

// configure redux store
const store = configureStore();

// render app
const domNode = document.getElementById('root');
const root = createRoot(domNode);
root.render(
  <Provider store={store}>
    <AppContext.Provider value={context}>
      <BrowserRouter>
        <App />
        {/*<AppMaintenance />*/}
      </BrowserRouter>
    </AppContext.Provider>
  </Provider>
);
