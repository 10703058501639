import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as coursesAPI from '../../../../actions/content/courses';
import * as progressAPI from '../../../../actions/progress';
import CourseLists from '../detail/CourseLists';
import CourseInfo from '../topics/CourseInfo';
import CourseVideo from '../topics/CourseVideo';
import CourseTaskList from '../topics/CourseTaskList';
import ShareMeta from '../../../common/ShareMeta';
import PopMessage from '../../../common/messages/PopMessage';
import LoadingMessage from '../../../common/messages/LoadingMessage';
import stateConfig from '../../../../config/state';
import config from '../../../../config/config';
import urls from '../../../../config/urls';
import { isPartAvailable, isPartOpen } from '../../../../utils/course';
import validator from '../../../../utils/validator';
import CourseTitle from '../detail/CourseTitle';
import Annotations from '../../../common/annotations/Annotations';
import AnnotationsToggle from '../../../common/annotations/AnnotationsToggle';
const { isEmpty, isNotEmpty, isDefined, isNotDefined } = validator;


function CourseTopic({ course, transcript, settings, courseComplete, onTopicChange, onProgressUpdated, navigation, courses, courseProgress, coursesAPI, progressAPI }) {
  const [topicLoaded, setTopicLoaded] = useState(false);
  const [topicStateId, setTopicStateId] = useState(null);
  const [topicId, setTopicId] = useState(null);
  const [topic, setTopic] = useState(null);
  const [open, setOpen] = useState(null);
  const [accessible, setAccessible] = useState(null);
  const [error, setError] = useState(null);
  const [complete, setComplete] = useState(false); 
  const [displayAnnotations, setDisplayAnnotations] = useState(false); 

  const navigate = useNavigate();
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let onStatusChange = config.emptyFn;
  let retrieveTopic = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(!isEmpty(topic) && settings.topicId !== topicId) {
      // new topic has been selected, load new topic
      setTopic(null);
      setOpen(null);
      setAccessible(null);
      setError(null);
      setTopicLoaded(false);
      setTopicId(settings.topicId);
      setTopicStateId(settings.topicStateId);
      retrieveTopic(settings.topicId, settings.topicStateId);
    }
  }, [settings.topicId, settings.topicStateId, topic, topicId, retrieveTopic]);

  useEffect(() => {
    if(!topicLoaded && isEmpty(topic) && courses.ids.includes(topicStateId)) {
      const data = courses.byId[topicStateId];
      if(data) {
        if(data.error) {
          setError({ display: true });
        } else {
          const lesson = course.lessons.find(lesson => {
            return lesson.parts.find(part => part.id === topicId);
          }) || {};
          let openObj = isPartOpen(data.opening);
          onTopicChange(lesson.id, topicId);
          setOpen(openObj);
          setTopic(data);
          setTopicLoaded(true);
        }
      }
    }
  }, [topicLoaded, course, topic, topicId, topicStateId, courses.ids, courses.byId, onTopicChange]);

  useEffect(() => {
    if(isNotDefined(accessible) && isNotEmpty(topicId)) {
      if(courseComplete) {
        setAccessible({ isAccessible: true });
      } else {
        const data = courseProgress.byId[`${stateConfig.keys.PROGRESS}.${course.id}`];
        if(data && !data.error) {
          const progressData = data && !data.error? data.progress : [];
          const isAccessible = isPartAvailable(topicId, course, transcript, progressData);
          setAccessible({ isAccessible });
        }
      }
    }
  }, [accessible, topicId, courseComplete, courseProgress.ids, courseProgress.byId, course, transcript]);

  useEffect(() => {
    let currentLessons = null; 
    if(courseProgress.byId[`progress.${course.id}`] && courseProgress.byId[`progress.${course.id}`].progress){
      currentLessons = courseProgress.byId[`progress.${course.id}`].progress;   
      let currentLesson = currentLessons.find(lesson => lesson.partId === topicId);
    
      if(currentLesson) {
        const currentLessonStatus = currentLesson.status; 
        if(currentLessonStatus === "complete") {
          setComplete(true); 
        } else {
          setComplete(false); 
        }
      }
    }
  },[courseProgress.ids, courseProgress.byId, course.id, topicId]); 

  initialize = () => {
    const { topicId, topicStateId } = settings;
    setTopicId(topicId);
    setTopicStateId(topicStateId);
    retrieveTopic(topicId, topicStateId);
  };

  onStatusChange = (status, topicType) => {
    progressAPI.updateTopicProgress(course.id, topic.id, { status, topicType });
    onProgressUpdated();
  };

  retrieveTopic = (id, stateId) => {
    const data = courses.byId[stateId];
    if(!data || data.error) {
      coursesAPI.getCourseTopicById(id);
    }
  };

  const clearPopMessage = () => {
    setError({ display: false });
  };

  
  if(isDefined(topic) && isDefined(accessible) && !accessible.isAccessible) {
    // redirect
    navigate(urls.course.replace(':slug', settings.slug.replace('course-', '')));
  }

  const toggleAnnotationsDisplay = event => {
    setDisplayAnnotations(!displayAnnotations); 
  }

  return (
    <div className="CourseTopic-main">
      { (isNotDefined(topic) || isNotDefined(accessible)) && isNotDefined(error) &&
        <div className="loading">
          <LoadingMessage message="Loading..." />
        </div>
      }
      { isDefined(error) && error.display &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>We could not load this resource.</p>
        </PopMessage>
      }
      { isDefined(topic) && isDefined(open) && !open.isOpen &&
        <div className="CourseTopic-not-available">
          <p>The content for this topic will not be available until {open.str}</p>
        </div>
      }
      { isDefined(topic) && isDefined(open) && open.isOpen && isDefined(accessible) && accessible.isAccessible &&
        <Fragment>
          <div className="CourseTopic-heading">
            <ShareMeta title={`${course.title}: ${topic.title}`} excerpt={isNotEmpty(course.excerpt)? course.excerpt : ''} image={course.thumbnail} />
            <CourseTitle title={course.title} subtitle={topic.title} />
          </div>
          { topic.topicType === 'video' &&
            <div>
              <CourseVideo course={course} topic={topic} courseComplete={courseComplete} onStatusChange={onStatusChange} />
              <AnnotationsToggle
                toggle={toggleAnnotationsDisplay}
                displayAnnotations={displayAnnotations}
                completed={complete}
                defaultTopMargin={3}
              />
              <Annotations
                content={{
                  id: course.id,
                  title: course.title,
                  subtitle: topic.title
                }}
                mediaType="video"
                mediaId={topicId}
                useMediaId={true}
                displayAnnotations={displayAnnotations}
              />
            </div>
          }
          { topic.topicType === 'task' &&
            <CourseTaskList course={course} topic={topic} courseComplete={courseComplete} onStatusChange={onStatusChange} />
          }
          <div className="summary" dangerouslySetInnerHTML={{__html: topic.description}} />
          { topic.lists &&
            <CourseLists lists={topic.lists} />
          }
          { topic.topicType === 'info' &&
            <CourseInfo course={course} topic={topic} courseComplete={courseComplete} onStatusChange={onStatusChange} />
          }
        </Fragment>
      }
      {navigation}
      <div className="Course-divider">
        <hr />
      </div>
    </div>
  );
}

CourseTopic.propTypes = {
  course: PropTypes.object,
  transcript: PropTypes.object,
  settings: PropTypes.object,
  courseComplete: PropTypes.bool,
  onTopicChange: PropTypes.func,
  onProgressUpdated: PropTypes.func,
  navigation: PropTypes.any
};

function mapStateToProps(state) {
  return { 
    courses: state.courses,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    coursesAPI: bindActionCreators(coursesAPI, dispatch),
    progressAPI: bindActionCreators(progressAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseTopic);
