import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import * as donationsAPI from '../../../actions/donations';
import ContactErrorPopMessage from '../../common/messages/ContactErrorPopMessage';
import NewDonationCard from './NewDonationCard';
import SelectDonationCard from './SelectDonationCard';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import { resetViewToTopOfPage } from '../../../utils/utils';

const stripeInitPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


function UpdateDonationCard({ donation, onSubmit, onCancel, donations, donationsAPI }) {
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState(null);
  const [mode, setMode] = useState(null);
  const [error, setError] = useState(false);
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const { responseStatus, cards: paymentMethods } = donations;
    if(loading && responseStatus === stateConfig.responseStatus.COMPLETE) {
      if(paymentMethods && paymentMethods.length > 1) {
        const selectableCards = paymentMethods.filter(method => method.paymentMethodId !== donation.paymentMethodId);
        setCards(selectableCards);
        setMode('select');
      } else {
        setMode('add');
      }
      setLoading(false);
    } else if(loading && responseStatus === stateConfig.responseStatus.ERROR) {
      setError(true);
      setLoading(false);
    }
  }, [loading, donation.paymentMethodId, donations]);

  initialize = () => {
    resetViewToTopOfPage();
    donationsAPI.getCards(donation.customerId);
  };

  const selectCard = selection => {
    if(selection === 'add') {
      setMode('add');
    } else {
      onSubmit(selection);
    }
  };

  const clearPopMessage = () => {
    setError(false);
  }; 

  return (
    <div className="UpdateDonationCard">
      <div className="content">
        <h2>Update Card</h2>
        { !loading && mode === 'select' &&
          <SelectDonationCard cards={cards} onSubmit={selectCard} onCancel={onCancel} />
        }
        { !loading && mode === 'add' &&
          <Elements stripe={stripeInitPromise}>
            <NewDonationCard onSubmit={onSubmit} onCancel={onCancel} />
          </Elements>
        }
      </div>
      { error &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p></p>
        </ContactErrorPopMessage>
      }
    </div>
  );
}

UpdateDonationCard.propTypes = {
  donation: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    donations: state.donations
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    donationsAPI: bindActionCreators(donationsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDonationCard);
