import { MBR_REGISTRATION_REQUEST, MBR_REGISTRATION_TOKEN_COMPLETE, MBR_REGISTRATION_COMPLETE,
         MBR_REGISTRATION_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function membershipRegistrationReducer(state = initialState.membershipRegistration, action) {
  const { type, data = {}, error = {} } = action;
  let responseCode, token, check, status = stateConfig.responseStatus;
  
  switch(type) {
    case MBR_REGISTRATION_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null });
    case MBR_REGISTRATION_TOKEN_COMPLETE:
      ({ token, check } = data);
      return Object.assign({}, state, { responseStatus: status.COMPLETE, token, check });
    case MBR_REGISTRATION_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, registered: true });
    case MBR_REGISTRATION_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default membershipRegistrationReducer;