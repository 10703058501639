import { ANNOTATION_LOAD_START, ANNOTATION_LOAD_COMPLETE, ANNOTATION_LOAD_ERR,
         ANNOTATION_ADD_REQUEST, ANNOTATION_ADD_COMPLETE, ANNOTATION_ADD_ERR, ANNOTATION_DELETE_REQUEST, ANNOTATION_DELETE_COMPLETE, ANNOTATION_DELETE_ERR, ANNOTATIONS_PRINT_REQUEST, ANNOTATIONS_PRINT_COMPLETE, ANNOTATIONS_PRINT_ERR, ANNOTATION_PRINT_REQUEST, ANNOTATION_PRINT_COMPLETE, ANNOTATION_PRINT_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';
import { clearStateById } from '../utils/state';

function annotationsReducer(state = initialState.annotations, action) {
  const { type, data, error } = action;
  const annotations = {
    ids: state.ids.slice(),
    byId: { ...state.byId }
  };
  let stateId = `${action.key}.${action.objectId}`;
  let responseCode, status = stateConfig.responseStatus;
  
  switch(type) {
    case ANNOTATION_LOAD_START:
      clearStateById(annotations.ids, annotations.byId, stateId);
      return annotations;
    case ANNOTATION_LOAD_COMPLETE:
      annotations.ids.push(stateId);
      annotations.byId[stateId] = data;
      return annotations;
    case ANNOTATION_LOAD_ERR:
      annotations.ids.push(stateId);
      annotations.byId[stateId] = { error: action.error };
      return annotations;
    case ANNOTATION_ADD_REQUEST:
      return Object.assign({}, annotations, { responseStatus: status.DEFAULT, responseCode: null });
      case ANNOTATION_ADD_COMPLETE:
      annotations.responseStatus = status.COMPLETE;
      clearStateById(annotations.ids, annotations.byId, stateId);
      annotations.ids.push(stateId);
      annotations.byId[stateId] = data;
      return annotations;
    case ANNOTATION_ADD_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    case ANNOTATION_DELETE_REQUEST:
      return Object.assign({}, annotations, { responseStatus: status.DEFAULT, responseCode: null }); 
      case ANNOTATION_DELETE_COMPLETE:
      annotations.responseStatus = status.COMPLETE;
      clearStateById(annotations.ids, annotations.byId, stateId); 
      annotations.ids.push(stateId); 
      annotations.byId[stateId] = data; 
      return annotations;
    case ANNOTATION_DELETE_ERR:
      ({ code: responseCode } = error); 
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode }); 
    case ANNOTATIONS_PRINT_REQUEST:
      return Object.assign({}, annotations, { responseStatus: status.DEFAULT, responseCode: null }); 
    case ANNOTATIONS_PRINT_COMPLETE:
      annotations.responseStatus = status.COMPLETE;
      clearStateById(annotations.ids, annotations.byId, stateId); 
      annotations.ids.push(stateId); 
      annotations.byId[stateId] = data.html; 
      return annotations; 
    case ANNOTATIONS_PRINT_ERR: 
      ({ code: responseCode } = error); 
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode }); 
    case ANNOTATION_PRINT_REQUEST:
      return Object.assign({}, annotations, { responseStatus: status.DEFAULT, responseCode: null }); 
    case ANNOTATION_PRINT_COMPLETE:
      annotations.responseStatus = status.COMPLETE;
      clearStateById(annotations.ids, annotations.byId, stateId); 
      annotations.ids.push(stateId); 
      annotations.byId[stateId] = data.html; 
      return annotations; 
    case ANNOTATION_PRINT_ERR: 
      ({ code: responseCode } = error); 
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode }); 
    default:
      return state;
  }
}

export default annotationsReducer;