import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';


function CheckboxField(props) {
  return (
    <Checkbox name={props.name} defaultChecked={props.defaultChecked} onChange={props.onChange} disabled={props.disabled} />
  );
}

function SwitchField(props) {
  return (
    <Switch name={props.name} defaultChecked={props.defaultChecked} color={props.color} disabled={props.disabled}
      onChange={props.onChange} />
  );
}

function CheckField(props) {
  return (
    <FormControlLabel
      className={`CheckField-label ${props.name}-label`}
      control={props.switch? <SwitchField {...props} /> : <CheckboxField {...props} />}
      label={props.label}
      disabled={props.disabled}
    />
  );
}

CheckField.defaultProps = {
  defaultChecked: false,
  switch: false,
  color: "secondary",
  disabled: false
}

CheckField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  onChange: PropTypes.func
};

export default CheckField;