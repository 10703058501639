import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import urls from '../../../config/urls';


const SGRImage = 'https://instituteofcatholicculture.net/wp-content/uploads/2019/11/sgr-logo-small.jpg';
const BGRImage = 'https://instituteofcatholicculture.net/wp-content/uploads/2019/11/bgr-logo-small.jpg';

function Heading({ type }) {
  const isLatin = type === 'latin';

  const switchReflectionsView = url => () => {
    window.location = url;
  };
  
  return (
    <div className="GospelReflections-heading">
      <div className="feature">
        <img src={isLatin? SGRImage : BGRImage} 
          alt={isLatin? 'Sunday Gospel Reflections' : 'Byzantine Gospel Reflections'} />
      </div>
      <div className="series-info">
        { isLatin? (
            <Fragment>
              <p>
                This weekly series offers an in-depth study of the lectionary readings for Sunday Mass. We 
                explore the Biblical, literal, and historical context as the foundation for spiritual and moral 
                application.
              </p>
              { /* BGR hidden for the time being per Father's request in Oct 2023, may be permanently removed later
              <div className="series-nav">
                <Button variant="text" color="secondary" onClick={switchReflectionsView(urls.reflectionsByzantine)}>
                  View Byzantine Reflections
                </Button>
              </div>
              */}
            </Fragment>
          ) : (
            <Fragment>
              <p>
                This weekly series offers an in-depth study of the lectionary readings for each Sunday’s Divine 
                Liturgy. We explore the Biblical, literal, and historical context as the foundation for spiritual 
                and moral application.
              </p>
              <div className="series-nav">
                <Button variant="text" color="secondary" onClick={switchReflectionsView(urls.reflectionsLatin)}>
                  View Roman Catholic Reflections
                </Button>
              </div>
            </Fragment>
          )
        }
      </div>
    </div>
  );
}

Heading.propTypes = {
  type: PropTypes.string
};

export default Heading;