import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import * as progressAPI from '../../../../actions/progress';
import Icon, { IconTypes } from '../../../common/Icon';
import stateConfig from '../../../../config/state';
import config from '../../../../config/config';
import validator from '../../../../utils/validator';
const { isEmpty } = validator;


function CourseTaskList({ course, topic: { id: topicId, content: taskList }, courseComplete, onStatusChange, courseProgress, progressAPI }) {
  const [currentTopic, setCurrentTopic] = useState(null);
  const [progressLoaded, setProgressLoaded] = useState(false);
  const [tasksLoaded, setTasksLoaded] = useState(false);
  const [progressStatus, setProgressStatus] = useState(null);
  const [taskStates, setTaskStates] = useState([]);
  const [complete, setComplete] = useState(false);
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(currentTopic && currentTopic !== topicId) {
      setCurrentTopic(topicId);
      setProgressLoaded(false);
      setTasksLoaded(false);
      setProgressStatus(null);
      setTaskStates([]);
      progressAPI.getTasks(course.id, topicId);
    }
  }, [topicId, currentTopic, course.id, progressAPI]);

  useEffect(() => {
    const stateId = `${stateConfig.keys.PROGRESS}.${course.id}`;
    if(!progressLoaded && courseProgress.ids.includes(stateId)) {
      const data = courseProgress.byId[stateId];
      const progressData = data && !data.error? data.progress : [];
      const progress = progressData.find(item => item.partId === topicId);
      const status = progress && progress.status? progress.status : null;
      setProgressLoaded(true);
      setProgressStatus(status);
      if(status === 'complete') {
        const tasks = taskList.items.map((item, index) => `task-${index}`);
        setTasksLoaded(true);
        setTaskStates(tasks);
        setComplete(true);
      }
    }
  }, [progressLoaded, courseProgress.ids, courseProgress.byId, course.id, topicId, taskList]);

  useEffect(() => {
    const stateId = `${stateConfig.keys.PROGRESS_TASKS}.${course.id}.${topicId}`;
    // note: progressLoaded check prevents a race condition
    if(progressLoaded && !tasksLoaded && courseProgress.ids.includes(stateId)) {
      const data = courseProgress.byId[stateId];
      let tasks = data && !data.error? data.tasks : [];
      tasks = progressStatus !== 'complete'? tasks : taskList.items.map((item, index) => `task-${index}`);
      setTasksLoaded(true);
      setTaskStates(tasks);
    }
  }, [progressLoaded, tasksLoaded, progressStatus, courseProgress.ids, courseProgress.byId, course.id, topicId, taskList, progressAPI]);

  initialize = () => {
    progressAPI.getTasks(course.id, topicId);
    setCurrentTopic(topicId);
  };

  const handleTaskCheck = index => event => {
    const taskArr = taskStates.slice();
    const taskKey = `task-${index}`;
    const i = taskStates.indexOf(taskKey);

    if(i === -1) {
      taskArr.push(taskKey);
    } else {
      taskArr.splice(i, 1);
    }

    setTaskStates(taskArr);
    progressAPI.updateTasks(course.id, topicId, { tasks: taskArr });
    if(progressStatus !== 'complete' && taskArr.length === taskList.items.length) {
      onStatusChange('complete', 'task');
      setComplete(true);
      setTimeout(() => {
        setProgressLoaded(false);
        setProgressStatus(null);
      }, 1000);
    } else if(progressStatus !== 'started') {
      onStatusChange('started', 'task');
      setComplete(false);
      setTimeout(() => {
        setProgressLoaded(false);
        setProgressStatus(null);
      }, 1000);
    }
  };

  return (
    <div className="CourseTaskList">
      { progressLoaded && tasksLoaded &&
        <Fragment>
          { !isEmpty(taskList.title) && 
            <h3>{taskList.title}</h3>
          }
          { !complete && !courseComplete &&
            <p className="CourseTaskList-instructions">
              Check off the item(s) below as you complete them.
            </p>
          }
          <div className="items">
            {
              taskList.items.map((item, index) => {
                const selected = taskStates.includes(`task-${index}`);
                return (
                  <div key={`item-${index}`} className={`item ${(selected || courseComplete)? 'selected' : 'not-selected'}`}>
                    <div className="action">
                      <Button variant="text" onClick={courseComplete? () => {} : handleTaskCheck(index)}>
                        <Icon type={(selected || courseComplete)? IconTypes.CheckboxChecked : IconTypes.CheckboxUnchecked} />
                      </Button>
                    </div>
                    <div className="text">
                      { 
                        item.url?
                          <a href={item.url} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{__html: item.text}} /> :
                          <span dangerouslySetInnerHTML={{__html: item.text}} />
                      }
                    </div>
                  </div>
                );
              })
            }
          </div>
          { (complete || courseComplete) &&
            <p className="status-message">All items are complete!</p>
          }
        </Fragment>
      }
    </div>
  );
}

CourseTaskList.propTypes = {
  course: PropTypes.object,
  topic: PropTypes.object,
  courseComplete: PropTypes.bool,
  onStatusChange: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    progressAPI: bindActionCreators(progressAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseTaskList);
