import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as appDisplay from '../../../actions/appDisplay';
import * as searchAPI from '../../../actions/search';
import urls from '../../../config/urls';
import Icon, { IconTypes } from '../../common/Icon';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';


function ExploreNav({  searchAPI, appDisplay }) {
  const [searchField, setSearchField] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(!location.pathname.includes('search')) {
      setSearchField('');
      searchAPI.clearSearch();
    }
  }, [location, searchAPI]);

  const handleExploreMenuToggle = () => {
    appDisplay.toggleExploreMenu();
  }

  const handleSearchFieldChange = (event) => {
    setSearchField(event.target.value);
  }

  const handleSearchRequest = (event) => {
    if(event && event.type === 'keydown' && event.key !== 'Enter') {
      return;
    }
    searchAPI.clearSearch();
    navigate(`${urls.search}?terms=${searchField}`);
  }
  
  return (
    <div className="AppHeader-exploreNav">
      <div className="container">
        <Button className="AppHeader-exploreButton" onClick={handleExploreMenuToggle} 
          aria-label="explore" disableFocusRipple={true} disableRipple={true} variant="contained">
          <Icon type={IconTypes.Menu} />
          <span>Explore</span>
        </Button>
        <Paper className="AppHeader-search">
          <InputBase
            className="search-field"
            placeholder="Tell us what you're looking for..."
            inputProps={{ 'aria-label': 'search Institute content' }}
            value={searchField}
            onKeyDown={handleSearchRequest}
            onChange={handleSearchFieldChange} />
          <IconButton className="search-icon icon" aria-label="search" onClick={handleSearchRequest}>
            <SearchIcon />
          </IconButton>
        </Paper>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { 
    appDisplay: bindActionCreators(appDisplay, dispatch),
    searchAPI: bindActionCreators(searchAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploreNav);