import React from 'react'; 
import { useNavigate } from 'react-router-dom';
import urls from '../../../config/urls';

function Banner() {
	const navigate = useNavigate(); 

	const nav = url => () => {
		navigate(url); 
	}

	const imgNoLogo = "https://instituteofcatholicculture.net/wp-content/uploads/2023/02/maBannerNoLogo.png";
	const imgWithLogo = "https://instituteofcatholicculture.net/wp-content/uploads/2023/02/maBannerWhiteNoRingNoSubtext.png";

	return (
		<div className="Magdala-Banner">
			<div className="Magdala-Banner-Container">
			<img
				className="Magdala-Banner-Image imgWithLogo"
				src={imgWithLogo}
				alt="Magdala Apostolate Banner"
				onClick={nav(urls.magdalaLandingPage)}
			/>
			<img
				className="Magdala-Banner-Image imgNoLogo"
				src={imgNoLogo}
				alt="Magdala Apostolate Banner"
				onClick={nav(urls.magdalaLandingPage)}
			/>
			</div>
		</div>
	)
}

export default Banner; 