import React from 'react';
import PropTypes from 'prop-types';
import SingleChoiceQuestion from './SingleChoiceQuestion';
import MultipleChoiceQuestion from './MultipleChoiceQuestion';
import ClozeQuestion from './ClozeQuestion';
import FreeAnswerQuestion from './FreeAnswerQuestion';
import validator from '../../../../../utils/validator';
const { isEmpty } = validator;


function CourseQuestion({ question, index, scored, disabled, displayInfo, onChange }) {
  return (
    <div className="CourseQuestion">
      { question.type === 'single' &&
        <SingleChoiceQuestion 
          question={question} 
          index={index} 
          scored={scored} 
          disabled={disabled}
          onChange={onChange} 
        />
      }
      { question.type === 'multiple' &&
        <MultipleChoiceQuestion 
          question={question} 
          index={index} 
          scored={scored} 
          disabled={disabled}
          onChange={onChange} 
        />
      }
      { question.type === 'cloze_answer' &&
        <ClozeQuestion 
          question={question} 
          index={index} 
          scored={scored} 
          disabled={disabled}
          onChange={onChange} 
        />
      }
      { question.type === 'free_answer' &&
        <FreeAnswerQuestion 
          question={question} 
          index={index} 
          scored={scored} 
          disabled={disabled}
          onChange={onChange} 
        />
      }
      { displayInfo && !isEmpty(question.additionalInfo) &&
        <div className="additional-info">
          <span className="label">Additional Information:</span>
          <span className="info" dangerouslySetInnerHTML={{__html: question.additionalInfo }} />
        </div>
      }
    </div>
  );
}

CourseQuestion.defaultProps = {
  disabled: false,
  displayInfo: false
};

CourseQuestion.propTypes = {
  question: PropTypes.object,
  index: PropTypes.number,
  scored: PropTypes.object,
  onChange: PropTypes.func
};

export default CourseQuestion;
