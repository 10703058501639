import { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { getAnalytics, logEvent } from "firebase/analytics";
import axios from 'axios';
import querystring from 'qs';
import { AppContext } from '../App';
import validator from '../utils/validator';
const { isNotEmpty } = validator;
axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;
const defaultConfig = {
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
};


function useAnalytics(pageTitle) {
  const [gaLogged, setGALogged] = useState(false);
  const [acLogged, setACLogged] = useState(false);
  const [waiting, setWaiting] = useState(true);
  const user = useSelector(state => state.user);
  const { firebaseApp } = useContext(AppContext);
  const googleAnalytics = getAnalytics(firebaseApp);
  const title = pageTitle || '';
  const page = window.location.pathname;
  const gaEnabled = process.env.REACT_APP_GA_ENABLED === 'true';

  /*
    Page view tracking for Google Analytics
  */
  useEffect(() => {
    if(!gaLogged) {
      setGALogged(true);
      if(gaEnabled) {
        logEvent(googleAnalytics, 'page_view', { page_path: page, page_title: title });
      } else {
        console.log('GA not enabled, console logging only...', 'page_view', { page_path: page, page_title: title });
      }
    }
  }, [gaLogged, page, title, googleAnalytics, gaEnabled]);

  /*
    Page view tracking for ActiveCampaign
    
    Note: as of April 2023 these requests still throw CORS and network errors 
    even when working. The only way to verify they are working is to check
    the contact's record on ActiveCampaign. Even whitelisting the domain in the
    AC console doesn't change this. These errors should be disregarded.
  */
  useEffect(() => {
    const { email } = user;
    if(!acLogged && waiting && user.userDataChecked) {
      setACLogged(true);
      if(isNotEmpty(email)) {
        let trackingURL = 'https://trackcmp.net/event';
        const data = {
          actid: '475247667',
          key: 'c357ab6f2cf7de590e277c7c3a62960ee3bd88a7',
          event: 'viewed_page',
          eventdata: page,
          visit: JSON.stringify({ email })
        };
        const params = querystring.stringify(data);
        axios.post(trackingURL, params, defaultConfig);
      }
      setWaiting(false);
    }
  }, [acLogged, waiting, user, user.userDataChecked, page]);
}

export default useAnalytics;