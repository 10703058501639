import React, { Fragment } from 'react';
import urls from '../../../config/urls';


function DonationDisclaimer(props) {
  return (
    <Fragment>
      <p className="disclaimer-message">
        <strong>Please note that we are in the process of migrating to a new donations processing platform. There may be data currently missing from this page.</strong> There are no required actions you need to take to resolve this. Rest assured all of your data will be restored by 1 January 2025. We appreciate your patience as we continue working to improve your user experience.  
      </p>
      <p className="disclaimer-message">
        If you have a question about your donation history or you would like to update or cancel any recurring/scheduled donations, please <a href={urls.contactUs} target="_blank" rel="noopener noreferrer">contact our staff</a>, we are happy to help!
      </p>
    </Fragment>
  )
}

export default DonationDisclaimer;