import { MAGDALA_REG_REQUEST, MAGDALA_RETRIEVED_COMMUNITIES, MAGDALA_REG_COMPLETE, 
         MAGDALA_REG_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function magdalaRegistrationReducer(state = initialState.magdalaRegistration, action) {
  const { type, data = {}, error = {} } = action;
  let responseCode, communities, status = stateConfig.responseStatus;
  
  switch(type) {
    case MAGDALA_REG_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null });
    case MAGDALA_RETRIEVED_COMMUNITIES:
      ({ communities } = data);
      return Object.assign({}, state, { responseStatus: status.COMPLETE, communities });
    case MAGDALA_REG_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, registered: true });
    case MAGDALA_REG_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default magdalaRegistrationReducer;