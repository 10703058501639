import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import urls from '../../../config/urls';

const CatechismImage = 'https://instituteofcatholicculture.net/wp-content/uploads/2019/11/catechism.jpg';
const HolyLandImage = 'https://instituteofcatholicculture.net/wp-content/uploads/2019/11/holy-land.jpg';
const MagdalaImage = 'https://instituteofcatholicculture.net/wp-content/uploads/2019/12/magdala-sisters.jpg';

function Culture({ user }) {
  const navigate = useNavigate();

  const nav = url => () => {
    navigate(url);
  }

  return (
    <div className="culture container">
      <div className="container-content">
        <div className="description">
          <h3 className="section-header">Forming the culture in which we live</h3>
          <div className="divider-bar"></div>
          <p className="section-description">
            Education is a gift from God to be used to sanctify and bless our communities. We are 
            committed to creating programs that help Christians become vessels of life.
          </p>
        </div>
        <div className="cards">
          <div className="card" onClick={nav(urls.coursesCatechism)}>
            <div className="feature-image" style={{backgroundImage: `url(${CatechismImage})`}}>
            </div>
            <h4 className="title">Catechism Courses</h4>
          </div>
          <div className="card" onClick={nav(urls.pilgrimage)}>
            <div className="feature-image" style={{backgroundImage: `url(${HolyLandImage})`}}>
            </div>
            <h4 className="title">Holy Land Pilgrimage</h4>
          </div>
          <div className="card" onClick={user.type === 'magdala-member'? nav(urls.magdalaCoursesPage) : nav(urls.magdalaLandingPage)}>
            <div className="feature-image" style={{backgroundImage: `url(${MagdalaImage})`}}>
            </div>
            <h4 className="title">Magdala Apostolate</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {user: state.user}
};
  
export default connect(mapStateToProps)(Culture); 