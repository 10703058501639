import React from 'react';
import { useNavigate } from 'react-router-dom';
import urls from '../../../config/urls'; 
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Callout from './Callout'; 
import PropTypes from 'prop-types';

function ActionsSection(props) {
  const content = props.content; 
  const card1 = content['landingActions.card1'].value; 
  const card2 = content['landingActions.card2'].value; 
  const card3 = content['landingActions.card3'].value; 
  const calloutText = content['landingActions.calloutText'].value; 
  const calloutButton = content['landingActions.calloutButton'].value;
  const navigate = useNavigate();

  const nav = URL => () => {
    navigate(URL);
  }

  return (
    <div className="Magdala-Actions">
      <div className="introduction">
        <div className="container-content">
          <div className="cards">
            <Card className="card register" onClick={nav(urls.magdalaRegistration)}>
              <div className="feature-image"  
                style={{backgroundImage: `url(${card1.image})`}}>
              </div>
              <div>
                <h4>{card1.title}</h4>
                <p>{card1.description}</p>
              </div>
              <div>
                <Button size="small" color="primary" onClick={nav(urls.events)}>
                  {card1.linkText}
                </Button>
              </div>
            </Card>
            <Card className="card program" onClick={nav(urls.magdalaProgramPage)}>
              <div className="feature-image" 
                style={{backgroundImage: `url(${card2.image})`}}></div>
              <div>
                <h4>{card2.title}</h4>
                <p>{card2.description}</p>
              </div>
              <div>
                <Button size="small" color="primary" onClick={nav(urls.magdalaProgramPage)}>
                  {card2.linkText} 
                </Button>
              </div>
            </Card>
            <Card className="card courses" onClick={nav(urls.magdalaCoursesPage)}>
              <div className="feature-image"  
                style={{backgroundImage: `url(${card3.image})`}}></div>
              <div>
                <h4>{card3.title}</h4>
                <p>{card3.description}</p>
              </div>
              <div>
                <Button size="small" color="primary" onClick={nav(urls.magdalaCoursesPage)}>
                  {card3.linkText}
                </Button>
              </div>
            </Card>
          </div>
        </div>
      <Callout 
        calloutText={calloutText}
        calloutButton={calloutButton}
      />
      </div>
    </div>
  );
}

ActionsSection.propTypes = {
	content: PropTypes.object
}

export default ActionsSection;