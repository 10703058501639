import React from 'react';
import Button from '@mui/material/Button';
import urls from '../../../config/urls'; 

function Help(props) {
  return (
    <div className="Help">
      <h3>Help/Feedback</h3>
      <p>
        Reach out if you have questions, are experiencing issues and need assistance, 
        or just want to provide some feedback.
      </p>
      <Button variant="text" onClick={() => window.open(urls.contactUs, '_blank')}>
        Contact Us
      </Button>
    </div>
  );
}

export default Help;