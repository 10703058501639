import { AUTH_START, AUTH_STEP, AUTH_COMPLETE, AUTH_LOGOUT, AUTH_ERR,
         AUTH_PASSCODE_SENT, AUTH_RESET_LINK_SENT, AUTH_RESET_COMPLETE,
         AUTH_USER_IDENTIFIED, AUTH_STATUS_RECEIVED, AUTH_STATUS_ERR,
         AUTH_USER_ID_ERR, AUTH_CHECKED, AUTH_PUBLISH_CONTEXT,
         AUTH_REMEMBERED, AUTH_PROVIDER_START } from '../actions/actionTypes';
import * as auth from '../api/authentication';
import config from '../config/config';
import errors from '../config/errors';
import { sessionStore } from '../utils/store';


export function publishContextParams(context) {
  return async dispatch => {
    dispatch({ type: AUTH_PUBLISH_CONTEXT, context });
  }
}

export function publishAuthChecked() {
  return async dispatch => {
    dispatch({ type: AUTH_CHECKED });
  }
}

export function startProviderLogin() {
  return async dispatch => {
    dispatch({ type: AUTH_PROVIDER_START });
  }
}

export function getAuthStatus(shouldReturnToken) {
  return async dispatch => {
    dispatch({ type: AUTH_START });
    try {
      let result = await auth.getAuthStatus(shouldReturnToken);
      if(shouldReturnToken && result.data && result.data.token) {
        dispatch({ type: AUTH_COMPLETE, data: result.data });
      } else if(!shouldReturnToken && result.data.success) {
        dispatch({ type: AUTH_STATUS_RECEIVED, data: result.data });
      } else {
        dispatch({ type: AUTH_STATUS_ERR, error: errors.RequestFailed });
      }
      dispatch({ type: AUTH_CHECKED }); // indicates that at least one auth/ID check has been completed
    } catch(error) {
      if(error.response && [403].includes(error.response.status)) {
        dispatch({ type: AUTH_STATUS_ERR, error: errors.RequestFailed });
        dispatch({ type: AUTH_USER_IDENTIFIED, data: error.response.data });
      } else {
        dispatch({ type: AUTH_STATUS_ERR, error: errors.RequestFailed });
      }
      dispatch({ type: AUTH_CHECKED }); // indicates that at least one auth/ID check has been completed
    }
  };
}

export function identifyDeviceOwner(email) {
  return async dispatch => {
    try {
      let result = await auth.identifyDeviceOwner(email);
      if(result.data.email) {
        dispatch({ type: AUTH_USER_IDENTIFIED, data: result.data });
      } else {
        dispatch({ type: AUTH_USER_ID_ERR });
      }
    } catch(error) {
      dispatch({ type: AUTH_USER_ID_ERR });
    }
  };
}

export function identifyWithEmail(email) {
  return async dispatch => {
    try {
      let result = await auth.identifyWithEmail(email);
      if(result.data.email) {
        dispatch({ type: AUTH_USER_IDENTIFIED, data: result.data });
      } else {
        throw Error('Failed to identify user');
      }
    } catch(error) {
      throw Error('Failed to identify user');
    }
  };
}

export function login(data) {
  return async dispatch => {
    dispatch({ type: AUTH_START });
    try {
      let result = await auth.login(data);
      if(result.data && result.data.success) {
        dispatch({ type: AUTH_COMPLETE, data: result.data });
      } else {
        dispatch({ type: AUTH_ERR, error: errors.AuthenticationError });
      }
    } catch(error) {
      if(error.response && error.response.data) {
        if(error.response.data.code === errors.CredentialFailure.code) {
          dispatch({ type: AUTH_ERR, error: errors.CredentialFailure });
        } else if(error.response.data.code === errors.MemberNotVerified.code) {
          dispatch({ type: AUTH_ERR, error: errors.MemberNotVerified });
        } else {
          dispatch({ type: AUTH_ERR, error: errors.AuthenticationError });
        }
      } else {
        dispatch({ type: AUTH_ERR, error: errors.AuthenticationError });
      }
    }
  };
}

export function logout() {
  return async dispatch => {
    try {
      await auth.logout();
      sessionStore(config.localStorage.trackingSessionId, '', false);
      dispatch({ type: AUTH_LOGOUT });
    } catch(error) {
      // Shouldn't occur, for the moment handling silently
    }
  };
}

export function sendPasscode(data) {
  return async dispatch => {
    dispatch({ type: AUTH_STEP });
    try {
      let result = await auth.sendPasscode(data);
      if(result.data && result.data.success) {
        dispatch({ type: AUTH_PASSCODE_SENT });
      } else {
        dispatch({ type: AUTH_ERR, error: errors.AuthenticationError });
      }
    } catch(error) {
      if(error.response && error.response.data) {
        dispatch({ type: AUTH_ERR, error: error.response.data });
      } else {
        dispatch({ type: AUTH_ERR, error: errors.AuthenticationError });
      }
    }
  };
}

export function loginWithPasscode(data) {
  return async dispatch => {
    dispatch({ type: AUTH_START });
    try {
      let result = await auth.loginWithPasscode(data);
      if(result.data && result.data.success) {
        dispatch({ type: AUTH_COMPLETE, data: result.data });
      } else {
        dispatch({ type: AUTH_ERR, error: errors.PasswordlessFailure });
      }
    } catch(error) {
      dispatch({ type: AUTH_ERR, error: errors.PasswordlessFailure });
    }
  };
}

export function loginWithProvider(data) {
  return async dispatch => {
    dispatch({ type: AUTH_START });
    try {
      let result = await auth.loginWithProvider(data);
      if(result.data && result.data.success) {
        dispatch({ type: AUTH_COMPLETE, data: result.data });
      } else {
        dispatch({ type: AUTH_ERR, error: errors.ProviderLoginFailure });
      }
    } catch(error) {
      dispatch({ type: AUTH_ERR, error: errors.ProviderLoginFailure });
    }
  };
}

export function rememberDevice(deviceDescription) {
  return async dispatch => {
    dispatch({ type: AUTH_STEP });
    try {
      await auth.rememberDevice(deviceDescription);
      dispatch({ type: AUTH_REMEMBERED });
    } catch(error) {
      dispatch({ type: AUTH_REMEMBERED });
    }
  };
}

export function loginWithDevice(shouldReturnToken) {
  return async dispatch => {
    dispatch({ type: AUTH_START });
    try {
      let result = await auth.loginWithDevice(shouldReturnToken);
      if(result.data && result.data.success) {
        dispatch({ type: AUTH_COMPLETE, data: result.data });
      } else {
        dispatch({ type: AUTH_ERR, error: errors.DeviceAuthenticationError });
      }
    } catch(error) {
      dispatch({ type: AUTH_ERR, error: errors.DeviceAuthenticationError });
    }
  };
}

export function logoutDevice() {
  return async dispatch => {
    try {
      await auth.logoutDevice();
      dispatch({ type: AUTH_LOGOUT });
    } catch(error) {
      // Shouldn't occur, for the moment handling silently
    }
  };
}

export function sendPasswordResetToken(email) {
  return async dispatch => {
    dispatch({ type: AUTH_START });
    try {
      await auth.sendPasswordResetToken(email);
      dispatch({ type: AUTH_RESET_LINK_SENT });
    } catch(error) {
      dispatch({ type: AUTH_ERR, error: errors.PasswordResetFailure });
    }
  };
}

export function resetPassword(token, password) {
  return async dispatch => {
    dispatch({ type: AUTH_START });
    try {
      await auth.resetPassword(token, password);
      dispatch({ type: AUTH_RESET_COMPLETE });
    } catch(error) {
      dispatch({ type: AUTH_ERR, error: errors.PasswordResetFailure });
    }
  };
}
