import { ALERT_RETRIEVED, ANNUAL_REPORT_RETRIEVED, ANNUAL_REPORT_ERR, MAGDALA_CONTENT_RETRIEVED, MAGDALA_CONTENT_ERR, 
         POPUP_RETRIEVED, WEB_CONFIG_RETRIEVED, FEATURE_BANNER_RETRIEVED, VIDEO_PROMO_RETRIEVED, PRIVACY_POLICY_RETRIEVED, 
         PRIVACY_POLICY_ERR } from '../actions/actionTypes';
import initialState from './initialState';

function systemContentReducer(state = initialState.systemContent, action) {  
  let systemContent = { ...state };
  const { type, data } = action;

  switch(type) {
    case ALERT_RETRIEVED:
      systemContent.alert = data.message? data : null;
      return systemContent;
    case ANNUAL_REPORT_RETRIEVED:
      systemContent.annualReport = data.settings? data : { error: true };
      return systemContent;
    case ANNUAL_REPORT_ERR:
      systemContent.annualReport = { error: data.code };
      return systemContent;
    case MAGDALA_CONTENT_RETRIEVED:
      systemContent.magdala = data.settings? data : { error: true };
      return systemContent;
    case MAGDALA_CONTENT_ERR:
      systemContent.magdala = { error: true }
      return systemContent; 
    case POPUP_RETRIEVED:
      systemContent.popup = data.popup? data.popup : null;
      return systemContent;
    case FEATURE_BANNER_RETRIEVED:
      systemContent.featureBanner = data.banner? data.banner : null;
      return systemContent;
    case VIDEO_PROMO_RETRIEVED:
      systemContent.videoPromo = data.videoPromo? data.videoPromo : null;
      return systemContent;
    case WEB_CONFIG_RETRIEVED:
      systemContent.webConfig = buildConfigObject(data);
      return systemContent;
    case PRIVACY_POLICY_RETRIEVED:
      systemContent.privacyPolicy = data.policy? data.policy : null;
      return systemContent;
    case PRIVACY_POLICY_ERR:
      systemContent.privacyPolicy = { error: data.code };
      return systemContent;
    default:
      return state;
  }
}

function buildConfigObject(data) {
  if(!data || !data.config) return null;
  return data.config.reduce((config, item) => {
    config[item.key] = item.value;
    return config;
  }, {})
}

export default systemContentReducer;