import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Element, scroller } from 'react-scroll';
import useAnalytics from '../../../../hooks/useAnalytics';
import * as behaviorAPI from '../../../../actions/behavior';
import Icon, { IconTypes } from '../../../common/Icon';
import ShareMeta from '../../../common/ShareMeta';
import MemberRegistrationForm from '../../../member/registration/MemberRegistrationForm';
import DetailMedia from './DetailMedia';
import DetailCourseAccess from './DetailCourseAccess';
import EventRegistration from '../registration/EventRegistration';
import config from '../../../../config/config';
import urls from '../../../../config/urls';
import Collapse from '@mui/material/Collapse';
const { deviceBreakpoints } = config;

function checkIsFavorite(favorites, eventData) {
  const index = favorites.findIndex(item => item === eventData.id);
  return index !== -1;
}

function DetailHeading({ eventData, referral, onOpenClassroom, favorites, user, behaviorAPI }) {
  const [isFavorite, setIsFavorite] = useState(checkIsFavorite(favorites, eventData));
  const [registrationFormDisplayed, setRegistrationFormDisplayed] = useState(false);
  const [memberRegistration, setMemberRegistration] = useState(null);
  const isSmallView = useMediaQuery({
    query: `(max-width: ${deviceBreakpoints.tablet}px)`
  });
  const { thumbnail: eventThumb, title, subtitle, type, excerpt, datetime: { date } } = eventData;
  const thumbnail = eventThumb || config.eventDefaultFeatureImage;
  const isLecture = (type === 'Lecture' || type === 'Lecture Series');
  const isCourse = (type === 'Self-Paced Course' || type === 'Live Course');
  const isSpecialEvent = (type === 'Special Event');
  const isCompleted = eventData && (eventData.status === 'Completed' || eventData.status === 'Completed - Pending');
  const eventRedirect = `${urls.events}/${eventData.slug}`;
  useAnalytics(`${title}: ${subtitle}`);

  useEffect(() => {
    const result = checkIsFavorite(favorites, eventData);
    if(result !== isFavorite) {
      setIsFavorite(result);
    }
  }, [favorites, eventData, isFavorite]);

  const onClickFavorite = () => {
    if(isFavorite) {
      behaviorAPI.removeFavorite(eventData.id);
      setIsFavorite(false);
    } else {
      behaviorAPI.addFavorite(eventData.id);
      setIsFavorite(true);
    }
  };

  const toggleRegistrationForm = () => {
    setRegistrationFormDisplayed(!registrationFormDisplayed);
    setTimeout(() => { 
      scroller.scrollTo('registration-scroll', { smooth: true });
    }, 50);
  };

  const memberRegistrationComplete = (error, data) => {
    setRegistrationFormDisplayed(false);
    if(error) {
      setMemberRegistration({ error });
    } else {
      setMemberRegistration({ success: true });
    }
  }

  return (
    <div className="EventDetail-heading">
      <ShareMeta title={`${title}: ${subtitle}`} excerpt={excerpt} image={thumbnail} />
      <div className="heading">
        <div className="Feature-container" style={{backgroundImage: `url(${thumbnail})`}}>
          <div className="actions">
            { user.email &&
              <Fragment>
                { isFavorite? 
                  <div className="favorite is-favorite" onClick={onClickFavorite}><Icon type={IconTypes.FavoriteFilled} /></div> :
                  <div className="favorite not-favorite" onClick={onClickFavorite}><Icon type={IconTypes.Favorite} /></div> }
              </Fragment>
            }
          </div>
          <div className="info-bar">
            <p className="type">{type}</p>
          </div>
        </div>
        <div className="content">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
          { !isSmallView && isCompleted && (isLecture || isSpecialEvent) &&
            <DetailMedia 
              eventData={eventData} 
              onOpenClassroom={onOpenClassroom} 
              isCompleted={isCompleted}
            />
          }
          { !isSmallView && !isCompleted && (isLecture || isSpecialEvent) &&
            <EventRegistration
              eventData={eventData}
              referral={referral}
              formDisplayed={registrationFormDisplayed} 
              onOpenForm={toggleRegistrationForm}
              memberRegistration={memberRegistration}
              onOpenClassroom={onOpenClassroom}
            />
          }
          { !isSmallView && isCourse &&
            <DetailCourseAccess eventData={eventData} />
          }
          { !isSmallView && isCompleted && isSpecialEvent && date &&
            <p className="special-event-status">This event took place on {date}</p>
          }
        </div>
      </div>
      { isSmallView && isCompleted && (isLecture || isSpecialEvent) &&
        <DetailMedia 
          eventData={eventData} 
          onOpenClassroom={onOpenClassroom} 
          isCompleted={isCompleted}
        />
      }
      { isSmallView && !isCompleted && (isLecture || isSpecialEvent) &&
        <EventRegistration eventData={eventData} referral={referral} formDisplayed={registrationFormDisplayed} 
          onOpenForm={toggleRegistrationForm} memberRegistration={memberRegistration} onOpenClassroom={onOpenClassroom} />
      }
      { isSmallView && isCourse &&
        <DetailCourseAccess eventData={eventData} />
      }
      { isSmallView && isCompleted && isSpecialEvent && date &&
        <p className="special-event-status">This event took place on {date}</p>
      }
      <Collapse className="registration-container" in={registrationFormDisplayed} unmountOnExit>
        <Element name="registration-scroll"></Element>
        <MemberRegistrationForm 
          collectPassword={false} 
          submitLabel="Register" 
          heading="Event Registration"
          onComplete={memberRegistrationComplete} 
          onCancel={toggleRegistrationForm} 
          source="event-registration" 
          contextParams={{ redirect: eventRedirect }}
        />
      </Collapse>
    </div>
  );
}

DetailHeading.propTypes = {
  eventData: PropTypes.object,
  referral: PropTypes.object,
  onOpenClassroom: PropTypes.func
};

function mapStateToProps(state) {
  const { favorites } = state.behavior;
  return { favorites, user: state.user };
}

function mapDispatchToProps(dispatch) {
  return { 
    behaviorAPI: bindActionCreators(behaviorAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailHeading);