import validator from './validator';
import { random } from './utils';

export function cleanObject(obj) {
  let cleanObj = Object.assign({}, obj);
  for(let prop in cleanObj) { 
    if(validator.isNotDefined(cleanObj[prop])) {
      delete cleanObj[prop];
    }
  }
  return cleanObj;
}

export function objectCompare(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

export function mapObject(obj, map, mapFrom, mapTo) {
  let newObj = {};
  map.forEach(item => {
    let fProp = item[mapFrom];
    let tProp = item[mapTo];
    newObj[tProp] = obj[fProp];
  });
  return newObj;
}

export function generateFieldMaps(fields) {
  let fieldMap = {};
  let fieldToNameMap = {};
  // for each field, generate random field name, map to one another
  // random field naming helps prevent autocomplete, may make more difficult for bots
  Object.keys(fields).forEach(key => {
    const field = `field_${random(7)}`;
    fieldMap[key] = field;
    fieldToNameMap[field] = key;
  });
  
  return { fieldMap, fieldToNameMap };
}

export function sortObjArray(arr, fieldName) {
  const sArr = arr.slice();
  sArr.sort((o1, o2) => {
    const v1 = o1[fieldName];
    const v2 = o2[fieldName];
    if(v1 < v2) return -1;
    if(v1 > v2) return 1;
    return 0;
  });
  return sArr;
}

