import React from 'react';
import Icon, { IconTypes } from '../../common/Icon';


function Participation(props) {
  return (
    <div className="participation container">
      <div className="container-content">
        <div className="description">
          <h3 className="section-header">Formation through participation</h3>
          <div className="divider-bar"></div>
          <p className="section-description">
            We believe that genuine transformation and formation in the faith occurs not simply through 
            receiving educational content but through participation.
          </p>
        </div>
        <div className="cards">
          <div className="card">
            <Icon type={IconTypes.WebinarParticipate} avatar={true} />
            <h4>Live Webinars</h4>
            <p>
              Participate in our live learning events. You will have the opportunity to sit in a virtual
              classroom with other students and submit questions for the instructor.
            </p>
          </div>
          <div className="card">
            <Icon type={IconTypes.EditContent} avatar={true} />
            <h4>Study Tools</h4>
            <p>
              Use study tools like notes or bookmarking to annotate critical concepts in lecture videos. 
              Revisit your annotations at a later time to refresh your memory.
            </p>
          </div>
          <div className="card">
            <Icon type={IconTypes.Class} avatar={true} />
            <h4>Certificate Courses</h4>
            <p>
              Take a semester length course live with an instructor or as a self-paced student. These 
              courses offer lectures, readings, assessments, and a certificate.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Participation;