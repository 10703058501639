import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import querystring from 'qs';
import TextField from '@mui/material/TextField';
import * as registrationAPI from '../../../actions/registration/member';
import RegistrationProviders from './providers/RegistrationProviders';
import Form from '../../common/form/Form';
import FormWrapper from '../../common/form/FormWrapper';
import CheckField from '../../common/form/CheckField';
import Icon, { IconTypes } from '../../common/Icon';
import Heading from '../../common/Heading';
import PopMessage from '../../common/messages/PopMessage';
import { cleanSpamCheckInput, cleanEmailInput } from '../../../utils/input';
import { generateFieldMaps } from '../../../utils/object';
import validator from '../../../utils/validator';
import errors from '../../../config/errors';
import urls from '../../../config/urls';
import stateConfig from '../../../config/state';
import config from '../../../config/config';

const defaultFields = {
  email: '',
  emailConfirmation: '',
  salutation: '',
  firstName: '',
  lastName: '',
  password: '',
  passwordConfirmation: '',
  check: '',
  source: ''
};

function MemberRegistrationForm({ 
    // param list
    collectSalutation,
    collectPassword,
    source,
    email,
    cancelLabel, 
    heading,
    submitLabel,
    onComplete,
    onCancel,
    contextParams,
    membershipRegistration, 
    registrationAPI
  }) {
  
  const [fields, setFields] = useState(defaultFields);
  const [fieldMap, setFieldMap] = useState({});
  const [fieldToNameMap, setFieldToNameMap] = useState({});
  const [acknowledgeSelection, setAcknowledgeSelection] = useState(false);
  const [submission, setSubmission] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [popMessage, setPopMessage] = useState(null);
  const [timer, setTimer] = useState(null);
  const location = useLocation();
  const { token, check: mathProblem, registered } = membershipRegistration;
  const context = { ...contextParams, source };
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    return () => {
      if(timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  useEffect(() => {
    if(submission && membershipRegistration.responseStatus === stateConfig.responseStatus.ERROR) {
      if(membershipRegistration.responseCode === errors.SpamValidationError.code) {
        setSubmission(false);
        setErrorData({ 'check': validator.codes.FAILED_MATCHES_VALUE });
        setPopMessage({ message: 'Please correct the specified fields and re-submit your registration.' });
      } else {
        onComplete(membershipRegistration.responseCode);
      }
    }

    if(submission && registered && membershipRegistration.responseStatus === stateConfig.responseStatus.COMPLETE) {
      if(fields.email && fields.emailConfirmation) {
        onComplete(null, fields);
      }
    }
  }, [submission, fields, registered, membershipRegistration, membershipRegistration.responseStatus, onComplete]);

  initialize = () => {
    // check for prefilled email
    const params = querystring.parse(location.search.substring(1));
    const prefilledEmail = cleanEmailInput(params.email) || '';
    
    // set up initial field values
    let fieldData = { ...fields };
    fieldData.email = email || prefilledEmail;
    fieldData.source = source;
    setFields(fieldData);

    // generate field maps, so we can use randomly named fields to thwart spam
    const maps = generateFieldMaps(fields);
    setFieldMap(maps.fieldMap);
    setFieldToNameMap(maps.fieldToNameMap);

    // tracks how long since the form has been loaded, expires the form after 29 minutes
    const t = setTimeout(() => {
      onComplete(errors.MemberRegistrationExpired.code);
    }, 1000 * 60 * 29); // 29 minutes
    setTimer(t);

    registrationAPI.getMemberRegistrationToken();
  };

  const handleFieldChange = (event) => {
    const fieldData = { ...fields };
    const name = fieldToNameMap[event.target.name];
    fieldData[name] = event.target.value;
    setFields(fieldData);
  }

  const handleAcknowledgement = (event) => {
    setAcknowledgeSelection(event.target.checked);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(!acknowledgeSelection) {
      setPopMessage({ message: 'You must acknowledge your acceptance of the privacy policy.' });
      return;
    }

    if(!validate()) {
      return;
    }

    const registration = { token, data: fields };
    registration.data.check = cleanSpamCheckInput(registration.data.check);
    setSubmission(true);
    setErrorData({});
    registrationAPI.createMembership(registration);
  }

  const validate = () => {
    let rules = [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'email' },
      { rule: validator.rules.EMAIL_MATCH, prop: 'emailConfirmation', against: fields.email },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'salutation', against: validator.patterns.SALUTATION, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'firstName', against: validator.patterns.NAME, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'firstName' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'lastName', against: validator.patterns.NAME, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'lastName' },
      { rule: validator.rules.PASSWORD_CHECK, prop: 'password', empty: true },
      { rule: validator.rules.PASSWORD_MATCH, prop: 'passwordConfirmation', against: fields.password },
      { rule: validator.rules.IS_DEFINED, prop: 'check' }
    ];

    if(collectPassword) {
      rules.push({ rule: validator.rules.IS_DEFINED, prop: 'password' });
    }
    
    const validationErrors = validator.validate(fields, rules);
    if(validationErrors) {
      setErrorData(validationErrors);
      setPopMessage({ message: 'Please correct the specified fields and re-submit your registration.' });
      return false
    }

    return true;
  }

  const clearPopMessage = () => {
    setPopMessage(null);
  }

  return (
    <div id="MemberRegistrationForm" className="panel">
      <FormWrapper squared={true} raised={true}>
        <Icon type={IconTypes.User} avatar={true} />
        { heading &&
          <Heading className="MemberRegistrationForm-heading" variant="h2">
            {heading}
          </Heading>
        }
        <div className="MemberRegistrationForm-providers">
          <RegistrationProviders contextParams={context} />
        </div>
        <div className="MemberRegistrationForm-separator">
          <hr />
          <span className="text">or</span>
        </div>
        <Heading className="MemberRegistrationForm-subheading" variant="h3">
          Complete the registration form below
        </Heading>
        <Form id='MemberRegistrationForm-form' onSubmit={handleSubmit} autoComplete="off"
          submitLabel={submitLabel} secondaryButtonLabel={cancelLabel} submission={submission} 
          onClickSecondary={onCancel}>
          <input type="hidden" name='token' value={token} />
          
          <TextField
            className="FormField-control full first"
            name={fieldMap['email']}
            label="Email *"
            placeholder='Email'
            onChange={handleFieldChange}
            helperText={validator.message(errorData['email'], 'email')}
            error={validator.isDefined(errorData['email'])}
            autoComplete={fieldMap['email']}
            value={fields.email}
            variant="standard"
          />

          <TextField
            className="FormField-control full"
            name={fieldMap['emailConfirmation']}
            label="Confirm your email *"
            placeholder='Email'
            onChange={handleFieldChange}
            helperText={validator.message(errorData['emailConfirmation'], 'email')}
            error={validator.isDefined(errorData['emailConfirmation'])}
            value={fields.emailConfirmation}
            variant="standard"
          />

          { collectSalutation &&
            <TextField
              className="FormField-control full"
              name={fieldMap['salutation']}
              label="Title (e.g. Mr., Mrs., Dr., etc.)"
              placeholder='Title'
              onChange={handleFieldChange}
              helperText={validator.message(errorData['salutation'], 'title')}
              error={validator.isDefined(errorData['salutation'])}
              autoComplete={fieldMap['salutation']}
              value={fields.salutation}
              variant="standard"
            />
          }

          <TextField
            className="FormField-control full"
            name={fieldMap['firstName']}
            label="First Name *"
            placeholder='First Name'
            onChange={handleFieldChange}
            helperText={validator.message(errorData['firstName'], 'first name')}
            error={validator.isDefined(errorData['firstName'])}
            autoComplete={fieldMap['firstName']}
            value={fields.firstName}
            variant="standard"
          />

          <TextField
            className="FormField-control full"
            name={fieldMap['lastName']}
            label="Last Name *"
            placeholder='Last Name'
            onChange={handleFieldChange}
            helperText={validator.message(errorData['lastName'], 'last name')}
            error={validator.isDefined(errorData['lastName'])}
            autoComplete={fieldMap['lastName']}
            value={fields.lastName}
            variant="standard"
          />

          { collectPassword &&
            <div>
              <TextField
                className="FormField-control full"
                name={fieldMap['password']}
                label="Enter an account password"
                type="password"
                onChange={handleFieldChange}
                helperText={validator.message(errorData['password'], 'password')}
                error={validator.isDefined(errorData['password'])}
                value={fields.password}
                variant="standard"
              />

              <TextField
                className="FormField-control full"
                name={fieldMap['passwordConfirmation']}
                label="Confirm your password"
                type="password"
                onChange={handleFieldChange}
                helperText={validator.message(errorData['passwordConfirmation'], 'password')}
                error={validator.isDefined(errorData['passwordConfirmation'])}
                value={fields.passwordConfirmation}
                variant="standard"
              />
            </div>
          }

          <div className="MemberRegistrationForm-spamCheck">
            <p>
              {`Help us separate people from spam. ${mathProblem}`}
            </p>
            <p className="MemberRegistrationForm-spamCheckInstruction">
              (Provide a single numeric answer)
            </p>
            <TextField
              className="FormField-control full"
              name={fieldMap['check']}
              onChange={handleFieldChange}
              helperText={validator.isDefined(errorData['check'])? errors.SpamValidationError.message : ''}
              error={validator.isDefined(errorData['check'])}
              value={fields.check}
              variant="standard"
            />
          </div>

          <div className="MemberRegistrationForm-policy">
            <p>
              Confidentiality is important to us. Please review our <a href={urls.privacyPolicy} target="_blank" rel="noopener noreferrer">privacy policy</a> 
              &nbsp;before submitting your registration.
            </p>
            <CheckField name="acknowledge" label="I have reviewed the privacy policy"
                onChange={handleAcknowledgement} />
          </div>
        
          { popMessage &&
            <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
              type={stateConfig.messageTypes.ERROR}>
              <p>{ popMessage.message }</p>
            </PopMessage>
          }
        </Form>
      </FormWrapper>
    </div>
  );
}

MemberRegistrationForm.defaultProps = {
  collectSalutation: false,
  collectPassword: true,
  source: 'member-registration',
  email: '',
  cancelLabel: 'Cancel'
};

MemberRegistrationForm.propTypes = {
  heading: PropTypes.string,
  submitLabel: PropTypes.string,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  contextParams: PropTypes.object
};

function mapStateToProps(state) {
  return {
    membershipRegistration: state.membershipRegistration
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    registrationAPI: bindActionCreators(registrationAPI, dispatch) 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberRegistrationForm);