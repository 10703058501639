import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import urls from '../../../config/urls';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


function MediaHeading({ title, description, data }) {

  const navigate = () => {
    window.location = data.actionLink;
  };

  return (
    <div className="MediaHeading">
      <div className="MediaHeading-content">
        <div className="media">
          { data.mediaType === 'image' &&
            <div className="responsive-image-container">
              <img src={data.image} width="348" height="196" alt="Feature" />
            </div>
          }
          { data.mediaType === 'video' &&
            <div className="responsive-iframe-container">
              <iframe
                src={`${urls.vimeoPlayer}/${data.videoId}`} 
                title="Feature video"
                frameBorder="0" allow="fullscreen" 
                allowFullScreen 
              />
            </div>
          }
        </div>
        <div className="main">
          <h2 className={`${!isEmpty(description)? 'with-desc' : 'without-desc'}`} dangerouslySetInnerHTML={{__html: title}} />
          { !isEmpty(description) &&
            <p dangerouslySetInnerHTML={{__html: description}} />
          }
          <div className="action">
            <Button className="MediaHeading-button" variant="outlined" onClick={navigate}>
              {data.actionTitle}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

MediaHeading.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object
};

export default MediaHeading;