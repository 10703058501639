import React, { useState } from 'react'; 
import Accordion from '@mui/material/Accordion'; 
import AccordionSummary from '@mui/material/AccordionSummary'; 
import AccordionDetails from '@mui/material/AccordionDetails'; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import TitleWithSubtext from '../../common/TitleWithSubtext';
import PropTypes from 'prop-types';

function TechTips(props) {

const { content } = props; 
const { value: titleWithSubtext } = content['program.techTips']; 
const { value: accordion } = content['techTips.accordion']; 

const [expanded, setExpanded] = useState(false);

const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);
};

	return (
		<div>
			<TitleWithSubtext titleWithSubtext={titleWithSubtext} />
			<div className="accordion-flex-container">
				<div className="accordion-container">
				{
					accordion.map((item, index) => {
						return (
						<Accordion expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} key={index}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon className="accordion-icon"/>}
								aria-controls={`panel${index}bh-content`}
								id={`panel${index}bh-header`}>
								{item.title}
							</AccordionSummary>
							<AccordionDetails>
								<div dangerouslySetInnerHTML={{__html: item.content }} />
							</AccordionDetails>
						</Accordion>
						)
					})
				}
				</div>
			</div>
		</div>
	)
}

TechTips.propTypes = {
	content: PropTypes.object
}

export default TechTips; 