import { EVENT_LOAD_START, EVENT_LOAD_COMPLETE, EVENT_LOAD_ERR } from '../actionTypes';
import * as events from '../../api/content/events';
import * as behavior from '../../api/behavior';
import stateConfig from '../../config/state';

const itemKey = stateConfig.keys.EVENT;
const listKey = stateConfig.keys.EVENT_LIST;

export function getEvents() {
  const slug = 'events';
  return async dispatch => {
    dispatch({ type: EVENT_LOAD_START, key: listKey, objectId: slug });
    try {
      let result = await events.getEvents();
      dispatch({ type: EVENT_LOAD_COMPLETE, data: result.data, key: listKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: EVENT_LOAD_ERR, error: err, key: listKey, objectId: slug });
    }
  };
}

export function getEventListBySlug(slug, linkedList = false) {
  return async dispatch => {
    dispatch({ type: EVENT_LOAD_START, key: listKey, objectId: slug });
    try {
      let result = await events.getEventListBySlug(slug, linkedList);
      dispatch({ type: EVENT_LOAD_COMPLETE, data: result.data, key: listKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: EVENT_LOAD_ERR, error: err, key: listKey, objectId: slug });
    }
  };
}

export function getEventBySlug(slug) {
  return async dispatch => {
    dispatch({ type: EVENT_LOAD_START, key: itemKey, objectId: slug });
    try {
      let result = await events.getEventBySlug(slug);
      dispatch({ type: EVENT_LOAD_COMPLETE, data: result.data, key: itemKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: EVENT_LOAD_ERR, error: err, key: itemKey, objectId: slug });
    }
  };
}

export function getFavorites() {
  const slug = 'favorites';
  return async dispatch => {
    dispatch({ type: EVENT_LOAD_START, key: listKey, objectId: slug });
    try {
      let result = await behavior.getFavoritesContent();
      dispatch({ type: EVENT_LOAD_COMPLETE, data: result.data, key: listKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: EVENT_LOAD_ERR, error: err, key: listKey, objectId: slug });
    }
  };
}

export function getWatched() {
  const slug = 'watched';
  return async dispatch => {
    dispatch({ type: EVENT_LOAD_START, key: listKey, objectId: slug });
    try {
      let result = await behavior.getWatchedContent();
      dispatch({ type: EVENT_LOAD_COMPLETE, data: result.data, key: listKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: EVENT_LOAD_ERR, error: err, key: listKey, objectId: slug });
    }
  };
}

export function browseEvents() {
  const slug = 'browse';
  return async dispatch => {
    dispatch({ type: EVENT_LOAD_START, key: listKey, objectId: slug });
    try {
      let result = await events.browseEvents();
      dispatch({ type: EVENT_LOAD_COMPLETE, data: result.data, key: listKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: EVENT_LOAD_ERR, error: err, key: listKey, objectId: slug });
    }
  };
}

export function getCourses() {
  const slug = stateConfig.keys.COURSE_LIST;
  return async dispatch => {
    dispatch({ type: EVENT_LOAD_START, key: listKey, objectId: slug });
    try {
      let result = await events.getCourses();
      dispatch({ type: EVENT_LOAD_COMPLETE, data: result.data, key: listKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: EVENT_LOAD_ERR, error: err, key: listKey, objectId: slug });
    }
  };
}
