import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as progressAPI from '../../../../actions/progress';
import CourseQuestion from './questions/CourseQuestion';
import QuestionSync from './questions/QuestionSync';
import validator from '../../../../utils/validator';
const { isEmpty, isDefined, isNotDefined } = validator;


function CourseQuestions({ courseId, assessment, started, inProgress, onComplete, progressAPI }) {
  const [results, setResults] = useState([]);
  const [answered, setAnswered] = useState([]);
  const [assessmentStarted, setAssessmentStarted] = useState(0);
  const [lastSaved, setLastSaved] = useState(0);
  const [lastChange, setLastChange] = useState(0);

  const handleUnload = useCallback(event => {
    if(lastChange > lastSaved) {
      event.preventDefault();
      event.returnValue = 'You have unsaved changes, are you sure you want to leave?';
      return 'You have unsaved changes, are you sure you want to leave?';
    }
  }, [lastSaved, lastChange]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  useEffect(() => {
    if(started !== assessmentStarted) {
      let rArr = assessment.questions.map((question, index) => {
        return { index, wpId: question.wpId, title: question.title, value: null, result: 'incorrect', compare: null };
      });
      setResults(rArr);
      setAnswered([]);
      setAssessmentStarted(started);
      setLastSaved(0);
      setLastChange(0);
    }
  }, [started, assessment, assessmentStarted]);

  const onQuestionChange = data => {
    if(results.length === 0 || data.compare === null) return;

    let arr = results.slice();
    let index = arr.findIndex(item => {
      return item.wpId === data.wpId
    });
    
    if(isDefined(arr[index]) && arr[index].compare !== data.compare) {
      arr[index] = data;
      let ansArr = arr.filter(item => item.value !== null);
      setLastChange(Date.now());
      setResults(arr);
      setAnswered(ansArr);
    }
  };

  const onSave = () => {
    const time = Date.now();
    setLastSaved(time);
    setLastChange(time);
    const data = {
      status: 'started',
      answers: answered,
      passed: false
    };
    progressAPI.updateAssessment(courseId, assessment.id, data);
  };

  const onSubmit = () => {
    const time = Date.now();
    setLastSaved(time);
    setLastChange(time);
    const correct = answered.reduce((n, a) => a.result === 'correct'? n+1 : n, 0);
    const score = parseInt((correct / answered.length) * 100);
    const data = {
      status: 'complete',
      answers: answered,
      score,
      passed: score >= assessment.passingPercentage
    };
    progressAPI.updateAssessment(courseId, assessment.id, data);
    onComplete({
      answers: answered,
      correct,
      total: answered.length,
      score: parseInt((correct / answered.length) * 100)
    });
  };

  const findMatch = (question, index) => {
    if(isEmpty(inProgress)) return null;
    let match = inProgress.answers.find(answer => question.wpId === answer.wpId || question.title === answer.title);
    if(isNotDefined(match)) {
      match = inProgress.answers.find(answer => index === answer.index);
    }
    return match || null;
  };

  return (
    <div className="CourseQuestions">
      <div className="CourseQuestions-status-bar App-floating-toolbar">
        { results.length === assessment.questions.length &&
          <QuestionSync 
            questionTotal={assessment.questions.length} 
            answered={answered.length}
            lastChange={lastChange}
            lastSaved={lastSaved}
            onSave={onSave}
            onSubmit={onSubmit} 
          />
        }
      </div>
      {
        assessment.questions.map((question, index) => {
          return (
            <CourseQuestion 
              key={`question-${index}`} 
              question={question} 
              index={index} 
              scored={findMatch(question, index)}
              onChange={onQuestionChange}
            />
          );
        })
      }
    </div>
  );
}

CourseQuestions.propTypes = {
  courseId: PropTypes.string,
  assessment: PropTypes.object,
  started: PropTypes.number,
  inProgress: PropTypes.object,
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { 
    progressAPI: bindActionCreators(progressAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseQuestions);
