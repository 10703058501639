import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button } from '@mui/material'; 
import Icon, { IconTypes } from '../../../common/Icon';  
import Slider from '@mui/material/Slider'; 
import { convertSecondsIntoTimeDisplay } from '../../../../utils/format'; 
import PropTypes from 'prop-types';
import validator from '../../../../utils/validator'; 
const { isEmpty } = validator; 

export default function AudioControls({ audio, audioEl, actions, duration }) {
  const [isPlaying, setIsPlaying] = useState(false); 
  const [position, setPosition] = useState(0); 
  const [playbackRate, setPlaybackRate] = useState(1); 
  
  const togglePlaying = event => {
    setIsPlaying(!isPlaying);
  }

  const moveForward = event => {
    audioEl.current.currentTime += 10;
  }

  const moveBackward = event => {
    audioEl.current.currentTime -= 10;
  }

  const changePlaybackRate = event => {
    if(playbackRate === 2){
      audioEl.current.playbackRate = 1; 
      setPlaybackRate(1); 
    } else if (playbackRate === 1.5){
      audioEl.current.playbackRate = 2;
      setPlaybackRate(2); 
    } else {
      audioEl.current.playbackRate += .25;
      setPlaybackRate(playbackRate + .25); 
    }
  }

  const playAnimationRef = useRef(); 

  const repeat = useCallback(() => {
    if(isEmpty(audioEl) || isEmpty(audioEl.current)) return;
    
    const currentTime = audioEl.current.currentTime;
    setPosition(currentTime); 

    playAnimationRef.current = requestAnimationFrame(repeat); 
  }, [audioEl, setPosition])

  const onSliderChange = (event, updatedValue) => {
    setPosition(updatedValue); 
    audioEl.current.currentTime = updatedValue; 
  }

  useEffect(() => { 
    if(isPlaying) {
      audioEl.current.play(); 

    } else {
      audioEl.current.pause(); 
    }
    playAnimationRef.current = requestAnimationFrame(repeat); 
  }, [isPlaying, audioEl, repeat]);

  function valueLabelFormat(value) {
    return convertSecondsIntoTimeDisplay(value); 
  }

  let actionButtons = null; 
  if(actions) {
    actionButtons = actions.map((action, index) => {
      return (
       <div key={index}>
        {action}
       </div>
      )
    })
  }

  return (
    <div className="audioControls">
      <div className="audioControlPanel">
        <Button variant="text" onClick={moveBackward}>
          <div className="audioSkipBtn">
            <Icon type={IconTypes.ArrowRotateLeft} size="xl" />
            <div className="audioSkipBtnLabel">10s</div>
          </div>
        </Button>
        <Button className="playBtn" variant="text" onClick={togglePlaying}> {isPlaying? <Icon type={IconTypes.Pause} size="2xl" /> : <Icon type={IconTypes.Play} size="2xl" />}</Button>
        <Button variant="text" onClick={moveForward}>
          <div className="audioSkipBtn">
              <Icon type={IconTypes.ArrowRotateRight} size="xl" />
              <div className="audioSkipBtnLabel">10s</div>
          </div>
        </Button>
      </div>
      <Slider
        aria-label="time-indicator"
        value={position}
        valueLabelDisplay="on"
        valueLabelFormat={valueLabelFormat}
        min={0}
        max={duration}
        onChange={onSliderChange}
      />
      <div className="audioSubControlPanel">
        <div className="audioFFBtn">
        <Button variant="text" size="small" onClick={changePlaybackRate}>
            <div>{`${playbackRate}x`}</div>
        </Button>
        </div>
        <a href={audio.url} target="_blank" rel="noopener noreferrer">
          <Button variant="text" className="downloadBtn" size="small" endIcon={<Icon type={IconTypes.Download} />}>
            Download
          </Button>
        </a>
        {actionButtons}
      </div>
    </div>
  );
}

AudioControls.propTypes = {
  audio: PropTypes.object,
  audioEl: PropTypes.object,
  actions: PropTypes.array,
  duration: PropTypes.number
};
