import React from 'react';
import useEventTracking from '../../../../hooks/useEventTracking';
import config from '../../../../config/config';
import analyticsEvents from '../../../../config/analytics/events';


function DonateOther() {
  const trackEvent = useEventTracking([ 
    config.trackingLoggers.googleEvents, 
    config.trackingLoggers.hubEvents 
  ]);

  return (
    <div className="Donate-other">
      <div className="mail">
        <h4>Donate by Mail</h4>
        <p>
          <strong>Please make checks payable to:</strong>
          Institute of Catholic Culture<br />
          P.O. Box 670<br />
          Front Royal, VA 22630
        </p>
      </div>
      <div className="paypal">
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank"
          onSubmit={() => trackEvent(analyticsEvents.donationRedirect('paypal'))}>
          <h4>Donate with PayPal</h4>
          <div className="paypal-donations">
            <input name="cmd" type="hidden" value="_donations" />
            <input name="bn" type="hidden" value="InstituteOfCatholicCulture" />
            <input name="business" type="hidden" value="admin@instituteofcatholicculture.org" />
            <input name="currency_code" type="hidden" value="USD" />
            <input alt="PayPal - The safer, easier way to pay online." name="submit" src="https://www.paypal.com/en_US/i/btn/btn_donateCC_LG.gif" type="image" />
            <img src="https://www.paypal.com/en_US/i/scr/pixel.gif" alt="" width="1" height="1" />
          </div>
        </form>
      </div>
      <div className="stock">
        <h4>Donate Stock</h4>
        <p>
          The Institute may also accept gifts via stock. Please call our office at (540) 635 - 7155 if 
          you would like assistance in making your gift.
        </p>
      </div>
    </div>
  );
}

export default DonateOther;