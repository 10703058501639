import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import SecondaryAuth from './SecondaryAuth';
import SecondaryNonAuth from './SecondaryNonAuth';

function SecondaryNavMenu(props) {
  const { user } = props;
  return (
    <Fragment>
      { user.email &&
        <SecondaryAuth user={user} />
      }
      { !user.email &&
        <SecondaryNonAuth />
      }
    </Fragment>
  )
}

SecondaryNavMenu.propTypes = {
  user: PropTypes.object
};

export default SecondaryNavMenu;