import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import useAnalytics from '../../../../hooks/useAnalytics';

function CourseTitle({ title, subtitle }) {
  useAnalytics(`${title}: ${subtitle}`);
  return (
    <Fragment>
      <h1>{title}</h1>
      <h2>{subtitle}</h2>
    </Fragment>
  );
}

CourseTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default CourseTitle;
