import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics';
import * as appDisplay from '../../../actions/appDisplay';
import * as profileAPI from '../../../actions/profile';
import Icon, { IconTypes } from '../../common/Icon';
import LoadingMessage from '../../common/messages/LoadingMessage';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import errors from '../../../config/errors';
import urls from '../../../config/urls';
import { resetViewToTopOfPage } from '../../../utils/utils';
import Paper from '@mui/material/Paper';
import MemberInfo from './MemberInfo';
import MagdalaMemberInfo from './MagdalaMemberInfo';


function PersonalInfo({ profile, profileAPI, appDisplay }) {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  useAnalytics('Profile: Personal Information');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(!submitting && profile.responseStatus === stateConfig.responseStatus.ERROR) {
      if(profile.responseCode === errors.ReAuthRequired.code) {
        navigate(`${urls.login}?ra=profile`);
      } else {
        appDisplay.displayPageMessage({
          message: 'Failed to load the member profile.',
          type: stateConfig.messageTypes.ERROR,
          time: 0
        });
      }
      setSubmitting(false);
    }
  }, [submitting, profile, profile.responseStatus, appDisplay, navigate]);

  useEffect(() => {
    if(submitting && profile.responseStatus === stateConfig.responseStatus.ERROR) {
      appDisplay.displayPageMessage({
        message: 'Failed to update the member profile.',
        type: stateConfig.messageTypes.ERROR,
        time: 0
      });
      setSubmitting(false);
    }

    if(submitting && profile.responseStatus === stateConfig.responseStatus.COMPLETE && profile.dataUpdated) {
      resetViewToTopOfPage();
      setSubmitting(false);
      appDisplay.displayPageMessage({
        message: 'Your member profile was successfully updated.',
        type: stateConfig.messageTypes.SUCCESS
      });
    }
  }, [submitting, profile, profile.responseStatus, profile.dataUpdated, appDisplay, navigate]);

  initialize = () => {
    resetViewToTopOfPage();
    profileAPI.getProfile();
  };

  return (
    <div id="MemberProfile">
      { !profile.data && profile.responseStatus !== stateConfig.responseStatus.ERROR && 
        <LoadingMessage message="Loading profile..." /> 
      }
      { profile.data &&
        <Paper id="MemberProfile-data" square={true}>
          <Icon type={IconTypes.UserInfo} avatar={true} />
          <h2>Personal Information</h2>
          <div id="MemberProfile-email" className="MemberProfile-section">
            <p className="MemberProfile-dataType">Email</p>
            <p className="MemberProfile-dataDisplay">
              {profile.data.email}
              <span className="email-message">
                If you would like to update your email&nbsp;
                <a href={urls.contactUs} target="_blank" rel="noopener noreferrer">use our contact form.</a>
              </span>
            </p>
          </div>

          { profile.data.type === 'magdala-member'? <MagdalaMemberInfo /> : <MemberInfo /> }
        </Paper>
      }
      { profile.data &&
        <div className="MemberProfile-deletion">
          Note: You have the right to be forgotten at any time by <Link to={urls.accountDeletion}>deleting your account</Link>.
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    profile: state.profile
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    appDisplay: bindActionCreators(appDisplay, dispatch),
    profileAPI: bindActionCreators(profileAPI, dispatch) 
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
