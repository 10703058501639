import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import countries from '../../../../config/data/countries';
import states from '../../../../config/data/states';
import validator from '../../../../utils/validator';


function DonateContact({ fields, errors, onFieldChange }) {
  const isForeign = fields.country && fields.country !== 'United States';

  return (
    <div className="DonateContact">
      <h3>Billing Details</h3>
      <div className="contact">
        <Select 
          className="FormField-control full country"
          variant="outlined" 
          name="country" 
          value={fields.country} 
          onChange={onFieldChange} 
          error={validator.isDefined(errors['country'])}
          helperText={validator.message(errors['country'], 'country')}
        >
          { 
            countries.data.map(item => (<MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>)) 
          }
        </Select>
        <TextField 
          className="FormField-control full addressLine1"
          variant="outlined" 
          label="Address Line 1" 
          name="addressLine1" 
          placeholder="Address Line 1" 
          value={fields.addressLine1} 
          onChange={onFieldChange} 
          error={validator.isDefined(errors['addressLine1'])} 
          helperText={validator.message(errors['addressLine1'], 'address line 1')}
        />
        <TextField 
          className="FormField-control full addressLine2"
          variant="outlined" 
          label="Address Line 2" 
          name="addressLine2" 
          placeholder="Address Line 2" 
          value={fields.addressLine2} 
          onChange={onFieldChange} 
          error={validator.isDefined(errors['addressLine2'])} 
          helperText={validator.message(errors['addressLine2'], 'address line 2')}
        />
        { isForeign &&
          <Fragment>
            <TextField 
              className="FormField-control full addressLine3"
              variant="outlined" 
              label="Address Line 3" 
              name="addressLine3" 
              placeholder="Address Line 3" 
              value={fields.addressLine3} 
              onChange={onFieldChange} 
              error={validator.isDefined(errors['addressLine3'])} 
              helperText={validator.message(errors['addressLine3'], 'address line 3')}
            />
            <TextField 
              className="FormField-control full phone"
              variant="outlined" 
              name="phone" 
              label="Phone Number" 
              value={fields.phone}
              placeholder="Phone Number" 
              onChange={onFieldChange}
              error={validator.isDefined(errors['phone'])} 
              helperText={validator.message(errors['phone'], 'phone number')}
            />
          </Fragment>
        }
        { !isForeign &&
          <div>
            <div className="two-column">
              <TextField 
                className="FormField-control city"
                variant="outlined" 
                label="City" 
                name="city" 
                placeholder="City" 
                value={fields.city} 
                onChange={onFieldChange} 
                error={validator.isDefined(errors['city'])} 
                helperText={validator.message(errors['city'], 'city')}
              />
              <Select 
                className="FormField-control state" 
                variant="outlined" 
                name="state" 
                value={fields.state} 
                onChange={onFieldChange}
                error={validator.isDefined(errors['state'])}
                helperText={validator.message(errors['state'], 'state')}
              >
                { 
                  states.data.map(item => (<MenuItem key={item.value} value={item.value}>{item.display}</MenuItem>)) 
                }
              </Select>
            </div>
            <div className="two-column">
              <TextField 
                className="FormField-control postalCode"
                variant="outlined" 
                name="postalCode" 
                label="Zip Code" 
                placeholder="Zip Code" 
                value={fields.postalCode} 
                onChange={onFieldChange} 
                error={validator.isDefined(errors['postalCode'])} 
                helperText={validator.message(errors['postalCode'], 'zip code')}
              />
              <TextField 
                className="FormField-control phone"
                variant="outlined" 
                name="phone" 
                label="Phone Number" 
                value={fields.phone}
                placeholder="Phone Number" 
                onChange={onFieldChange}
                error={validator.isDefined(errors['phone'])} 
                helperText={validator.message(errors['phone'], 'phone number')}
              />
            </div>
          </div>
        }
      </div>
    </div>
  );
}

DonateContact.propTypes = {
  fields: PropTypes.object,
  errors: PropTypes.object,
  onFieldChange: PropTypes.func
};

export default DonateContact;