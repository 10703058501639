import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useAnalytics from '../../../hooks/useAnalytics';
import * as eventsAPI from '../../../actions/content/events';
import EventList from '../events/display/EventList';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import SEOMeta from '../../common/SEOMeta';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Courses({ events, favorites, eventsAPI }) {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState(null);
  const [error, setError] = useState(null);
  const stateId = `${stateConfig.keys.EVENT_LIST}.${stateConfig.keys.COURSE_LIST}`;
  useAnalytics('Courses');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const data = events.byId[stateId];
    if(loading && isEmpty(courses) && isNotEmpty(data) && events.ids) {
      if(data.error) {
        setError(data.error);
      } else {
        setCourses(data);
      }
      setLoading(false);
    }
  }, [loading, courses, stateId, events, events.ids]);

  initialize = () => {
    resetViewToTopOfPage();
    const data = events.byId[stateId];
    if(data && !data.error) {
      setCourses(data);
      setLoading(false);
    } else {
      eventsAPI.getCourses();
    }
  };

  const clearPopMessage = () => {
    setError(null);
  };
  
  return (
    <div className="MainContent-container">
      { isEmpty(courses) && isEmpty(error) && <LoadingMessage message="Loading..." /> }
      
      <div className="EventList-container courses">
        { isNotEmpty(error) &&
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>We could not load the requested page.</p>
          </PopMessage>
        }

        { isNotEmpty(courses) &&
          <SEOMeta 
            title="Courses" 
            description="In-depth live and self-paced certificate courses on the Catechism, Sacred Scripture, and more." />
        }

        {/*
          Note: the cards per render has been set artificially high to ensure all courses are displayed, but
          courses lists should be changed back to a reasonable value once the courses page has been revised.
        */}
        { isNotEmpty(courses) && 
          <EventList events={courses.results} displayType={config.eventListTypes.BROWSE}
            heading="Courses" cardsPerRender={50} useHeadingForPage={true} />
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    events: state.events, 
    favorites: state.behavior.favorites 
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    eventsAPI: bindActionCreators(eventsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
