import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Collapse from '@mui/material/Collapse';
import urls from '../../../../config/urls';
import config from '../../../../config/config';
const { deviceBreakpoints } = config;


function DetailDescription({ eventData }) {
  const [displayInstructors, setDisplayInstructors] = useState([]);
  const { description, instructors } = eventData;
  const isSmallView = useMediaQuery({
    query: `(max-width: ${deviceBreakpoints.small}px)`
  });

  const showInstructorBio = instructorId => event => {
    let instructors = displayInstructors.slice();
    instructors.push(instructorId);
    setDisplayInstructors(instructors);
  }
  
  return (
    <div className="EventDetail-description">
      { description &&
        <Fragment>
          <h3>Event Description</h3>
          <div className="summary" dangerouslySetInnerHTML={{__html: description}} />
        </Fragment>
      }
      <div className="instructors">
        <h3>{ instructors.length === 1? 'Instructor' : 'Instructors' }</h3>
        {
          instructors.map(instructor => {
            return (
              <div key={instructor.id} className="instructor">
                <div className="thumbnail">
                  <img src={instructor.imageURL} alt={`${instructor.name}`} />
                </div>
                <div>
                  <h4 className="name">{instructor.name}</h4>
                  { instructors.length === 1 && !isSmallView && // display bio by default
                    <div className="bio" dangerouslySetInnerHTML={{__html: instructor.bio}} />
                  }
                  { (instructors.length > 1 || isSmallView) && // display expandable bio
                    <Collapse in={displayInstructors.includes(instructor.id)}>
                      <div className="bio" dangerouslySetInnerHTML={{__html: instructor.bio}} />
                    </Collapse>
                  }
                  <div className="links">
                    { (instructors.length > 1 || isSmallView) && !displayInstructors.includes(instructor.id) && 
                      <button className="bio-link link-display" onClick={showInstructorBio(instructor.id)}>
                        View bio
                      </button>
                    }
                    <Link className="more-link" to={urls.instructor.replace(':instructorId', instructor.id)}>
                      More from this instructor
                    </Link>
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

DetailDescription.propTypes = {
  eventData: PropTypes.object
};

export default DetailDescription;