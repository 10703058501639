import React from 'react';
import PropTypes from 'prop-types';
import Form from '../../../common/form/Form';
import validator from '../../../../utils/validator';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';


export default function ProfilePhone(props) {
  const { editing, errors, data, exists, onFieldChange, onSubmit, onDelete } = props;
  const phone = data || {};
  return (
    <div>
      { editing && 
        <div className="MemberProfile-itemEdit">
          <Form id='ProfileEditPhone-form' onSubmit={onSubmit} 
            onClickSecondary={exists? onDelete : null}
            secondaryButtonLabel="Delete" autoComplete="off" submitLabel='Save'>
            <FormControl variant="standard" className="full">
              <InputLabel id="phone-type-select-label">Phone Type</InputLabel>
              <Select 
                className="FormField-control full" 
                variant="standard" 
                name="type"
                label="Type"
                labelId="phone-type-select-label"
                value={phone.type} 
                onChange={onFieldChange}
                error={validator.isDefined(errors['type'])}
                helperText={validator.message(errors['type'], 'type')}
              >
                <MenuItem value=""><em>Select phone type</em></MenuItem>
                <MenuItem value="home">Home</MenuItem>
                <MenuItem value="work">Work</MenuItem>
                <MenuItem value="mobile">Mobile</MenuItem>
              </Select>
              { validator.isDefined(errors['type']) &&
                <FormHelperText className="FormField-error">
                  {validator.message(errors['type'], 'type')}
                </FormHelperText>
              }
            </FormControl>
            <TextField
              className="FormField-control full"
              name="number"
              label="Phone Number"
              placeholder='Phone Number'
              onChange={onFieldChange}
              helperText={validator.message(errors['number'], 'phone number')}
              error={validator.isDefined(errors['number'])}
              value={phone.number}
              variant="standard"
            />
          </Form>
        </div>
      }
    </div>
  );
}

ProfilePhone.defaultProps = {
  editing: false,
  exists: false,
  errors: {}
};

ProfilePhone.propTypes = {
  data: PropTypes.object,
  onFieldChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func
};

export function phoneFromProfile(data) {
  const phone = (data.phones && data.phones.length > 0)? data.phones[0] : {};
  return {
    id: phone._id,
    number: phone.number || '', 
    type: phone.type || ''
  };
}

export function formatPhone(data) {
  const { number, type } = data;
  let display = 'No phone number on record';
  if(number) {
    display = `${number} (${type})`;
  }

  return display;
}

export function validatePhone(data) {
  const validationErrors = validator.validate(data, [
    { rule: validator.rules.IS_DEFINED, prop: 'type' },
    { rule: validator.rules.MATCHES_PATTERN, prop: 'number', against: validator.patterns.PHONE, empty: true },
    { rule: validator.rules.IS_DEFINED, prop: 'number' }
  ]);

  return validationErrors? validationErrors : { valid: true };
}

