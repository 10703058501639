import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Icon, { IconTypes } from '../../../common/Icon';
import urls from '../../../../config/urls';

function DetailEventApplicationStatus({ user }) {
  const { firstName } = user; 

  return (
    <Fragment>
      <div className="EventDetail-event-application-status">
        <div>
          <Icon type={IconTypes.Complete} />
          <span> Thanks for registering, {firstName}!</span>
        </div>
        <p>When the event starts, a red "Join Webinar" button will appear above.</p>
        <p className="max-line-width">If you don't see it, try refreshing your page. If you have any questions, please <a href={urls.contactUs}>contact us</a>. Please ensure you are logged into your ICC account to access the event.</p>
        <p>A recording of the lecture will be posted here following the event.</p>
      </div>
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(DetailEventApplicationStatus);
