import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as eventsAPI from '../../actions/content/events';
import config from '../../config/config';
import stateConfig from '../../config/state';
import validator from '../../utils/validator';
const { isEmpty, isNotEmpty} = validator;


function useEvent(slug) {
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState(null);
  const [error, setError] = useState(null);
  const events = useSelector(state => state.events);
  const dispatch = useDispatch();
  const stateId = `${stateConfig.keys.EVENT}.${slug}`;
  let initialize = config.emptyFn;

  useEffect(() => {
    if(loading && isEmpty(event)) {
      initialize();
    } else if(isNotEmpty(event) && slug !== event.slug) {
      setLoading(true);
      setEvent(null);
      setError(null);
    }
  }, [loading, event, slug, initialize]);

  useEffect(() => {
    const data = events.byId[stateId];
    if(loading && isEmpty(event) && events.ids && isNotEmpty(data)) {
      if(data.error) {
        setError(data.error);
      } else {
        setEvent(data);
      }
      setLoading(false);
    }
  }, [loading, stateId, event, events.ids, events.byId]);
  
  initialize = () => {
    const data = events.byId[stateId];
    if(!data || data.error) {
      dispatch(eventsAPI.getEventBySlug(slug));
    } else {
      setEvent(data);
    }
  };

  // return null:
  // - if nothing has been loaded
  // - if the slug changed but the new event data hasn't been loaded yet
  return ((event === null && error === null) || (event !== null && event.slug !== slug))? null : { key: slug, event, error };
}

export default useEvent;