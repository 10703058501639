import axios from 'axios';
import urls from '../../config/urls';
import config from '../../config/config';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

const systemPath = '/content/system';

export async function getAlert() {
  return await axios.get(`${urls.hubUIApiRoot}${systemPath}/alert`, { timeout: config.wpTimeout });
}

export async function getAnnualReport() {
  return await axios.get(`${urls.hubUIApiRoot}${systemPath}/annual-report`, { timeout: config.wpTimeout });
}

export async function getMagdalaContent() {
  return await axios.get(`${urls.hubUIApiRoot}${systemPath}/magdala`, { timeout: config.wpTimeout });
}

export async function getPopup() {
  return await axios.get(`${urls.hubUIApiRoot}${systemPath}/popup`, { timeout: config.wpTimeout });
}

export async function getFeatureBanner() {
  return await axios.get(`${urls.hubUIApiRoot}${systemPath}/feature/banner`, { timeout: config.wpTimeout });
}

export async function getVideoPromo() {
  return await axios.get(`${urls.hubUIApiRoot}${systemPath}/feature/video-promo`, { timeout: config.wpTimeout });
}

export async function getPrivacyPolicy() {
  return await axios.get(`${urls.hubUIApiRoot}${systemPath}/policies/privacy`, { timeout: config.wpTimeout });
}

export async function getWebConfig() {
  return await axios.get(`${urls.hubUIApiRoot}${systemPath}/web/config`, { timeout: config.wpTimeout });
}
