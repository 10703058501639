import React from 'react';
import PropTypes from 'prop-types';
import Reflection from './Reflection';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


class ReflectionsList extends React.Component {
  render() {
    const { months, selectedMonth, reflections, onMonthChange, onSelection } = this.props;

    return (
      <div className="GospelReflections-content list">
        <h3>Select the month you would like to view:</h3>
        { months && selectedMonth &&
          <Select 
            name="month" 
            variant="standard"
            value={selectedMonth} 
            onChange={onMonthChange}>
            {
              months.map(month => {
                return (<MenuItem key={month} value={month}>{month}</MenuItem>);
              })
            }
          </Select>
        }
        <div className="reflection-list">
          {
            reflections.map(reflection => {
              return (
                <Reflection key={reflection.id} reflection={reflection} onSelection={onSelection} />
              );
            })
          }
        </div>
      </div>
    );
  }
}

ReflectionsList.propTypes = { 
  months: PropTypes.array, 
  selectedMonth: PropTypes.string, 
  onMonthChange: PropTypes.func,
  reflections: PropTypes.array,
  onSelection: PropTypes.func
}

export default ReflectionsList;