import { MBR_ACTIVATION_REQUEST, MBR_ACTIVATION_COMPLETE, MBR_ACTIVATION_RESEND,
         MBR_ACTIVATION_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function membershipActivationReducer(state = initialState.membershipActivation, action) {
  const { type, data = {}, error = {} } = action;
  let responseCode, passwordToken, status = stateConfig.responseStatus;
  
  switch(type) {
    case MBR_ACTIVATION_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null, resent: false });
    case MBR_ACTIVATION_COMPLETE:
      ({ ptoken: passwordToken } = data);
      return Object.assign({}, state, { responseStatus: status.COMPLETE, passwordToken: passwordToken || '' });
    case MBR_ACTIVATION_RESEND:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, resent: true });
    case MBR_ACTIVATION_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default membershipActivationReducer;