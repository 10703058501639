import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@mui/material/Button';
import useAnalytics from '../../../hooks/useAnalytics';
import * as postAPI from '../../../actions/content/posts';
import * as appDisplay from '../../../actions/appDisplay';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import { resetViewToTopOfPage } from '../../../utils/utils';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SEOMeta from '../../common/SEOMeta';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Retreat({ posts, postAPI, appDisplay }) {
  const [loading, setLoading] = useState(true);
  const [retreat, setRetreat] = useState(null);
  const [error, setError] = useState(null);
  const stateId = `${stateConfig.keys.PAGE}.living-catholic`;
  useAnalytics('Living Catholic');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let displayPageBanner = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const data = posts.byId[stateId];
    if(loading && isEmpty(retreat) && isNotEmpty(data) && posts.ids) {
      if(data.error) {
        setError(data.error);
      } else {
        setRetreat(data);
      }
      setLoading(false);
    }
  }, [loading, retreat, stateId, posts, posts.ids]);

  useEffect(() => {
    if(isNotEmpty(retreat)) {
      displayPageBanner();
    }
  }, [retreat, displayPageBanner]);

  initialize = () => {
    resetViewToTopOfPage();
    const data = posts.byId[stateId];
    if(isNotEmpty(data) && !data.error) {
      setRetreat(data);
      setLoading(false);
    } else {
      postAPI.getPageBySlug('living-catholic');
    }
  };

  displayPageBanner = () => {
    const { title, subtitle, headerImage: image } = retreat;
    appDisplay.displayPageBanner({ title, subtitle, image });
  };

  const clearPopMessage = () => {
    setError(null);
  };

  return (
    <div className="Retreat-container">
      { !retreat && !error && <LoadingMessage message="Loading..." /> }

      { error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page.</p>
        </PopMessage>
      }

      { retreat && !error &&
        <div className="Retreat-content">
          <SEOMeta 
            title={retreat.title} 
            description={retreat.subtitle} />
          <div className="Retreat-heading">
            <h3>{retreat.date}</h3>
            <p className="status">{retreat.registrationStatus}</p>
            { retreat.registrationButton !== null &&
              <div className="registration">
                <Button 
                  variant="contained" 
                  color="secondary" 
                  onClick={() => window.open(retreat.registrationButton.url, '_blank')}>
                  {retreat.registrationButton.displayText || 'Register Now'}
                </Button>
              </div>
            }
          </div>
          { retreat.tripDetails &&
            <div className="Retreat-trip-details">
              {
                retreat.tripDetails.map((item, index) => {
                  return (
                    <Accordion key={`trip-details-${index}`}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {item.heading}
                      </AccordionSummary>
                      <AccordionDetails>
                        <div dangerouslySetInnerHTML={{__html: item.content}} />
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              }
            </div>
          }
          <div className="Retreat-general" dangerouslySetInnerHTML={{__html: retreat.generalContent}} />
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { posts: state.posts };
}

function mapDispatchToProps(dispatch) {
  return { 
    postAPI: bindActionCreators(postAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Retreat);
