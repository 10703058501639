
// APP DISPLAY ACTIONS
export const EXPLORE_MENU_TOGGLED = 'EXPLORE_MENU_TOGGLED';
export const PAGE_MESSAGE_PUBLISHED = 'PAGE_MESSAGE_PUBLISHED';
export const PAGE_BANNER_PUBLISHED = 'PAGE_BANNER_PUBLISHED';

// ACCOUNT ACTIONS
export const ACCT_DELETE_REQUEST = 'ACCT_DELETE_REQUEST';
export const ACCT_DELETE_EMAIL_SENT = 'ACCT_DELETE_EMAIL_SENT';
export const ACCT_DELETE_COMPLETE = 'ACCT_DELETE_COMPLETE';
export const ACCT_DELETE_ERR = 'ACCT_DELETE_ERR';
export const ACCT_POLICY_REQUEST = 'ACCT_POLICY_REQUEST';
export const ACCT_POLICY_RETRIEVED = 'ACCT_POLICY_RETRIEVED';
export const ACCT_POLICY_STATUS_UPDATED = 'ACCT_POLICY_STATUS_UPDATED';
export const ACCT_POLICY_ERR = 'ACCT_POLICY_ERR';

// ANNOTATIONS
export const ANNOTATION_LOAD_START = 'ANNOTATION_LOAD_START';
export const ANNOTATION_LOAD_COMPLETE = 'ANNOTATION_LOAD_COMPLETE';
export const ANNOTATION_LOAD_ERR = 'ANNOTATION_LOAD_ERR';
export const ANNOTATION_ADD_REQUEST = 'ANNOTATION_ADD_REQUEST';
export const ANNOTATION_ADD_COMPLETE = 'ANNOTATION_ADD_COMPLETE';
export const ANNOTATION_ADD_ERR = 'ANNOTATION_ADD_ERR';
export const ANNOTATION_DELETE_REQUEST = 'ANNOTATION_DELETE_REQUEST'; 
export const ANNOTATION_DELETE_COMPLETE = 'ANNOTATION_DELETE_COMPLETE'; 
export const ANNOTATION_DELETE_ERR = 'ANNOTATION_DELETE_ERR'; 
export const ANNOTATION_PRINT_REQUEST = 'ANNOTATION_PRINT_REQUEST'; 
export const ANNOTATION_PRINT_COMPLETE = 'ANNOTATION_PRINT_COMPLETE'; 
export const ANNOTATION_PRINT_ERR = 'ANNOTATION_PRINT_ERR'; 
export const ANNOTATIONS_PRINT_REQUEST = 'ANNOTATIONS_PRINT_REQUEST'; 
export const ANNOTATIONS_PRINT_COMPLETE = 'ANNOTATIONS_PRINT_COMPLETE'; 
export const ANNOTATIONS_PRINT_ERR = 'ANNOTATIONS_PRINT_ERR'; 


// AUTH ACTIONS
export const AUTH_PUBLISH_CONTEXT = 'AUTH_PUBLISH_CONTEXT';
export const AUTH_CHECKED = 'AUTH_CHECKED';
export const AUTH_STATUS_RECEIVED = 'AUTH_STATUS_RECEIVED';
export const AUTH_STATUS_ERR = 'AUTH_STATUS_ERR';
export const AUTH_USER_IDENTIFIED = 'AUTH_USER_IDENTIFIED';
export const AUTH_USER_ID_ERR = 'AUTH_USER_ID_ERR';
export const AUTH_START = 'AUTH_START';
export const AUTH_STEP = 'AUTH_STEP';
export const AUTH_COMPLETE = 'AUTH_COMPLETE';
export const AUTH_ERR = 'AUTH_ERR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REMEMBERED = 'AUTH_REMEMBERED';
export const AUTH_PASSCODE_SENT = 'AUTH_PASSCODE_SENT';
export const AUTH_RESET_LINK_SENT = 'AUTH_RESET_LINK_SENT';
export const AUTH_RESET_COMPLETE = 'AUTH_RESET_COMPLETE';
export const AUTH_PROVIDER_START = 'AUTH_PROVIDER_START';

// BEHAVIOR ACTIONS
export const FAVORITES_RECEIVED = 'FAVORITES_RECEIVED';
export const FAVORITE_ADDED = 'FAVORITE_ADDED';
export const FAVORITE_REMOVED = 'FAVORITE_REMOVED';
export const WATCHED_RECEIVED = 'WATCHED_RECEIVED';
export const WATCHED_CHECKPOINT = 'WATCHED_CHECKPOINT';
export const WATCHED_LOGGED = 'WATCHED_LOGGED';
export const WATCHED_LOAD_START = 'WATCHED_LOAD_START';
export const WATCHED_LOAD_COMPLETE = 'WATCHED_LOAD_COMPLETE';
export const WATCHED_LOAD_ERR = 'WATCHED_LOAD_ERR';
export const WATCHING_JUMPPOINT = 'WATCHING_JUMPPOINT';

// CONTENT ACTIONS - POSTS
export const POST_LOAD_START = 'PAGE_LOAD_START';
export const POST_LOAD_COMPLETE = 'PAGE_LOAD_COMPLETE';
export const POST_LOAD_ERR = 'PAGE_LOAD_ERR';

// CONTENT ACTIONS - COURSES/TOPICS/ASSESSMENTS
export const COURSE_LOAD_START = 'COURSE_LOAD_START';
export const COURSE_LOAD_COMPLETE = 'COURSE_LOAD_COMPLETE';
export const COURSE_LOAD_ERR = 'COURSE_LOAD_ERR';

// CONTENT ACTIONS - EVENTS
export const EVENT_LOAD_START = 'EVENT_LOAD_START';
export const EVENT_LOAD_COMPLETE = 'EVENT_LOAD_COMPLETE';
export const EVENT_LOAD_ERR = 'EVENT_LOAD_ERR';

// CONTENT ACTIONS - INSTRUCTORS
export const INSTRUCTOR_LOAD_START = 'INSTRUCTOR_LOAD_START';
export const INSTRUCTOR_LOAD_COMPLETE = 'INSTRUCTOR_LOAD_COMPLETE';
export const INSTRUCTOR_LOAD_ERR = 'INSTRUCTOR_LOAD_ERR';

// CONTENT ACTIONS - REFLECTIONS
export const REFLECTION_LOAD_START = 'REFLECTION_LOAD_START';
export const REFLECTION_LOAD_COMPLETE = 'REFLECTION_LOAD_COMPLETE';
export const REFLECTION_LOAD_ERR = 'REFLECTION_LOAD_ERR';

// COURSE PROGRESS
export const PROGRESS_LOAD_START = 'PROGRESS_LOAD_START';
export const PROGRESS_LOAD_COMPLETE = 'PROGRESS_LOAD_COMPLETE';
export const PROGRESS_LOAD_ERR = 'PROGRESS_LOAD_ERR';

// DONATION ACTIONS
export const DONATION_REQUEST = 'DONATION_REQUEST';
export const DONATION_STARTED = 'DONATION_STARTED';
export const DONATION_COMPLETE = 'DONATION_COMPLETE';
export const DONATION_ERR = 'DONATION_ERR';
export const DONATION_UPDATE_REQUEST = 'DONATION_UPDATE_REQUEST';
export const DONATION_UPDATE_COMPLETE = 'DONATION_UPDATE_COMPLETE';
export const DONATION_UPDATE_ERR = 'DONATION_UPDATE_ERR';
export const DONOR_HISTORY_LOAD_START = 'DONOR_HISTORY_LOAD_START';
export const DONOR_HISTORY_LOAD_COMPLETE = 'DONOR_HISTORY_LOAD_COMPLETE';
export const DONOR_HISTORY_LOAD_ERR = 'DONOR_HISTORY_LOAD_ERR';
export const DONOR_CARDS_LOAD_START = 'DONOR_CARDS_LOAD_START';
export const DONOR_CARDS_LOAD_COMPLETE = 'DONOR_CARDS_LOAD_COMPLETE';
export const DONOR_CARDS_LOAD_ERR = 'DONOR_CARDS_LOAD_ERR';

// PROFILE ACTIONS
export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_RETRIEVED = 'PROFILE_RETRIEVED';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const PASSWORD_UPDATED = 'PASSWORD_UPDATED';
export const PROFILE_ERR = 'PROFILE_ERR';

// MAGDALA REGISTRATION ACTIONS
export const MAGDALA_REG_REQUEST = 'MAGDALA_REG_REQUEST';
export const MAGDALA_RETRIEVED_COMMUNITIES = 'MAGDALA_RETRIEVED_COMMUNITIES';
export const MAGDALA_REG_COMPLETE = 'MAGDALA_REG_COMPLETE';
export const MAGDALA_REG_ERR = 'MAGDALA_REG_ERR';

// MEMBER REGISTRATION ACTIONS
export const MBR_REGISTRATION_REQUEST = 'MBR_REGISTRATION_REQUEST';
export const MBR_REGISTRATION_TOKEN_COMPLETE = 'MBR_REGISTRATION_TOKEN_COMPLETE';
export const MBR_REGISTRATION_COMPLETE = 'MBR_REGISTRATION_COMPLETE';
export const MBR_REGISTRATION_ERR = 'MBR_REGISTRATION_ERR';
export const MBR_ACTIVATION_REQUEST = 'MBR_ACTIVATION_REQUEST';
export const MBR_ACTIVATION_COMPLETE = 'MBR_ACTIVATION_COMPLETE';
export const MBR_ACTIVATION_RESEND = 'MBR_ACTIVATION_RESEND';
export const MBR_ACTIVATION_ERR = 'MBR_ACTIVATION_ERR';

// NOTIFICATIONS - EMAIL
export const EMAIL_SETTINGS_REQUEST = 'EMAIL_SETTINGS_REQUEST';
export const EMAIL_SETTINGS_RECEIVED = 'EMAIL_SETTINGS_RECEIVED';
export const EMAIL_SETTINGS_COMPLETE = 'EMAIL_SETTINGS_COMPLETE';
export const EMAIL_SETTINGS_ERR = 'EMAIL_SETTINGS_ERR';

// REGISTRATION - CONTACT
export const CNT_REGISTRATION_REQUEST = 'CNT_REGISTRATION_REQUEST';
export const CNT_REGISTRATION_COMPLETE = 'CNT_REGISTRATION_COMPLETE';
export const CNT_REGISTRATION_ERR = 'CNT_REGISTRATION_ERR';

// REGISTRATION - COURSE
export const CRS_REGISTRATION_REQUEST = 'CRS_REGISTRATION_REQUEST';
export const CRS_REGISTRATION_COMPLETE = 'CRS_REGISTRATION_COMPLETE';
export const CRS_REGISTRATION_STATUS = 'CRS_REGISTRATION_STATUS';
export const CRS_REGISTRATION_MARK_COMPLETE = 'CRS_REGISTRATION_MARK_COMPLETE';
export const CRS_REGISTRATION_ERR = 'CRS_REGISTRATION_ERR';

// REGISTRATION - EVENT
export const EVT_REGISTRATION_REQUEST = 'EVT_REGISTRATION_REQUEST';
export const EVT_REGISTRATION_COMPLETE = 'EVT_REGISTRATION_COMPLETE';
export const EVT_REGISTRATION_STATUS = 'EVT_REGISTRATION_STATUS';
export const EVT_REGISTRATION_ERR = 'EVT_REGISTRATION_ERR';

// SHARE LINK
export const SHARE_LINK_REQUEST = 'SHARE_LINK_REQUEST';
export const SHARE_LINK_COMPLETE = 'SHARE_LINK_COMPLETE';
export const SHARE_LINK_RETRIEVED = 'SHARE_LINK_RETRIEVED';
export const SHARE_LINK_ERR = 'SHARE_LINK_ERR';
export const SHARE_TOKEN_REQUEST = 'SHARE_TOKEN_REQUEST';
export const SHARE_TOKEN_VALIDATED = 'SHARE_TOKEN_VALIDATED';
export const SHARE_TOKEN_ERR = 'SHARE_TOKEN_ERR';

// SEARCH ACTIONS
export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_COMPLETE = 'SEARCH_COMPLETE';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const SEARCH_ERR = 'SEARCH_ERR';

// TAXONOMY ACTIONS
export const TAXONOMY_REQUEST = 'TAXONOMY_REQUEST';
export const TAXONOMY_COMPLETE = 'TAXONOMY_COMPLETE';
export const TAXONOMY_ERR = 'TAXONOMY_ERR';

// SYSTEM CONTENT ACTIONS
export const ALERT_RETRIEVED = 'ALERT_RETRIEVED';
export const ANNUAL_REPORT_RETRIEVED = 'ANNUAL_REPORT_RETRIEVED';
export const ANNUAL_REPORT_ERR = 'ANNUAL_REPORT_ERR';
export const MAGDALA_CONTENT_RETRIEVED = 'MAGDALA_CONTENT_RETRIEVED'; 
export const MAGDALA_CONTENT_ERR = 'MAGDALA_CONTENT_ERR'; 
export const WEB_CONFIG_RETRIEVED = 'WEB_CONFIG_RETRIEVED';
export const FEATURE_BANNER_RETRIEVED = 'FEATURE_BANNER_RETRIEVED';
export const VIDEO_PROMO_RETRIEVED = 'VIDEO_PROMO_RETRIEVED';
export const POPUP_RETRIEVED = 'POPUP_RETRIEVED';
export const PRIVACY_POLICY_RETRIEVED = 'PRIVACY_POLICY_RETRIEVED';
export const PRIVACY_POLICY_ERR = 'PRIVACY_POLICY_ERR';