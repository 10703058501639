import { EXPLORE_MENU_TOGGLED, PAGE_MESSAGE_PUBLISHED, 
         PAGE_BANNER_PUBLISHED } from './actionTypes';
import state from '../config/state';

export function toggleExploreMenu() {
  return dispatch => {
    dispatch({ type: EXPLORE_MENU_TOGGLED });
  };
}

export function displayPageMessage(messageData) {
  const data = Object.assign({
    type: state.messageTypes.INFO,
    time: 30
  }, messageData);
  return dispatch => {
    dispatch({ type: PAGE_MESSAGE_PUBLISHED, data });
  };
}

export function displayPageBanner(bannerData) {
  const data = Object.assign({ subtitle: '' }, bannerData);
  return dispatch => {
    dispatch({ type: PAGE_BANNER_PUBLISHED, data });
  };
}
