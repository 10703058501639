import React from 'react';
import ProviderRegistration from './ProviderRegistration';
import useAnalytics from '../../../../hooks/useAnalytics';

function GoogleRegistration() {
  useAnalytics('Google Registration');
  return (
    <ProviderRegistration providerId='google' />
  );
}

export default GoogleRegistration;