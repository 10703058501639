import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import Reflections from '../../data/Reflections';
import Classroom from './content/Classroom';
import config from '../../../config/config';
import urls from '../../../config/urls';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;

function ReflectionClassroom() {
  const [type, setType] = useState(null);
  const [slug, setSlug] = useState(null);
  const [reflectionId, setReflectionId] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [mediaId, setMediaId] = useState(null);
  const [baseURL, setBaseURL] = useState(null);
  const [mediaChanged, setMediaChanged] = useState(false);
  const [mediaChangeDone, setMediaChangeDone] = useState(true);
  const [reflection, setReflection] = useState(null);
  const [error, setError] = useState(false);
  const location = useLocation();
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(mediaChanged && mediaId === null && mediaType === null) {
      const { pathname } = location;
      const pathParts = pathname.split('/');
      setMediaType(pathParts[4]);
      setMediaId(pathParts[5]);
      setMediaChanged(false);
      setMediaChangeDone(true);
    }
  }, [location, mediaChanged, mediaId, mediaType]);

  useEffect(() => {
    if(mediaChangeDone && mediaId !== null && mediaType !== null) {
      setMediaChangeDone(true);
    }
  }, [mediaChangeDone, mediaId, mediaType]);

  const onReflectionsLoaded = data => {
    if(isNotEmpty(data.error)) {
      setError(true);
    } else {
      const { id, title, video, audio, reading } = data.reflections.find(item => item.id === reflectionId);
      setReflection({
        id,
        title,
        type: 'Reflection',
        media: {
          video: video? [ video ] : [],
          audio: audio? [ audio ] : [],
          resource: reading? [ reading ] : []
        }
      });
    }
  };

  initialize = () => {
    const { pathname } = location;
    const pathParts = pathname.split('/');
    const isLatin = pathParts[2] === 'sunday-gospel';
    if(!isLatin) {
      // BGR hidden for the time being per Father's request in Oct 2023, may be permanently removed later
      window.location = urls.reflectionsLatin;
      return;
    }

    const reflectionId = pathParts[3];
    setType(isLatin? 'latin' : 'byzantine');
    setSlug(isLatin? 'sunday-gospel' : 'byzantine-gospel');
    setReflectionId(reflectionId);
    setMediaType(pathParts[4]);
    setMediaId(pathParts[5]);
    const base = isLatin? urls.reflectionsLatinClassroomBase : urls.reflectionsByzantineClassroomBase;
    setBaseURL(base.replace(':reflectionId', reflectionId));
  };
  
  const onPathChange = () => {
    setMediaChanged(true);
    setMediaType(null);
    setMediaId(null);
  };

  const clearPopMessage = () => {
    setError(false);
  };

  const reloadingMedia = mediaChanged || !mediaChangeDone;

  return (
    <div className="ReflectionClassroom">
      { isNotEmpty(type) && isNotEmpty(slug) && <Reflections type={type} slug={slug} onReflectionsLoaded={onReflectionsLoaded} /> }

      { (reloadingMedia || isEmpty(reflection)) && !error && <LoadingMessage message="Loading..." /> }

      { error &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>We could not load the requested media.</p>
        </PopMessage>
      }
      
      { !reloadingMedia && isNotEmpty(reflection) && 
        <Classroom 
          content={reflection} 
          error={isNotEmpty(error)} 
          mediaType={mediaType}
          mediaId={mediaId} 
          baseURL={baseURL}
          onPathChange={onPathChange}
        /> 
      }
    </div>
  );
}

export default ReflectionClassroom;