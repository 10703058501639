import errors from '../config/errors';


function isNotDefined(value) {
  // looking for null or undefined, but allowing other falsy values
  return (null === value || typeof value === 'undefined');
}

function isDefined(value) {
  return !isNotDefined(value);
}

function isEmpty(value) {
  // looking for falsy values other than zero of boolean false
  const val = (typeof value === 'string')? value.trim() : value;
  return (!val && typeof val !== 'boolean' && typeof val !== 'number');
}

function isNotEmpty(value) {
  return !isEmpty(value);
}

function isInteger(value) {
  return Number.isInteger(parseInt(value, 10));
}

function isInList(value, list) {
  return list.includes(value);
}

function matchesValue(value, against) {
  return value === against;
}

function matchesPattern(value, pattern) {
  return pattern.test(value);
}

function validPassword(password) {
  const p = password.trim();
  return p.length >= 6 && p.length <= 50;
}

function matchesEmail(confirmationEmail, email) {
  return confirmationEmail === email;
}

function matchesPassword(confirmationPassword, password) {
  return confirmationPassword === password;
}

const rules = Object.freeze({
  EMAIL_MATCH:      Symbol('EMAIL_MATCH'),
  IS_DEFINED:       Symbol('IS_DEFINED'),
  IS_INTEGER:       Symbol('IS_INTEGER'),
  IN_LIST:          Symbol('IN_LIST'),
  MATCHES_VALUE:    Symbol('MATCHES_VALUE'),
  MATCHES_PATTERN:  Symbol('MATCHES_PATTERN'),
  PASSWORD_CHECK:   Symbol('PASSWORD_CHECK'),
  PASSWORD_MATCH:   Symbol('PASSWORD_MATCH')
});

const patterns = Object.freeze({
  SALUTATION: /^([A-Za-z.,\s])+$/,
  NAME: /^([A-Za-z'\-\s])+$/,
  PHONE: /^([0-9\-+()\s])+$/,
  EMAIL: /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,

  ADDRESS_LINE: /^([A-Za-z0-9\-'.,\s])+$/,
  CITY: /^([A-Za-z'\-\s])+$/,
  STATE: /^([A-Za-z\s])+$/,
  ZIP_CODE: /^([A-Za-z0-9\-\s])+$/,
  COUNTRY: /^([A-Za-z'\-\s])+$/,

  CURRENCY: /^[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$/,
  CURRENCY_NO_CENTS: /^[0-9]{1,3}(?:,?[0-9]{3})*?$/
});

const codes = Object.freeze({
  FAILED_IS_DEFINED:       'V010',
  FAILED_TYPE_CHECK:       'V011',
  FAILED_IS_INTEGER:       'V012',
  FAILED_IN_LIST:          'V020',
  FAILED_MATCHES_VALUE:    'V030',
  FAILED_MATCHES_PATTERN:  'V031',
  FAILED_PASSWORD_CHECK:   'V050',
  FAILED_PASSWORD_MATCH:   'V051',
  FAILED_EMAIL_MATCH:      'VO60'  
});

function message(code, fieldName) {
  switch(code) {
    case codes.FAILED_EMAIL_MATCH: return 'The email values you entered did not match';
    case codes.FAILED_PASSWORD_CHECK: return 'Please provide a valid password (minimum of 6 characters)';
    case codes.FAILED_PASSWORD_MATCH: return 'The password values you entered did not match';
    case codes.FAILED_IS_DEFINED: return `The ${fieldName || ''} field is required`;
    default: 
      return code? `The ${fieldName || ''} value you entered is not valid` : null;
  }
}

function validate(object, validations) {
  let errors;
  validations.forEach(validation => {
    if(!object[validation.prop] && validation.empty) return; // allows empty field

    const { fn, code } = validator(validation.rule);
    if(!fn(object[validation.prop], validation.against)) {
      errors = (errors)? errors : {};
      errors[validation.prop] = code;
    }
  });
  return errors;
}

function validator(rule) {
  switch(rule) {
    case rules.EMAIL_MATCH:     return { fn: matchesEmail, code: codes.FAILED_EMAIL_MATCH };
    case rules.IS_DEFINED:      return { fn: isNotEmpty, code: codes.FAILED_IS_DEFINED };
    case rules.IS_INTEGER:      return { fn: isInteger, code: codes.FAILED_IS_INTEGER };
    case rules.IN_LIST:         return { fn: isInList, code: codes.FAILED_IN_LIST } ;
    case rules.MATCHES_VALUE:   return { fn: matchesValue, code: codes.FAILED_MATCHES_VALUE };
    case rules.MATCHES_PATTERN: return { fn: matchesPattern, code: codes.FAILED_MATCHES_PATTERN };
    case rules.PASSWORD_CHECK:  return { fn: validPassword, code: codes.FAILED_PASSWORD_CHECK };
    case rules.PASSWORD_MATCH:  return { fn: matchesPassword, code: codes.FAILED_PASSWORD_MATCH };
    default: return { 
      fn: () => { return false; }, 
      code: 'V001' 
    }
  }
}

function error(validationErrors) {
  const ve = errors.ValidationError;
  let error = new Error(ve.message);
  error.validationError = {
    code: ve.code,
    message: ve.message,
    details: validationErrors
  };
  return error;
}

const validationLib = {
  rules,
  patterns,
  codes,
  message,
  validate,
  error,
  isDefined,
  isNotDefined,
  isEmpty,
  isNotEmpty,
  isInteger,
  isInList,
  matchesValue,
  matchesPattern,
  validPassword,
  matchesEmail,
  matchesPassword
};

export default validationLib;