import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CheckField from '../../../../common/form/CheckField';
import validator from '../../../../../utils/validator';
const { isEmpty } = validator;


function MultipleChoiceQuestion({ question, index, scored, disabled, onChange }) {
  const [selections, setSelections] = useState([]);

  useEffect(() => {
    let data = scored || { index, wpId: question.wpId, title: question.title, value: null, result: 'incorrect', compare: null };
    if(selections.length > 0) {
      data.value = selections.map(selection => parseInt(selection.replace('answer-', '')));
      data.compare = data.value.reduce((compare, value) => ''+compare+value);
      const results = data.value.map(value => question.answers[value].correct);
      const correct = question.answers.filter(answer => answer.correct);
      data.result = !results.includes(false) && results.length === correct.length? 'correct' : 'incorrect';
    }
    onChange(data);
  }, [index, scored, selections, question, onChange]);

  const handleSelection = index => event => {
    const selArr = selections.slice();
    const answerKey = `answer-${index}`;
    const i = selArr.indexOf(answerKey);

    if(event.target.checked && i === -1) {
      selArr.push(answerKey);
    } else if(!event.target.checked && i !== -1) {
      selArr.splice(i, 1);
    }
    
    setSelections(selArr);
  };

  const defaultChecked = index => {
    if(isEmpty(scored)) return false;
    
    if(scored.value && typeof scored.value.includes === 'function') {
      return scored.value.includes(index);
    } else {
      return scored.value === index;
    }
  };

  return (
    <div className="MultipleChoiceQuestion">
      <p className="question-text" dangerouslySetInnerHTML={{__html: `${index+1}. ${question.text}`}} />
      <div className="answers">
        {
          question.answers.map((answer, index) => {
            return (
              <CheckField 
                key={`answer-${index}`}
                name={`answer-${index}`} 
                label={(<span dangerouslySetInnerHTML={{__html: answer.text}} />)}
                defaultChecked={defaultChecked(index)}
                disabled={disabled}
                onChange={handleSelection(index)} 
                color="primary"
              />
            );
          })
        }
      </div>
      { disabled && // means we are in display only results view
        <div className={`scored-result ${scored.result}`}>
          {scored.result === 'correct'? 'Correct!' : 'Incorrect.'}
        </div>
      }
    </div>
  );
}

MultipleChoiceQuestion.defaultProps = {
  scored: null,
  disabled: false
};

MultipleChoiceQuestion.propTypes = {
  question: PropTypes.object,
  index: PropTypes.number,
  onChange: PropTypes.func
};

export default MultipleChoiceQuestion;
