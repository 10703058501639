import React from 'react';
import { ReactComponent as QuoteImage } from '../../../images/QuotationMarks.svg'; 

class Quote extends React.Component {
  render() {
    return (
      <div className="Magdala-Home-testimonials">
        <div>
          <QuoteImage />
          <div className="quote">
            <p>
            Soon afterward He went on through cities and villages, preaching and bringing the good news of the kingdom of God. And the twelve were with him, and also some women...who provided for them out of their means.
            </p>
            <p className="name">– Luke 8:1-3</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Quote;