import axios from 'axios';
import urls from '../../config/urls';
import config from '../../config/config';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

const coursesPath = '/content/courses';

export async function getCourses() {
  return await axios.get(`${urls.hubUIApiRoot}${coursesPath}`, { timeout: config.wpTimeout });
}

export async function getCourseBySlug(slug) {
  return await axios.get(`${urls.hubUIApiRoot}${coursesPath}/slug/${slug}`, { timeout: config.wpTimeout });
}

export async function getCourseLiveStatus(slug) {
  return await axios.get(`${urls.hubUIApiRoot}${coursesPath}/slug/${slug}/live`, { timeout: config.wpTimeout });
}

export async function getTopicById(topicId) {
  return await axios.get(`${urls.hubUIApiRoot}${coursesPath}/topics/${topicId}`, { timeout: config.wpTimeout });
}

export async function getAssessmentById(assessmentId) {
  return await axios.get(`${urls.hubUIApiRoot}${coursesPath}/assessments/${assessmentId}`, { timeout: config.wpTimeout });
}
