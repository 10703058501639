import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import querystring from 'qs';
import useAnalytics from '../../../hooks/useAnalytics';
import * as appDisplay from '../../../actions/appDisplay';
import MemberBenefits from './MemberBenefits';
import MemberRegistrationForm from './MemberRegistrationForm';
import NewMemberConfirmation from './NewMemberConfirmation';
import ProviderRegistrationConfirmation from './providers/ProviderRegistrationConfirmation';
import PageTitle from '../../common/PageTitle';
import PopMessage from '../../common/messages/PopMessage';
import ContactErrorPopMessage from '../../common/messages/ContactErrorPopMessage';
import validator from '../../../utils/validator';
import urls from '../../../config/urls';
import errors from '../../../config/errors';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import { resetViewToTopOfPage } from '../../../utils/utils';
import Button from '@mui/material/Button';
import SEOMeta from '../../common/SEOMeta';


function MemberRegistration({ appDisplay }) {
  const [displayMarketing, setDisplayMarketing] = useState(true);
  const [registrationComplete, setRegistrationComplete] = useState(false);
  const [providerRegistration, setProviderRegistration] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const location = useLocation();
  useAnalytics('Member Registration');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  initialize = () => {
    resetViewToTopOfPage();
    const params = querystring.parse(location.search.substring(1));
    if(params.m) {
      setDisplayMarketing((params.m === 'show'));
    }
    if(params.provider && params.provider === 'success') {
      setRegistrationComplete(true);
      setProviderRegistration(true);
    }
  };

  const refresh = () => {
    window.location.href = urls.memberRegistration;
  };

  const onComplete = (code) => {
    resetViewToTopOfPage();

    if(validator.isEmpty(code)) {
      appDisplay.displayPageMessage({
        message: 'Your registration has been successfully submitted!',
        type: stateConfig.messageTypes.SUCCESS,
        time: 0
      });
      setRegistrationComplete(true);
      setErrorCode(null);
    } else {
      setRegistrationComplete(false);
      setErrorCode(code);
    }
  }

  const clearPopMessage = () => {
    setErrorCode(null);
  };

  return (
    <div id="MemberRegistration">
      { !registrationComplete && errorCode !== errors.MemberRegistrationExpired.code &&
        <div>
          <SEOMeta 
            title="Join Now" 
            description="Become an Institute member to gain unlimited access to our online library and classrooms." />
          <PageTitle title="Become an Institute Member"
            subtitle="Know the Faith.&nbsp;&nbsp; Love the Faith.&nbsp;&nbsp; Live the Faith." />
          <div className="panels">
            <div id="MemberRegistration-information" className="panel">
              { displayMarketing &&
                <MemberBenefits />
              }
            </div>
            <div id="MemberRegistration-formContainer">
              <MemberRegistrationForm 
                onComplete={onComplete}
                submitLabel="Register"
                heading="Create a membership to gain unlimited access to our online library and classrooms" 
                source="member-registration"
                contextParams={{ redirect: urls.memberRegistration }}
              />
            </div>
          </div>
        </div>
      }

      { registrationComplete && !providerRegistration &&
        <NewMemberConfirmation />
      }

      { registrationComplete && providerRegistration &&
        <ProviderRegistrationConfirmation />
      }

      { errorCode && errorCode === errors.MemberRegistrationExpired.code &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}
          action={<Button className="LinkButton" color="secondary" onClick={refresh}>Try again</Button>}
          >
          <p>{errors.MemberRegistrationExpired.message}</p>
        </PopMessage>
      }

      { errorCode && errorCode !== errors.MemberRegistrationExpired.code &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>{errors.MemberRegistrationError.message}</p>
        </ContactErrorPopMessage>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return { appDisplay: bindActionCreators(appDisplay, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberRegistration);