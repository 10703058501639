import { COURSE_LOAD_START, COURSE_LOAD_COMPLETE, COURSE_LOAD_ERR } from '../actionTypes';
import * as courses from '../../api/content/courses';
import stateConfig from '../../config/state';

const courseKey = stateConfig.keys.COURSE;
const courseListKey = stateConfig.keys.COURSE_LIST;
const assessmentKey = stateConfig.keys.COURSE_ASSESSMENT;
const topicKey = stateConfig.keys.COURSE_TOPIC;

export function getCourses() {
  return async dispatch => {
    const objectId = 'summary';
    dispatch({ type: COURSE_LOAD_START, key: courseListKey, objectId });
    try {
      let result = await courses.getCourses();
      dispatch({ type: COURSE_LOAD_COMPLETE, data: result.data, key: courseListKey, objectId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: COURSE_LOAD_ERR, error: err, key: courseListKey, objectId });
    }
  };
}

export function getCourseBySlug(slug) {
  return async dispatch => {
    dispatch({ type: COURSE_LOAD_START, key: courseKey, objectId: slug });
    try {
      let result = await courses.getCourseBySlug(slug);
      dispatch({ type: COURSE_LOAD_COMPLETE, data: result.data, key: courseKey, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: COURSE_LOAD_ERR, error: err, key: courseKey, objectId: slug });
    }
  };
}

export function getCourseTopicById(topicId) {
  return async dispatch => {
    dispatch({ type: COURSE_LOAD_START, key: topicKey, objectId: topicId });
    try {
      let result = await courses.getTopicById(topicId);
      dispatch({ type: COURSE_LOAD_COMPLETE, data: result.data, key: topicKey, objectId: topicId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: COURSE_LOAD_ERR, error: err, key: topicKey, objectId: topicId });
    }
  };
}

export function getCourseAssessmentById(assessmentId) {
  return async dispatch => {
    dispatch({ type: COURSE_LOAD_START, key: assessmentKey, objectId: assessmentId });
    try {
      let result = await courses.getAssessmentById(assessmentId);
      dispatch({ type: COURSE_LOAD_COMPLETE, data: result.data, key: assessmentKey, objectId: assessmentId });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: COURSE_LOAD_ERR, error: err, key: assessmentKey, objectId: assessmentId });
    }
  };
}
