import axios from 'axios';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

export async function getMagdalaCommunities() {
  return await axios.get(`${urls.hubUIApiRoot}/member/magdala/communities`);
}

export async function submitMagdalaProgramApplication(registration) {
  return await axios.post(`${urls.hubUIApiRoot}/member/magdala/registration/`, registration);
}