import { ALERT_RETRIEVED, ANNUAL_REPORT_RETRIEVED, ANNUAL_REPORT_ERR, MAGDALA_CONTENT_RETRIEVED, MAGDALA_CONTENT_ERR, 
         POPUP_RETRIEVED, WEB_CONFIG_RETRIEVED, FEATURE_BANNER_RETRIEVED, VIDEO_PROMO_RETRIEVED, PRIVACY_POLICY_RETRIEVED, 
         PRIVACY_POLICY_ERR } from '../actionTypes';
import * as system from '../../api/content/system';
import errors from '../../config/errors';


export function getAlert() {
  return async dispatch => {
    try {
      let result = await system.getAlert();
      dispatch({ type: ALERT_RETRIEVED, data: result.data });
    } catch(error) {
      // Fail silently, the alert simply won't be displayed
    }
  };
}

export function getAnnualReport() {
  return async dispatch => {
    try {
      let result = await system.getAnnualReport();
      dispatch({ type: ANNUAL_REPORT_RETRIEVED, data: result.data });
    } catch(error) {
      dispatch({ type: ANNUAL_REPORT_ERR, data: errors.RequestFailed });
    }
  };
}

export function getMagdalaContent() {
  return async dispatch => {
    try {
      let result = await system.getMagdalaContent();
      dispatch({ type: MAGDALA_CONTENT_RETRIEVED, data: result.data });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error; 
      dispatch({ type: MAGDALA_CONTENT_ERR, error: err }); 
    }
  };
}

export function getPopup() {
  return async dispatch => {
    try {
      let result = await system.getPopup();
      dispatch({ type: POPUP_RETRIEVED, data: result.data });
    } catch(error) {
      // Fail silently, the popup simply won't be displayed
    }
  };
}

export function getFeatureBanner() {
  return async dispatch => {
    try {
      let result = await system.getFeatureBanner();
      dispatch({ type: FEATURE_BANNER_RETRIEVED, data: result.data });
    } catch(error) {
      // Fail silently, the banner simply won't be displayed
    }
  };
}

export function getVideoPromo() {
  return async dispatch => {
    try {
      let result = await system.getVideoPromo();
      dispatch({ type: VIDEO_PROMO_RETRIEVED, data: result.data });
    } catch(error) {
      // Fail silently, the promo simply won't be displayed
    }
  };
}

export function getPrivacyPolicy() {
  return async dispatch => {
    try {
      let result = await system.getPrivacyPolicy();
      dispatch({ type: PRIVACY_POLICY_RETRIEVED, data: result.data });
    } catch(error) {
      dispatch({ type: PRIVACY_POLICY_ERR, data: errors.RequestFailed });
    }
  };
}

export function getWebConfig() {
  return async dispatch => {
    try {
      let result = await system.getWebConfig();
      dispatch({ type: WEB_CONFIG_RETRIEVED, data: result.data });
    } catch(error) {
      // Fail silently
    }
  };
}