import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as coursesAPI from '../../../actions/content/courses';
import * as progressAPI from '../../../actions/progress';
import StandardSystemPopup from '../../layout/popup/StandardSystemPopup';
import LoadingMessage from '../../common/messages/LoadingMessage';
import CourseAssessmentResults from '../../content/courses/assessments/CourseAssessmentResults';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { formatDateTimeET } from '../../../utils/format';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


function TranscriptAssessments({ transcript, courses, courseProgress, coursesAPI, progressAPI }) {
  const [loaded, setLoaded] = useState(false);
  const [records, setRecords] = useState(null);
  const [popup, setPopup] = useState({});
  const [popupError, setPopupError] = useState(null);
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(!loaded && isEmpty(records)) {
      const slug = `course-${transcript.slug}`;
      const pId = `${stateConfig.keys.PROGRESS_HISTORY}.${transcript.courseId}`;
      const cId = `${stateConfig.keys.COURSE}.${slug}`;
      const pData = courseProgress.ids.includes(pId)? courseProgress.byId[pId] : null;
      const cData = courses.ids.includes(cId)? courses.byId[cId] : null;
      if(pData && cData) {
        if(pData.error || cData.error) {
          setRecords([]);
          setLoaded(true);
        } else {
          // build a list of all assessments associated with the course
          let assessments = cData.lessons.reduce((arr, lesson) => {
            const arr2 = lesson.parts.filter(part => part.type === 'assessment');
            return arr.concat(arr2);
          }, []);
          assessments = assessments.concat(cData.exams);
          // reduce down into a map of assessment IDs to titles
          assessments = assessments.reduce((obj, assessment) => {
            obj[assessment.id] = assessment.title;
            return obj;
          }, {});
          // enrich assessment history w/ titles
          let rArr = pData.records.map(record => { 
            const title = assessments[record.assessmentId];
            return { ...record, title };
          });
          setRecords(rArr);
          setLoaded(true);
        }
      }
    }
  }, [loaded, records, transcript, courses.ids, courses.byId, courseProgress.ids, courseProgress.byId]);

  useEffect(() => {
    if(!isEmpty(popup.record) && isEmpty(popup.assessment)) {
      const aId = `${stateConfig.keys.COURSE_ASSESSMENT}.${popup.assessmentId}`;
      const aData = courses.ids.includes(aId)? courses.byId[aId] : null;
      if(aData) {
        if(aData.error) {
          setPopupError({ display: true });
        } else {
          const obj = { ...popup };
          obj.assessment = aData;
          setPopup(obj);
        }
      }
    }
  }, [popup, courses.ids, courses.byId]);

  initialize = () => {
    if(config.courseArchived.includes(transcript.status)) {
      const arr = !isEmpty(transcript.assessments)? transcript.assessments : [];
      setRecords(arr);
      setLoaded(true);
    } else {
      retrieveAssessments();
    }
  };

  const retrieveAssessments = () => {
    const slug = `course-${transcript.slug}`;
    let data = courses.byId[`${stateConfig.keys.COURSE}.${slug}`];
    if(!data || data.error) {
      coursesAPI.getCourseBySlug(slug);
    }

    data = courseProgress.byId[`${stateConfig.keys.PROGRESS_HISTORY}.${transcript.courseId}`];
    if(!data || data.error) {
      progressAPI.getAllAssessmentHistory(transcript.courseId);
    }
  };

  const showAssessmentResults = record => () => {
    setPopup({ assessmentId: record.assessmentId, assessment: null, record });

    let data = courses.byId[`${stateConfig.keys.COURSE_ASSESSMENT}.${record.assessmentId}`];
    if(!data || data.error) {
      coursesAPI.getCourseAssessmentById(record.assessmentId);
    }
  };

  const closePopup = () => {
    setPopup({});
    setPopupError(null);
  };

  return (
    <div className="TranscriptAssessments">
      { !loaded &&
        <LoadingMessage message="Loading..." />
      }
      { loaded && !isEmpty(records) && records.length > 0 &&
        records.map((record, index) => {
          const shouldLink = record.status === 'complete';
          return (
            <div key={`assessment-record-${index}`} className="assessment-record">
              <span className={`title ${shouldLink? 'link' : ''}`} 
                onClick={shouldLink? showAssessmentResults(record) : () => {}}>
                {record.title}
              </span>
              <span className="score">{record.score? `${record.score}%` : 'Saved' }</span>
              <span className="date">{formatDateTimeET(record.lastUpdated)}</span>
            </div>
          );
        })
      }
      { loaded && (isEmpty(records) || records.length === 0) &&
        <p className="no-records">There are no assessment records to display.</p>
      }
      <StandardSystemPopup 
        className="TranscriptAssessments-popup"
        open={!isEmpty(popup.record)} 
        title="Assessment Results" 
        onClose={closePopup}>
        { isEmpty(popup.assessment) && isEmpty(popupError) &&
          <LoadingMessage message="Loading..." />
        }
        { !isEmpty(popupError) &&
          <p>This resource could not be loaded.</p>
        }
        { !isEmpty(popup.record) && !isEmpty(popup.assessment) &&
          <CourseAssessmentResults 
            assessment={popup.assessment}
            results={popup.record}
            onRestart={() => {}}
            embedded={true}
          />
        }
      </StandardSystemPopup>
    </div>
  );
}

TranscriptAssessments.propTypes = {
  transcript: PropTypes.object
};

function mapStateToProps(state) {
  return { 
    courses: state.courses,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    coursesAPI: bindActionCreators(coursesAPI, dispatch),
    progressAPI: bindActionCreators(progressAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TranscriptAssessments);

