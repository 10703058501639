import { FAVORITES_RECEIVED, FAVORITE_ADDED, FAVORITE_REMOVED,
         WATCHED_RECEIVED, WATCHED_CHECKPOINT, WATCHED_LOGGED,
         WATCHED_LOAD_START, WATCHED_LOAD_COMPLETE, WATCHED_LOAD_ERR,
         WATCHING_JUMPPOINT } from './actionTypes';
import * as behavior from '../api/behavior';
import config from '../config/config';
import stateConfig from '../config/state';
import { sessionRetrieve, sessionStore } from '../utils/store';
import validator from '../utils/validator';
const { isNotEmpty } = validator;

const watchedItemKey = stateConfig.keys.WATCHED;

export function getBehaviorData() {
  return async dispatch => {
    // will ignore all errors, if fail to load any behavior data all missing data
    // will be silently ignored and not incorporated
    await getFavorites(dispatch);
    await getWatched(dispatch);
  };
}

async function getFavorites(dispatch) {
  try {
    let result = await behavior.getFavorites();
    if(result && result.data) {
      dispatch({ type: FAVORITES_RECEIVED, data: result.data });
    }
  } catch(error) {
    // fail silently
  }
}

export function addFavorite(eventId) {
  return async dispatch => {
    dispatch({ type: FAVORITE_ADDED, eventId });
    try {
      await behavior.addFavorite(eventId);
    } catch(error) {
      // fail silently
    }
  };
}

export function removeFavorite(eventId) {
  return async dispatch => {
    dispatch({ type: FAVORITE_REMOVED, eventId });
    try {
      await behavior.removeFavorite(eventId);
    } catch(error) {
      // fail silently
    }
  };
}

async function getWatched(dispatch) {
  try {
    let result = await behavior.getWatched();
    if(result && result.data) {
      dispatch({ type: WATCHED_RECEIVED, data: result.data });
    }
  } catch(error) {
    // fail silently
  }
}

export function getWatchedMedia(eventId, mediaId) {
  return async dispatch => {
    const id = `${eventId}.${mediaId}`;
    dispatch({ type: WATCHED_LOAD_START, key: watchedItemKey, objectId: id });
    try {
      let result = await behavior.getWatchedMedia(eventId, mediaId);
      dispatch({ type: WATCHED_LOAD_COMPLETE, data: result.data, key: watchedItemKey, objectId: id });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: WATCHED_LOAD_ERR, error: err, key: watchedItemKey, objectId: id });
    }
  };
}

export function publishWatchedCheckpoint(data) {
  return async dispatch => {
    dispatch({ type: WATCHED_CHECKPOINT, data });
  };
}

export function logWatched(data) {
  return async dispatch => {
    dispatch({ type: WATCHED_LOGGED, data });
    try {
      await behavior.logWatched(data);
    } catch(error) {
      // fail silently
    }
  };
}

export function publishJumpPointForCurrentMedia(jumpPoint) {
  return async dispatch => {
    dispatch({ type: WATCHING_JUMPPOINT, jumpPoint });
  };
}

export async function trackSessionEvent(event, user = null) {
  try {
    let sessionId = sessionRetrieve(config.localStorage.trackingSessionId, false);
    let sessionData = {
      sessionId,
      app: 'web',
      source: event.source,
      user: null,
      event: {
        category: event.category,
        type: event.type,
        source: event.source,
        data: event.data
      }
    };
    if(user && isNotEmpty(user.trackingId) && isNotEmpty(user.email)) {
      sessionData.user = { trackingId: user.trackingId, email: user.email };
    }

    let result = await behavior.trackSessionEvent(sessionData);
    if(result.data.sessionId && result.data.sessionId !== sessionId) {
      // either this is a new session creation (ID didn't exist locally) or the tracking session expired
      // store the new tracking session ID
      sessionId = result.data.sessionId;
      sessionStore(config.localStorage.trackingSessionId, result.data.sessionId, false);
    }

    if(event.type === config.googleAdTrackingType) {
      trackGoogleAdConversion(event.source, sessionId);
    }
  } catch(error) {
    // fail silently
  }
}

async function trackGoogleAdConversion(source, sessionId) {
  try {
    window.gtag('event', 'conversion', {
      'send_to': process.env.REACT_APP_GAD_CONVERSION_ID,
      'transaction_id': `${source}.${sessionId}`, // passing source + session ID ensures each conversion gets tracked once per session
    });
  } catch(error) {
    // fail silently
  }
}