import React from 'react';
import { useNavigate } from 'react-router-dom';
import urls from '../../../config/urls';
import Button from '@mui/material/Button';

function Pitch() {
  const navigate = useNavigate();

  const nav = url => () => {
    navigate(url);
  }

  return (
    <div className="Home-pitch container">
      <div className="container-content">
        <div className="callout">
          <h3><span>Convinced?</span> Signing up takes less than a minute and is completely free.</h3>
          <div className="join-button">
            <Button variant="outlined" onClick={nav(urls.memberRegistration)}>Get Started</Button>
          </div>
        </div>
        <div className="message">
          <h5>Why do we offer all of this for free?</h5>
          <p>
            In a consumer focused culture, we often devalue that which is offered without charge,
            and yet in Isaiah we read "every one who thirsts, come to the waters; and he who has no money,  
            come, buy and eat!" That which is most precious, Our Lord Jesus Christ gave us freely. Thus,
            the Institute will always offer our education for free. We continue our mission with the support
            of our students who share this vision.
            <Button variant="outlined" onClick={() => window.location = urls.donateFundraiseupEOY2024btn}>Support the Institute</Button>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Pitch;