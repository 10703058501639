import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import urls from '../../config/urls';

function MagdalaToolbar() {
	const location = useLocation();
	const { pathname: path } = location; 

	const menuStatus = {
		program: '',
		courses: '',
		curriculum: ''
	}; 

	if (path === urls.magdalaProgramPath || path === `${urls.magdalaProgramPath}/`) {
		menuStatus.program = 'active'
	} else if ((path === urls.magdalaCoursesPath || path === `${urls.magdalaCoursesPath}/`) || path.includes('courses/magdala-apostolate') || path.includes('events/magdala-apostolate')) {
		menuStatus.courses = 'active'
	} else if (path === urls.magdalaCurriculumPath || path === `${urls.magdalaCurriculumPath}/`) {
		menuStatus.curriculum = 'active' 
	} else {
		return null; 
	}
	
	return (
	<Fragment>
		<div>
			<div className={`Magdala-Toolbar`}>
				<div className="Magdala-Toolbar-mainNav">
					<div className={`menu-item`}>
						<Link to={urls.magdalaLandingPage}>About</Link>
					</div>
					<div className="vertical-divider">|</div>
					<div className={`menu-item ${menuStatus.program}`}>
						<Link to={urls.magdalaProgramPage}>Program Details</Link>
					</div>
					<div className="vertical-divider">|</div>
					<div className={`menu-item ${menuStatus.courses}`}>
						<Link to={urls.magdalaCoursesPage}>Courses</Link>
					</div>
					<div className="vertical-divider">|</div>
					<div className={`menu-item ${menuStatus.curriculum}`}>
						<Link to={urls.magdalaCurriculumPage}>Curriculum</Link>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
	)
}

export default MagdalaToolbar;