import React from 'react';
import PropTypes from 'prop-types';
import Form from '../../../../common/form/Form';
import TextField from '@mui/material/TextField';
import validator from '../../../../../utils/validator';


export default function ProfileName(props) {
  const { editing, errors, data, onFieldChange, onSubmit } = props;
  return (
    <div>
      { editing &&
        <div className="MemberProfile-itemEdit">
          <Form id='ProfileEditName-form' onSubmit={onSubmit} autoComplete="off" submitLabel='Save'>
            <TextField
              className="FormField-control full"
              name="salutation"
              label="Title (e.g. Mo., Sr., Novice, etc.)"
              placeholder='Title'
              onChange={onFieldChange}
              helperText={validator.message(errors['salutation'], 'title')}
              error={validator.isDefined(errors['salutation'])}
              value={data.salutation}
              variant="standard"
            />
            <TextField
              className="FormField-control full"
              name="firstName"
              label="Religious Name"
              placeholder='Religious Name'
              onChange={onFieldChange}
              helperText={validator.message(errors['firstName'], 'religious name')}
              error={validator.isDefined(errors['firstName'])}
              value={data.firstName}
              variant="standard"
            />
          </Form>
        </div>
      }
    </div>
  );
}

ProfileName.defaultProps = {
  editing: false,
  errors: {}
};

ProfileName.propTypes = {
  data: PropTypes.object,
  onFieldChange: PropTypes.func,
  onSubmit: PropTypes.func
};

export function nameFromProfile(data) {
  return { 
    salutation: data.salutation || '', 
    firstName: data.firstName || ''
  };
}

export function formatName(data) {
  const { salutation, firstName } = data;
  let name = 'No name on record';
  if(firstName) {
    name = salutation? ` ${salutation} ` : ' ';
    name += `${firstName}`;
  }

  return name;
}

export function validateName(data) {
  const validationErrors = validator.validate(data, [
    { rule: validator.rules.MATCHES_PATTERN, prop: 'salutation', against: validator.patterns.SALUTATION, empty: true },
    { rule: validator.rules.MATCHES_PATTERN, prop: 'firstName', against: validator.patterns.NAME, empty: true },
    { rule: validator.rules.IS_DEFINED, prop: 'firstName' }
  ]);
    
  return validationErrors? validationErrors : { valid: true };
}