import axios from 'axios';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;

export async function getCourseProgress(courseId) {
  return await axios.get(`${urls.hubUIApiRoot}/member/course/progress/${courseId}`);
}

export async function deleteCourseProgress(courseId, status) {
  return await axios.post(`${urls.hubUIApiRoot}/member/course/progress/${courseId}/remove`, { status });
}

export async function updateTopicProgress(courseId, topicId, data) {
  return await axios.post(`${urls.hubUIApiRoot}/member/course/progress/${courseId}/topics/${topicId}`, data);
}

export async function getTasks(courseId, listId) {
  return await axios.get(`${urls.hubUIApiRoot}/member/course/progress/${courseId}/tasks/${listId}`);
}

export async function updateTasks(courseId, listId, data) {
  return await axios.post(`${urls.hubUIApiRoot}/member/course/progress/${courseId}/tasks/${listId}`, data);
}

export async function getAssessmentHistory(courseId, assessmentId) {
  return await axios.get(`${urls.hubUIApiRoot}/member/course/progress/${courseId}/assessments/${assessmentId}`);
}

export async function getAllAssessmentHistory(courseId) {
  return await axios.get(`${urls.hubUIApiRoot}/member/course/progress/${courseId}/assessments`);
}

export async function getAssessmentRecord(courseId, assessmentId, recordId) {
  return await axios.get(`${urls.hubUIApiRoot}/member/course/progress/${courseId}/assessments/${assessmentId}/records/${recordId}`);
}

export async function updateAssessment(courseId, assessmentId, data) {
  return await axios.post(`${urls.hubUIApiRoot}/member/course/progress/${courseId}/assessments/${assessmentId}`, data);
}

export async function getCourseTranscripts() {
  return await axios.get(`${urls.hubUIApiRoot}/member/course/progress/transcripts`);
}

export async function getProgressSummary() {
  return await axios.get(`${urls.hubUIApiRoot}/member/course/progress/summary`);
}