import React, { useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as authAPI from '../../../actions/authentication';
import urls from '../../../config/urls';
import stateConfig from '../../../config/state';
import { deviceDescription } from '../../../utils/device';
import { resetViewToTopOfPage } from '../../../utils/utils';


function RememberDevice({ authentication, authAPI }) {
  const { responseStatus: authResponseStatus } = authentication;
  const navigate = useNavigate();

  useEffect(() => {
    resetViewToTopOfPage();
  }, []);

  const rememberDevice = () => {
    const device = deviceDescription();
    authAPI.rememberDevice(device);
  };

  const continueWithLogin = useCallback(() => {
    navigate(urls.loginRedirect);
  }, [navigate]);

  useEffect(() => {
    if(authResponseStatus === stateConfig.authStatus.DEVICE_REMEMBERED) {
      continueWithLogin();
    }
  }, [authResponseStatus, continueWithLogin]);

  return (
    <div id="RememberDevice">
      <div className="content">
        <p className="title">Remember this device?</p>
        <p className="message">
          We can remember you on trusted devices, so that you do not have to login every time you 
          access your membership. Use this feature only on devices that you own and trust.
        </p>
        <div className="actions multiple">
          <Button variant="contained" color="primary" onClick={rememberDevice}>
            Remember This Device
          </Button>
          <Button variant="outlined" onClick={continueWithLogin}>
            Not Now
          </Button>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authAPI: bindActionCreators(authAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RememberDevice);