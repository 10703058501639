import React from 'react';
import PropTypes from 'prop-types';
import useAnalytics from '../../../hooks/useAnalytics';

function PageTitle({ type, data, className }) {
  useAnalytics(`${type}: ${data.title}`);
  return (
    <h1 className={`PageTitle ${className || ''}`}>{data.title}</h1>
  );
}

PageTitle.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object
};

export default PageTitle;
