import { MAGDALA_REG_REQUEST, MAGDALA_RETRIEVED_COMMUNITIES, MAGDALA_REG_COMPLETE, 
         MAGDALA_REG_ERR } from '../actions/actionTypes';
import * as magdala from '../api/magdala';
import errors from '../config/errors';


export function getMagdalaCommunities() {
  return async dispatch => {
    dispatch({ type: MAGDALA_REG_REQUEST });
    try {
      let result = await magdala.getMagdalaCommunities();
      if(result.data && result.data.communities) {
        dispatch({ type: MAGDALA_RETRIEVED_COMMUNITIES, data: result.data });
      } else {
        dispatch({ type: MAGDALA_REG_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: MAGDALA_REG_ERR, error: errors.RequestFailed });
    }
  };
}

export function submitMagdalaProgramApplication(application) {
  return async dispatch => {
    dispatch({ type: MAGDALA_REG_REQUEST });
    try {
      let result = await magdala.submitMagdalaProgramApplication(application);
      if(result.data.success) {
        dispatch({ type: MAGDALA_REG_COMPLETE });
      } else {
        dispatch({ type: MAGDALA_REG_ERR, error: errors.RequestFailed });
      }
    } catch(error) {
      dispatch({ type: MAGDALA_REG_ERR, error: errors.RequestFailed });
    }
  };
}