import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../../hooks/useAnalytics';
import useEventTracking from '../../../hooks/useEventTracking';
import * as authAPI from '../../../actions/authentication';
import ProviderAuthRedirect from './ProviderAuthRedirect';
import ContactErrorPopMessage from '../../common/messages/ContactErrorPopMessage';
import stateConfig from '../../../config/state';
import urls from '../../../config/urls';
import config from '../../../config/config';
import analyticsEvents from '../../../config/analytics/events';
import { localRetrieve } from '../../../utils/store';
import { Fragment } from 'react';


function LoginFacebook({ authentication, authAPI }) {
  const [popMessage, setPopMessage] = useState(null);
  const navigate = useNavigate();
  useAnalytics('Facebook Login');
  const trackEvent = useEventTracking([ 
    config.trackingLoggers.googleEvents, 
    config.trackingLoggers.hubEvents 
  ]);
  const { responseStatus: authResponseStatus } = authentication;

  const onComplete = data => {
    const contextParams = localRetrieve(config.localStorage.authContextParams, true);
    const authData = Object.assign({}, data, { contextParams });
    authAPI.loginWithProvider(authData);
  };

  const onError = error => {
    setPopMessage('Facebook authentication failed.');
  };

  const clearPopMessage = () => {
    navigate(urls.login);
  };

  useEffect(() => {
    if(authResponseStatus === stateConfig.authStatus.AUTH_COMPLETE) {
      trackEvent(analyticsEvents.loginComplete('facebook'));
      navigate(urls.rememberDevice);
    }

    if(authResponseStatus === stateConfig.authStatus.ERROR) {
      setPopMessage('Facebook authentication failed to complete.');
    }
  }, [authResponseStatus, authAPI, navigate, trackEvent]);

  return (
    <Fragment>
      { popMessage !== null &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>{popMessage}</p>
        </ContactErrorPopMessage>
      }

      <ProviderAuthRedirect providerId="facebook" onComplete={onComplete} onError={onError} />
    </Fragment>
  );
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authAPI: bindActionCreators(authAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFacebook);