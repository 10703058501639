import React, { Fragment, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useAnalytics from '../../hooks/useAnalytics';
import * as eventsAPI from '../../actions/content/events';
import EventList from '../content/events/display/EventList';
import LoadingMessage from '../common/messages/LoadingMessage';
import PopMessage from '../common/messages/PopMessage';
import RestrictedContentPrompt from '../content/common/RestrictedContentPrompt';
import config from '../../config/config';
import errors from '../../config/errors';
import stateConfig from '../../config/state';
import { resetViewToTopOfPage } from '../../utils/utils';
import SEOMeta from '../common/SEOMeta';
import validator from '../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Watched({ events, watched, user, eventsAPI }) {
  const [loading, setLoading] = useState(false);
  const [showMemberPrompt, setShowMemberPrompt] = useState(false);
  const [eventList, setEventList] = useState(null);
  const [error, setError] = useState(null);
  const stateId = `${stateConfig.keys.EVENT_LIST}.watched`;
  useAnalytics('Recently Watched');
  // defining fns used in useEffect blocks
  let retrieveWatched = config.emptyFn;

  useEffect(() => {
    resetViewToTopOfPage();
  }, []);

  useEffect(() => {
    if(user.userDataChecked && isNotEmpty(user.email)) {
      retrieveWatched();
    } else if(user.userDataChecked && isEmpty(user.email)) {
      setShowMemberPrompt(true);
      setLoading(false);
    }
  }, [user.userDataChecked, user.email, retrieveWatched]);

  useEffect(() => {
    const data = events.byId[stateId];
    if(loading && isEmpty(eventList) && isNotEmpty(data) && events.ids) {
      if(isEmpty(data.error) || data.error.code !== errors.UserIdentificationFailed.code) {
        if(data.error) {
          setError(data.error);
        } else {
          setEventList(data);
        }
      }
      setLoading(false);
    }
  }, [loading, eventList, stateId, events, events.ids]);

  useEffect(() => {
    setEventList(null);
    setTimeout(retrieveWatched, 1000);
  }, [watched, retrieveWatched]);

  retrieveWatched = () => {
    setLoading(true);
    eventsAPI.getWatched();
  };

  const clearPopMessage = () => {
    setError(null);
  };
  
  return (
    <div className="MainContent-container">
      <SEOMeta 
        title="Recently Watched" 
        description="Go back and finish a program, or watch it again." />

      { isEmpty(eventList) && isEmpty(error) && !showMemberPrompt && <LoadingMessage message="Loading..." /> }
      
      <div className="EventList-container watched">
        { error &&
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>We could not load the requested page.</p>
          </PopMessage>
        }

        { isNotEmpty(eventList) && eventList.results && !showMemberPrompt &&
          <Fragment>
            { eventList.results.length === 0 &&
              <div className="MyList-none">
                <h2>Recently Watched Events</h2>
                <p>You do not currently have any recently watched events</p>
              </div>
            }
            { eventList.results.length > 0 &&
              <EventList events={eventList.results} displayType={config.eventListTypes.BROWSE}
                heading={`Recently Watched Events`} cardsPerRender={config.defaultCardsPerRender} />
            }
          </Fragment>
        }

        { showMemberPrompt &&
          <RestrictedContentPrompt />
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    events: state.events, 
    watched: state.behavior.watched,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    eventsAPI: bindActionCreators(eventsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Watched);
