import React from 'react';
import urls from '../../../config/urls';
import Button from '@mui/material/Button';
import TitleWithSubtext from '../../common/TitleWithSubtext';
import PropTypes from 'prop-types';

function MagdalaContactUs(props) {
  
  const { content } = props; 
  const {value: titleWithSubtext } = content['contactUs.intro']; 
  const button = content['contactUs.button'].value; 

  const openContact = () => {
    window.open(urls.magdalaContactUs, '_blank')
  }

  return (
    <div className="Magdala-Program-Contact-Us container">
      <div className="container-content">
        <TitleWithSubtext titleWithSubtext={titleWithSubtext} />
        <Button variant="outlined" onClick={openContact}>{button.title}</Button>
      </div>
    </div>
  );
}

MagdalaContactUs.propTypes = {
  content: PropTypes.object
}

export default MagdalaContactUs;