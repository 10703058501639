import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as shareAPI from '../../../../actions/share';
import ShareForm from './ShareForm';
import Icon, { IconTypes } from '../../../common/Icon';
import urls from '../../../../config/urls';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import ShareIcon from '@mui/icons-material/Share';


class DetailSharing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareOpen: false,
      copied: false
    };

    this.toggleShareDisplay = this.toggleShareDisplay.bind(this);
    this.shareOnSocialMedia = this.shareOnSocialMedia.bind(this);
    this.copyShareLink = this.copyShareLink.bind(this);
  }

  componentDidMount() {
    const { slug } = this.props.eventData;
    this.props.shareAPI.getEventShareLink(slug);
  }

  toggleShareDisplay() {
    this.setState({ shareOpen: !this.state.shareOpen });
  }

  shareOnSocialMedia = platformUrl => event => {
    const url = platformUrl + window.location.href;
    window.open(url, '_blank');
  }

  async copyShareLink() {
    await navigator.clipboard.writeText(this.props.share.eventLink);
    this.setState({ copied: true });
  }

  render() {
    const { shareOpen, copied } = this.state;
    const { user, share, eventData } = this.props;
    const { categories } = eventData;

    return (
      <div className="EventDetail-sharing">
        <div className="content">
          <Button variant="contained" className="action share" color="primary"
            onClick={this.toggleShareDisplay}>
            <ShareIcon /> Share
          </Button>
          <p className="categories">
            { categories && categories.length > 0 &&
              <Fragment>
                <strong>Related to:</strong>
                { 
                  categories.map((category, index) => {
                    let { slug, name } = category;
                    return (
                      <Fragment key={slug}>
                        <Link to={`/events/categories/${slug}`}>{name}</Link>
                        { index !== categories.length-1? ', ' : '' }
                      </Fragment>
                    );
                  })
                }
              </Fragment>
            }
          </p>
        </div>
        <Collapse className="actions" in={shareOpen} unmountOnExit>
          <Paper className="share-actions" elevation={4}>
            <h4>Share this event to your feed</h4>
            <div className="buttons">
              <Button variant="outlined" color="primary" onClick={this.shareOnSocialMedia(urls.shareFacebook)}>
                <Icon type={IconTypes.ShareFacebook} /> Facebook
              </Button>
              <Button variant="outlined" color="primary" onClick={this.shareOnSocialMedia(urls.shareTwitter)}>
                <Icon type={IconTypes.ShareTwitter} /> Twitter
              </Button>
            </div>
            { user.email && share.eventLink &&
              <div className="share-event-link">
                <h4>Share a link to this event</h4>
                <p>{share.eventLink}</p>
                <Button variant="outlined" color="primary" onClick={this.copyShareLink}>
                  <Icon type={IconTypes.Copy} /> { copied? 'Copied' : 'Copy Link' }
                </Button>
              </div>
            }
            { user.email &&
              <div className="email">
                <h4>Share this event with an email to a friend or family member</h4>
                <p>Note: Your name will display to the email recipient.</p>
                <ShareForm eventData={eventData} onComplete={this.toggleShareDisplay} />
              </div>
            }
          </Paper>
        </Collapse>
      </div>
    );
  }
}

DetailSharing.propTypes = {
  eventData: PropTypes.object
};

function mapStateToProps(state) {
  return { 
    user: state.user,
    share: state.share
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    shareAPI: bindActionCreators(shareAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailSharing);