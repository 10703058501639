import { PROFILE_REQUEST, PROFILE_RETRIEVED, PROFILE_UPDATED, PASSWORD_UPDATED,
         PROFILE_ERR } from '../actions/actionTypes';
import * as profile from '../api/profile';
import errors from '../config/errors';


export function getProfile() {
  return async dispatch => {
    dispatch({ type: PROFILE_REQUEST });
    try {
      let result = await profile.getProfile();
      if(result && result.data) {
        dispatch({ type: PROFILE_RETRIEVED, data: result.data });
      } else {
        dispatch({ type: PROFILE_ERR, error: errors.ResponseMalformed });
      }
    } catch(error) {
      if(error.response && [401, 403].includes(error.response.status)) {
        dispatch({ type: PROFILE_ERR, error: errors.ReAuthRequired });
      } else {
        dispatch({ type: PROFILE_ERR, error: errors.RequestFailed });
      }
    }
  };
}

export function updateProfile(dataType, profileData) {
  return async dispatch => {
    dispatch({ type: PROFILE_REQUEST });
    try {
      let result = await profile.updateProfile(dataType, profileData);
      if(result && result.data) {
        dispatch({ type: PROFILE_UPDATED, data: result.data });
      } else {
        dispatch({ type: PROFILE_ERR, error: errors.ResponseMalformed });
      }
    } catch(error) {
      dispatch({ type: PROFILE_ERR, error: errors.RequestFailed });
    }
  };
}

export function deleteProfileItem(dataType, itemId) {
  return async dispatch => {
    dispatch({ type: PROFILE_REQUEST });
    try {
      let result = await profile.deleteProfileItem(dataType, itemId);
      if(result && result.data) {
        dispatch({ type: PROFILE_UPDATED, data: result.data });
      } else {
        dispatch({ type: PROFILE_ERR, error: errors.ResponseMalformed });
      }
    } catch(error) {
      dispatch({ type: PROFILE_ERR, error: errors.RequestFailed });
    }
  };
}

export function updatePassword(passwordData) {
  return async dispatch => {
    dispatch({ type: PROFILE_REQUEST });
    try {
      await profile.updatePassword(passwordData);
      dispatch({ type: PASSWORD_UPDATED });
    } catch(error) {
      if(error.response && error.response.data) {
        dispatch({ type: PROFILE_ERR, error: error.response.data });
      } else {
        dispatch({ type: PROFILE_ERR, error: errors.RequestFailed });
      }
    }
  };
}