import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Player from '@vimeo/player';
import urls from '../../../config/urls';


function VimeoVideoEmbed({ video, meta, onProgress, onEnded, startTime, mediaJumpPoint }) {
  const [videoId, setVideoId] = useState(null);
  const [videoFrame, setVideoFrame] = useState(null);
  const [player, setPlayer] = useState(null);
  const [tracking, setTracking] = useState(false);


  useEffect(() => {
    if(video.id !== videoId) {
      // a new video has loaded, clean up any existing player event tracking
      if(player) {
        player.off('progress');
        player.off('ended');
      }
      setVideoId(video.id);
    }
  }, [videoId, video.id, player]);

  const videoLoaded = () => {
    const player = new Player(videoFrame);
    setPlayer(player);
    setTracking(false); 
    onProgress({ videoId, type: 'video', percent: 0, time: startTime || 0 });
  };

  const videoProgress = useCallback(async progress => {
    const { duration } = progress;
    const seconds = await player.getCurrentTime();
    const percent = seconds / duration;
    if(onProgress) {
      onProgress({ videoId, type: 'video', percent, time: seconds, duration });
    }
  }, [player, onProgress, videoId]);

  const videoEnded = useCallback(() => {
    if(onEnded) {
      onEnded({ videoId, type: 'video' });
    }
  }, [videoId, onEnded]);

  useEffect(() => {
    if(!tracking && player) {
      player.on('progress', videoProgress);
      player.on('ended', videoEnded);
      setTracking(true);
    }
  }, [player, tracking, videoProgress, videoEnded]);

  useEffect(() => {
    if(mediaJumpPoint) {
      player.setCurrentTime(mediaJumpPoint);
    }
  }, [player, mediaJumpPoint]);

  useEffect(() => {
    if(player && startTime > 0) {
      player.setCurrentTime(startTime);
    }
  }, [player, startTime]);

  return (
    <div className="video">
      <iframe id={`vimeoVideoPlayer-${video.id}`} 
        src={`${urls.vimeoPlayer}/${video.id}${video.hash? `?h=${video.hash}` : ''}`} 
        title={meta.title} 
        frameBorder="0" allow="autoplay; fullscreen" 
        allowFullScreen 
        ref={el => setVideoFrame(el)} 
        onLoad={videoLoaded}>
      </iframe>
    </div>
  );
}

VimeoVideoEmbed.defaultProps = {
  startTime: 0
};

VimeoVideoEmbed.propTypes = {
  video: PropTypes.object,
  meta: PropTypes.object,
  onProgress: PropTypes.func,
  onEnded: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    mediaJumpPoint: state.behavior.mediaJumpPoint 
  };
}

export default connect(mapStateToProps)(VimeoVideoEmbed);

