import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, IconButton } from '@mui/material';
import Icon, { IconTypes } from '../Icon';


function AnnotationsToggle({ toggle, displayAnnotations, completed, defaultTopMargin }) { 
  const [margin, setMargin] = useState(''); 

  useEffect(() => {
    if(completed) {
      setMargin('completed-margin'); 
    } else {
      setMargin('incomplete-margin'); 
    }   
  }, [completed]); 

  return (
    <div className="annotations-toggle" style={{marginTop: `${defaultTopMargin}px`}}>
      <div className={`display-btn ${margin}`}>
        <Button variant="text" className="annotationsBtn" size="small" endIcon={<Icon type={IconTypes.PenField} />} onClick={() => toggle()}>{displayAnnotations? 'Hide Annotations' : 'Annotations'}</Button>
      </div>
      <div className={`display-btn-mobile ${margin}`}>
        <IconButton size="small" onClick={() => toggle()}>
          <Icon type={IconTypes.PenField} />
        </IconButton>
      </div>
    </div>
  )
}

AnnotationsToggle.propTypes = {
  toggle: PropTypes.func,
  displayAnnotations: PropTypes.bool
};

AnnotationsToggle.defaultProps = {
  completed: false,
  defaultTopMargin: 5
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(AnnotationsToggle);