import querystring from 'qs';
import config from '../config/config';
import { sessionStore, sessionRetrieve } from './store';

export function getTestModes() {
  // pull in any test mode values from URL params
  const params = querystring.parse(window.location.search.substring(1));
  const paramValue = params.testmodes || '';
  const paramTestModes = paramValue.split(',');
  // pull existing session test mode values
  const sessionTestModes = sessionRetrieve(config.localStorage.testModes, true) || [];
  // insert any new test modes into the session list, store it, and return it
  let testModes = [...sessionTestModes, ...paramTestModes.filter(item => item && !sessionTestModes.includes(item))];
  testModes = testModes.map(item => item.trim());
  sessionStore(config.localStorage.testModes, testModes, true);
  return testModes;
}