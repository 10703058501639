import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Icon, { IconTypes } from '../../../common/Icon';
import { formatTimeForDisplay } from '../../../../utils/format';
import validator from '../../../../utils/validator';
import Collapse from '@mui/material/Collapse';


class DetailParticipation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayLocation: false
    };
    
    this.showLocation = this.showLocation.bind(this);
  }

  showLocation() {
    this.setState({ displayLocation: true });
  }

  render() {
    const { displayLocation } = this.state;
    const { dateDisplay, time, discussionTime } = this.props.eventData.datetime;
    const { location } = this.props.eventData.registration;
    
    return (
      <div className="EventDetail-participation">
        <h3>Event Details</h3>
        <div className="participation-item date">
          <Icon type={IconTypes.Events} inline={true} />
          <p className="date-display">{dateDisplay}</p>
        </div>
        <div className="participation-item time">
          <Icon type={IconTypes.Time} inline={true} />
          <div>
            { validator.isNotEmpty(discussionTime) &&
              <p className="discussion-time">
                Pre-Class Discussion: &nbsp;<span className="time-display">{ formatTimeForDisplay(discussionTime) }</span>
              </p>
            }
            <p className="lecture-time">
              Lecture: &nbsp;<span className="time-display">{ formatTimeForDisplay(time) }</span>
            </p>
          </div>
        </div>
        <div className="participation-item where">
          <Icon type={IconTypes.User} inline={true} />
          <div>
            { !location || location === 'online-only'? 
              'Registration is required.' :
              'Registration is required. Participants may attend online or in person.' 
            }
            { location && location !== 'online-only' &&
              <Fragment>
                { !displayLocation && 
                  <button className="link-display" onClick={this.showLocation}>In-person attendance information</button> 
                }
                <Collapse in={displayLocation}>
                  <div className="location">
                    <p className="room">{location.room}</p>
                    <p className="address">{location.address}</p>
                    <p className="maps-url">
                      <a href={location.mapsURL} target="_blank" rel="noopener noreferrer">View in Google Maps</a>
                    </p>
                  </div>
                </Collapse>
              </Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

DetailParticipation.propTypes = {
  eventData: PropTypes.object
};

export default DetailParticipation;