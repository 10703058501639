import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import StandardContent from './StandardContent';
import MediaContent from './MediaContent';
import DonationContent from './DonationContent';


function PopupContent({ type, title, description, snippet, data, onUpdate }) {
  return (
    <Fragment>
      { type === 'standard' &&
        <StandardContent type={type} title={title} description={description} snippet={snippet} />
      }
      { type === 'media' &&
        <MediaContent title={title} description={description} data={data} />
      }
      { type === 'donation' &&
        <DonationContent title={title} description={description} data={data} onUpdate={onUpdate} />
      }
    </Fragment>
  );
}

PopupContent.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  snippet: PropTypes.string,
  data: PropTypes.object,
  onUpdate: PropTypes.func
};

export default PopupContent;