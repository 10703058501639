import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function CategoryNavigation(props) {
  return (
    <Tabs
      orientation="horizontal"
      variant="scrollable"
      indicatorColor="secondary"
      textColor="secondary"
      value={props.currentTab}
      onChange={props.onNavigationChange}
      className="Curriculum-tabs">
      <Tab label="Foundations" />
      <Tab label="Core" />
      <Tab label="Electives" />
    </Tabs>
  );
}

CategoryNavigation.propTypes = {
  curriculum: PropTypes.object,
  onNavigationChange: PropTypes.func
};

export default CategoryNavigation;