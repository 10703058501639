import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useAnalytics from '../../../hooks/useAnalytics';
import * as instructorsAPI from '../../../actions/content/instructors';
import EventList from '../events/display/EventList';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import SEOMeta from '../../common/SEOMeta';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Instructor({ instructors, instructorsAPI }) {
  const [loading, setLoading] = useState(true);
  const [instructor, setInstructor] = useState(null);
  const [error, setError] = useState(null);
  const [stateId, setStateId] = useState(null);
  useAnalytics('Instructor Bio');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const data = instructors.byId[stateId];
    if(loading && isEmpty(instructor) && isNotEmpty(data) && instructors.ids) {
      if(data.error) {
        setError(data.error);
      } else {
        setInstructor(data);
      }
      setLoading(false);
    }
  }, [loading, instructor, stateId, instructors, instructors.ids]);

  initialize = () => {
    resetViewToTopOfPage();
    const instructorId = window.location.pathname.replace('/instructors/', '');
    const stateId = `${stateConfig.keys.INSTRUCTOR}.${instructorId}`;
    const data = instructors.byId[stateId];
    setStateId(stateId);

    if(isNotEmpty(data) && !data.error) {
      setInstructor(data);
      setLoading(false);
    } else {
      instructorsAPI.getInstructorById(instructorId);
    }
  };

  const clearPopMessage = () => {
    setError(null);
  };

  return (
    <div className="MainContent-container">
      { !instructor && !error && <LoadingMessage message="Loading..." /> }
      
      <div className="Instructor-container">
        { error &&
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>We could not load the requested page.</p>
          </PopMessage>
        }

        { instructor && 
          <Fragment>
            <SEOMeta 
              title="Instructors" 
              description={`Explore courses taught by ${instructor.name}.`} />
            <div className="heading">
              <div className="thumbnail">
                <img src={instructor.imageURL} alt={`${instructor.name}`} />
              </div>
              <div>
                <h2 className="name">{instructor.name}</h2>
                <div className="bio" dangerouslySetInnerHTML={{__html: instructor.bio}} />
              </div>
            </div>
            <div className="bio-alt" dangerouslySetInnerHTML={{__html: instructor.bio}} />
            <div className="EventList-container instructor">
              <EventList events={instructor.events} displayType={config.eventListTypes.BROWSE}
                heading={`Events by this instructor`} cardsPerRender={config.defaultCardsPerRender} />
            </div>
          </Fragment>
        }
      </div>
    </div>
  );
}


function mapStateToProps(state) {
  return { instructors: state.instructors };
}

function mapDispatchToProps(dispatch) {
  return { 
    instructorsAPI: bindActionCreators(instructorsAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Instructor);
