import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import * as coursesAPI from '../../../actions/content/courses';
import * as progressAPI from '../../../actions/progress';
import Course from './Course';
import CourseTopic from './topics/CourseTopic';
import CourseAssessment from './assessments/CourseAssessment';
import CourseNavigation from './detail/CourseNavigation';
import CourseOutline from './detail/CourseOutline';
import CourseHelp from './detail/CourseHelp';
import Icon, { IconTypes } from '../../common/Icon';
import PopMessage from '../../common/messages/PopMessage';
import LoadingMessage from '../../common/messages/LoadingMessage';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import validator from '../../../utils/validator';
import urls from '../../../config/urls';
const { isEmpty, isNotEmpty } = validator;


function CourseRouter({ user, courses, courseProgress, coursesAPI, progressAPI }) {
  const [loading, setLoading] = useState(true);
  const [courseLoaded, setCourseLoaded] = useState(false);
  const [transcriptLoaded, setTranscriptLoaded] = useState(false);
  const [reloadTranscript, setReloadTranscript] = useState(false);
  const [settings, setSettings] = useState(null);
  const [pathname, setPathname] = useState(null);
  const [course, setCourse] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [currentLessonId, setCurrentLessonId] = useState(null);
  const [currentPartId, setCurrentPartId] = useState(null);
  const [progressUpdated, setProgressUpdated] = useState(0);
  const [error, setError] = useState(null);
  const [registered, setRegistered] = useState(false);
  const [courseComplete, setCourseComplete] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let coursePathSettings = config.emptyFn;
  let topicPathSettings = config.emptyFn;
  let assessmentPathSettings = config.emptyFn;
  let findTranscript = config.emptyFn;
  let retrieveTranscripts = config.emptyFn;
  let onRegistration = config.emptyFn;

  useEffect(() => {
    if(pathname !== location.pathname) {
      initialize();
    }
  }, [pathname, location.pathname, initialize]);

  useEffect(() => {
    if(settings && settings.stateId) {
      const data = courses.byId[settings.stateId];
      if(!courseLoaded && user.userDataChecked && isNotEmpty(data) && courses.ids) {
        if(data.error) {
          setError({ display: true });
        } else {
          setCourse(data);
          setCourseLoaded(true);
        }
      }
    }
  }, [courseLoaded, settings, user.userDataChecked, courses.ids, courses.byId]);

  useEffect(() => {
    if(courseLoaded) {
      retrieveTranscripts();
    }
  }, [courseLoaded, retrieveTranscripts]);

  useEffect(() => {
    if(reloadTranscript) {
      progressAPI.getCourseTranscripts();
    }
  }, [reloadTranscript, progressAPI]);

  useEffect(() => {
    const stateId = `${stateConfig.keys.PROGRESS_TRANSCRIPTS}.all`;
    const data = courseProgress.byId[stateId];
    if(!transcriptLoaded && !isEmpty(course) && isNotEmpty(data) && courseProgress.ids) {
      if(data.error) {
        setError({ display: true });
      } else {
        const match = findTranscript(data);
        if(isEmpty(match)) {
          setRegistered(false);
          setTranscript({});
          setTranscriptLoaded(true);
        } else {
          setRegistered(true);
          setTranscript(match);
          setTranscriptLoaded(true);
          if(config.courseComplete.includes(match.status)) {
            setCourseComplete(true);
          }
        }
      }
    }
  }, [transcriptLoaded, course, courseProgress.ids, courseProgress.byId, findTranscript]);

  useEffect(() => {
    if(loading && courseLoaded && transcriptLoaded) {
      setLoading(false);
      setReloadTranscript(false);
    }
  }, [loading, courseLoaded, transcriptLoaded]);

  useEffect(() => {
    if(!isEmpty(course) && !isEmpty(transcript) && !courseComplete) {
      const stateId = `${stateConfig.keys.PROGRESS}.${course.id}`;
      if(transcript.status !== 'complete' && courseProgress.ids.includes(stateId)) {
        const data = courseProgress.byId[stateId];
        if(data && data.courseComplete) {
          setCourseComplete(true);
        }
      }
    }
  }, [course, transcript, courseComplete, courseProgress.ids, courseProgress.byId]);

  initialize = () => {
    resetViewToTopOfPage();
    const isTopic = window.location.pathname.includes('/topics/');
    const isAssessment = window.location.pathname.includes('/assessments/');
    const isCourseHome = !(isTopic || isAssessment);

    let sObj = {};
    if(isCourseHome) {
      sObj = coursePathSettings();      
      setSettings({ name: 'Course', ...sObj });
      setCurrentLessonId(null);
      setCurrentPartId(null);
    } else if(isTopic) {
      sObj = topicPathSettings();  
      setSettings({ name: 'CourseTopic', ...sObj });
    } else if(isAssessment) {
      sObj = assessmentPathSettings();  
      setSettings({ name: 'CourseAssessment', ...sObj });
    }

    const { slug, stateId } = sObj;
    const data = courses.byId[stateId];
    if(!data || data.error) {
      coursesAPI.getCourseBySlug(slug);
    }

    setPathname(sObj.pathname);
  };

  coursePathSettings = () => {
    const pathname = location.pathname;
    const slug = `course-${pathname.replace('/courses/', '')}`;
    const stateId = `${stateConfig.keys.COURSE}.${slug}`;
    return { slug, stateId, pathname };
  };

  topicPathSettings = () => {
    const pathname = location.pathname;
    const urlParts = pathname.split('/');
    const topicSlugParts = urlParts[4].split('-');
    const courseSlug = `course-${urlParts[2]}`;
    const topicId = topicSlugParts[1];
    const courseStateId = `${stateConfig.keys.COURSE}.${courseSlug}`;
    const topicStateId = `${stateConfig.keys.COURSE_TOPIC}.${topicId}`;
    return { slug: courseSlug, stateId: courseStateId, topicId, topicStateId, pathname };
  };

  assessmentPathSettings = () => {
    const pathname = location.pathname;
    const urlParts = pathname.split('/');
    const assessmentSlugParts = urlParts[4].split('-');
    const courseSlug = `course-${urlParts[2]}`;
    const assessmentId = assessmentSlugParts[1];
    const courseStateId = `${stateConfig.keys.COURSE}.${courseSlug}`;
    const assessmentStateId = `${stateConfig.keys.COURSE_ASSESSMENT}.${assessmentId}`;
    return { slug: courseSlug, stateId: courseStateId, assessmentId, assessmentStateId, pathname };
  };

  findTranscript = data => {
    if(!isEmpty(course)) {
      return data.transcripts.find(transcript => transcript.courseId === course.id && transcript.status !== config.courseRecordOnly);
    }
  };

  retrieveTranscripts = () => {
    const data = courseProgress.byId[`${stateConfig.keys.PROGRESS_TRANSCRIPTS}.all`];
    if(isEmpty(user.email)) {
      setTranscript({});
      setTranscriptLoaded(true);
    } else if(!data || data.error) {
      progressAPI.getCourseTranscripts();
    }
  };

  onRegistration = () => {
    setLoading(true);
    setTranscriptLoaded(false);
    setTranscript(null);
    setReloadTranscript(true);
  };

  const onPartSelection = (lessonId, partId) => {
    setCurrentLessonId(lessonId);
    setCurrentPartId(partId);
  };

  const onProgressUpdated = () => {
    setTimeout(() => {
      setProgressUpdated(Date.now);
    }, 1500);
  };

  const clearPopMessage = () => {
    setError({ display: false });
  };

  const navigateHome = () => {
    const { slug } = settings;
    let url = urls.course.replace(':slug', slug.replace('course-', ''));
    navigate(url);
  };

  const renderNavigation = () => {
    return (
      <CourseNavigation 
        course={course} 
        transcript={transcript} 
        selectedPartId={settings.name === 'CourseTopic'? settings.topicId : settings.assessmentId}
        courseComplete={courseComplete} 
        lastUpdated={progressUpdated}
      />
    );
  };

  const authenticated = user && isNotEmpty(user.email) && user.authenticated;
  const userDataChecked = user && user.userDataChecked;
  if(!isEmpty(settings) && (settings.name === 'CourseTopic' || settings.name === 'CourseAssessment') && userDataChecked && transcriptLoaded && (!registered || !authenticated)) {
    // redirect
    navigate(urls.course.replace(':slug', settings.slug.replace('course-', '')));
  }

  return (
    <div className={`CourseRouter ${!isEmpty(settings)? settings.name : 'Course'}`}>
      { loading && isEmpty(error) &&
        <LoadingMessage message="Loading..." />
      }
      { !isEmpty(error) && error.display &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}>
          <p>We could not load this course.</p>
        </PopMessage>
      }
      { !loading && isEmpty(error) &&
        <div className={`${settings.name}-content`}>
          { settings.name !== 'Course' &&
            <div className="nav-home">
              <Button variant="text" color="primary" onClick={navigateHome}>
                <Icon type={IconTypes.ArrowBack} /> Course Home
              </Button>
            </div>
          }
          { settings.name === 'Course' &&
            <Course 
              course={course} 
              transcript={transcript}
              authenticated={authenticated} 
              registered={registered}
              courseComplete={courseComplete}
              onRegistration={onRegistration}
            />
          }
          { settings.name === 'CourseTopic' && authenticated &&
            <CourseTopic 
              course={course} 
              transcript={transcript}
              settings={settings} 
              courseComplete={courseComplete}
              onTopicChange={onPartSelection}
              onProgressUpdated={onProgressUpdated} 
              navigation={renderNavigation()}
            />
          }
          { settings.name === 'CourseAssessment' && authenticated &&
            <CourseAssessment 
              course={course} 
              transcript={transcript}
              settings={settings} 
              courseComplete={courseComplete}
              courseLocked={config.courseArchived.includes(transcript.status)}
              onAssessmentChange={onPartSelection}
              onProgressUpdated={onProgressUpdated}
              navigation={renderNavigation()}
            />
          }
          <div className="Course-sidebar">
            <CourseOutline 
              course={course} 
              currentLessonId={currentLessonId} 
              selectedPartId={currentPartId} 
              courseHome={settings.name === 'Course'}
              transcript={transcript}
              authenticated={authenticated}
              registered={registered}
              courseComplete={courseComplete}
              updated={progressUpdated}
            />
            <CourseHelp />
          </div>
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    user: state.user,
    courses: state.courses,
    courseProgress: state.courseProgress
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    coursesAPI: bindActionCreators(coursesAPI, dispatch),
    progressAPI: bindActionCreators(progressAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseRouter);