import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import * as authenticationAPI from '../../../../actions/authentication';
import Icon, { IconTypes } from '../../../common/Icon';
import PopMessage from '../../../common/messages/PopMessage';
import ContactErrorPopMessage from '../../../common/messages/ContactErrorPopMessage';
import MemberRegistrationForm from '../../../member/registration/MemberRegistrationForm';
import ApplicationEmail from './ApplicationEmail';
import ApplicationName from './ApplicationName';
import config from '../../../../config/config';
import urls from '../../../../config/urls';
import validator from '../../../../utils/validator';
import { resetViewToTopOfPage } from '../../../../utils/utils';
const { isNotEmpty, isEmpty } = validator;


function ApplicationIdentification({ event, onContinue, user, authenticationAPI }) {
  const [email, setEmail] = useState('');
  const [applicationOpen, setApplicationOpen] = useState(true);
  const [membershipPrompt, setMembershipPrompt] = useState(false);
  const [membershipError, setMembershipError] = useState(false);
  const [notEligibleError, setNotEligibleError] = useState(false);
  const [applicationRedirect, setApplicationRedirect] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(event && event.magdala && isNotEmpty(user.email) && isNotEmpty(user.type) && user.type !== config.memberTypes.magdala) {
      setNotEligibleError(true);
    }
  }, [event, user.email, user.type]);

  initialize = () => {
    const { pathname, search } = location;
    setApplicationRedirect(`${pathname}${search}`);
  };

  const displayMembershipPrompt = (email) => {
    setMembershipPrompt(true);
    setEmail(email);
  };

  const backToEmailEntry = () => {
    resetViewToTopOfPage();
    setMembershipPrompt(false);
    setEmail('');
  };

  const memberRegistrationComplete = async (error, fields) => {
    if(error) {
      setMembershipError(true);
      return;
    }

    try {
      await authenticationAPI.identifyDeviceOwner();
    } catch(error) {
      // fail silently if this does not work for some reason
    }

    onContinue(fields, true);
  };

  const onNameUpdate = (nameFields) => {
    onContinue({ email: user.email, ...nameFields }, false);
  };

  const magdalaRegistration = () => {
    navigate(urls.magdalaRegistration);
  };

  const clearPopMessage = () => {
    setApplicationOpen(false);
  };

  return (
    <div className="ApplicationIdentification">
      { isEmpty(user.email) && !membershipPrompt &&
        <div className="email-identification">
          <h2>{event.title}</h2>
          <ApplicationEmail onContinue={displayMembershipPrompt} />
        </div>
      }
      { isNotEmpty(user.email) && !membershipPrompt && !notEligibleError &&
        <div className="update-name">
          <h2>{event.title}</h2>
          <ApplicationName event={event} onContinue={onNameUpdate} />
        </div>
      }
      { isEmpty(user.email) && membershipPrompt && !membershipError &&
        <div className="membership-creation application-step">
          <div className="back">
            <Button variant="text" onClick={backToEmailEntry}>
              <Icon type={IconTypes.ArrowBack} /> Go Back
            </Button>
          </div>

          <h2>{event.title}</h2>
          <p className="title"><strong>Pre-Application Step:</strong> Member Registration</p>
          <p className="message">
            It looks like you are new to the Institute. Please create your free membership account before proceeding 
            with your application.
          </p>
          <MemberRegistrationForm 
            collectSalutation={true} 
            collectPassword={true} 
            submitLabel="Continue"
            email={email} 
            onComplete={memberRegistrationComplete} 
            source="course-application" 
            contextParams={{ redirect: applicationRedirect, collectSalutation: true }}
          />
        </div>
      }
      { membershipError && applicationOpen &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>
            We were unable to create a membership for you at this time. Please wait a bit and try 
            again. If this issue continues please reach out to us using our contact form.
          </p>
        </ContactErrorPopMessage>
      }
      { notEligibleError && applicationOpen &&
        <PopMessage type="error" horizontal="center" open={true} onClose={clearPopMessage}
          action={
            <Button variant="outlined" onClick={magdalaRegistration}>
              Register Now
            </Button>
          }>
          <p>
            You do not appear to be eligible to apply for this Magdala Apostolate course. If you are a religious 
            sister or community, please click the link below to register for the Magdala Apostolate program. If you 
            have already registered for the Magdala Apostolate program, please ensure you are logged in and try again.
          </p>
        </PopMessage>
      }
    </div>
  );
}

ApplicationIdentification.propTypes = {
  event: PropTypes.object,
  onContinue: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authenticationAPI: bindActionCreators(authenticationAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationIdentification);
