import React from 'react';
import PropTypes from 'prop-types';
import DonationEmbed from '../common/DonationEmbed';
import validator from '../../../utils/validator';
const { isEmpty } = validator;


function DonationHeading({ title, description, data }) {
  return (
    <div className="DonationHeading">
      <div className="DonationHeading-content">
        <div className="main">
          <h2 dangerouslySetInnerHTML={{__html: title}} />
          { !isEmpty(description) &&
            <p dangerouslySetInnerHTML={{__html: description}} />
          }
        </div>
        <DonationEmbed data={data} />
      </div>
    </div>
  );
}

DonationHeading.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.object
};

export default DonationHeading;