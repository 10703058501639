
const errors = {
  SpamValidationError: { code: 'F201', message: 'You entered an incorrect value for this field' },
  DuplicateRecordError: { code: 'F223', message: 'This value already exists' },
  CredentialFailure: { code: 'F240', message: 'Authentication failed' },
  DeviceAuthenticationError: { code: 'F241', message: 'Device authentication failed' },
  MemberNotVerified: { code: 'F242', message: 'Member not verified' },
  ReAuthRequired: { code: 'F243', message: 'Re-authentication required' },
  AuthenticationError: { code: 'F245', message: 'Authentication failed' },
  AuthenticationCodeFailure: { code: 'F246', message: 'Authentication failed' },
  PasswordlessFailure: { code: 'F260', message: 'Authentication failed' },
  ActivationResendFailure: { code: 'F261', message: 'Failed to re-send activation' },
  PasswordResetFailure: { code: 'F262', message: 'Failed to reset password' },
  ProviderLoginFailure: { code: 'F263', message: 'Authentication failed' },
  UserIdentificationFailed: { code: 'F283', message: 'Failed to identify user' },
  TokenValidationFailed: { code: 'F288', message: 'Failed to validate token' },
  ResponseMalformed: { code: 'RR100', message: 'Response to client was malformed' },
  RequestFailed: { code: 'RR101', message: 'Request failed' },
  ContentResourceNotFoundError: { code: 'F1404', message: 'The requested resource was not found' },
  ResourceNotFoundError: { code: 'F104', message: 'The requested resource was not found' },
  MemberRegistrationInitError: { code: 'MR001', message: 'We are experiencing technical difficulties with our registration form.' },
  MemberRegistrationExpired: { code: 'MR002', message: 'Your registration form expired. Would you like to refresh the form and try again?' },
  MemberRegistrationError: { code: 'MR003', message: 'Your registration request could not be completed.' },
  MemberActivationError: { code: 'MA001', message: 'Member activation failed.' },
  MemberActivationExpired: { code: 'MA002', message: 'The member activation code was invalid or expired.' },
  EventCompletedError: { code: 'ER001', message: 'The event has completed and is no longer open for registration.' },
  ValidationError: { code: 'CF200', message: 'Client validation failed' }
};

export default errors;