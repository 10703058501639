import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import useEvent from '../../hooks/content/useEvent';
import validator from '../../utils/validator';
const { isNotEmpty } = validator;

function Event({ slug, onEventLoaded }) {
  const eventData = useEvent(slug);

  useEffect(() => {
    // Don't fire the loaded fn unless the event data key and the slug match. This keeps the fn 
    // from being called too early when slug changes, but new event data hasn't been loaded yet.
    if(isNotEmpty(eventData) && eventData.key === slug) {
      onEventLoaded(eventData);
    }
  }, [slug, eventData, onEventLoaded]);

  return (<Fragment></Fragment>);
}

Event.propTypes = {
  slug: PropTypes.string,
  onEventLoaded: PropTypes.func
};

export default Event;