import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useWatched from '../../hooks/content/useWatched';
import validator from '../../utils/validator';
const { isNotEmpty } = validator;

function Watched({ content, onWatchedLoaded }) {
  const [loaded, setLoaded] = useState(false);
  const watchedData = useWatched(content);

  useEffect(() => {
    if(!loaded && isNotEmpty(watchedData)) {
      setLoaded(true);
      onWatchedLoaded(watchedData);
    }
  }, [loaded, watchedData, onWatchedLoaded]);

  return (<Fragment></Fragment>);
}

Watched.propTypes = {
  content: PropTypes.object,
  onWatchedLoaded: PropTypes.func
};

export default Watched;