import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Icon, { IconTypes } from '../../../common/Icon';
import Collapse from '@mui/material/Collapse';
import validator from '../../../../utils/validator'; 
const { isNotEmpty, isEmpty } = validator; 

function ClassroomOutline({ content, mediaType, mediaId, baseURL, watched, navigateHome, contentType }) {
  const [openTab, setOpenTab] = useState(null); 
  const [openPartTab, setOpenPartTab] = useState(null); 
  const [resourceTab, setResourceTab] = useState(true); 
  const { media: { video, audio, resource } } = content;
  
  const expandPartTab = partIndex => () => {
    if(partIndex === openPartTab) {
      setOpenPartTab(null); 
    } else {
      setOpenPartTab(partIndex); 
    }
  }
  
  const expandResourceTab = tab => () => {
    if(tab === 'resources') {
      setResourceTab(!resourceTab); 
    } else if (tab === openTab) {
      setOpenTab(null);
    } else {
      setOpenTab(tab); 
    }
  }

  const renderResourceItem = resource => {
    return (
      <Fragment>
        { isEmpty(resource.url) &&
        <div className="items">
          <Icon className="resource-item" type={IconTypes.Document} />
          <p dangerouslySetInnerHTML={{__html: resource.title}} />
        </div>
        }
        { !isEmpty(resource.url) &&
        <div className="items">
          <Icon className="resource-item"type={IconTypes.Document} />
          <a href={resource.url}
            target="_blank"
            rel="noopener noreferrer"
            dangerouslySetInnerHTML={{__html: resource.title}}
          />
        </div>
        }
      </Fragment>
    )
  }
          
  const renderResourcesTab = resourcesArr => {
    return (
      <Fragment>
        <div className="tab">
          <div className="tab-heading">
            <Icon className="title" type={IconTypes.InfoCircle} />
            <h4 onClick={expandResourceTab("resources")}>Resources</h4>
          </div>
          <Collapse className="resource-parts" in={resourceTab}>
            { resourcesArr && resourcesArr.length > 0 &&
              resourcesArr.map((resource, index) => {
                return (
                  <div key={`${index}:${resource.title}`}>
                    {renderResourceItem(resource)}
                  </div>
                )
              })
            }
          </Collapse>
        </div>
      </Fragment>
    )
  }

  const determinePartStatus = (videoParts, audioParts, mapIndex) => {
    let watchStarted = false;
    let watchComplete = false; 
    let statuses = []; 
  
    if(videoParts && videoParts.length > 0) {
      statuses = videoParts.map((videoItem, index) => {
        let videoWatchLog = watched.find(log => log.mediaId === videoItem.id); 
        let audioWatchLog = null; 
        if(audioParts && audioParts[index]) {
          audioWatchLog = watched.find(log => log.mediaId === audioParts[index].id); 
        }
        if((videoWatchLog) || (audioWatchLog)) {
          let videoStarted = null; 
          let audioStarted = null; 
          if(isNotEmpty(videoWatchLog)) {
            videoStarted = isNotEmpty(videoWatchLog.completed)
          }
          if(isNotEmpty(audioWatchLog)) {
            audioStarted = isNotEmpty(audioWatchLog.completed); 
          }
          
          let currentStatus = null; 
          if((videoStarted && !videoWatchLog.completed) || (audioStarted && !audioWatchLog.completed)) {
            currentStatus = "incomplete"; 
            return currentStatus; 
          } else if((videoStarted && videoWatchLog.completed) || (audioStarted && audioWatchLog.completed)) {
            currentStatus = "complete"; 
            return currentStatus; 
          }
        }
        return "not-started"; 
      }); 
      watchStarted = statuses[mapIndex] === 'incomplete'; 
      watchComplete = statuses[mapIndex] === 'complete'; 
      
    } else if(audioParts && audioParts.length > 0) { // audio only
      statuses = audioParts.map((audioItem) => {
        let audioWatchLog = watched.find(log => log.mediaId === audioItem.id); 
        let audioStarted = null;
        if(isNotEmpty(audioWatchLog)) {
          audioStarted = isNotEmpty(audioWatchLog.completed);
        }
        let currentStatus = null; 
        if(audioStarted && !audioWatchLog.completed) {
          currentStatus = "incomplete"; 
          return currentStatus; 
        } else if(audioStarted && audioWatchLog.completed) {
          currentStatus = "complete"; 
          return currentStatus; 
        }
        return "not-started"; 
      });
      watchStarted = statuses[mapIndex] === 'incomplete'; 
      watchComplete = statuses[mapIndex] === 'complete'; 
    }
    const status = {
      watchStarted,
      watchComplete
    }
    return status;
  }

  const setProgressIcon = (status) => {
    const { watchStarted, watchComplete } = status; 
    const iconType = 
    (!watchStarted && !watchComplete? "NotStarted":
      watchStarted && !watchComplete? "Incomplete":
      watchComplete? "CompleteFilled": "NotStarted");
    return iconType; 
  }

  const setTabHeading = (videoParts, audioParts, videoPart, audioPart) => {
    let tabHeading = null; 
    let headingPrefix = null;
    let headingSuffix = null; 

    if(videoParts.length > 0) {
      headingPrefix = videoParts.length === 1? 'Media' : videoPart.title; 
      
      if(audioPart) {
        headingSuffix = ((videoPart.id === mediaId) || (audioPart.id === mediaId))? '(Playing)' : ''; 
      } else if(!audioPart) {
        headingSuffix = (videoPart.id === mediaId)? '(Playing)' : ''; 
      }
      
    } else if(audioParts.length > 0) { // audio only
      headingPrefix = audioPart.title; 
      headingSuffix = (audioPart.id === mediaId)? '(Playing)' : ''; 
    } 
    
    tabHeading = `${headingPrefix} ${headingSuffix}`;
    return tabHeading;
  }

  const setCollapseCondition = (videoParts, audioParts, videoPart, audioPart, mapIndex) => {
    let collapseCondition = null;
    if(videoParts && videoParts.length > 0) {
      if((videoParts && videoParts.length === 1) || (audioParts && audioParts.length === 1)) {
        collapseCondition = true; 
      } else if(videoParts.length >= 1) {
        if(audioPart) {
          collapseCondition = (videoPart.id === mediaId || mediaId === audioPart.id) || (mapIndex === openPartTab); 
        } else if(!audioPart) {
          collapseCondition = (videoPart.id === mediaId) || (mapIndex === openPartTab);  
        }
      } 
    } else if(audioParts && audioParts.length > 0) { // audio only use case
      if(audioParts && audioParts.length === 1) {
        collapseCondition = true; 
      } else if(audioParts.length >= 1) {
        collapseCondition = (audioPart.id === mediaId || mapIndex === openPartTab); 
      }
    }
    return collapseCondition; 
  }

  const renderMediaTab = (progressIcon, tabHeading, collapseCondition, videoPart, audioPart, mapIndex) => {
    return (
      <div key={mapIndex}>
        <div className='tab'>
          <div className='tab-heading'>
            <Icon className="title" type={IconTypes[progressIcon]} />
            <h4 onClick={expandPartTab(mapIndex)}>{tabHeading}</h4>
          </div>
          <Collapse className="parts" in={collapseCondition}>
            <div className='items'>
              { videoPart && 
                <Link to={`${baseURL}/video/${videoPart.id}`} className={(mediaType === 'video' && mediaId === videoPart.id)? 'active' : ''}>Watch</Link>
              }
              { videoPart && audioPart && <span>|</span>}
              { audioPart &&
                  <Link to={`${baseURL}/audio/${audioPart.id}`} className={(mediaType === 'audio' && mediaId === audioPart.id)? 'active' : ''}>Listen</Link>
              }
            </div>
          </Collapse>
        </div>
      </div>
    )
  }

  const renderMediaTabs = () => {
    let tabs, videoParts, videoPart, audioParts, audioPart = null; 
    if(content.media.video) videoParts = content.media.video;
    if(content.media.audio) audioParts = content.media.audio;
    
    if(videoParts && videoParts.length > 0) {
      tabs = videoParts.map((videoPart, index) => {
        if(audioParts) audioPart = audioParts[index];
        
        const status = determinePartStatus(videoParts, audioParts, index); 
        const progressIcon = setProgressIcon(status); 
        const tabHeading = setTabHeading(videoParts, audioParts, videoPart, audioPart); 
        const collapseCondition = setCollapseCondition(videoParts, audioParts, videoPart, audioPart, index); 
        
        return renderMediaTab(progressIcon, tabHeading, collapseCondition, videoPart, audioPart, index);
      })
    } else if(audioParts && audioParts.length > 0) { // audio only use case
      tabs = audioParts.map((audioPart, index) => {
        const status = determinePartStatus(videoParts, audioParts, index); 
        const progressIcon = setProgressIcon(status); 
        const tabHeading = setTabHeading(videoParts, audioParts, videoPart, audioPart); 
        const collapseCondition = setCollapseCondition(videoParts, audioParts, videoPart, audioPart, index); 
        
        return renderMediaTab(progressIcon, tabHeading, collapseCondition, videoPart, audioPart, index);
      })
    }
    return tabs;
  }  

  return (
    <div className="ClassroomOutline">
      <div className="ClassroomOutline-heading">
        <h3>{contentType} Outline</h3>
        <Button variant="outlined" color="primary" onClick={navigateHome}>
          {contentType} Home
        </Button>
      </div>
      <div className="ClassroomOutline-list">
        { ((video && video.length > 0) || (audio && audio.length > 0)) &&
          <div>
            {renderMediaTabs()}
          </div>
        }
        { resource && resource.length > 0 &&
          <div className="resourceTab">
            {renderResourcesTab(resource)}
          </div>
        }
      </div>
    </div>
  );
}

ClassroomOutline.propTypes = {
  content: PropTypes.object,
  mediaType: PropTypes.string,
  mediaId: PropTypes.string,
  baseURL: PropTypes.string,
  watched: PropTypes.array,
  navigateHome: PropTypes.func,
  contentType: PropTypes.string
};

function mapStateToProps(state) {
  return { watched: state.behavior.watched }; 
}

export default connect(mapStateToProps)(ClassroomOutline); 