import React from 'react';
import Icon, { IconTypes } from '../../common/Icon';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

export default function NewMemberConfirmation(props) {
  return (
    <div id="NewMemberConfirmation" size="double">
      <div className="NewMemberConfirmation-followup">
        <h2>Next Up: Activate Your New Membership</h2>
        <div className="NewMemberConfirmation-steps">
          <Card className="NewMemberConfirmation-step one" square={false}>
            <CardContent className="NewMemberConfirmation-instructions">
              <h3><Icon type={IconTypes.Email} avatar={true} spacing="padded" inline={true} /> Step 1</h3>
            </CardContent>
            <CardContent>
              <p>
                You will receive an account activation email in your email inbox.
              </p>
            </CardContent>
          </Card>
          <Card className="NewMemberConfirmation-step two" square={false}>
            <CardContent className="NewMemberConfirmation-instructions">
              <h3><Icon type={IconTypes.Click} avatar={true} spacing="padded" inline={true} /> Step 2</h3>
            </CardContent>
            <CardContent>
              <p>
                To complete setting up your membership, open the activation email and click on the 'Activate Your Membership' button in that email.
              </p>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}