import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import EventListRow from './EventListRow';
import Icon, { IconTypes } from '../../../common/Icon';
import config from '../../../../config/config';
const { deviceBreakpoints } = config;

function EventList({ 
    displayType,
    cardsPerRender,
    moreAtLimit,
    showIntroduction,
    useHeadingForPage,
    events,
    heading,
    description,
    moreText,
    moreLink
  }) {
  const [rowsDisplayed, setRowsDisplayed] = useState(1);


  // define view boundaries
  const isExtraSmallView = useMediaQuery({
    query: `(max-width: ${deviceBreakpoints.extraSmall}px)`
  });
  const isSmall = useMediaQuery({
    query: `(max-width: ${deviceBreakpoints.smallTablet}px)`
  });
  const isSmallView = isSmall && !isExtraSmallView;
  const isMedium = useMediaQuery({
    query: `(max-width: ${deviceBreakpoints.medium}px)`
  });
  const isMediumView = isMedium && !isSmallView;
  const isLarge = useMediaQuery({
    query: `(max-width: ${deviceBreakpoints.extraLarge}px)`
  });
  const isLargeView = isLarge && !isMediumView;

  const determineRows = (events, cardsPerRow) => {
    let rows = [];
    for(let i=0; i<events.length; i+=cardsPerRow) {
      let items = events.slice(i, i+cardsPerRow);
      let limit = cardsPerRow-items.length;
      for(let k=0; k<limit; ++k) {
        items.push({ placeholder: true });
      }
      rows.push(items);
    }
    return rows;
  };

  const onMoreSelected = () => {
    setRowsDisplayed(rowsDisplayed+1);
  };

  const renderConfig = () => {
    if(isExtraSmallView) {
      return { cardsPerRender: 3, cardsPerRow: 1, sizeClass: 'size-xsm' };
    } else if(isSmallView) {
      return { cardsPerRender: 4, cardsPerRow: 2, sizeClass: 'size-sm' };
    } else if(isMediumView) {
      return { cardsPerRender: 3, cardsPerRow: 3, sizeClass: 'size-md' };
    } else if(isLargeView) {
      return { cardsPerRender: 4, cardsPerRow: 4, sizeClass: 'size-lg' };
    } else {
      return { cardsPerRender: 5, cardsPerRow: 5, sizeClass: 'size-xlg' };
    }
  };

  const renderHeading = () => {
    return useHeadingForPage? (<h1>{heading}</h1>) : (<h2>{heading}</h2>);
  };

  const { cardsPerRender: defaultCardsPerRender, cardsPerRow, sizeClass } = renderConfig();
  const numberOfCards = cardsPerRender || defaultCardsPerRender;
  const renderBlock = events.slice(0, rowsDisplayed*numberOfCards);
  const rows = determineRows(renderBlock, cardsPerRow);
  
  return (
    <div className="EventList">
      { (showIntroduction && heading) && renderHeading() }
      { (showIntroduction && description) && <div className="list-description">{description}</div> }
      {
        rows.map((row, index) => {
          return (
            <Fragment key={`row-${index}`}>
              <EventListRow className={`row-${index} ${sizeClass}`} displayType={displayType} events={row} />
              { index < rows.length-1 && <div className="spacer"></div> }
            </Fragment>
          );
        })
      }
      { (!moreText || moreAtLimit) && renderBlock.length < events.length &&
        <div className="more-link">
          <span onClick={onMoreSelected}>+ Show More</span>
        </div>
      }
      { !moreAtLimit && moreText && moreLink &&
        <div className="more-link">
          <Link to={moreLink}>{moreText}</Link> &nbsp;<Icon type={IconTypes.ArrowForward} />
        </div>
      }
      { moreAtLimit && moreText && moreLink && renderBlock.length >= events.length &&
        <div className="more-link">
          <Link to={moreLink}>{moreText}</Link> &nbsp;<Icon type={IconTypes.ArrowForward} />
        </div>
      }
    </div>
  );
}

EventList.defaultProps = {
  displayType: config.eventListTypes.BROWSE,
  cardsPerRender: 0,
  moreAtLimit: false,
  showIntroduction: true,
  useHeadingForPage: false
};

EventList.propTypes = {
  events: PropTypes.array,
  heading: PropTypes.string,
  description: PropTypes.element,
  moreText: PropTypes.string,
  moreLink: PropTypes.string
};

export default EventList;