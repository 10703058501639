import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import * as behaviorAPI from '../../../../api/behavior'; 
import validator from '../../../../utils/validator';
const { isNotEmpty } = validator;


function CourseJoinLive({ transcript, liveTopicId }) {

  const openLiveEvent = () => {
    window.open(transcript.webinarLink, '_blank');
    if(isNotEmpty(liveTopicId)) {
      behaviorAPI.trackCourseAttendance(transcript.courseId, liveTopicId);
    }
  };

  return (
    <div className="CourseJoinLive">
      <div className="next-content">
        <h4>Live Course Lecture</h4>
        <p className="highlighted">Class is live right now!</p>
      </div>
      <div className="next-action">
        <Button variant="contained" color="secondary" onClick={openLiveEvent}>
          Join the Class
        </Button>
      </div>
    </div>
  );
}

CourseJoinLive.propTypes = {
  transcript: PropTypes.object,
  liveTopicId: PropTypes.string
};

export default CourseJoinLive;
