import gaEvents from './gaEvents';
import hubEvents from './hubEvents';

const eventDefinitions = {

  /*
  Application Events
  */
  appLoad: (page) => {
    return { 
      hub: hubEvents.appLoad(page)
    };
  },

  /*
  Authentication Events
  */
  loginComplete: (method) => { 
    return { 
      ga: gaEvents.loginComplete(method),
      hub: hubEvents.loginComplete(method)
    };
  },

  /*
  Content Selection Events
  */
  listen: (contentType, contentId, audioId) => { 
    return { 
      ga: gaEvents.listen(contentId, audioId),
      hub: hubEvents.listen(contentType, contentId, audioId)
    };
  },
  watch: (contentType, contentId, videoId) => { 
    return { 
      ga: gaEvents.watch(contentId, videoId),
      hub: hubEvents.watch(contentType, contentId, videoId)
    };
  },

  /*
  Conversions
  */
  googleAdClick: () => {
    return { 
      hub: hubEvents.googleAdClick()
    };
  },

  /*
  Donation Events
  */

  donationForm: (fields) => {
    return { 
      ga: gaEvents.donationForm(fields),
      hub: hubEvents.donationForm(fields)
    };
  },
  donationRedirect: (service) => {
    return { 
      ga: gaEvents.donationRedirect(service),
      hub: hubEvents.donationRedirect(service)
    };
  },
  donationCompleted: (transactionId, fields) => {
    return { 
      ga: gaEvents.donationCompleted(transactionId, fields),
      hub: hubEvents.donationCompleted(transactionId, fields)
    };
  }

};

export default eventDefinitions;