import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux'; 
import { useNavigate } from 'react-router-dom'; 
import PropTypes from 'prop-types';
import { scroller, Element } from 'react-scroll';
import Button from '@mui/material/Button';
import * as courseAPI from '../../../api/content/courses'; 
import Icon, { IconTypes } from '../../common/Icon';
import CourseStatus from './detail/CourseStatus';
import CourseLogin from './detail/CourseLogin';
import CourseRegistration from './detail/CourseRegistration';
import CourseHeading from './detail/CourseHeading';
import CourseNextAction from './detail/CourseNextAction';
import CourseJoinLive from './detail/CourseJoinLive';
import CourseDescription from './detail/CourseDescription';
import config from '../../../config/config';
import validator from '../../../utils/validator';
import urls from '../../../config/urls'; 
const { isEmpty } = validator;


function Course({ course, transcript, authenticated, user, registered, courseComplete, onRegistration }) {
  const [loaded, setLoaded] = useState(false);
  const [live, setLive] = useState(false);
  const [liveTopicId, setLiveTopicId] = useState(false);
  const navigate = useNavigate(); 
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let checkLiveStatus = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  initialize = () => {
    if(course.type === 'live') {
      checkLiveStatus();
    } else {
      setLoaded(true);
    }
  };

  checkLiveStatus = async () => {
    try {
      const resp = await courseAPI.getCourseLiveStatus(course.slug);
      if(resp.data && resp.data.live) {
        setLive(true);
        setLiveTopicId(resp.data.liveTopicId);
      }
      setLoaded(true);
    } catch(error) {
      // fail silently
    }
  };

  return (
    <div className={`Course-main ${authenticated? 'auth' : 'not-auth'}`}>
      <CourseHeading course={course} transcript={transcript} />
      { !authenticated &&
        <CourseLogin slug={course.slug.replace('course-', '')} />
      }
      { authenticated && course.magdala && user.type !== 'magdala-member' &&
        <div className="MagdalaOnlyPrompt">
          <div className="message-content">
            <h4>Closed Course</h4>
            <p className="message">This course can only be accessed by registered Magdala Apostolate members.</p>
            <p className="magdala-signup-prompt">*Are you a religious sister? <span onClick={() => navigate(urls.magdalaProgramPage)}>Register</span> for free to the Magdala Apostolate today!</p>
          </div>
        </div>
      }
      { authenticated && !registered && (!course.magdala || user.type === 'magdala-member' ) &&
        <CourseRegistration course={course} onRegistration={onRegistration} />
      }
      { authenticated && registered &&
        <CourseStatus course={course} transcript={transcript} courseComplete={courseComplete} />
      }
      { loaded && authenticated && registered && !courseComplete && config.courseNotComplete.includes(transcript.status) && (!live || isEmpty(transcript.webinarLink)) &&
        <CourseNextAction course={course} transcript={transcript} />
      }
      { loaded && authenticated && registered && !courseComplete && live && config.courseNotComplete.includes(transcript.status) &&  !isEmpty(transcript.webinarLink) &&
        <CourseJoinLive transcript={transcript} liveTopicId={liveTopicId} />
      }
      <div className="scroll-btn">
        <Button variant="outlined" color="primary" onClick={() => scroller.scrollTo('outline-scroll', { smooth: true })}>
          <Icon type={IconTypes.ArrowDown} /> Course Outline
        </Button>
      </div>
      <CourseDescription course={course} />
      <div className="scroll-point">
        <Element name="outline-scroll"></Element>
      </div>
      <div className="spacer"></div>
    </div>
  );
}

Course.propTypes = {
  course: PropTypes.object,
  transcript: PropTypes.object,
  authenticated: PropTypes.bool,
  registered: PropTypes.bool,
  onRegistration: PropTypes.func,
  courseComplete: PropTypes.bool
};

function mapStateToProps(state) {
  return { user: state.user }
}

export default connect(mapStateToProps)(Course);