import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import FormHelperText from '@mui/material/FormHelperText';
import ContactErrorPopMessage from '../../common/messages/ContactErrorPopMessage';
import Form from '../../common/form/Form';
import validator from '../../../utils/validator';


const cardElementStyle = {
  style: {
    base: {
      color: "#3C3C3C",
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#545454"
      }
    },
    invalid: {
      color: "#D32F2F",
      iconColor: "#FFA000"
    }
  }
};

function NewDonationCard({ onSubmit, onCancel }) {
  const [cardEntered, setCardEntered] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [transactionError, setTransactionError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleCardChange = (event) => {
    const err = event.error? event.error.message : null;
    setCardEntered(!event.empty);
    const message = err && err.includes('postal code') && err.includes('incomplete')? 
      'You must also provide the postal code associated with your card in the credit card field' : err;
    setCardError(message);
  };

  const addNewCard = async event => {
    event.preventDefault();

    if(!cardEntered) {
      setCardError('Credit card information is required.');
      return;
    }

    // First create the new payment method
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    });

    if(!payload.error) {
      if(payload.paymentMethod) {
        // Now complete update on Hub
        const { last4, exp_month, exp_year } = payload.paymentMethod.card;
        const card = { 
          paymentMethodId: payload.paymentMethod.id, 
          last4, 
          expires: { month: exp_month, year: exp_year },
          isNew: true
        };
        onSubmit(card);
      } else {
        setTransactionError('The donation failed to process: network request error');
      }
    } else {
      setTransactionError(`The donation failed to process: ${payload.error.message}`);
    }
  };

  const clearPopMessage = () => {
    setTransactionError(null);
  }

  return (
    <div className="NewDonationCard">
      { validator.isNotEmpty(transactionError) && 
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>{transactionError}</p>
        </ContactErrorPopMessage>
      }
      <Form onSubmit={addNewCard} onClickSecondary={onCancel} autoComplete="off" 
        submitLabel="Add Card" secondaryButtonLabel="Cancel">
        <div className="card">
          <CardElement id="card-element" options={cardElementStyle} onChange={handleCardChange} />
          { validator.isNotEmpty(cardError) &&
            <div className="card-element-error">
              <FormHelperText error={true}>{cardError}</FormHelperText>
            </div>
          }
        </div>
      </Form>
    </div>
  );
}

NewDonationCard.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default NewDonationCard;