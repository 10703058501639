import React, { useState, useEffect, createContext, useCallback } from 'react';
import { connect } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import querystring from 'qs';
import './App.scss';
import { ThemeProvider } from '@mui/material/styles';
import Theme from './styles/Theme';
import useFirebaseUserTracking from './hooks/useFirebaseUserTracking';
import useEventTracking from './hooks/useEventTracking';
import Header from './components/layout/Header';
import Toolbar from './components/layout/Toolbar';
import MagdalaToolbar from './components/layout/MagdalaToolbar';
import PageMessage from './components/layout/PageMessage';
import PageBanner from './components/layout/PageBanner';
import FeatureHeading from './components/marketing/FeatureHeading';
import ExploreMenu from './components/layout/menus/ExploreMenu';
import Footer from './components/layout/Footer';
import NotFound from './components/NotFound';
import Home from './components/Home';
import Login from './components/authentication/Login';
import LoginGoogle from './components/authentication/providers/LoginGoogle';
import LoginFacebook from './components/authentication/providers/LoginFacebook';
import Logout from './components/authentication/Logout';
import LogoutCompleted from './components/authentication/LogoutCompleted';
import RememberDevice from './components/authentication/remember/RememberDevice';
import ActivationPrompt from './components/authentication/activation/ActivationPrompt';
import LoginRedirect from './components/authentication/redirect/LoginRedirect';
import PasswordResetRequest from './components/authentication/PasswordResetRequest';
import PasswordReset from './components/authentication/PasswordReset';
import PrivacyPolicy from './components/content/about/PrivacyPolicy';
import MemberActivation from './components/member/registration/MemberActivation';
import MemberRegistration from './components/member/registration/MemberRegistration';
import GoogleRegistration from './components/member/registration/providers/GoogleRegistration';
import FacebookRegistration from './components/member/registration/providers/FacebookRegistration';
import PersonalInfo from './components/member/profile/PersonalInfo';
import ChangePassword from './components/member/profile/ChangePassword';
import ManageEmailPreferences from './components/member/notifications/ManageEmailPreferences';
import AccountDeletion from './components/member/account/AccountDeletion';
import CompleteAccountDeletion from './components/member/account/CompleteAccountDeletion';
import MagdalaLanding from './components/magdala/MagdalaLanding';
import MagdalaProgram from './components/magdala/MagdalaProgram'; 
import MagdalaCourses from './components/magdala/MagdalaCourses'; 
import MagdalaCurriculum from './components/magdala/MagdalaCurriculum'; 
import MagdalaRegistration from './components/magdala/registration/MagdalaRegistration'; 
import Donate from './components/member/donate/Donate';
import Share from './components/member/share/ShareLink';
import Donations from './components/my/Donations';
import Favorites from './components/my/Favorites';
import Transcript from './components/my/Transcript';
import Watched from './components/my/Watched';
import EventClassroom from './components/content/classroom/EventClassroom';
import ReflectionClassroom from './components/content/classroom/ReflectionClassroom';
import Events from './components/content/events/Events';
import Browse from './components/content/events/Browse';
import LinkedList from './components/content/events/LinkedList';
import ByCategory from './components/content/events/ByCategory';
import Curriculum from './components/content/events/curriculum/Curriculum';
import Event from './components/content/events/Event';
import Instructors from './components/content/instructors/Instructors';
import Instructor from './components/content/instructors/Instructor';
import Courses from './components/content/courses/Courses';
import CourseRouter from './components/content/courses/CourseRouter';
import Catechism from './components/content/courses/Catechism';
import CourseApplication from './components/content/courses/CourseApplication';
import GospelReflections from './components/content/reflections/GospelReflections';
import About from './components/content/about/About';
import Staff from './components/content/about/Staff';
import Leadership from './components/content/about/Leadership';
import Pilgrimage from './components/content/programs/Pilgrimage';
import Retreat from './components/content/programs/Retreat';
import AnnualReportExpired from './components/content/reports/AnnualReportExpired';
import Page from './components/content/posts/Page';
import Article from './components/content/posts/Article';
import Search from './components/content/search/Search';
import Filter from './components/content/search/Filter';
import urls from './config/urls';
import config from './config/config';
import analyticsEvents from './config/analytics/events';
import validator from './utils/validator';
const { isNotEmpty } = validator;


function App({ user }) {
  const [pageClass, setPageClass] = useState('');
  const [pageDisplay, setPageDisplay] = useState('display-normal');
  const [boxingDisplay, setBoxingDisplay] = useState('display-boxing');
  const [displayMagdalaToolbar, setDisplayMagdalaToolbar] = useState(false); 
  const location = useLocation();
  // definining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let onLoadEventLogging = config.emptyFn;
  let handlePageSetup = config.emptyFn;
  let checkForRedirectConditions = config.emptyFn;
  const setTrackingId = useFirebaseUserTracking();
  const trackHubEvent = useEventTracking([  
    // note: this logger is deliberately set up to only log to Hub if event
    // tracking is added later for GAE then set up a separate logger
    config.trackingLoggers.hubEvents 
  ]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    handlePageSetup();
  }, [location, handlePageSetup]);

  useEffect(() => {
    if(user.userDataChecked) {
      if(isNotEmpty(user.email) && isNotEmpty(user.trackingId)) {
        setTrackingId(user.trackingId);
      } else {
        setTrackingId(null);
      }
    }
  }, [user.email, user.trackingId, user.userDataChecked, setTrackingId]);

  initialize = () => { 
    onLoadEventLogging();
    checkForRedirectConditions();
    handlePageSetup();
  };

  handlePageSetup = useCallback(() => {
    const { pathname } = location;
    
    /*
    Apply any special application level style classes that pertain to this content
    */
    let pageClassVal = (pathname === '/' ||
                     pathname === '/magdala' ||
                     pathname === '/magdala/' ||
                     pathname === '/magdala/program' ||
                     pathname === '/magdala/program/' ||
                     pathname === '/magdala/courses' ||
                     pathname === '/magdala/courses/' ||
                     pathname === '/magdala/curriculum' ||
                     pathname === '/magdala/curriculum/')
                    ? 'home' : 'page'+pathname.replace(/\//g, '-');
  
    pageClassVal = pageClassVal.includes('assessments-assessment')? 'page-course-assessment' : pageClassVal;

    let boxingDisplayVal = 'display-boxing';
    const noBoxing = urls.noBoxing.find(dPath => dPath.exact? dPath.match === pathname : pathname.includes(dPath.match));
    if(isNotEmpty(noBoxing)) {
      boxingDisplayVal = `display-noboxing`;
    }
    
    let pageDisplayVal = 'display-normal';
    const distractionless = urls.distractionless.find(dPath => dPath.exact? dPath.match === pathname : pathname.includes(dPath.match));
    if(isNotEmpty(distractionless)) {
      pageDisplayVal = `display-content-only`;
    }

    setPageClass(pageClassVal);
    setPageDisplay(pageDisplayVal);
    setBoxingDisplay(boxingDisplayVal);
    setDisplayMagdalaToolbar((pathname.includes('courses/magdala-apostolate') || pathname.includes('events/magdala-apostolate'))? true : false);  
  }, [location]);

  onLoadEventLogging = () => {
    const { pathname, search } = location;
    let params = querystring.parse(search.substring(1));
    if(params.source === config.trackingSource.googleAds) {
      let adId = params[config.googleAdIDParam];
      trackHubEvent(analyticsEvents.googleAdClick(), { 
        source: adId? `gad.${adId}` : 'gad'
      });
    } else {
      trackHubEvent(analyticsEvents.appLoad(pathname), { 
        source: params.tks
      });
    }
  };

  checkForRedirectConditions = () => {
    const { pathname } = location;
    
    /*
    Handle domain conversions for alternate domains or old domains that now point to our primary website
    */
    let url = window.location.href;
    if(url && (url.includes('.app') || url.includes('www.')) && !url.includes('icctest') && process.env.REACT_APP_PERFORM_REDIRECTS === 'true') {
      url = url.replace('.app', '.org').replace('www.', '');
      window.location = url;
      return;
    }

    if(url && url.includes('iccradio.org') && process.env.REACT_APP_PERFORM_REDIRECTS === 'true') {
      window.location = urls.events;
      return;
    }

    if(url && url.includes('https://instituteofcatholicculture.education') && process.env.REACT_APP_PERFORM_REDIRECTS === 'true') {
      let slugConfig = urls.courseMigrationRedirects.find(item => url.includes(item.oldSlug));
      if(slugConfig) {
        window.location = `https://instituteofcatholicculture.org${urls.course.replace(':slug', slugConfig.newSlug)}`;
      } else {
        window.location = `https://instituteofcatholicculture.org${urls.notFound}?s=course`;
      }
    }
    
    if(url && url.includes('https://magdalaapostolate.org') && process.env.REACT_APP_PERFORM_REDIRECTS === 'true') {
      let pathConfig = urls.magdalaRedirects.find(item => url.includes(item.oldPath));
      if(pathConfig) {
        window.location = `https://instituteofcatholicculture.org${pathConfig.newPath}`;
      } else {
        window.location = `https://instituteofcatholicculture.org/magdala`;
      }
    }
              
    /*
    2023 Annual Report 
    */
    if(url && url.includes('https://instituteofcatholicculture.org/2023-report') && process.env.REACT_APP_PERFORM_REDIRECTS === 'true') {
      window.location = 'https://instituteofcatholicculture.ac-page.com/2023-report-mailing';
    }

    /*
    Handle donation campaign redirects: special, easy-to-remember URLs which map to a set of campaign specific donation page parameters
    */
    // 2024 EOY Drive Reply Device Re-direct
    if(pathname && pathname === urls.replyDeviceEOY2024redirectPath) {
      window.location = urls.donateFundraiseupEOY2024replyDevice;
    }
    // Legacy Campaign Re-directs
    let legacyDonationRedirectMatch = urls.legacyCampaignRedirects.includes(pathname);
    if(legacyDonationRedirectMatch) {
      window.location = urls.donateFundraiseupEOY2024btn; 
    }

    /*
    Cut off access to legacy donation drive-related direct links to our custom stripe-based donation form, re-route to a FundraiseUp form
    i.e '/donate?campaign=2024pledgerd' no longer available
    */
    let legacyDonationCampaign = pathname === '/donate' ? true : false; 
    if(legacyDonationCampaign) {
      window.location = urls.donateFundraiseupEOY2024btn; 
    }

  };

  const shouldHideToolbar = (pageClass) => {
    if(pageClass.includes('page-courses') && pageClass.includes('-topics-')) {
      return true;
    } else if(pageClass.includes('page-courses') && pageClass.includes('-assessments-')) {
      return true;
    } else if(pageClass.includes('page-events') && (pageClass.includes('-video-') || pageClass.includes('-audio'))) {
      return true;
    } else if(pageClass.includes('page-events-magdala-apostolate')) {
      return true;
    } else if(displayMagdalaToolbar === true) {
      return true;
    }

    return false;
  }

  const displayToolbar = shouldHideToolbar(pageClass)? ' hide-toolbar' : '';
  const displayClass = `App ${pageDisplay} ${boxingDisplay} ${pageClass}${displayToolbar}`;
  return (
    <div className={displayClass}>
      <Helmet>
        <title>Institute of Catholic Culture - Catholic Adult Education</title>
        <meta name="description" content="The Institute of Catholic Culture is an adult catechetical organization dedicated to the Church’s call for a new evangelization" />
      </Helmet>
      <ThemeProvider theme={Theme}>
        <Header />
        <div id="HeaderOffset"></div>
        <PageMessage />
        <PageBanner />
        <FeatureHeading />
        <Toolbar />
        { displayMagdalaToolbar &&
          <MagdalaToolbar />
        }
        <ExploreMenu />
        <main>
          <Routes>
            <Route path={urls.root} element={<Home />} />
            {/* Authentication */}
            <Route path={urls.login} element={<Login/> } />
            <Route path={urls.loginGoogle} element={<LoginGoogle/> } />
            <Route path={urls.loginFacebook} element={<LoginFacebook/> } />
            <Route path={urls.logout} element={<Logout/> } />
            <Route path={urls.logoutCompleted} element={<LogoutCompleted />} />
            <Route path={urls.rememberDevice} element={<RememberDevice/> } />
            <Route path={urls.loginActivationPrompt} element={<ActivationPrompt/> } />
            <Route path={urls.loginRedirect} element={<LoginRedirect/> } />
            <Route path={urls.pwdReset} element={<PasswordReset/> } />
            <Route path={urls.pwdResetRequest} element={<PasswordResetRequest/> } />
            {/* Member Actions (e.g., account, registration, profile, etc.) */}
            <Route path={urls.privacyPolicy} element={<PrivacyPolicy/> } />
            <Route path={urls.accountDeletion} element={<AccountDeletion />} />
            <Route path={urls.accountDeletionComplete} element={<CompleteAccountDeletion />} />
            <Route path={urls.memberActivation} element={<MemberActivation/> } />
            <Route path={urls.memberRegistration} element={<MemberRegistration/> } />
            <Route path={urls.memberGoogleRegistration} element={<GoogleRegistration/> } />
            <Route path={urls.memberFacebookRegistration} element={<FacebookRegistration/> } />
            <Route path={urls.profile} element={<PersonalInfo/> } />
            <Route path={urls.profileChangePassword} element={<ChangePassword/> } />
            <Route path={urls.profileEmailNotifications} element={<ManageEmailPreferences />} />
            <Route path={urls.share} element={<Share/> } />
            {/* Magdala Apostolate*/}
            <Route path={urls.magdalaRegistration} element={<MagdalaRegistration/> } />
            <Route path={urls.magdalaLandingPage} element={<MagdalaLanding/>} />
            <Route path={urls.magdalaProgramPage} element={<MagdalaProgram/>} />
            <Route path={urls.magdalaCoursesPage} element={<MagdalaCourses/>} />
            <Route path={urls.magdalaCurriculumPage} element={<MagdalaCurriculum/>} />
            {/* Donation */}
            <Route path={urls.donate} element={<Donate/> } />
            {/* Personalized Content/Data Displays (e.g., favorites, transcript, donation, etc.) */}
            <Route path={urls.myDonations} element={<Donations/> } />
            <Route path={urls.myFavorites} element={<Favorites/> } />
            <Route path={urls.myTranscript} element={<Transcript/> } />
            <Route path={urls.myWatched} element={<Watched/> } />
            {/* Classroom */}
            <Route path={urls.eventsClassroomVideo} element={<EventClassroom />} />
            <Route path={urls.eventsClassroomAudio} element={<EventClassroom />} />
            <Route path={urls.reflectionsLatinClassroomVideo} element={<ReflectionClassroom />} />
            <Route path={urls.reflectionsLatinClassroomAudio} element={<ReflectionClassroom />} />
            <Route path={urls.reflectionsByzantineClassroomVideo} element={<ReflectionClassroom />} />
            <Route path={urls.reflectionsByzantineClassroomAudio} element={<ReflectionClassroom />} />
            {/* Events */}
            <Route path={urls.events} element={<Events/> } />
            <Route path={urls.eventsBrowse} element={<Browse/> } />
            <Route path={urls.eventsLinkedList} element={<LinkedList/> } />
            <Route path={urls.eventsByCategory} element={<ByCategory/> } />
            <Route path={urls.event} element={<Event/> } />
            {/* Instructors */}
            <Route path={urls.instructors} element={<Instructors/> } />
            <Route path={urls.instructor} element={<Instructor/> } />
            {/* Courses */}
            <Route path={urls.courses} element={<Courses/> } />
            <Route path={urls.coursesCatechism} element={<Catechism/> } />
            <Route path={urls.courseApplication} element={<CourseApplication/> } />
            <Route path={urls.course} element={<CourseRouter/> } />
            <Route path={urls.courseTopic} element={<CourseRouter/> } />
            <Route path={urls.courseAssessment} element={<CourseRouter/> } />
            {/* Learn More */}
            { /* Learn more features were implemented w/radio features, which are disabled. If these features are 
            re-activated they should be evaluated prior to re-enabling them to make sure they don't need updates
            <Route path={urls.learnMore} element={<LearnMore/> } />
            <Route path={urls.learnConfirmation} element={<LearnMoreConfirmation/> } />
            */}
            {/* Reflections */}
            <Route path={urls.reflectionsLatin} element={<GospelReflections/> } />
            { /* BGR hidden for the time being per Father's request in Oct 2023, may be permanently removed later
              <Route path={urls.reflectionsByzantine} element={<GospelReflections/> } />
            */}
            <Route path={urls.reflection} element={<GospelReflections/> } />
            {/* Migrated paths from old website */}
            <Route path={urls.loginMigrated} element={<Login/> } />
            <Route path={urls.donateMigrated} element={<Donate/> } />
            <Route path={urls.missionMigrated} element={<About/> } />
            <Route path={urls.boardMigrated} element={<Leadership/> } />
            <Route path={urls.scholarsMigrated} element={<Instructors/> } />
            <Route path={urls.privacyPolicyMigrated} element={<PrivacyPolicy/> } />
            <Route path={urls.libraryMigrated} element={<Browse/> } />
            <Route path={urls.symposiumMigrated} element={<Courses/> } />
            <Route path={urls.talkMigrated} element={<Event/> } />
            {/* General Content */}
            <Route path={urls.about} element={<About/> } />
            <Route path={urls.staff} element={<Staff/> } />
            <Route path={urls.leadership} element={<Leadership/> } />
            <Route path={urls.pilgrimage} element={<Pilgrimage/> } />
            <Route path={urls.retreat} element={<Retreat/> } />
            <Route path={urls.retreatName} element={<Retreat/> } />
            <Route path={urls.search} element={<Search/> } />
            <Route path={urls.filter} element={<Filter/> } />
            <Route path={urls.article} element={<Article/> } />
            <Route path={urls.curriculum} element={<Curriculum/> } />
            <Route path={urls.annualReport} element={<AnnualReportExpired/> } />
            <Route path={urls.page} element={<Page/> } />
            <Route path={urls.registrationLandingPage} element={<Page/> } />
            {/* Fallback */}
            <Route path="*" element={<NotFound/> } />
          </Routes>
        </main>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    user: state.user
  };
}

export const AppContext = createContext();

export default connect(mapStateToProps)(App);
