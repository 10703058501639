import { jsPDF } from 'jspdf';
import axios from 'axios';
import config from '../config/config';
import urls from '../config/urls';
import { Buffer } from 'buffer';
const pdfConfig = config.pdf;

export function generateCertificatePage1(data) {
  const doc = createPDFDocument();
  const page1 = pdfConfig.certificatePage1;
  // page 1
  doc.addImage(data.image1, 'PNG', 0, 0, pdfConfig.landscape[0], pdfConfig.landscape[1]);
  doc.setTextColor(pdfConfig.defaultTextColor);
  doc.setFont(pdfConfig.defaultFont);
  doc.setFontSize(16);
  doc.text(data.completionDate, page1.dateLocation[0], page1.dateLocation[1]);
  doc.setFontSize(24);
  const nameLocation = page1.nameLocationFn(doc.getTextWidth(data.name));
  doc.text(data.name, nameLocation[0], nameLocation[1]);
  // page 2
  doc.addPage();
  doc.addImage(data.image2, 'PNG', 0, 0, pdfConfig.landscape[0], pdfConfig.landscape[1]);
  // download to the user's computer
  doc.save(`${data.fileName}.pdf`);
}

export async function getImageData(imageUrl) {
  try {
    const url = imageUrl.replace(urls.contentSiteUrl, urls.prodContentSiteUrl); // ensure prod URL is always used, even in test
    const req = axios.create({
      withCredentials: false
    });
    const resp = await req.get(url, { responseType: 'arraybuffer'});
    return Buffer.from(resp.data, 'binary').toString('base64');
  } catch(error) {
    throw error;
  }
}

function createPDFDocument() {
  return new jsPDF({
    orientation: "landscape",
    unit: "in",
    format: pdfConfig.landscape
  });
}