import axios from 'axios';
import urls from '../config/urls';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 10000;


/**
 * ACCOUNT DELETION
 */

export async function sendAccountDeletionEmail() {
  return await axios.post(`${urls.hubUIApiRoot}/member/account/delete/send`);
}

export async function deleteAccount(token) {
  const data = { token };
  return await axios.post(`${urls.hubUIApiRoot}/member/account/delete/confirm`, data);
}


/**
 * PRIVACY POLICY
 */

export async function getPrivacyPolicyStatus() {
  return await axios.post(`${urls.hubUIApiRoot}/member/account/privacy/policy`);
}

export async function updatePrivacyPolicyStatus(data) {
  return await axios.post(`${urls.hubUIApiRoot}/member/account/privacy/policy/acknowledge`, data);
}