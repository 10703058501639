import React from 'react';
import PropTypes from 'prop-types';
import Icon, { IconTypes } from '../../../common/Icon';
import validator from '../../../../utils/validator';
const { isEmpty } = validator;


function CourseLists({ lists }) {

  const listIcon = icon => {
    switch(icon) {
      case 'bullet-list':
        return (<Icon type={IconTypes.ContentList} />);
      case 'task-list':
        return (<Icon type={IconTypes.TaskList} />);
      default:
        return (<span></span>);
    }
  };

  const renderList = (list, index) => {
    return(
      <div key={`list-${index}`} className="list">
        <div className="list-heading">
          {listIcon(list.icon)}
          <h5>{list.title}</h5>
        </div>
        <ul>
          {list.items.map(renderItem)}
        </ul>
      </div>
    );
  };

  const renderItem = (item, index) => {
    return (
      <li key={`item-${index}`}>
        { !isEmpty(item.url)?
          <a href={item.url} target="_blank" rel="noopener noreferrer" dangerouslySetInnerHTML={{__html: item.text}} /> :
          <span dangerouslySetInnerHTML={{__html: item.text}} />
        }
      </li>
    );
  };

  return (
    <div className="CourseLists">
      {lists.map(renderList)}
    </div>
  );
}

CourseLists.propTypes = {
  lists: PropTypes.array
};

export default CourseLists;
