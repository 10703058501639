import React, { Fragment, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Player from '@vimeo/player';
import config from '../../../config/config';
import urls from '../../../config/urls';
import validator from '../../../utils/validator';
const { isNotEmpty } = validator;


function VideoEmbed({ video, startTime, onProgress, mediaJumpPoint }) {
  const videoEl = useRef(null);
  const [player, setPlayer] = useState(null);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [currentJumpPoint, setCurrentJumpPoint] = useState(-1);
  let disconnectPlayerEvents = config.emptyFn;
  let videoProgress = config.emptyFn;

  useEffect(() => {
    return () => {
      disconnectPlayerEvents();
    };
  }, [disconnectPlayerEvents]);
  
  useEffect(() => {
    if(isNotEmpty(currentVideoId) && video.id !== currentVideoId) {
      setCurrentVideoId(video.id);
      disconnectPlayerEvents();
    }
  }, [video.id, currentVideoId, disconnectPlayerEvents]);

  useEffect(() => {
    if(isNotEmpty(player) && mediaJumpPoint > 0 && mediaJumpPoint !== currentJumpPoint) {
      setCurrentJumpPoint(mediaJumpPoint);
      player.setCurrentTime(mediaJumpPoint);
    }
  }, [mediaJumpPoint, currentJumpPoint, player]);

  useEffect(() => {
    if(isNotEmpty(player)) {
      if(startTime) {
        player.setCurrentTime(startTime);
      }
      player.on('progress', videoProgress);
    }
  }, [player, startTime, videoProgress]);

  const videoLoaded = () => {
    const { id } = video;
    const player = new Player(videoEl.current);
    setPlayer(player);
    onProgress({ id, type: 'video', percent: 0, time: startTime || 0 });
  }

  videoProgress = progress => {
    const { id } = video;
    const { duration } = progress;
    player.getCurrentTime().then(function(seconds) {
      const percent = seconds / duration;
      onProgress({ id, type: 'video', percent, time: seconds, duration });
    });
  };

  disconnectPlayerEvents = () => {
    if(player) {
      player.off('progress');
    }
  };
  
  return (
    <Fragment>
      { video.serviceProvider === 'YouTube' &&
        <div className="support-notice">
          <strong>Please note:</strong> this video does not support some normal video features such as progress tracking or pinned annotations.
        </div>
      }
      <div className="video">
        { video.serviceProvider === 'YouTube' &&
          <iframe id={`classroomVideoPlayer-${video.id}`}
            src={`${urls.youtubePlayer}/${video.id}`}
            title={video.title} 
            frameBorder="0" 
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>
          </iframe>
        }
        { video.serviceProvider !== 'YouTube' && // defaults to Vimeo
          <iframe id={`classroomVideoPlayer-${video.id}`} 
            src={`${urls.vimeoPlayer}/${video.id}${video.hash? `?h=${video.hash}` : ''}`} 
            title={video.title} 
            frameBorder="0" 
            allow="autoplay; fullscreen" 
            allowFullScreen 
            ref={videoEl} 
            onLoad={videoLoaded}>
          </iframe>
        }
      </div>
    </Fragment>
  );
}

VideoEmbed.defaultProps = {
  startTime: 0
};

VideoEmbed.propTypes = {
  video: PropTypes.object,
  onProgress: PropTypes.func
};

function mapStateToProps(state) {
  return { mediaJumpPoint: state.behavior.mediaJumpPoint };
}

export default connect(mapStateToProps)(VideoEmbed);