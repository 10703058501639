import { useContext } from 'react';
import { getAnalytics, setUserId } from "firebase/analytics";
import { AppContext } from '../App';


function useFirebaseUserTracking() {
  const { firebaseApp } = useContext(AppContext);
  const googleAnalytics = getAnalytics(firebaseApp);
  
  const setTrackingId = (trackingId) => {
    setUserId(googleAnalytics, trackingId)
  };

  return setTrackingId;
}

export default useFirebaseUserTracking;