import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Form from '../../common/form/Form';


function SelectDonationCard({ cards, onSubmit, onCancel }) {
  const [selection, setSelection] = useState('none');

  const onFieldChange = event => {
    setSelection(event.target.value);
  }

  const selectCard = event => {
    event.preventDefault();
    if(selection === 'none') return;
    if(selection === 'add') {
      onSubmit('add');
    } else {
      const card = cards.find(card => card.paymentMethodId === selection);
      onSubmit({ ...card, isNew: false });
    }
  };

  return (
    <div className="SelectDonationCard">
      <Form onSubmit={selectCard} onClickSecondary={onCancel} autoComplete="off" 
        submitLabel="Continue" secondaryButtonLabel="Cancel">
        <Select name="month" value={selection} onChange={onFieldChange}>
          <MenuItem value='none'>Select the card you would like to use</MenuItem>
          {
            cards.map(card => {
              const { paymentMethodId, last4, expires: { month, year } } = card;
              return (<MenuItem key={paymentMethodId} value={paymentMethodId}>Last 4: {last4}, Expires: {month}/{year}</MenuItem>);
            })
          }
          <MenuItem value='add'>Add a new card</MenuItem>
        </Select>
      </Form>
    </div>
  );
}

SelectDonationCard.propTypes = {
  cards: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default SelectDonationCard;