import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from '../common/form/Form';
import TextField from '@mui/material/TextField';
import validator from '../../utils/validator';


function LoginCredentialsForm({ defaultEmail, submission, onSubmit }) {
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});

  const handleFieldChange = event => {
    const fObj = Object.assign({}, fields);
    fObj[event.target.name] = event.target.value;
    setFields(fObj);
  }

  const onSubmitCrendentials = event => {
    event.preventDefault();
    if(!validate()) {
      return;
    }
    const authData = Object.assign({}, fields);
    onSubmit(authData);
  }

  const validate = () => {
    const data = fields;
    const validationErrors = validator.validate(data, [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'email' },
      { rule: validator.rules.PASSWORD_CHECK, prop: 'password', empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'password' }
    ]);

    if(validationErrors) {
      setErrors(validationErrors);
      return false
    }

    return true;
  }

  useEffect(() => {
    setFields({
      email: defaultEmail,
      password: ''
    });
  }, [defaultEmail]);

  return (
    <Form id="LoginCredentialsForm" onSubmit={onSubmitCrendentials} submitLabel="Log In" submission={submission}>
      <TextField
        className="FormField-control full first"
        name="email"
        label="Email *"
        placeholder='Email'
        onChange={handleFieldChange}
        helperText={validator.message(errors['email'], 'email')}
        error={validator.isDefined(errors['email'])}
        value={fields.email}
        variant="standard"
      />

      <TextField
        className="FormField-control full"
        name="password"
        label="Password *"
        type="password"
        placeholder='Password'
        onChange={handleFieldChange}
        helperText={validator.message(errors['password'], 'password')}
        error={validator.isDefined(errors['password'])}
        value={fields.password}
        variant="standard"
      />
    </Form>
  );
}

LoginCredentialsForm.defaultProps = {
  defaultEmail: '',
  submission: false
};

LoginCredentialsForm.propTypes = {
  onSubmit: PropTypes.func
};

export default LoginCredentialsForm;