import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import StandardSystemPopup from '../../../layout/popup/StandardSystemPopup';
import CourseAssessmentResults from './CourseAssessmentResults';
import { formatDateTimeET } from '../../../../utils/format';
import validator from '../../../../utils/validator';
const { isEmpty } = validator;


function CourseAssessmentHistory({ assessment, records }) {
  const [popup, setPopup] = useState({});

  const closePopup = () => {
    setPopup({});
  };

  return (
    <div className="CourseAssessmentHistory">
      <h3>Assessment History</h3>
      <ol>
        {
          records.map((record, index) => {
            return (
              <li key={`record-${index}`} className="record">
                <span className="score">{record.status === 'complete'? `${record.score}%` : 'Saved'}</span>
                { (record.status !== 'complete' || !record.answers || record.answers.length === 0) &&
                  <span className="date">{formatDateTimeET(record.lastUpdated)}</span>
                }
                { record.status === 'complete' && record.answers && record.answers.length > 0 &&
                  <Button variant="text" onClick={() => setPopup({ record })}>
                    {formatDateTimeET(record.lastUpdated)}
                  </Button>
                }
              </li>
            );
          })
        }
      </ol>
      { !isEmpty(popup.record) &&
        <StandardSystemPopup 
          className="CourseAssessment-history-popup"
          open={!isEmpty(popup.record)} 
          title="Assessment Results" 
          onClose={closePopup}>
          <CourseAssessmentResults 
            assessment={assessment}
            results={popup.record}
            onRestart={() => {}}
            embedded={true}
          />
        </StandardSystemPopup>
      }
    </div>
  );
}

CourseAssessmentHistory.propTypes = {
  assessment: PropTypes.object,
  records: PropTypes.array
};

export default CourseAssessmentHistory;