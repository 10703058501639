import { SHARE_LINK_REQUEST, SHARE_LINK_COMPLETE, SHARE_LINK_RETRIEVED, SHARE_LINK_ERR,
         SHARE_TOKEN_REQUEST, SHARE_TOKEN_VALIDATED, SHARE_TOKEN_ERR } from './actionTypes';
import * as share from '../api/share';
import errors from '../config/errors';


export function validateShareToken(token) {
  return async dispatch => {
    dispatch({ type: SHARE_TOKEN_REQUEST });
    try {
      let result = await share.validateShareToken(token);
      dispatch({ type: SHARE_TOKEN_VALIDATED, data: result.data });
    } catch(error) {
      if(error.response && error.response.data && 
         error.response.data.code === errors.TokenValidationFailed.code) {
        dispatch({ type: SHARE_TOKEN_ERR, error: errors.TokenValidationFailed });
      } else {
        dispatch({ type: SHARE_TOKEN_ERR, error: errors.RequestFailed });
      }
    }
  };
}

export function shareEventLink(data) {
  return async dispatch => {
    dispatch({ type: SHARE_LINK_REQUEST });
    try {
      let result = await share.shareEventLink(data);
      dispatch({ type: SHARE_LINK_COMPLETE, data: result.data });
    } catch(error) {
      dispatch({ type: SHARE_LINK_ERR, error: errors.RequestFailed });
    }
  };
}

export function getEventShareLink(slug) {
  return async dispatch => {
    dispatch({ type: SHARE_LINK_REQUEST });
    try {
      let result = await share.getEventShareLink(slug);
      dispatch({ type: SHARE_LINK_RETRIEVED, data: result.data });
    } catch(error) {
      dispatch({ type: SHARE_LINK_ERR, error: errors.RequestFailed });
    }
  };
}