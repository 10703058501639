import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useAnalytics from '../../../hooks/useAnalytics';
import * as searchAPI from '../../../actions/search';
import FilterToolbar from './FilterToolbar';
import FilterList from './FilterList';
import EventList from '../events/display/EventList';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import SEOMeta from '../../common/SEOMeta';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Filter({ search, taxonomy, searchAPI }) {
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [results, setResults] = useState(null);
  const [taxonomyResults, setTaxonomyResults] = useState(null);
  const [error, setError] = useState(null);
  const [limitError, setLimitError] = useState(false);
  useAnalytics('Search: Filter');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if(loading && isEmpty(taxonomyResults) && taxonomy.responseStatus === stateConfig.responseStatus.ERROR) {
      setError(taxonomy.responseCode);
      setLoading(false);
    } else if(loading && isEmpty(taxonomyResults) && taxonomy.responseStatus === stateConfig.responseStatus.COMPLETE) {
      setTaxonomyResults(taxonomy.results);
      setLoading(false);
    }
  }, [loading, taxonomyResults, taxonomy, taxonomy.responseStatus]);

  useEffect(() => {
    if(searching && isEmpty(results) && search.responseStatus === stateConfig.responseStatus.ERROR) {
      setError(search.responseCode);
      setSearching(false);
    } else if(searching && isEmpty(results) && search.responseStatus === stateConfig.responseStatus.COMPLETE) {
      setResults(search.results);
      setSearching(false);
    }
  }, [searching, results, search, search.responseStatus]);

  initialize = () => {
    resetViewToTopOfPage();
    if(isNotEmpty(taxonomy.results)) {
      setTaxonomyResults(taxonomy.results);
      setLoading(false);
    } else {
      searchAPI.getTaxonomy();
    }
  };

  const getFilterList = (type) => {
    if(type === 'content-type') return selectedTypes.slice();
    if(type === 'date-range') return selectedDates.slice();
    if(type === 'categories') return selectedCategories.slice();
    if(type === 'tags') return selectedTags.slice();
  };

  const updateFilterList = (type, list) => {
    if(type === 'content-type') setSelectedTypes(list);
    if(type === 'date-range') setSelectedDates(list);
    if(type === 'categories') setSelectedCategories(list);
    if(type === 'tags') setSelectedTags(list);
  };

  const addFilter = filter => () => {
    if(numberOfFilters() === config.filterLimit) {
      setLimitError(true);
      return;
    }

    const { type } = filter;
    let filterList = (type === 'content-type' || type === 'date-range')? [] : getFilterList(type);
    filterList.push(filter);
    updateFilterList(type, filterList);
  };

  const removeFilter = filter => () => {
    const { type, slug } = filter;
    let filterList = getFilterList(type);
    let index = filterList.findIndex(item => item.slug === slug);
    filterList.splice(index, 1);
    updateFilterList(type, filterList);
    setResults(null);
    setLimitError(false);
  };

  const numberOfFilters = () => {
    return selectedTypes.length + selectedDates.length + selectedCategories.length + selectedTags.length;
  };

  const performSearch = () => {
    let query = {};
    enrichQuery(query, selectedTypes, 'type');
    enrichQuery(query, selectedDates, 'range');
    enrichQuery(query, selectedCategories, 'categories');
    enrichQuery(query, selectedTags, 'tags');
    setSearching(true);
    setLimitError(false);
    searchAPI.searchWithFilters(query);
  };

  const enrichQuery = (query, filters, key) => {
    let slugs = filters.map(item => item.slug);
    if(slugs.length) {
      query[key] = slugs.join(',');
    }
  };

  const clearFilters = () => {
    setSelectedTypes([]);
    setSelectedDates([]);
    setSelectedCategories([]);
    setSelectedTags([]);
    setResults(null);
  };

  const clearPopMessage = () => {
    setError(null);
  };

  return (
    <div className="Filter">
      <SEOMeta 
        title="Filter Search" 
        description={`Search over ${config.libraryHoursOfContent} hours of lecture and course content in our library.`} />
      <FilterToolbar className="Filter-toolbar"
        selectedFilters={{ selectedTypes, selectedDates, selectedCategories, selectedTags }} 
        onRemove={removeFilter} onSearch={performSearch} onClear={clearFilters}
        displayingResults={validator.isDefined(results)} />

      { (loading || searching) && <div className="center"><LoadingMessage message="Loading..." /></div> }

      { !loading && isNotEmpty(error) &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>{isNotEmpty(taxonomyResults)? 'We could not perform the requested search.' : 'This page failed to load properly.'}</p>
        </PopMessage>
      }

      { limitError &&
        <div className="limit-error">{`You cannot use more than ${config.filterLimit} filters at once.`}</div>
      }

      { !loading && !searching && isEmpty(error) && isEmpty(results) && isNotEmpty(taxonomyResults) &&
        <FilterList className="Filter-list"
          selectedFilters={{ selectedTypes, selectedDates, selectedCategories, selectedTags }} 
          taxonomy={taxonomyResults} onAdd={addFilter} onRemove={removeFilter} />
      }

      { !loading && isEmpty(error) && isNotEmpty(results) &&
        <div className="EventList-container search">
          <h2>
            <span className="number">{results.length}</span>
            <span className="message">&nbsp;matching {results.length === 1? 'result' : 'results'}</span>
          </h2>
          { validator.isDefined(results) &&
            <Fragment>
              <EventList events={results} displayType={config.eventListTypes.BROWSE}
                cardsPerRender={config.defaultCardsPerRender} />
            </Fragment>
          }
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { 
    search: state.search,
    taxonomy: state.taxonomy
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    searchAPI: bindActionCreators(searchAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Filter);