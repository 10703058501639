import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as behaviorAPI from '../../../../actions/behavior';
import Icon, { IconTypes } from '../../../common/Icon';
import config from '../../../../config/config';
import urls from '../../../../config/urls';
import validator from '../../../../utils/validator';


function EventCard({ displayFavorite, displayProgress, favorites, watched, eventData, user, behaviorAPI }) {
  const [isFavorite, setIsFavorite] = useState(null);
  const [percentWatched, setPercentWatched] = useState(0);
  const navigate = useNavigate();
  // const rating = null; // not currently being used
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let checkIsFavorite = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const favorite = checkIsFavorite();
    if(favorite !== isFavorite) {
      setIsFavorite(favorite);
    }
  }, [favorites, eventData, isFavorite, checkIsFavorite]);

  const onClick = () => {
    const { slug, type, status, linkedList, course } = eventData;
    
    /* For event card(s) asw Magdala Apostolate Semesters, user click should navigate to Magdala Courses page, vs a dedicated page as in other use cases (i.e. lent/advent related course lists, etc.) Desired tag for Magdala Semester Course List is 'Magdala Apostolate Course List' tag, correlating to 'magdala-apostolate-course-list' slug. All magdala-related tags/slugs included here to accomodate deviation from desired behavior */
    const magdalaTagsRegex = new RegExp('magdala-apostolate', 'g'); 
    if(linkedList !== null) {
      if(validator.matchesPattern(linkedList.slug, magdalaTagsRegex)) {
        navigate(urls.magdalaCoursesPage);
      } else {
        navigate(urls.eventsLinkedList.replace(':slug', linkedList.slug));
      }
    } else if(status !== 'Upcoming' && (type === 'Live Course' || type === 'Self-Paced Course')) {
      if(course && course.releaseDate) {
        navigate(`/events/${slug}`);
      } else {
        navigate(`/courses/${slug}`);
      }
    } else {
      navigate(`/events/${slug}`);
    }
  }

  initialize = () => {
    setIsFavorite(checkIsFavorite());
    const log = watched.find(log => log.eventId === eventData.id);
    if(log && log.progress < 100) {
      return setPercentWatched(log.progress);
    }
  };

  checkIsFavorite = () => {
    const index = favorites.findIndex(item => item === eventData.id);
    return index !== -1;
  };

  const onClickFavorite = (event) => {
    event.stopPropagation();
    if(isFavorite) {
      behaviorAPI.removeFavorite(eventData.id);
      setIsFavorite(false);
    } else {
      behaviorAPI.addFavorite(eventData.id);
      setIsFavorite(true);
    }
  }

  const determineTypeDisplay = () => {
    const { type } = eventData;
    switch(type) {
      case 'Course List':
        return 'Courses';
      case 'Lecture List':
        return 'Lectures';
      default:
        return type;
    }
  }
  
  const { thumbnail: eventThumb, title, subtitle, status, date, instructor, linkedList } = eventData;
  const thumbnail = eventThumb || config.eventDefaultFeatureImage;
  const isLinkedListCard = linkedList !== null;

  return (
    <div className="EventCard" onClick={onClick}>
      <div className="feature" style={{backgroundImage: `url(${thumbnail})`}}>
        <div className="overlay"></div>
        <div className="actions">
          { displayFavorite && !validator.isEmpty(user.email) &&
            <Fragment>
              { isFavorite? 
                <div className="favorite is-favorite" onClick={onClickFavorite}><Icon type={IconTypes.FavoriteFilled} /></div> :
                <div className="favorite not-favorite" onClick={onClickFavorite}><Icon type={IconTypes.Favorite} /></div> }
            </Fragment>
          }
        </div>
        <div className="progress-bar">
          { displayProgress && percentWatched !== null && !(percentWatched === 0) &&
            <Fragment>
              <div className="background"></div>
              <div className="progress" style={{width: `${percentWatched}%`}}></div>
            </Fragment>
          }
        </div>
        <div className="info-bar">
          { //Not currently being used
          /*displayRating && rating !== null && !(rating === 0) && 
            <div className="rating"><Icon type={IconTypes.RatingFilled} /> {rating}</div>
          */
          }
          <p className="type">{determineTypeDisplay()}</p>
        </div>
      </div>
      <div className="description">
        <p className="title">{title}</p>
        <p className="subtitle">{subtitle}</p>
        <p className="instructor">{isLinkedListCard? linkedList.description : instructor.name}</p>
        <div className="meta-tags">
          { status === 'Running Now' &&
            <div className="highlight"><Icon type={IconTypes.EventRunning} inline={true} /> {status}</div> 
          }
          { status === 'Upcoming' && !isLinkedListCard &&
            <div><Icon type={IconTypes.Events} inline={true} /> &nbsp;{date}</div> 
          }
        </div>
      </div>
    </div>
  );
}

EventCard.defaultProps = {
  displayFavorite: false,
  displayRating: false,
  displayProgress: false
};

EventCard.propTypes = {
  eventData: PropTypes.object
};

function mapStateToProps(state) {
  const { favorites, watched } = state.behavior;
  return { favorites, watched, user: state.user };
}

function mapDispatchToProps(dispatch) {
  return { 
    behaviorAPI: bindActionCreators(behaviorAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventCard);