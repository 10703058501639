import * as issues from '../api/issues';

export function logIssue(location, data) {
  return async dispatch => {
    try {
      await issues.logIssue(location, data);
    } catch(error) {
      // fail silently
    }
  };
}