import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Input from '@mui/material/Input';
import validator from '../../../../../utils/validator';
const { isEmpty } = validator;


function ClozeQuestion({ question, index, scored, disabled, onChange }) {
  const [placeholders, setPlaceholders] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const items = question.answers.map(answer => answer.placeholder);
    const defaults = !isEmpty(scored)? scored.value : question.answers.map(() => '');
    setPlaceholders(items);
    setFields(defaults);
  }, [scored, question.answers]);

  useEffect(() => {
    let data = scored || { index, wpId: question.wpId, title: question.title, value: null, result: 'incorrect' };
    const filled = fields.filter(field => !isEmpty(field));
    if(filled.length > 0) {
      data.value = fields;
      data.compare = data.value.reduce((compare, value) => ''+compare+value);
      const correct = fields.filter((field, index) => {
        return question.answers[index].options.find(option => {
          return option.toLowerCase().trim() === field.toLowerCase().trim();
        });
      });
      data.result = correct.length === fields.length? 'correct' : 'incorrect';
    }
    onChange(data);
  }, [index, scored, fields, question, onChange]);

  const handleFieldChange = index => event => {
    const values = fields.slice();
    values[index] = event.target.value;
    setFields(values);
  };

  const renderQuestionText = text => {
    let pos = 0, index = 0;
    return (
      <Fragment>
        {
          placeholders.map(placeholder => {
            const i1 = index, i2 = index+1;
            const pos1 = pos; 
            const pos2 = text.indexOf(placeholder);
            const pos3 = i2 === placeholders.length? text.length : text.indexOf(placeholders[i2]);
            pos = pos3;
            index++;
            return (
              <Fragment key={`placeholder${i1}`}>
                <span dangerouslySetInnerHTML={{__html: text.slice(pos1, pos2)}} />
                <Input 
                  name={`placeholder-${i1}`} 
                  onChange={handleFieldChange(i1)}
                  value={fields[i1]} 
                  disabled={disabled}
                />
                <span dangerouslySetInnerHTML={{__html: text.slice(pos2+placeholder.length, pos3)}} />
              </Fragment>
            );
          })
        }
      </Fragment>
    );
  };

  return (
    <div className="ClozeQuestion">
      <div className="question-text">
        {`${index+1}. `}
        {renderQuestionText(question.text)}
      </div>
      { disabled && // means we are in display only results view
        <div className={`scored-result ${scored.result}`}>
          {scored.result === 'correct'? 'Correct!' : 'Incorrect.'}
        </div>
      }
    </div>
  );
}

ClozeQuestion.defaultProps = {
  scored: null,
  disabled: false
};

ClozeQuestion.propTypes = {
  question: PropTypes.object,
  index: PropTypes.number,
  onChange: PropTypes.func
};

export default ClozeQuestion;
