import React, { Fragment, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'qs';
import * as appDisplayAPI from '../../actions/appDisplay';
import * as authAPI from '../../actions/authentication';
import useAnalytics from '../../hooks/useAnalytics';
import useEventTracking from '../../hooks/useEventTracking';
import LoginCredentialsForm from './LoginCredentialsForm';
import LoginPasswordlessForm from './LoginPasswordlessForm';
import LoginProviders from './providers/LoginProviders';
import Icon, { IconTypes } from '../common/Icon';
import LinkButton from '../common/LinkButton';
import Heading from '../common/Heading';
import LoadingMessage from '../common/messages/LoadingMessage';
import ContactErrorPopMessage from '../common/messages/ContactErrorPopMessage';
import config from '../../config/config';
import errors from '../../config/errors';
import stateConfig from '../../config/state';
import urls from '../../config/urls';
import analyticsEvents from '../../config/analytics/events';
import { resetViewToTopOfPage } from '../../utils/utils';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


function Login(props) {
  const [initStarted, setInitStarted] = useState(false);
  const [initComplete, setInitComplete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [deviceLogin, setDeviceLogin] = useState(true);
  const [submission, setSubmission] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentEmail, setCurrentEmail] = useState(props.defaultEmail || '');
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [reAuthentication, setReAuthentication] = useState(null);
  const [passwordless, setPasswordless] = useState(false);
  const [popMessage, setPopMessage] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  useAnalytics('Login');
  const trackEvent = useEventTracking([ 
    config.trackingLoggers.googleEvents, 
    config.trackingLoggers.hubEvents 
  ]);
  const { authenticated, defaultEmail, authAPI, appDisplayAPI } = props;
  const { responseStatus: authResponseStatus, responseCode: authResponseCode } = props.authentication;
  let trackLogin = config.emptyFn;

  // INIT
  useEffect(() => {
    if(!initStarted) {
      setInitStarted(true);
      resetViewToTopOfPage();
      
      const params = querystring.parse(location.search.substring(1));

      if(params.code) {
        // this is the second half of a passwordless login
        setDeviceLogin(false);
        setPasswordless(true);
        authAPI.loginWithPasscode({ code: params.code });
      } else {
        // set context params for later in the auth sequence
        let contextParams = {};
        if(params.referrer) {
          contextParams.referrer = params.referrer;
        }
        if(params.permalink) {
          contextParams.permalink = params.permalink;
        }
        if(params.ra) {
          const cpConfig = config.reAuthentication[params.ra] || {};
          contextParams.reAuthentication = {
            message: cpConfig.message,
            key: params.ra,
            slug: params.rs
          };
          setReAuthentication(contextParams.reAuthentication);
        }
        
        authAPI.publishContextParams(contextParams);

        const pwParam = params.pc === 'true'? true : false;
        setPasswordChanged(pwParam);
        
        // check if already authenticated
        authAPI.getAuthStatus();
      }

      setInitComplete(true);
    }
  }, [initStarted, location.search, authAPI]);

  useEffect(() => {
    if(passwordChanged) {
      appDisplayAPI.displayPageMessage({
        message: 'Voilà! Your password has been changed. You will need to log back in with your new password.',
        type: stateConfig.messageTypes.SUCCESS
      });
    }
  }, [passwordChanged, appDisplayAPI]);

  useEffect(() => {
    if(initComplete && authenticated && authResponseStatus === stateConfig.authStatus.AUTH_COMPLETE) {
      if(deviceLogin) {
        trackLogin('device');
        // successfully logged in w/device cookie or device already logged in, don't show remember device prompt
        setTimeout(() => { // slight delay to ensure state is fully set
          navigate(urls.loginRedirect);
        }, 250);
      } else {
        trackLogin(passwordless? 'passwordless' : 'password');
        // go to remember device prompt
        navigate(urls.rememberDevice);
      }
    }

    if(initComplete && authResponseStatus === stateConfig.authStatus.NOT_AUTHENTICATED) {
      // attempt to login with remembered device
      authAPI.loginWithDevice();
    }
    
    if(initComplete && authResponseStatus === stateConfig.authStatus.ERROR) {
      // handle auth failures
      setSubmission(false);
      setLoading(false);

      if(authResponseCode === errors.MemberNotVerified.code) {
        authAPI.publishContextParams({ email: currentEmail });
        navigate(urls.loginActivationPrompt);
      } if(authResponseCode === errors.DeviceAuthenticationError.code) {
        // all auto login methods failed, login form will be displayed
        setDeviceLogin(false);
        if(reAuthentication) { // if re-auth message was passed in then display it
          appDisplayAPI.displayPageMessage({ message: reAuthentication.message });
        }
      } else if(authResponseCode === errors.CredentialFailure.code) {
        setPopMessage('Either the email or password that you entered is incorrect.');
      } else if(authResponseCode === errors.AuthenticationError.code) {
        setPopMessage('A failure occurred during the login process. If this problem persists please reach out to a staff member.');
      } else if(authResponseCode === errors.PasswordlessFailure.code) {
        setPopMessage('Your authentication link could not be verified and may have expired.');
      }
    }
  }, [initComplete, authenticated, passwordless, authResponseStatus, authResponseCode, reAuthentication, deviceLogin, currentEmail, authAPI, appDisplayAPI, navigate, trackLogin]);


  const handleTabChange = (event, index) => {
    setCurrentTab(index);
  }

  const handleLogin = (data) => {
    setSubmission(true);
    setCurrentEmail(data.email);
    authAPI.login(data);
  }

  const clearPopMessage = () => {
    setPopMessage(null);
  }

  trackLogin = method => {
    trackEvent(analyticsEvents.loginComplete(method));
  };


  return (
    <div id="Login-container">
      <Paper id="Login">
        <Icon avatar={true} type={IconTypes.Lock} />
        <Heading>Member Login</Heading>
        
        { loading && <LoadingMessage message="Loading..." /> }

        { popMessage !== null &&
          <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
            <p>{popMessage}</p>
            </ContactErrorPopMessage>
        }

        { !loading &&
          <Fragment>
            <div className="Login-providers">
              <LoginProviders />
            </div>
            <Tabs className="Login-tabs" textColor="primary" indicatorColor="primary" centered
              value={currentTab} onChange={handleTabChange}>
              <Tab label="Email+Password" />
              <Tab label="Email Only" />
            </Tabs>
            { currentTab === 0 && 
              <LoginCredentialsForm onSubmit={handleLogin} submission={submission} 
                defaultEmail={defaultEmail} /> 
            }
            { currentTab === 1 &&
              <LoginPasswordlessForm defaultEmail={defaultEmail} reportEmail={email => setCurrentEmail(email)} />
            }
          </Fragment>
        }
      </Paper>
      { !loading &&
        <div id="Login-passwordReset">
          <LinkButton label="Forgot your password?" to={urls.pwdResetRequest} 
            color="secondary" icon={IconTypes.Help} />
          <LinkButton label="Not a member? Sign up now" to={urls.memberRegistration} 
            className="sign-up-button"
            color="default" icon={IconTypes.Help} />
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    authenticated: state.user.authenticated,
    defaultEmail: state.user.email || '',
    authentication: state.authentication
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    appDisplayAPI: bindActionCreators(appDisplayAPI, dispatch),
    authAPI: bindActionCreators(authAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);