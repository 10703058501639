import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import querystring from 'qs';
import FormHelperText from '@mui/material/FormHelperText';
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import useEventTracking from '../../../hooks/useEventTracking';
import * as donationsAPI from '../../../actions/donations';
import * as profileAPI from '../../../actions/profile';
import DonateIdentification from './fields/DonateIdentification';
import DonateContact from './fields/DonateContact';
import DonateAmount from './fields/DonateAmount';
import { addressFromProfile } from '../profile/personal/ProfileAddress';
import { phoneFromProfile } from '../profile/personal/ProfilePhone';
import Form from '../../common/form/Form';
import ContactErrorPopMessage from '../../common/messages/ContactErrorPopMessage';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import analyticsEvents from '../../../config/analytics/events';
import validator from '../../../utils/validator';


const steps = {
  COLLECTION: 'collection',
  INTENT_REQUEST: 'intent-request',
  STRIPE_REQUEST: 'stripe-request',
  SUBSCRIPTION_REQUEST: 'subscription-request',
  STORE_REQUEST: 'store-request'
};

const cardElementStyle = {
  style: {
    base: {
      color: "#3C3C3C",
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#545454"
      }
    },
    invalid: {
      color: "#D32F2F",
      iconColor: "#FFA000"
    }
  }
};

const fieldsInit = {
  email: '',
  firstName: '',
  lastName: '',
  country: 'United States',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  state: 'AL',
  postalCode: '',
  phone: '',
  amount: '',
  frequency: 'one-time',
  method: 'cc',
  campaign: null
};

function DonateForm({ onComplete, submitLabel, campaignCode, addressRequired, donations, user, profile, donationsAPI, profileAPI }) {
  const [userLoaded, setUserLoaded] = useState(false);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [submission, setSubmission] = useState(false);
  const [step, setStep] = useState(steps.COLLECTION);
  const [fields, setFields] = useState(fieldsInit);
  const [errors, setErrors] = useState({});
  const [cardEntered, setCardEntered] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [transactionError, setTransactionError] = useState(null);
  const [recurringPMId, setRecurringPMId] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const trackEvent = useEventTracking([ 
    config.trackingLoggers.googleEvents, 
    config.trackingLoggers.hubEvents 
  ]);
  let popTransactionError = null;
  // defining fns used by useEffect blocks
  let initialize = config.emptyFn;
  let trackLoadEvent = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);
  
  useEffect(() => {
    if(!user.userDataChecked || userLoaded) return;
    
    const params = querystring.parse(window.location.search.substring(1));
    let fieldsObj = Object.assign({}, fieldsInit, {
      email: user.email || '',
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      frequency: params.frequency || 'one-time',
      amount: params.amount || '',
      campaign: params.campaign || campaignCode
    });

    setUserLoaded(true);
    setFields(fieldsObj);
    trackLoadEvent(fieldsObj);
  }, [user, user.email, userLoaded, campaignCode, trackLoadEvent]);

  useEffect(() => {
    if(userLoaded && !profileLoaded && profile.data) {
      const { country, addressLine1, addressLine2, addressLine3, city, state, postalCode } = addressFromProfile(profile.data);
      const { number } = phoneFromProfile(profile.data);
      let fieldsObj = Object.assign({}, fields, {
        country, addressLine1, addressLine2, addressLine3, city, state: state || fieldsInit.state, postalCode, phone: number
      });
      setProfileLoaded(true);
      setFields(fieldsObj);
    }
  }, [profile.data, userLoaded, profileLoaded, fields]);

  useEffect(() => {
    // handle server response for payment intent setup
    const { responseStatus, transaction } = donations;
    if(responseStatus === stateConfig.responseStatus.COMPLETE && 
       step === steps.INTENT_REQUEST && 
       transaction && transaction.clientSecret) {
      // first half of OT transaction complete, now submit to Stripe
      const { email, firstName, lastName, amount, campaign } = fields;
      const { customerId, donationId } = transaction;
      setStep(steps.STRIPE_REQUEST);
      (async () => {
        const payload = await stripe.confirmCardPayment(transaction.clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement)
          }
        });
       
        if(!payload.error) {
          // Transaction completed successfully, store in Hub and notify user
          setStep(steps.STORE_REQUEST);
          const paymentMethodId = payload.paymentIntent.payment_method;
          const completedTransaction = { email: email.toLowerCase(), firstName, lastName, amount, customerId, donationId, paymentMethodId, metadata: { campaign } };
          donationsAPI.storeDonationTransaction(completedTransaction);
          trackEvent(analyticsEvents.donationCompleted(donationId, fields));
          onComplete();
        } else {
          popTransactionError(`The donation failed to process: ${payload.error.message}`);
        }
      })();
    } else if(responseStatus === stateConfig.responseStatus.ERROR && step === steps.INTENT_REQUEST) {
      popTransactionError('The donation failed to process.');
    }
  }, [donations, fields, step, donationsAPI, onComplete, popTransactionError, stripe, elements, trackEvent]);

  useEffect(() => {
    const { responseStatus } = donations;
    // handle server response for recurring donation setup
    if(responseStatus === stateConfig.responseStatus.COMPLETE && step === steps.SUBSCRIPTION_REQUEST) {
      trackEvent(analyticsEvents.donationCompleted(recurringPMId || '', fields));
      onComplete();
    } else if(responseStatus === stateConfig.responseStatus.ERROR && step === steps.SUBSCRIPTION_REQUEST) {
      popTransactionError('The donation failed to process.');
    }
  }, [donations, step, fields, recurringPMId, onComplete, popTransactionError, trackEvent]);

  initialize = () => {
    if(!profile.data) {
      profileAPI.getProfile();
    }

    // make sure any previous donation transactions in session are cleared from state
    donationsAPI.resetDonationTransactionState();
  };

  trackLoadEvent = (eventData) => {
    trackEvent(analyticsEvents.donationForm(eventData));
  };

  const handleFieldChange = (event) => {
    let { name, value } = event.target;
    let fieldObj = { ...fields };
    fieldObj[name] = value;
    setFields(fieldObj);
  }

  const handleAmountBlur = (event) => {
    if(fields.amount.includes('.')) {
      let fieldObj = { ...fields };
      fieldObj.amount = `${fields.amount.split('.')[0]}.00`;
      setFields(fieldObj);
    }
  };

  const handleCardChange = (event) => {
    const err = event.error? event.error.message : null;
    setCardEntered(!event.empty);
    const message = err && err.includes('postal code') && err.includes('incomplete')? 
      'You must also provide the postal code associated with your card in the credit card field' : err;
    setCardError(message);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if(!validate()) {
      return;
    }
   
    // format transaction data
    const { 
      email, firstName, lastName, country, addressLine1, addressLine2, addressLine3, 
      city, state, postalCode, phone, amount, frequency, campaign 
    } = fields;

    let data = {
      email: email.toLowerCase(), firstName, lastName, amount: parseInt(amount), addressLine1,
      metadata: { firstName, lastName, campaign, phone }
    };

    if(country === 'United States') {
      Object.assign(data.metadata, { country, addressLine1, addressLine2, city, state, postalCode });
    } else {
      Object.assign(data.metadata, { country, addressLine1, addressLine2, addressLine3 });
    }

    // perform transaction
    setSubmission(true);
    if(frequency === 'one-time') {
      setStep(steps.INTENT_REQUEST);
      donationsAPI.startDonationTransaction(data);
    } else {
      setStep(steps.SUBSCRIPTION_REQUEST);
      submitRecurring(data);
    }
  }

  const submitRecurring = async (data) => {
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement)
    });

    if(!payload.error) {
      if(payload.paymentMethod) {
        // Now complete subscription on Hub
        const { last4, exp_month, exp_year } = payload.paymentMethod.card;
        data.paymentMethodId = payload.paymentMethod.id;
        data.card = { last4, expires: { month: exp_month, year: exp_year }};
        donationsAPI.performRecurringDonation(data);
        setRecurringPMId(data.paymentMethodId); // for use later w/tracking
      } else {
        popTransactionError('The donation failed to process: network request error');
      }
    } else {
      popTransactionError(`The donation failed to process: ${payload.error.message}`);
    }
  }

  const validate = () => {
    let rules = [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'email' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'firstName', against: validator.patterns.NAME, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'firstName' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'lastName', against: validator.patterns.NAME, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'lastName' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'phone', against: validator.patterns.PHONE, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'country' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'addressLine1', against: validator.patterns.ADDRESS_LINE, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'addressLine2', against: validator.patterns.ADDRESS_LINE, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'city', against: validator.patterns.CITY, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'state', against: validator.patterns.STATE, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'postalCode', against: validator.patterns.ZIP_CODE, empty: true },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'amount', against: validator.patterns.CURRENCY, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'amount' }
    ];
 
    if(fields.country === 'United States' && addressRequired) {
      rules = rules.concat([
        { rule: validator.rules.IS_DEFINED, prop: 'addressLine1' },
        { rule: validator.rules.IS_DEFINED, prop: 'city' },
        { rule: validator.rules.IS_DEFINED, prop: 'state' },
        { rule: validator.rules.IS_DEFINED, prop: 'postalCode' }
      ]);
    } else if(fields.country !== 'United States' && addressRequired) {
      rules = rules.concat([
        { rule: validator.rules.IS_DEFINED, prop: 'addressLine1' },
        { rule: validator.rules.IS_DEFINED, prop: 'addressLine2' },
        { rule: validator.rules.MATCHES_PATTERN, prop: 'addressLine3', against: validator.patterns.ADDRESS_LINE, empty: true }
      ]);
    }

    let validationErrors = validator.validate(fields, rules);
    if(validationErrors) {
      setErrors(validationErrors);
    } else if(parseInt(fields.amount) === 0) {
      validationErrors = { amount: 'The amount cannot be zero.' };
      setErrors(validationErrors);
    }

    if(!cardEntered) {
      setCardError('Credit card information is required.')
    }

    return validationErrors? false : true;
  }

  popTransactionError = useCallback((message) => {
    setTransactionError(message);
    setSubmission(false);
    setStep(steps.COLLECTION);
    donationsAPI.resetDonationTransactionState();
  }, [donationsAPI]);

  const clearPopMessage = () => {
    setTransactionError(null);
  }

  const cardElementOptions = { ...cardElementStyle, value: { postalCode: fields.postalCode }};

  return (
    <Fragment>
      { validator.isNotEmpty(transactionError) && 
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>{transactionError}</p>
        </ContactErrorPopMessage>
      }
      <Form onSubmit={onSubmit} submission={submission} autoComplete="off" submitLabel={submitLabel}>
        <DonateIdentification fields={fields} errors={errors} onFieldChange={handleFieldChange} />
        { addressRequired &&
          <DonateContact fields={fields} errors={errors} onFieldChange={handleFieldChange} />
        }
        <DonateAmount fields={fields} errors={errors} onFieldChange={handleFieldChange} onAmountBlur={handleAmountBlur} />
        <div className="card">
          <CardElement id="card-element" options={cardElementOptions} onChange={handleCardChange} />
          { validator.isNotEmpty(cardError) &&
              <div className="card-element-error">
              <FormHelperText error={true}>{cardError}</FormHelperText>
            </div>
          }
        </div>
      </Form>
    </Fragment>
  );
}

DonateForm.defaultProps = {
  submitLabel: 'Complete Donation',
  campaignCode: null,
  addressRequired: true
};

DonateForm.propTypes = {
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    donations: state.donations, 
    user: state.user,
    profile: state.profile
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    donationsAPI: bindActionCreators(donationsAPI, dispatch),
    profileAPI: bindActionCreators(profileAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DonateForm);