import React from 'react';
import Paper from '@mui/material/Paper';

export default function FormWrapper(props) {
  return (
    <Paper className={`FormWrapper ${props.className}`} 
      square={props.squared} elevation={props.raised? 10 : 2}>
      {props.children}
    </Paper>
  );
}

FormWrapper.defaultProps = {
  className: '',
  squared: false,
  raised: false
};