import urls from './urls';

const config = {
  libraryHoursOfContent: 1000,
  defaultCardsPerRender: 10,
  completedWatchingPercentage: 95,
  courseCompletedWatchingPercentage: 90,
  watchLogInterval: 180, // 3 minutes
  courseWatchLogInterval: 60, // 1 minute
  filterLimit: 5,
  wpTimeout: 30000,
  emptyFn: () => {},

  deviceBreakpoints: {
    extraLarge: 1300,
    large: 1200,
    desktop: 1024,
    medium: 960,
    tablet: 768,
    smallTablet: 700,
    small: 600,
    extraSmall: 450,
    phone: 420,
    smallPhone: 350
  },

  mobileDeviceClasses: ['small', 'phone', 'smallPhone'],

  memberTypes: {
    member: 'member',
    magdala: 'magdala-member'
  },

  contentTypes: [
    'Self-Paced Course',
    'Lecture Series',
    'Lecture'
  ],

  donationMethods: {
    ach: 'ACH',
    cc: 'Credit Card',
    paypal: 'PayPal'
  },

  courseNotStarted: ['pending', 'registered'],
  courseNotComplete: ['pending', 'registered', 'started'],
  courseComplete: ['complete', 'archived', 'archived-recorded'],
  courseArchived: ['archived', 'archived-recorded'],
  courseRegistered: 'registered',
  courseRecordOnly: 'archived-recorded',

  dateRangeMap: [
    { name: 'Within the Last 3 Months', slug: '3-months' },
    { name: 'Within the Last 6 Months', slug: '6-months' },
    { name: 'Within the Last Year', slug: '12-months' }
  ],

  monthNames: [
    "January", "February", "March", "April", "May", "June", "July", 
    "August", "September", "October", "November", "December"
  ],

  eventDefaultFeatureImage: 'https://instituteofcatholicculture.net/wp-content/uploads/2019/10/icc-default-logo-large.jpg',
  eventListTypes: {
    CURRENT_AND_UPCOMING: 'CURRENT_AND_UPCOMING',
    BROWSE: 'LIBRARY'
  },

  profileDataTypes: {
    NAME: 'NAME',
    LEGAL_NAME: 'LEGAL_NAME',
    ADDRESS: 'ADDRESS',
    PHONE: 'PHONE'
  },

  reAuthentication: {
    'deletion': {
      url: urls.accountDeletion,
      message: 'Please provide your login credentials to proceed with account deletion.'
    },
    'donations': {
      url: urls.myDonations,
      message: 'Please provide your login credentials to access your donations.'
    },
    'email-preferences': {
      url: urls.profileEmailNotifications,
      message: 'Please provide your login credentials to access your email preferences.'
    },
    'course': {
      url: slug => urls.course.replace(':slug', slug),
      message: 'Please provide your login credentials to access course content.'
    },
    'profile': {
      url: urls.profile,
      message: 'Please provide your login credentials to access your profile.'
    },
    'profile-password': {
      url: urls.profileChangePassword,
      message: 'Please provide your login credentials to access your profile.'
    },
    'transcript': {
      url: urls.myTranscript,
      message: 'Please provide your login credentials to access your transcript.'
    },
  },

  localStorage: {
    authContextParams: 'auth.contextParams',
    registrationContextParams: 'registration.contextParams',
    eventTrialAccess: 'event.trialAccess',
    privacyPolicyPrompt: 'privacy.policy',
    trackingSessionId: 'tracking.sessionId',
    registrationMethod: 'registration.method',
    testModes: 'testModes'
  },

  trackingCategories: {
    view: 'view', 
    click: 'click', 
    form: 'form', 
    watch: 'watch', 
    listen: 'listen', 
    search: 'search',
    conversion: 'conversion',
    identification: 'identification'
  },

  trackingSource: {
    googleAds: 'icc-gad'
  },

  trackingLoggers: {
    googleEvents: 'ga',
    hubEvents: 'hub'
  },

  googleAdIDParam: 'icc-gaid',
  googleAdTrackingType: 'google-ad',

  testModes: {
    googleAnalyticsEvents: 'GAE',
    hubBehaviorTracking: 'HBT'
  },

  validReferrers: {
    'instituteofcatholicculture.education': {
      'student-profile': urls.myTranscript,
      'default': slug => urls.course.replace(':slug', slug)
    }
  },

  pdf: {
    defaultTextColor: '#102B4E',
    defaultFont: 'Helvetica',
    landscape: [11, 8.5],
    certificatePage1: {
      dateLocation: [2.35, 6.4],
      nameLocationFn: (nameWidth) => [5.5-(nameWidth/2), 3.55]
    }
  }
};

export default config;