import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import * as shareAPI from '../../../../actions/share';
import Form from '../../../common/form/Form';
import ContactErrorPopMessage from '../../../common/messages/ContactErrorPopMessage';
import validator from '../../../../utils/validator';
import stateConfig from '../../../../config/state';


class ShareForm extends React.Component {
  constructor(props) {
    super(props);

    const { user } = props;
    this.state = {
      fields: {
        email: '',
        firstName: user.firstName || '',
        lastName: user.lastName || ''
      },
      errors: {},
      shareError: null
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onSubmitEmail = this.onSubmitEmail.bind(this);
    this.clearPopMessage = this.clearPopMessage.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { responseStatus, responseCode } = this.props.share;
    const { responseStatus: prevResponseStatus } = prevProps.share;

    if(responseStatus !== prevResponseStatus) {
      if(responseStatus === stateConfig.responseStatus.ERROR) {
        this.setState({ submission: false, shareError: responseCode });
      }
      
      if(responseStatus === stateConfig.responseStatus.COMPLETE) {
        this.props.onComplete();
      }
    }
  }

  handleFieldChange(event) {
    let { name, value } = event.target;
    let fields = { ...this.state.fields };
    fields[name] = value;
    this.setState({ fields });
  }

  onSubmitEmail(event) {
    event.preventDefault();
    if(!this.validate()) {
      return;
    }

    const { fields } = this.state;
    const { user, eventData } = this.props;
    const { title, subtitle, slug } = eventData;
    const { firstName, lastName, email } = fields;

    const eventTitle = `${title}${subtitle? ': '+subtitle : ''}`;
    const data = {
      submission: false,
      title: eventTitle,
      email: user.email,
      firstName,
      lastName,
      recipientEmail: email,
      eventSlug: slug
    };

    this.props.shareAPI.shareEventLink(data);
    this.setState({ submission: true, errors: {} });
  }

  validate() {
    const data = this.state.fields;
    const validationErrors = validator.validate(data, [
      { rule: validator.rules.IS_DEFINED, prop: 'firstName' },
      { rule: validator.rules.IS_DEFINED, prop: 'lastName' },
      { rule: validator.rules.MATCHES_PATTERN, prop: 'email', against: validator.patterns.EMAIL, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'email' }
    ]);
    
    if(validationErrors) {
      this.setState({ errors: validationErrors });
      return false
    }

    return true;
  }

  clearPopMessage() {
    this.setState({ shareError: null });
  }

  render() {
    const { submission, fields, errors, shareError } = this.state;

    return (
      <Form onSubmit={this.onSubmitEmail} autoComplete="off" submitLabel="Share Event" submission={submission}>
        <div className="name-fields">
          <TextField 
            className="FormField-control first"
            variant="outlined" 
            name="firstName" 
            label="Your First Name" 
            value={fields.firstName}
            placeholder="First Name" 
            onChange={this.handleFieldChange}
            error={validator.isDefined(errors['firstName'])} 
            helperText={validator.message(errors['firstName'], 'first name')}
          />
          <TextField 
            className="FormField-control first"
            variant="outlined" 
            name="lastName" 
            label="Your Last Name" 
            value={fields.lastName}
            placeholder="Last Name" 
            onChange={this.handleFieldChange}
            error={validator.isDefined(errors['lastName'])} 
            helperText={validator.message(errors['lastName'], 'last name')}
          />
        </div>

        <TextField 
          className="FormField-control full"
          variant="outlined" 
          name="email" 
          label="Your friend/family member's email" 
          value={fields.email}
          placeholder="Email address" 
          onChange={this.handleFieldChange}
          error={validator.isDefined(errors['email'])} 
          helperText={validator.message(errors['email'], 'email')}
        />

        { shareError && 
          <ContactErrorPopMessage horizontal="center" open={true} onClose={this.clearPopMessage}>
            <p>We were unable to share this event link.</p>
          </ContactErrorPopMessage>
        }
      </Form>
    );
  }
}

ShareForm.propTypes = {
  eventData: PropTypes.object,
  onComplete: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    user: state.user, 
    share: state.share 
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    shareAPI: bindActionCreators(shareAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareForm);