import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithRedirect, getRedirectResult } from "firebase/auth";
import { AppContext } from '../../../App';
import * as authAPI from '../../../actions/authentication';
import LoadingMessage from '../../common/messages/LoadingMessage';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function ProviderAuthRedirect({ providerId, authentication, authAPI, onComplete, onError }) {
  const { responseStatus: authResponseStatus } = authentication;
  const { firebaseApp } = useContext(AppContext);
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  initialize = () => {
    const auth = getAuth(firebaseApp);

    if(authResponseStatus === stateConfig.authStatus.PROVIDER_START) {
      const provider = providerId === 'google'? new GoogleAuthProvider() : new FacebookAuthProvider();
      signInWithRedirect(auth, provider);
    } else {
      getRedirectResult(auth).then(async function(result) {
        const idToken = await auth.currentUser.getIdToken();
        if(result && result.user && idToken) {
          let email = result.user.email;
          if(isEmpty(email) && result.user.providerData && isNotEmpty(result.user.providerData[0])) {
            email = result.user.providerData[0].email;
          }
          onComplete({ 
            provider: providerId, 
            email,
            name: result.user.displayName,
            credentials: { idToken, uid: result.user.uid }
          });
        } else {
          onError({ code: 'PA100', message: 'Malformed provider response' });
        }
      }).catch(function(error) {
        onError(error);
      });
    }
  };

  return (
    <div className={`LoginProvider ${providerId}`}>
      <LoadingMessage message="Loading..." />
    </div>
  );
}

ProviderAuthRedirect.propTypes = {
  providerId: PropTypes.string,
  onComplete: PropTypes.func,
  onError: PropTypes.func
};

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    authAPI: bindActionCreators(authAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderAuthRedirect);