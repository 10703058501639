import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useAnalytics from '../../../hooks/useAnalytics';
import * as postAPI from '../../../actions/content/posts';
import * as appDisplay from '../../../actions/appDisplay';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import { resetViewToTopOfPage } from '../../../utils/utils';
import stateConfig from '../../../config/state';
import config from '../../../config/config';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SEOMeta from '../../common/SEOMeta';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Pilgrimage({ posts, postAPI, appDisplay }) {
  const [loading, setLoading] = useState(true);
  const [pilgrimage, setPilgrimage] = useState(null);
  const [error, setError] = useState(null);
  const stateId = `${stateConfig.keys.PAGE}.pilgrimage-to-the-holy-land`;
  useAnalytics('Pilgrimmage');
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let displayPageBanner = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const data = posts.byId[stateId];
    if(loading && isEmpty(pilgrimage) && isNotEmpty(data) && posts.ids) {
      if(data.error) {
        setError(data.error);
      } else {
        setPilgrimage(data);
      }
      setLoading(false);
    }
  }, [loading, pilgrimage, stateId, posts, posts.ids]);

  initialize = () => {
    resetViewToTopOfPage();
    const data = posts.byId[stateId];
    if(isNotEmpty(data) && !data.error) {
      setPilgrimage(data);
      setLoading(false);
    } else {
      postAPI.getPageBySlug('pilgrimage-to-the-holy-land');
    }
  };

  useEffect(() => {
    if(isNotEmpty(pilgrimage)) {
      displayPageBanner();
    }
  }, [pilgrimage, displayPageBanner]);

  displayPageBanner = () => {
    const { title, subtitle, headerImage: image } = pilgrimage;
    appDisplay.displayPageBanner({ title, subtitle, image });
  };

  const clearPopMessage = () => {
    setError(null);
  };

  return (
    <div className="Pilgrimage-container">
      { isEmpty(pilgrimage) && isEmpty(error) && <LoadingMessage message="Loading..." /> }

      { isNotEmpty(error) &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
          type={stateConfig.messageTypes.ERROR}>
          <p>We could not load the requested page.</p>
        </PopMessage>
      }

      { isNotEmpty(pilgrimage) && isEmpty(error) &&
        <div className="Pilgrimage-content">
          <SEOMeta 
            title="Holy Land Pilgrimage" 
            description="Joining the Institute of Catholic Culture for our journey to the Holy Land is a unique opportunity to walk in the footsteps of our Lord, to discover the sacred places of the Bible and on-site Bible studies with Father Hezekias Carnazzo." />
          <div className="Pilgrimage-heading">
            <h3>{pilgrimage.date}</h3>
            <p className="status">{pilgrimage.registrationStatus}</p>
          </div>
          { pilgrimage.tripDetails &&
            <div className="Pilgrimage-trip-details">
              {
                pilgrimage.tripDetails.map((item, index) => {
                  return (
                    <Accordion key={`trip-details-${index}`}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {item.heading}
                      </AccordionSummary>
                      <AccordionDetails>
                        <div dangerouslySetInnerHTML={{__html: item.content}} />
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              }
            </div>
          }
          <div className="Pilgrimage-general" dangerouslySetInnerHTML={{__html: pilgrimage.generalContent}} />
        </div>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return { posts: state.posts };
}

function mapDispatchToProps(dispatch) {
  return { 
    postAPI: bindActionCreators(postAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Pilgrimage);
