import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import * as privacyPolicyAPI from '../../actions/account/privacyPolicy';
import config from '../../config/config';
import stateConfig from '../../config/state';
import urls from '../../config/urls';
import { detectPrerender } from '../../utils/device';
import { localStore, localRetrieve } from '../../utils/store';


function PrivacyPrompt({ onClose, user, privacyPolicy, privacyPolicyAPI }) {
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checkingStatus, setCheckingStatus] = useState(false);
  const [prompted, setPrompted] = useState(false);
  const [reprompt, setReprompt] = useState(false);
  const [prerender, setPrerender] = useState(false);

  useEffect(() => {
    if(!init) {
      const localPrompt = localRetrieve(config.localStorage.privacyPolicyPrompt);
      if(localPrompt) {
        setPrompted(true);
      }
      if(detectPrerender()) {
        setPrerender(true);
        setLoading(false);
        onClose();
      }
      setInit(true);
    }
  }, [init, onClose]);

  useEffect(() => {
    if(init && loading && !checkingStatus && user.userDataChecked && !prerender) {
      if(user.email) { // identified, load policy data to determine what to do
        setCheckingStatus(true);
        privacyPolicyAPI.getPrivacyPolicyStatus();
      } else { // not identified
        if(prompted) {
          // nothing to do, we've prompted on this device before
          onClose();
        } else {
          // haven't prompted, go ahead and do so now
          localStore(config.localStorage.privacyPolicyPrompt, Date.now());
          setLoading(false);
        }
      }
    }
  }, [init, loading, checkingStatus, prompted, user.email, user.userDataChecked, onClose, privacyPolicyAPI, prerender]);

  useEffect(() => {
    if(init && loading && checkingStatus && !prerender) {
      const { responseStatus, acknowledged } = privacyPolicy;
      if(responseStatus === stateConfig.responseStatus.COMPLETE || responseStatus === stateConfig.responseStatus.ERROR) {
        setCheckingStatus(false);
        if(acknowledged) {
          // we've prompted on this member before, store on this device and close
          localStore(config.localStorage.privacyPolicyPrompt, Date.now());
          onClose();
        } else {
          // do not have a stored prompt for this member, do so now
          privacyPolicyAPI.updatePrivacyPolicyStatus({ location: 'prompt' });
          if(prompted) {
            // this member has been prompted before, but the acknowledged flag has been cleared
            // this occurs when the policy has been updated and we are wanting members to be presented w/new policy
            localStore(config.localStorage.privacyPolicyPrompt, Date.now()); // update timestamp locally
            setReprompt(true);
            setLoading(false);
          } else {
            // haven't stored prompt locally, go ahead and do so now
            localStore(config.localStorage.privacyPolicyPrompt, Date.now());
            setLoading(false);
          }
        }
      }
    }
  }, [init, loading, prompted, checkingStatus, onClose, privacyPolicy, privacyPolicyAPI, prerender]);

  return (
    <div className="PrivacyPrompt">
      { !loading && (!prompted || reprompt) && !prerender &&
        <div className="PrivacyPrompt-content">
          <div className="main">
            <div className="message">
              <h3>Notice</h3>
              <p>
                Our website and some of our third party tools make use of cookies or other identifiers, which are
                required for the proper functioning of our services. We take your privacy seriously. If you would like 
                to understand more about our use of cookies or how we handle personal data we encourage you to take a 
                look at our <Link to={urls.privacyPolicy} onClick={onClose}>privacy policy</Link>. We treat continued use of our website as 
                acceptance of our terms of use.
              </p>
            </div>
            <div className="actions">
              <Button className="acknowledge" variant="contained" color="primary" onClick={onClose}>
                OK
              </Button>
            </div>
          </div>
          <div className="close">
            <IconButton onClick={onClose}>
              <CloseIcon color='white' />
            </IconButton>
          </div>
        </div>
      }
    </div>
  );
}

PrivacyPrompt.propTypes = {
  onClose: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    user: state.user,
    privacyPolicy: state.privacyPolicy
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    privacyPolicyAPI: bindActionCreators(privacyPolicyAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPrompt);