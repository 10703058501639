import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as behaviorAPI from '../../../../actions/behavior';
import useWatched from '../../../../hooks/content/useWatched';
import ClassroomHeading from './ClassroomHeading';
import ClassroomOutline from './ClassroomOutline';
import ClassroomVideo from './ClassroomVideo';
import ClassroomAudio from './ClassroomAudio';
import VideoPromo from '../../../common/media/VideoPromo';
import validator from '../../../../utils/validator';
import Annotations from '../../../common/annotations/Annotations';
import AnnotationsToggle from '../../../common/annotations/AnnotationsToggle';
import Help from '../../common/Help';
import ClassroomNavigation from './ClassroomNavigation';
import Button from '@mui/material/Button';
import Icon, { IconTypes } from '../../../common/Icon';
import config from '../../../../config/config';
import urls from '../../../../config/urls';
import { localRetrieve } from '../../../../utils/store';
const { isEmpty, isNotEmpty } = validator;


function Classroom({ content, mediaType, mediaId, baseURL, onPathChange, behaviorAPI, watchRecord, user }) {
  const { title, subtitle } = content; 
  const [init, setInit] = useState(false);
  const [media, setMedia] = useState(null);
  const [startTime, setStartTime] = useState(0);
  const [checkpointData, setCheckpointData] = useState(null);
  const [lastMediaLogTime, setLastMediaLogTime] = useState(0);
  const [completed, setCompleted] = useState(false); 
  const [displayAnnotations, setDisplayAnnotations] = useState(false); 
  const watched = useWatched(content);
  const location = useLocation();
  const navigate = useNavigate(); 
  let initialize = config.emptyFn;
  let logProgress = config.emptyFn;
  let hasTrialAccess = config.emptyFn;
  let isIdentified = config.emptyFn;

  useEffect(() => {
    if(!init && user.userDataChecked) {
      if(isIdentified() && isNotEmpty(watched)) {
        initialize();
      } else if(!isIdentified()) {
        if(content.type === "Reflection" || hasTrialAccess()) {
          initialize();
        } else {
          // unidentified users w/o trial access should be kicked back to the event home
          navigate(urls.event.replace(':slug', content.slug));
        }
      }
    }
  }, [initialize, hasTrialAccess, init, watched, content.type, content.slug, navigate, isIdentified, user.userDataChecked]);

  useEffect(() => {
    if(!location.pathname.includes(mediaId)) {
      onPathChange();
    }
  }, [onPathChange, mediaId, location]);

  useEffect(() => {
    if(isEmpty(checkpointData)) return;

    const logInterval = config.watchLogInterval;
    const { time } = checkpointData;
    if(time - lastMediaLogTime >= logInterval || time < lastMediaLogTime) {
      setLastMediaLogTime(time);
      logProgress(checkpointData);
    }
  }, [checkpointData, lastMediaLogTime, logProgress]);
  
  useEffect(() => {   
    if(!user.userDataChecked || !isIdentified()) return;

    let currentlyWatched = watchRecord.find(record => record.mediaId === mediaId); 
    if(currentlyWatched && currentlyWatched.completed === true) {
      setCompleted(true); 
    }
  }, [watchRecord, mediaId, isIdentified, user.userDataChecked]); 
  
  initialize = () => {
    setInit(true);
    if(isIdentified() && watched) {
      const log = watched.logs.find(log => log.mediaId === mediaId);
      setStartTime((log? log.lastWatchPoint : 0));
    }
    const mediaList = content.media[mediaType];
    setMedia(mediaList.find(item => item.id === mediaId));
  };

  hasTrialAccess = () => {
    const trialAccess = localRetrieve(config.localStorage.eventTrialAccess)  || '';
    return trialAccess.includes(content.slug);
  }

  isIdentified = () => {
    return (user.userDataChecked && isNotEmpty(user.email));
  };
  
  const onProgress = data => {
    if(!isIdentified()) return; 
    setCheckpointData(data);
    behaviorAPI.publishWatchedCheckpoint(data);
  };
  
  logProgress = data => {
    const { percent, time } = data;
    const { id, type } = content;
    if(!id) return;
    
    const progress = parseInt(percent * 100);
    const lastWatchPoint = parseInt(time);
    behaviorAPI.logWatched({ eventId: id, eventType: type, mediaId, mediaType, progress, lastWatchPoint });
  };

  const toggleAnnotationsDisplay = event => {
    setDisplayAnnotations(!displayAnnotations); 
  }

  const navigateHome = event => {
    if (content.type === "Reflection" && location.pathname.includes('sunday-gospel')) {
      let url = urls.reflectionsLatinClassroomBase.replace('/:reflectionId', '');
      navigate(url);    
    } else if (content.type === "Reflection" && location.pathname.includes('byzantine-gospel')) {
      let url = urls.reflectionsByzantineClassroomBase.replace('/:reflectionId', ''); 
      navigate(url); 
    } else {
      navigate(baseURL); 
    }
  }

  let contentType = content.type === "Lecture Series" ? "Series" :
                    content.type === "Reflection" ? "Reflection" :
                    "Lecture";
  
  return (
    <div className="Classroom">
      { isNotEmpty(media) &&
        <Fragment>
          <div className="Classroom-content">
            <div className="nav-home">
              <Button variant="text" color="primary" onClick={navigateHome}>
                <Icon type={IconTypes.ArrowBack} /> {contentType} Home
              </Button>
            </div>
            <ClassroomHeading title={title} subtitle={subtitle} media={media} />
            { mediaType === 'video' &&
              <ClassroomVideo media={media} startTime={startTime} onProgress={onProgress} />
            }
            { mediaType === 'video' &&
              <VideoPromo 
                isComplete={completed} 
                contentType={content.type === 'Reflection'? 'reflection' : 'ondemand'} 
              />
            }
            { mediaType === 'audio' &&
              <ClassroomAudio
                media={media}
                startTime={startTime}
                onProgress={onProgress}
                toggle={toggleAnnotationsDisplay}
                displayAnnotations={displayAnnotations}
              />
            }
            { completed && 
              <p className="status-message">You have completed this item</p>
            }
            { (mediaType !== "audio" && isIdentified()) &&
              <AnnotationsToggle
                toggle={toggleAnnotationsDisplay}
                displayAnnotations={displayAnnotations}
                completed={completed}
              />
            }
            <Annotations
              content={content}
              mediaType={mediaType}
              mediaId={mediaId}
              completed={completed}
              displayAnnotations={displayAnnotations}
             />
            { content.type === "Lecture Series" && 
              <ClassroomNavigation
                content={content}
                watchRecord={watchRecord}
                mediaType={mediaType}
                media={media}
                baseURL={baseURL}
              />
            }
          </div>
          <div className="Classroom-sidebar">
            <ClassroomOutline 
              content={content} 
              mediaType={mediaType} 
              mediaId={mediaId} 
              baseURL={baseURL}
              navigateHome={navigateHome}
              contentType={contentType}
            />
            <Help />
          </div>
        </Fragment>
      }
    </div>
  );
}

Classroom.propTypes = {
  content: PropTypes.object,
  mediaType: PropTypes.string,
  mediaId: PropTypes.string,
  baseURL: PropTypes.string,
  onPathChange: PropTypes.func
};

function mapStateToProps(state) {
  return { 
    watchRecord: state.behavior.watched,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    behaviorAPI: bindActionCreators(behaviorAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Classroom);