import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import validator from '../../../utils/validator';
const { isDefined } = validator;

function EmailConfirmation({ email, onComplete }) {
  const [confirmEmail, setConfirmEmail] = useState(email);
  const [emailError, setEmailError] = useState(null);

  const onFieldChange = ({ target: { value } }) => {
    setEmailError(null);
    setConfirmEmail(value);
  };

  const checkEmailConfirmation = () => {
    if(!validate()) {
      setEmailError('The email you entered is not valid');
      return;
    }

    if(confirmEmail !== email) {
      onComplete(confirmEmail);
    } else {
      onComplete();
    }
  };

  const validate = () => {
    const validationErrors = validator.validate({ confirmEmail }, [
      { rule: validator.rules.MATCHES_PATTERN, prop: 'confirmEmail', against: validator.patterns.EMAIL, empty: true },
      { rule: validator.rules.IS_DEFINED, prop: 'confirmEmail' }
    ]);
    return validationErrors? false : true;
  }

  return (
    <div className="EmailConfirmation Form">
      <div className="EmailConfirmation-content">
      <h1>Email Preferences</h1>
        <p className="EmailConfirmation-message">
          Please confirm the email address that you want to manage communications for
        </p>

        <TextField
          className="FormField-control EmailConfirmation-email full"
          name="email"
          label="Email"
          placeholder='Email'
          onChange={onFieldChange}
          helperText={emailError}
          error={isDefined(emailError)}
          value={confirmEmail}
          variant="standard"
        />

        <div className="EmailConfirmation-submit">
          <Button variant="contained" color="primary" onClick={checkEmailConfirmation}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}

EmailConfirmation.propType = {
  email: PropTypes.string,
  onComplete: PropTypes.func
};

export default EmailConfirmation;