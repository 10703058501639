import React from 'react';
import PropTypes from 'prop-types';
import Form from '../../../../common/form/Form';
import TextField from '@mui/material/TextField';
import validator from '../../../../../utils/validator';


export default function ProfileLegalName(props) {
  const { editing, errors, data, onFieldChange, onSubmit } = props;
  return (
    <div>
      { editing &&
        <div className="MemberProfile-itemEdit">
          <Form id='ProfileEditName-form' onSubmit={onSubmit} autoComplete="off" submitLabel='Save'>
            <TextField
              className="FormField-control full"
              name="legalFirstName"
              label="Legal First Name"
              placeholder="Legal First Name"
              onChange={onFieldChange}
              helperText={validator.message(errors['legalFirstName'], 'legal first name')}
              error={validator.isDefined(errors['legalFirstName'])}
              value={data.legalFirstName}
              variant="standard"
            />
            <TextField
              className="FormField-control full"
              name="lastName"
              label="Legal Last Name"
              placeholder="Legal Last Name"
              onChange={onFieldChange}
              helperText={validator.message(errors['lastName'], 'last name')}
              error={validator.isDefined(errors['lastName'])}
              value={data.lastName}
              variant="standard"
            />
          </Form>
        </div>
      }
    </div>
  );
}

ProfileLegalName.defaultProps = {
  editing: false,
  errors: {}
};

ProfileLegalName.propTypes = {
  data: PropTypes.object,
  onFieldChange: PropTypes.func,
  onSubmit: PropTypes.func
};

export function legalNameFromProfile(data) {
  return { 
    lastName: data.lastName || '',
    legalFirstName: data.legalFirstName || '',
  };
}

export function formatLegalName(data) {
  const { legalFirstName, lastName } = data;
  let name = 'No name on record';
  if(legalFirstName) {
    name = `${legalFirstName} ${lastName}`;
  }

  return name;
}

export function validateLegalName(data) {
  const validationErrors = validator.validate(data, [
    { rule: validator.rules.MATCHES_PATTERN, prop: 'lastName', against: validator.patterns.NAME, empty: true },
    { rule: validator.rules.IS_DEFINED, prop: 'lastName' },
    { rule: validator.rules.MATCHES_PATTERN, prop: 'legalFirstName', against: validator.patterns.NAME, empty: true }, 
    { rule: validator.rules.IS_DEFINED, prop: 'legalFirstName' }      
]);

  return validationErrors? validationErrors : { valid: true}; 
}