import React, { Fragment, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'qs';
import useAnalytics from '../../../hooks/useAnalytics';
import * as searchAPI from '../../../actions/search';
import EventList from '../events/display/EventList';
import LoadingMessage from '../../common/messages/LoadingMessage';
import PopMessage from '../../common/messages/PopMessage';
import config from '../../../config/config';
import urls from '../../../config/urls';
import stateConfig from '../../../config/state';
import { resetViewToTopOfPage } from '../../../utils/utils';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import SEOMeta from '../../common/SEOMeta';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function Search({ search, searchAPI }) {
  const [loading, setLoading] = useState(true);
  const [typing, setTyping] = useState(false);
  const [terms, setTerms] = useState(null);
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  useAnalytics('Search: Text');
  // defining fns used in useEffect blocks
  let performSearch = config.emptyFn;
  let getTerms = config.emptyFn;


  useEffect(() => {
    resetViewToTopOfPage();
    setTerms(getTerms);
  }, [performSearch, getTerms]);

  useEffect(() => {
    if(location.pathname.includes('/search')) {
      resetViewToTopOfPage();
      setTerms(getTerms);
    }
  }, [location, performSearch, getTerms]);

  useEffect(() => {
    if(isNotEmpty(terms)) {
      performSearch();
    }
  }, [terms, performSearch]);

  useEffect(() => {
    if(loading && isEmpty(results) && search.responseStatus) {
      if(search.responseStatus === stateConfig.responseStatus.ERROR) {
        setLoading(false);
        setResults(null);
        setError(`Search failed: ${search.responseCode}`);
      } else if(search.responseStatus === stateConfig.responseStatus.COMPLETE) {
        setLoading(false);
        setResults(search.results);
        setError(null);
      }
    }
  }, [loading, results, search, search.responseStatus]);

  getTerms = () => {
    const params = querystring.parse(window.location.search.substring(1));
    return params.terms;
  };

  performSearch = () => {
    if(terms) {
      setTyping(false);
      setResults(null);
      setLoading(true);
      searchAPI.searchWithTerms(terms);
    }
  };

  const handleSearchFieldChange = (event) => {
    setTerms(event.target.value);
    setTyping(true);
  };

  const handleSearchRequest = (event) => {
    if(event && event.type === 'keydown' && event.key !== 'Enter') {
      return;
    }
    navigate(`${urls.search}?terms=${terms}`);
  };

  const clearPopMessage = () => {
    setError(null);
  };

  return (
    <div className="Search">
      <SEOMeta 
        title="Search" 
        description={`Search over ${config.libraryHoursOfContent} hours of lecture and course content in our library.`} />
      
      <Paper className="Search-field">
        <InputBase
          className="search-field"
          placeholder="Tell us what you're looking for..."
          inputProps={{ 'aria-label': 'search Institute content' }}
          value={terms}
          onKeyDown={handleSearchRequest}
          onChange={handleSearchFieldChange} />
        <IconButton className="search-icon icon" aria-label="search" onClick={handleSearchRequest}>
          <SearchIcon />
        </IconButton>
      </Paper>

      { loading && <LoadingMessage message="Loading..." /> }
      
      <div className="Search-results">
        { error &&
          <PopMessage horizontal="center" open={true} onClose={clearPopMessage}
            type={stateConfig.messageTypes.ERROR}>
            <p>We could not perform the requested search.</p>
          </PopMessage>
        }

        { !loading && !typing && !error &&
          <div className="MainContent-container">
            { !terms &&
              <h2>
                <span className="message">Please enter your search terms</span>
              </h2>
            }
            { terms &&
              <div className="EventList-container search">
                <h2>
                  <span className="number">{!results? '0': results.length}</span>
                  <span className="message">&nbsp;results matching the terms:</span>
                  <span className="terms">&nbsp;{terms}</span>
                </h2>
                { results &&
                  <Fragment>
                    <EventList events={results} displayType={config.eventListTypes.BROWSE}
                      cardsPerRender={config.defaultCardsPerRender} />
                  </Fragment>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    search: state.search
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    searchAPI: bindActionCreators(searchAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);