import { MBR_REGISTRATION_REQUEST, MBR_REGISTRATION_TOKEN_COMPLETE, 
         MBR_REGISTRATION_COMPLETE, MBR_REGISTRATION_ERR, MBR_ACTIVATION_REQUEST, 
         MBR_ACTIVATION_COMPLETE, MBR_ACTIVATION_RESEND, MBR_ACTIVATION_ERR } from '../../actions/actionTypes';
import * as registration from '../../api/registration';
import errors from '../../config/errors';


export function getMemberRegistrationToken() {
  return async dispatch => {
    dispatch({ type: MBR_REGISTRATION_REQUEST });
    try {
      let result = await registration.getMemberRegistrationToken();
      if(result.data.token && result.data.check) {
        dispatch({ type: MBR_REGISTRATION_TOKEN_COMPLETE, data: result.data });
      } else {
        dispatch({ type: MBR_REGISTRATION_ERR, error: errors.MemberRegistrationInitError });
      }
    } catch(error) {
      dispatch({ type: MBR_REGISTRATION_ERR, error: errors.MemberRegistrationInitError });
    }
  };
}

export function createMembership(registrationData) {
  return async dispatch => {
    dispatch({ type: MBR_REGISTRATION_REQUEST });
    try {
      let result = await registration.createMembership(registrationData);
      if(result.data.success) {
        dispatch({ type: MBR_REGISTRATION_COMPLETE, data: result.data });
      } else {
        dispatch({ type: MBR_REGISTRATION_ERR, error: errors.MemberRegistrationError });
      }
    } catch(error) {
      if(error.response && error.response.data && error.response.data.code) {
        // error from server
        dispatch({ type: MBR_REGISTRATION_ERR, error: error.response.data });
      } else {
        // unexpected
        dispatch({ type: MBR_REGISTRATION_ERR, error: errors.MemberRegistrationError });
      }
    }
  };
}

export function activateMember(code) {
  return async dispatch => {
    dispatch({ type: MBR_ACTIVATION_REQUEST });
    try {
      let result = await registration.activateMember(code);
      dispatch({ type: MBR_ACTIVATION_COMPLETE, data: result.data });
    } catch(error) {
      // error from server
      if(error.response && error.response.data && error.response.data.code === 'F101') {
        dispatch({ type: MBR_ACTIVATION_ERR, error: errors.MemberActivationExpired });
      } else {
        dispatch({ type: MBR_ACTIVATION_ERR, error: errors.MemberActivationError });
      }
    }
  };
}

export function resendMemberActivation(email) {
  return async dispatch => {
    dispatch({ type: MBR_ACTIVATION_REQUEST });
    try {
      await registration.resendMemberActivation(email);
      dispatch({ type: MBR_ACTIVATION_RESEND });
    } catch(error) {
      dispatch({ type: MBR_ACTIVATION_ERR, error: errors.ActivationResendFailure });
    }
  };
}
