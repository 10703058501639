import { combineReducers } from 'redux';
import accountDeletion from './accountDeletion';
import annotations from './annotations';
import appDisplay from './appDisplay';
import authentication from './authentication';
import behavior from './behavior';
import contactRegistration from './contactRegistration';
import courseProgress from './courseProgress';
import courseRegistration from './courseRegistration';
import courses from './courses';
import donations from './donations';
import emailSettings from './emailSettings';
import eventRegistration from './eventRegistration';
import events from './events';
import instructors from './instructors';
import magdalaRegistration from './magdalaRegistration';
import membershipActivation from './membershipActivation';
import membershipRegistration from './membershipRegistration';
import posts from './posts';
import privacyPolicy from './privacyPolicy';
import profile from './profile';
import reflections from './reflections';
import search from './search';
import share from './share';
import systemContent from './systemContent';
import taxonomy from './taxonomy';
import user from './user';
import { AUTH_LOGOUT } from '../actions/actionTypes';

const appReducer = combineReducers({
  accountDeletion,
  annotations,
  appDisplay,
  authentication,
  behavior,
  courseProgress,
  contactRegistration,
  courseRegistration,
  courses,
  donations,
  emailSettings,
  eventRegistration,
  events, 
  instructors,
  magdalaRegistration,
  membershipActivation,
  membershipRegistration,
  posts,
  privacyPolicy,
  profile,
  reflections,
  search,
  share,
  systemContent,
  taxonomy,
  user
});

const rootReducer = (state, action) => {
  if(action.type === AUTH_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;