import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import querystring from 'qs';
import * as registrationAPI from '../../../../actions/registration/event';
import * as authenticationAPI from '../../../../actions/authentication';
import * as appDisplay from '../../../../actions/appDisplay';
import DetailMedia from '../detail/DetailMedia';
import Icon, { IconTypes } from '../../../common/Icon';
import LoadingMessage from '../../../common/messages/LoadingMessage';
import ContactErrorPopMessage from '../../../common/messages/ContactErrorPopMessage';
import { resetViewToTopOfPage } from '../../../../utils/utils';
import validator from '../../../../utils/validator';
import stateConfig from '../../../../config/state';
import config from '../../../../config/config';
import Button from '@mui/material/Button';
const { isNotEmpty, isEmpty } = validator;


function EventRegistration({ 
    eventData,
    referral,
    formDisplayed,
    onOpenForm,
    memberRegistration,
    onOpenClassroom,
    eventRegistration, 
    user, 
    registrationAPI, 
    authenticationAPI, 
    appDisplay 
  }) {
  
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submission, setSubmission] = useState(false);
  const [webinarId, setWebinarId] = useState('');
  const [marketingTag, setMarketingTag] = useState('');
  const [memberRegistrationComplete, setMemberRegistrationComplete] = useState(false);
  const [currentRegistration, setCurrentRegistration] = useState(null);
  const [referralChecked, setReferralChecked] = useState(false);
  const [error, setError] = useState(false);
  const location = useLocation();
  const { email, firstName, lastName } = user;
  // defining fns used in useEffect blocks
  let initialize = config.emptyFn;
  let checkEventRegistrationStatus = config.emptyFn;
  let performEventRegistration = config.emptyFn;
  let updateAfterMemberRegistration = config.emptyFn;

  useEffect(() => {
    if(user.userDataChecked) {
      initialize();
    }
  }, [initialize, user.userDataChecked]);

  useEffect(() => {
    const { id: eventId } = eventData;
    const registration = eventRegistration.byId[eventId];

    if(loading && isEmpty(currentRegistration) && isNotEmpty(registration) && eventRegistration.ids) {
      // the result of the initial registration check
      setCurrentRegistration(registration);
      setLoading(false);
    }
  }, [loading, currentRegistration, eventData, eventRegistration, eventRegistration.ids]);

  useEffect(() => {
    if(submission) {
      setCurrentRegistration(null);
    }
  }, [submission]);

  useEffect(() => {
    if(init && loading && !referralChecked && referral.processed) {
      // wait until both user identification and the existence of a referral email has been processed
      if(isEmpty(user.email) && isEmpty(referral.email)) {
        // neither a user or referral is present, so we don't need to check for a registration
        setLoading(false);
      } else {
        const params = querystring.parse(location.search.substring(1));
        const providerRedirect = (params.provider && params.provider === 'success')? true : false;
        setReferralChecked(true);
        if(providerRedirect) {
          // we just finished a registration provider redirect, so complete the process
          performEventRegistration();
        } else {
          // check to see if a registration exists
          checkEventRegistrationStatus();
        }
      }
    }
  }, [init, loading, referralChecked, referral, user.email, location, performEventRegistration, checkEventRegistrationStatus]);

  useEffect(() => {
    const { id: eventId } = eventData;
    const registration = eventRegistration.byId[eventId];

    if(submission && isEmpty(currentRegistration) && eventRegistration.ids && isNotEmpty(registration)) {
      // the result of the event registration attempt
      if(registration.registered) {
        resetViewToTopOfPage();
        setSubmission(false);
        setCurrentRegistration(registration);
        appDisplay.displayPageMessage({
          message: "You have successfully registered!",
          type: stateConfig.messageTypes.SUCCESS,
          time: 10
        });
      } else {
        setError(true);
        setSubmission(false);
      }
    }
  }, [submission, currentRegistration, eventData, eventRegistration, eventRegistration.ids, appDisplay]);

  useEffect(() => {
    if(!memberRegistrationComplete && isNotEmpty(memberRegistration)) {
      updateAfterMemberRegistration();
    }
  }, [memberRegistrationComplete, memberRegistration, updateAfterMemberRegistration]);

  useEffect(() => {
    if(memberRegistrationComplete && isNotEmpty(user.email)) {
      performEventRegistration();
    }
  }, [user.email, memberRegistrationComplete, performEventRegistration]);

  initialize = () => {
    const { id: eventId, slug, registration: { webinarId, marketingTag } } = eventData;
    setWebinarId(webinarId? webinarId.replace(/-/gi, '') : '');
    setMarketingTag(marketingTag || `event-${slug}`);
    setInit(true);

    const registration = eventRegistration.byId[eventId];
    if(isNotEmpty(registration)) {
      setLoading(false);
      setCurrentRegistration(registration);
    }
  };

  checkEventRegistrationStatus = () => {
    const { id: eventId } = eventData;
    if(isNotEmpty(email)) {
      const data = { email, eventId, webinarId, identify: false };
      registrationAPI.getEventRegistrationStatus(data);
    } else if(isNotEmpty(referral.email)) {
      const data = { email: referral.email, eventId, webinarId, identify: true };
      registrationAPI.getEventRegistrationStatus(data);
    } else {
      setLoading(false);
      setCurrentRegistration(null);
    }
  };

  updateAfterMemberRegistration = async () => {
    const { error } = memberRegistration;
    if(error) {
      setError(true);
      return;
    }
    
    setMemberRegistrationComplete(true);
    await authenticationAPI.identifyDeviceOwner();
  };

  performEventRegistration = () => {
    const { email, firstName, lastName } = user;
    const { id: eventId  } = eventData;
    const registrationData = { email, firstName, lastName, marketingTag };
    setSubmission(true);
    registrationAPI.createEventRegistration(eventId, webinarId, registrationData);
  };

  const clearPopMessage = () => {
    setError(false);
  };

  return(
    <div className="EventRegistration">
      { loading && !error && isEmpty(currentRegistration) &&
        <div className="EventRegistration-loading">
          <LoadingMessage message="Loading registration..." />
        </div>
      }

      { !submission && isEmpty(currentRegistration) && !memberRegistrationComplete && isEmpty(email) &&
        <div className="EventRegistration-notIdentified">
          { !formDisplayed &&
            <Button className="EventRegistration-button" variant="contained" color="secondary" onClick={onOpenForm}>
              <span><Icon type={IconTypes.Register} /> Register Now</span>
            </Button>
          }
        </div>
      }

      { !submission && isNotEmpty(currentRegistration) && !currentRegistration.registered && !memberRegistrationComplete && isNotEmpty(email) &&
        <div className="EventRegistration-identified">
          <Button className="EventRegistration-button" variant="contained" color="secondary" onClick={performEventRegistration}>
            <span><Icon type={IconTypes.Register} /> Register Now</span> 
          </Button>
          <p className="registrant">
            <span className="label">Registrant Name:</span> {firstName} {lastName} 
          </p>
        </div>
      }

      { submission &&
        <div className="EventRegistration-identified">
          <Button className="EventRegistration-button" variant="contained" color="secondary" disabled={true}>
            <span><FontAwesomeIcon icon={faSpinnerThird} spin /> Processing...</span>
          </Button>
        </div>
      }

      { isNotEmpty(currentRegistration) && currentRegistration.registered &&
        <div className="EventRegistration-registered">
          <DetailMedia 
            isCompleted={false}
            eventData={eventData} 
            eventRegistration={currentRegistration} 
            onOpenClassroom={onOpenClassroom} 
          />
        </div>
      }

      { !loading && error &&
        <ContactErrorPopMessage horizontal="center" open={true} onClose={clearPopMessage}>
          <p>We were unable to register you for this event.</p>
        </ContactErrorPopMessage>
      }
    </div>
  );
}

EventRegistration.propTypes = {
  eventData: PropTypes.object,
  referral: PropTypes.object,
  formDisplayed: PropTypes.bool,
  onOpenForm: PropTypes.func,
  memberRegistration: PropTypes.object,
  onOpenClassroom: PropTypes.func
};

function mapStateToProps(state) {
  const { eventRegistration, user } = state;
  return { eventRegistration, user }
}

function mapDispatchToProps(dispatch) {
  return { 
    registrationAPI: bindActionCreators(registrationAPI, dispatch),
    authenticationAPI: bindActionCreators(authenticationAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventRegistration);