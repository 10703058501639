import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as systemAPI from '../../../actions/content/system';
import * as appDisplay from '../../../actions/appDisplay';
import config from '../../../config/config';
import stateConfig from '../../../config/state';
import { viewport } from '../../../utils/device';
import validator from '../../../utils/validator';
const { isEmpty, isNotEmpty } = validator;


function VideoPromo({ isComplete, contentType, systemContent, systemAPI, appDisplay }) {
  const [initTime] = useState(Date.now());
  const [loading, setLoading] = useState(true);
  const [promo, setPromo] = useState(null);
  let initialize = config.emptyFn
  let displayPromo = config.emptyFn;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const data = systemContent.videoPromo;
    if(loading && isEmpty(promo) && isNotEmpty(data)) {
      setPromo(data);
      setLoading(false);
    }
  }, [loading, promo, systemContent.videoPromo]);

  useEffect(() => {
    if(isComplete && isNotEmpty(promo)) {
      // make sure the view hasn't just loaded, if the video was already completed
      // from a previous watch session this will kick off as soon as the video and
      // watch data is loaded. If the completion notification arrives too quickly
      // then ignore it.
      if(Date.now() - initTime < 5000) {
        return;
      }

      // determine if this promo applies to this context
      const { onDemand, reflections, courses } = promo.applyTo;
      const shouldDisplay = (onDemand && contentType === 'ondemand')? true :
        (reflections && contentType === 'reflection')? true :
        (courses && contentType === 'course');
      
      if(shouldDisplay) {
        displayPromo();
      }
    }
  }, [isComplete, contentType, promo, displayPromo, initTime]);

  initialize = () => {
    const data = systemContent.videoPromo;
    if(isNotEmpty(data)) {
      setPromo(data);
      setLoading(false);
    } else {
      systemAPI.getVideoPromo();
    }
  };

  displayPromo = () => {
    const viewportInfo = viewport();
    const isMobileDevice = config.mobileDeviceClasses.includes(viewportInfo.deviceClass);
    const message = isMobileDevice && isNotEmpty(promo.mobileMessage)? promo.mobileMessage : promo.message;
    appDisplay.displayPageMessage({
      message,
      type: stateConfig.messageTypes.PROMO,
      time: parseInt(promo.timeLimit),
      speed: 0.4,
      button: promo.button
    });
  };

  return (
    <Fragment></Fragment>
  );
}

VideoPromo.propTypes = {
  isComplete: PropTypes.bool,
  contentType: PropTypes.string
};

function mapStateToProps(state) {
  return { 
    systemContent: state.systemContent
  };
}

function mapDispatchToProps(dispatch) {
  return { 
    systemAPI: bindActionCreators(systemAPI, dispatch),
    appDisplay: bindActionCreators(appDisplay, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPromo);