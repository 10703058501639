import React from 'react';
import Featured from './Featured';
import Participation from './Participation';
import Culture from './Culture';


function Offerings() {
  return (
    <div className="Home-offerings">
      <Featured />
      <Participation />
      <Culture />
    </div>
  );
}

export default Offerings;