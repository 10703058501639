import { AUTH_START, AUTH_STEP, AUTH_COMPLETE, AUTH_ERR,
         AUTH_PASSCODE_SENT, AUTH_RESET_LINK_SENT, AUTH_RESET_COMPLETE,
         AUTH_STATUS_ERR, AUTH_STATUS_RECEIVED, AUTH_PUBLISH_CONTEXT,
         AUTH_REMEMBERED, AUTH_PROVIDER_START } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function authenticationReducer(state = initialState.authentication, action) {
  const { type, data = {}, error = {} } = action;
  const resetState = Object.assign({}, initialState.authentication, { contextParams: state.contextParams });
  let responseCode, contextParams, status = stateConfig.authStatus;

  switch(type) {
    case AUTH_PUBLISH_CONTEXT:
      return Object.assign({}, state, { contextParams: action.context });
    case AUTH_PROVIDER_START:
      return Object.assign({}, state, { responseStatus: status.PROVIDER_START });
    case AUTH_START:
      return Object.assign({}, resetState, { responseStatus: status.WAITING, responseCode: null });
    case AUTH_STEP:
      return Object.assign({}, state, { responseStatus: status.WAITING, responseCode: null });
    case AUTH_STATUS_RECEIVED:
      return Object.assign({}, state, { responseStatus: status.AUTH_COMPLETE, responseCode: null });
    case AUTH_COMPLETE:
      ({ contextParams } = data);
      return Object.assign({}, state, { responseStatus: status.AUTH_COMPLETE, contextParams: contextParams || state.contextParams || {} });
    case AUTH_REMEMBERED:
      return Object.assign({}, state, { responseStatus: status.DEVICE_REMEMBERED });
    case AUTH_PASSCODE_SENT:
      return Object.assign({}, state, { responseStatus: status.AUTH_CODE_SENT });
    case AUTH_RESET_LINK_SENT:
      return Object.assign({}, state, { responseStatus: status.RESET_LINK_SENT });
    case AUTH_RESET_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.RESET_COMPLETE });
    case AUTH_STATUS_ERR:
      return Object.assign({}, state, { responseStatus: status.NOT_AUTHENTICATED });
    case AUTH_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default authenticationReducer;