import randomstring from 'randomstring';

export function random(length = 32) {
  return randomstring.generate(length);
}

export function randomInt(min, max) {
  const floor = max - min + 1;
  return Math.floor(Math.random() * Math.floor(floor)) + min;
}

export function resetViewToTopOfPage() {
  window.scrollTo(0, 0);
}

export function encodeDataToken(data) {
  try {
    var base64 = btoa(JSON.stringify(data));
    return base64.replace(/\+/gi, '.')
    .replace(/\//gi, '_')
    .replace(/=/gi, '-');
  } catch(e) {
  }

  return null;
}

export function decodeDataToken(token) {
  try {
    var base64 = token.replace(/\./gi, '+')
    .replace(/_/gi, '/')
    .replace(/-/gi, '=');

    return JSON.parse(atob(base64));
  } catch(e) {
  }

  return null;
}

export function slugify(text) {
  return text? text.replace(/[^a-zA-Z0-9]+/g, ' ').trim().replace(/\s/g, '-').toLowerCase() : '';
};
