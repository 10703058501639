import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import urls from '../../../config/urls'; 

function CategoryDisplay(props) {
  const { description, eventCategories } = props.curriculum[props.category];
  return (
    <div className={`${props.category} tab-content`}>
      <p className="category-description">{description}</p>
      <div className="events">
        {
          eventCategories.map((eventCategory, categoryIndex) => {
            const { categoryName, events } = eventCategory; 
            const eventList = events.map((event, eventIndex) => {
              return (
                <div key={`event-${eventIndex}`} className="event">
                  <p className="title">
                    { event.slug? <Link to={urls.event.replace(':slug', event.slug)}>{event.title}</Link> : event.title }
                  </p>
                </div>
              )
            })
          
          return (
            <div key={`event-${categoryIndex}`} className="category">
              <h3>{categoryName}</h3>
              {eventList}
            </div>
          )
          })
        }
      </div>
    </div>
  );
}

CategoryDisplay.propTypes = {
  category: PropTypes.string,
  curriculum: PropTypes.object
};

export default CategoryDisplay;