import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { formatDateForDisplay } from '../../../utils/format';


function ScheduledDonations({ donations }) {
  return (
    <Fragment>
      { donations.length > 0 &&
        <div className="ScheduledDonations">
          <h2>Scheduled Recurring Donations</h2>
          <div className="scheduled-list">
            {
              donations.map((donation, index) => {
                return (
                  <div key={`scheduled-donation-${index}`} className="scheduled-donation">
                    <div className="amount">
                      ${donation.amount}.00/{donation.frequency}
                    </div>
                    <div className="date">
                      <span>Starting On:</span>&nbsp;
                      {formatDateForDisplay(new Date(donation.startingOn))}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      }
    </Fragment>
  );
}

ScheduledDonations.propTypes = {
  donations: PropTypes.array
};

export default ScheduledDonations;