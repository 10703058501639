import React from 'react';

function LogoutCompleted() {
  return (
    <div id="Logout">
      <p>You have been logged out</p>
    </div>
  );
}

export default LogoutCompleted;