import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import validator from '../../../../utils/validator';
const { isDefined } = validator;

function ClassroomNavigation({ content, mediaType, media, baseURL }) {
  const { title } = content; 
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const arr = content.media[mediaType]; 
    const index = arr.findIndex(item => item.id === media.id); 
    
    if((index - 1) >= 0) {
      setPrevious(arr[index - 1]); 
    } 
    if((index + 1) <= arr.length) {
      setNext(arr[index + 1]); 
    }
  }, [media, content.media, mediaType]); 

  const navToPart = part => () => {
    let url = `${baseURL}/${mediaType}/${part.id}`; 
    navigate(url); 
  };

  return (
    <Fragment>
      { (isDefined(previous) || isDefined(next)) &&
      <div className="ClassroomNavigation">
        <hr />
        <div className="nav-actions">
          <div className="nav-action prev">
            { isDefined(previous) && 
              <Fragment>
                <Button variant="outlined" color="primary" onClick={navToPart(previous)}>
                  Previous
                </Button>
                <div className="nav-title">{`${title} - ${previous.title}`}</div>
              </Fragment>
            }
          </div>
          <div className="nav-action next">
            { isDefined(next) && 
              <Fragment>
                <Button variant="outlined" color="primary" onClick={navToPart(next)}>
                  Next
                </Button>
                <div className="nav-title">{`${title} - ${next.title}`}</div>
              </Fragment>
            }
          </div>
        </div>
      </div>
    }
    </Fragment>
  );
}

ClassroomNavigation.propTypes = {
  content: PropTypes.object,
  mediaType: PropTypes.string,
  media: PropTypes.object,
  baseURL: PropTypes.string
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(ClassroomNavigation);
