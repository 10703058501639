import React, { Fragment, useState, useEffect } from 'react'; 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import useAnalytics from '../../hooks/useAnalytics';
import Banner from './common/Banner';
import MagdalaToolbar from '../layout/MagdalaToolbar';
import Cards from './Program/Cards';
import EnrollmentRequirements from './Program/EnrollmentRequirements';
import { resetViewToTopOfPage } from '../../utils/utils';
import SEOMeta from '../common/SEOMeta';
import TechTips from './Program/TechTips';
import MagdalaContactUs from './Program/MagdalaContactUs';
import MobileNav from './common/MobileNav';
import * as systemContentAPI from '../../actions/content/system'; 
import LoadingMessage from '../common/messages/LoadingMessage';
import PopMessage from '../common/messages/PopMessage'; 
import stateConfig from '../../config/state'; 

function MagdalaProgram({ systemContent, systemContentAPI}) {
  const [init, setInit] = useState(false); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [content, setContent] = useState(null);
  useAnalytics('Magdala Program Details');
    
  useEffect(() => {
    if(!init) {
      resetViewToTopOfPage();
      const magdalaContent = systemContent.magdala; 
      if(Boolean(magdalaContent) && Boolean(magdalaContent.settings)) {
        setContent(parseContent(magdalaContent.settings)); 
        setLoading(false);
      } else {
        systemContentAPI.getMagdalaContent(); 
      }
      
      setInit(true); 
    }
  },[init, systemContent, systemContentAPI]); 
    
  useEffect(() => {
    const magdalaContent = systemContent.magdala; 
    if(loading && !Boolean(content) && Boolean(magdalaContent)) {
      setLoading(false); 
      if(Boolean(magdalaContent.error) || !Boolean(magdalaContent.settings)) {
        setError(true);
      } else {
        setContent(parseContent(magdalaContent.settings));
      }
    }
  }, [loading, content, systemContent.magdala]);

  const parseContent = (settings) => {
    return settings.reduce((obj, setting) => {
      const { key, type, value } = setting;
      obj[key] = { type, value };
      return obj;
    }, {});
  };

  const clearPopMessage = () => {
    setError(false); 
  }

  return (
    <div>
    { loading && !error && <div className='loadMessage'><LoadingMessage message="Loading..." /></div>}
    { !loading && error &&
        <PopMessage horizontal="center" open={true} onClose={clearPopMessage} type={stateConfig.messageTypes.ERROR}>
          <p>Failed to retrieve page content. Please try refreshing the page. If this issue persists, please contact ICC staff so we can resolve the issue as soon as possible. Thank you!</p>
        </PopMessage>
      }
    { !loading && Boolean(content) &&
      <Fragment>
        <SEOMeta
          title="Magdala Apostolate Program Details"
          description="Each term we offer free, semester-long courses for religious sisters and communities. Any professed religious sister, novice, or postulant can apply with the permission of her superior."
        />
        <Banner />
        <MagdalaToolbar /> 
        <MobileNav />
        <div className="Magdala-Program">
          <Cards content={content}/>
          <EnrollmentRequirements content={content} />
          <TechTips content={content} />
          <MagdalaContactUs content={content} />
        </div>
      </Fragment>
    }
    </div>
  )
}

function mapStateToProps(state) {
  return { 
    systemContent: state.systemContent
  };
}
  
function mapDispatchToProps(dispatch) {
  return { 
    systemContentAPI: bindActionCreators(systemContentAPI, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MagdalaProgram); 