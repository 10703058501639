import React from 'react';
import PropTypes from 'prop-types';
import FilterGroup from './FilterGroup';
import config from '../../../config/config';


function FilterList(props) {
  const { className, selectedFilters, taxonomy, onAdd, onRemove } = props;
  const { selectedTypes, selectedDates, selectedCategories, selectedTags } = selectedFilters;

  return (
    <div className={className}>
      <div className="col1">
        <FilterGroup heading="Content Type" itemClass="type" filterType="content-type"
          selectedFilters={selectedTypes} filterMap={config.contentTypes} 
          onAdd={onAdd} onRemove={onRemove} />
        <FilterGroup heading="Date Range" itemClass="date" filterType="date-range"
          selectedFilters={selectedDates} filterMap={config.dateRangeMap} 
          onAdd={onAdd} onRemove={onRemove} />
      </div>
      <div className="col2">
        <FilterGroup heading="Categories" itemClass="category" filterType="categories"
          selectedFilters={selectedCategories} filterMap={taxonomy.categories} 
          onAdd={onAdd} onRemove={onRemove} />
      </div>
      <div className="col3">
        <FilterGroup heading="Tags" itemClass="tag" filterType="tags"
          selectedFilters={selectedTags} filterMap={taxonomy.tags} 
          onAdd={onAdd} onRemove={onRemove} />
      </div>
    </div>
  );
}

FilterList.propTypes = {
  selectedFilters: PropTypes.object,
  taxonomy: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func
};

export default FilterList;