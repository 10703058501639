import { POST_LOAD_START, POST_LOAD_COMPLETE, POST_LOAD_ERR } from '../actionTypes';
import * as posts from '../../api/content/posts';
import state from '../../config/state';


export function getArticleBySlug(slug) {
  const key = state.keys.ARTICLE;
  return getPostBySlug(slug, key);
}

export function getPageBySlug(slug) {
  const key = state.keys.PAGE;
  return getPostBySlug(slug, key);
}

export function getSnippetBySlug(slug) {
  const key = state.keys.SNIPPET;
  return getPostBySlug(slug, key);
}

function getPostBySlug(slug, key) {
  return async dispatch => {
    dispatch({ type: POST_LOAD_START, key, objectId: slug });
    try {
      let result = await posts.getPostBySlug(slug, key);
      dispatch({ type: POST_LOAD_COMPLETE, data: result.data, key, objectId: slug });
    } catch(error) {
      const err = error.response && error.response.data? error.response.data : error;
      dispatch({ type: POST_LOAD_ERR, error: err, key, objectId: slug });
    }
  };
}

