import React from 'react';
import { useNavigate } from 'react-router-dom';
import urls from '../../../config/urls';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

function Callout(props) {
  const { calloutText, calloutButton } = props; 
  const navigate = useNavigate();

  const nav = URL => () => {
    navigate(URL);
  }

  return (
    <div className="Magdala-Student-Access-Callout container">
      <div className="callout">
        <h3><span>Already registered for a course?</span> {calloutText}</h3>
        <div className="join-button">
          <Button variant="outlined" onClick={nav(urls.myTranscript)}>{calloutButton.title}</Button>
        </div>
      </div>
    </div>
  );
}

Callout.propTypes = {
  calloutText: PropTypes.string,
  calloutButton: PropTypes.object
}

export default Callout;