import { CNT_REGISTRATION_REQUEST, CNT_REGISTRATION_COMPLETE, CNT_REGISTRATION_ERR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function contactRegistrationReducer(state = initialState.contactRegistration, action) {
  const { type, error } = action;
  let responseCode, status = stateConfig.responseStatus;
  
  switch(type) {
    case CNT_REGISTRATION_REQUEST:
      return Object.assign({}, state, { responseStatus: status.DEFAULT, responseCode: null });
    case CNT_REGISTRATION_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.COMPLETE });
    case CNT_REGISTRATION_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default contactRegistrationReducer;