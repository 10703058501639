import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import config from '../../../../config/config';
import validator from '../../../../utils/validator';
import AudioControls from './AudioControls';
const { isEmpty, isNotEmpty } = validator;

function AudioEmbed({ audio, startTime, onProgress, actions, mediaJumpPoint }) {
  const [lastTime, setLastTime] = useState(0);
  const [checkpointData, setCheckpointData] = useState(null);
  const [currentAudioId, setCurrentAudioId] = useState(null);
  const [currentJumpPoint, setCurrentJumpPoint] = useState(-1);
  const [duration, setDuration] = useState(0);
  const audioEl = useRef(); 
  let disconnectPlayerEvents = config.emptyFn;

  useEffect(() => {
    return () => {
      disconnectPlayerEvents();
    };
  }, [disconnectPlayerEvents]);
  
  useEffect(() => {
    if(isNotEmpty(currentAudioId) && audio.id !== currentAudioId) {
      setCurrentAudioId(audio.id);
      disconnectPlayerEvents();
    }
  }, [audio.id, currentAudioId, disconnectPlayerEvents]);

  useEffect(() => {
    if(isEmpty(audioEl) || isEmpty(audioEl.current)) return;

    if(isNotEmpty(audioEl) && mediaJumpPoint > 0 && mediaJumpPoint !== currentJumpPoint) {
      setCurrentJumpPoint(mediaJumpPoint);
      audioEl.current.currentTime = mediaJumpPoint;
    }
  }, [audioEl, mediaJumpPoint, currentJumpPoint]);

  useEffect(() => {
    if(isEmpty(checkpointData)) return;

    const { id } = audio;
    const { time } = checkpointData;
    if(duration === 0 || Math.abs(time - lastTime) < 1) return;

    const percent = time / duration;
    onProgress({ id, type: 'audio', percent, time, duration });
    setLastTime(time);
  }, [checkpointData, audio, duration, lastTime, onProgress]);

  const audioLoaded = () => {
    const { id } = audio;
    const player = audioEl.current;
    onProgress({ id, type: 'audio', percent: 0, time: startTime || 0 });
    player.addEventListener('canplay', setStartTime);
    player.addEventListener('timeupdate', audioProgress);
    player.addEventListener('loadedmetadata', audioDuration);
  };

  const setStartTime = () => {
    if(isEmpty(audioEl) || isEmpty(audioEl.current)) return;

    const player = audioEl.current;
    if(startTime) {
      player.currentTime = startTime;
    }
    player.removeEventListener('canplay', setStartTime);
  };

  const audioDuration = () => {
    if(isEmpty(audioEl) || isEmpty(audioEl.current)) return;
    setDuration(audioEl.current.duration);
  };

  const audioProgress = () => {
    if(isEmpty(audioEl) || isEmpty(audioEl.current)) return;
    const time = parseInt(audioEl.current.currentTime);
    setCheckpointData({ time });
  };

  disconnectPlayerEvents = () => {
    const player = audioEl? audioEl.current : null;
    if(player) {
      player.removeEventListener('timeupdate', audioProgress);
      player.removeEventListener('loadedmetadata', audioDuration);
    }
  };

  return (
    <div className="audio">
      <AudioControls
        audio={audio}
        audioEl={audioEl}
        actions={actions}
        duration={duration}
      />
      <audio id={`classroomAudioPlayer-${audio.id}`}
        src={audio.url} 
        type="audio/mpeg" 
        ref={audioEl}
        onLoadStart={audioLoaded}>
      </audio>
    </div>
  );
}

AudioEmbed.defaultProps = {
  startTime: 0
};

AudioEmbed.propTypes = {
  audio: PropTypes.object,
  onProgress: PropTypes.func,
  actions: PropTypes.array,
};

function mapStateToProps(state) {
  return { 
    mediaJumpPoint: state.behavior.mediaJumpPoint 
  };
}

export default connect(mapStateToProps)(AudioEmbed);