import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appDisplayActions from '../../../actions/appDisplay';
import ExploreList from './ExploreList';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';


class ExploreMenu extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isOpen: false
    }

    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { exploreMenuOpen } = this.props;

    if(exploreMenuOpen !== prevProps.exploreMenuOpen &&
       exploreMenuOpen !== this.state.isOpen) {
      this.setState({ isOpen: exploreMenuOpen });
    }
  }

  onClose(event) {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    
    this.props.appDisplayActions.toggleExploreMenu();
    this.setState({ isOpen: false });
  }

  onOpen(event) {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div className="ExploreMenu">
        <SwipeableDrawer
          open={this.state.isOpen}
          onClose={this.onClose}
          onOpen={this.onOpen}
        >
          <ExploreList onClose={this.onClose} />
        </SwipeableDrawer>
      </div>
    );
  }
}

ExploreMenu.propTypes = {
  exploreMenuOpen: PropTypes.bool
};

function mapStateToProps(state) {
  const { exploreMenuOpen } = state.appDisplay;
  return { exploreMenuOpen };
}

function mapDispatchToProps(dispatch) {
  return { 
    appDisplayActions: bindActionCreators(appDisplayActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploreMenu);