import { SEARCH_REQUEST, SEARCH_COMPLETE, SEARCH_ERR, SEARCH_CLEAR } from '../actions/actionTypes';
import initialState from './initialState';
import stateConfig from '../config/state';

function searchReducer(state = initialState.search, action) {  
  const { type, data, error } = action;
  let responseCode, status = stateConfig.responseStatus;

  switch(type) {
    case SEARCH_REQUEST:
    case SEARCH_CLEAR:
      return Object.assign({}, initialState.search);
    case SEARCH_COMPLETE:
      return Object.assign({}, state, { responseStatus: status.COMPLETE, results: data.results });
    case SEARCH_ERR:
      ({ code: responseCode } = error);
      return Object.assign({}, state, { responseStatus: status.ERROR, responseCode });
    default:
      return state;
  }
}

export default searchReducer;